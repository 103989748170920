import { useCallback } from 'react';

import {
  type AssignAccountsToFeatureFlagMutationVariables,
  useAssignAccountsToFeatureFlagMutation,
} from '../../generated';

export const useAssignAccountsToFeatureFlag = () => {
  const [assignAccountsToFeatureFlag] = useAssignAccountsToFeatureFlagMutation({
    refetchQueries: ['Accounts', 'FeatureFlags'],
  });
  return useCallback(
    (
      featureFlagName: AssignAccountsToFeatureFlagMutationVariables['featureFlagName'],
      accountIds: AssignAccountsToFeatureFlagMutationVariables['accountIds']
    ) => {
      return assignAccountsToFeatureFlag({
        variables: {
          featureFlagName,
          accountIds,
        },
      });
    },
    [assignAccountsToFeatureFlag]
  );
};
