import { useMemo } from 'react';

import {
  type CurrentTemplateDefinitionByRenderTemplateQueryVariables,
  useCurrentTemplateDefinitionByRenderTemplateQuery,
} from '../../generated';

const DEFAULT_STATE = {
  textElements: {},
  logo: {},
  transition: {},
};

export const useCurrentTemplateDefinitionByRenderTemplate = (
  renderTemplateId: CurrentTemplateDefinitionByRenderTemplateQueryVariables['renderTemplateId']
) => {
  const { loading, error, data } = useCurrentTemplateDefinitionByRenderTemplateQuery({
    variables: { renderTemplateId },
  });

  const currentTemplateDefinitionByTemplate = data
    ? (data?.currentTemplateDefinitionByRenderTemplate?.definition ?? DEFAULT_STATE)
    : undefined;

  return useMemo(
    () => ({ currentTemplateDefinitionByTemplate, error, loading }),
    [currentTemplateDefinitionByTemplate, error, loading]
  );
};
