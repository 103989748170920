import type { ComponentProps, FC } from 'react';

import { MainNavigation, VisuallyHidden, withPopoverMenu } from '@cofenster/web-components';

import type { StaffUser } from '../../../contexts/staffUser/useStaffUser';

import { ProfilePopover } from './ProfilePopover';

export type ProfileButtonProps = Omit<ComponentProps<typeof MainNavigation.ProfileButton>, 'children' | 'name'> & {
  user: StaffUser;
};

export const ProfileButton: FC<ProfileButtonProps> = ({ user, ...rest }) => {
  const userName = user.firstname ?? user.lastname ?? '';

  const ProfileButton = withPopoverMenu(MainNavigation.ProfileButton, {
    children: <ProfilePopover user={user} />,
    anchorOriginX: 'left',
    anchorOriginY: 'top',
    transformOriginY: 'bottom',
  });

  return (
    <ProfileButton {...rest} name={userName}>
      <span aria-hidden="true">{userName}</span>
      <VisuallyHidden>Member menu</VisuallyHidden>
    </ProfileButton>
  );
};
