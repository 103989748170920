import { useMemo } from 'react';

import { type MusicQueryResult, type MusicQueryVariables, useMusicQuery } from '../../generated';

export type Music = NonNullable<MusicQueryResult['data']>['music'];

export const useMusic = (id: MusicQueryVariables['id']) => {
  const { loading, error, data } = useMusicQuery({ variables: { id } });
  const music = data?.music ?? undefined;
  return useMemo(() => ({ music, loading, error }), [music, loading, error]);
};
