import { type FC, useCallback, useRef, useState } from 'react';

import { styled, useSnackbars } from '@cofenster/web-components';

import type { TemplateFontAsset } from '../../../api/generated';
import { useImportRenderTemplate } from '../../../api/hooks/renderTemplate/useImportRenderTemplate';
import type { RenderTemplate } from '../../../api/hooks/renderTemplate/useRenderTemplate';
import { useGotoRenderTemplate } from '../../../hooks/useGotoRenderTemplate';

import { FontsList } from './FontsList';
import { Import } from './Import';

const MainContainer = styled('div')(() => ({
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useImport = () => {
  const importRenderTemplate = useImportRenderTemplate();
  return useCallback(
    async (values: string) => {
      try {
        const result = await importRenderTemplate(values);
        const newRenderTemplate = result.data?.importRenderTemplate;
        return newRenderTemplate;
      } catch (error) {
        if (
          typeof error === 'object' &&
          error !== null &&
          'graphQLErrors' in error &&
          Array.isArray(error.graphQLErrors)
        ) {
          throw new Error(error.graphQLErrors[0].message);
        }
        return;
      }
    },
    [importRenderTemplate]
  );
};

export const TemplateImportContent: FC = () => {
  const templateToImport = useRef<HTMLTextAreaElement>(null);
  const gotoTemplate = useGotoRenderTemplate();
  const [renderTemplateId, setTemplateId] = useState<string>('');
  const [fonts, setFonts] = useState<TemplateFontAsset[]>([]);

  const importTemplate = useImport();
  const { openSnackbar } = useSnackbars();

  const processTemplate = async () => {
    const isJsonString = (str: string) => {
      try {
        JSON.parse(str);
      } catch {
        return false;
      }
      return true;
    };
    if (templateToImport.current) {
      const template = templateToImport.current.value;
      const templateJson = JSON.parse(templateToImport.current.value) as RenderTemplate & {
        fonts: TemplateFontAsset[];
      };

      if (!isJsonString(template)) {
        openSnackbar({
          variant: 'error',
          children: 'Invalid JSON.',
        });

        return;
      }
      try {
        const newTemplate = await importTemplate(template);
        setTemplateId(newTemplate?.id ?? '');
        templateToImport.current.value = '';

        if (templateJson.fonts.length > 0) {
          setFonts(templateJson.fonts);
        } else {
          gotoTemplate(newTemplate?.id ?? '');
        }

        return;
      } catch {
        openSnackbar({
          variant: 'error',
          children: 'The template already exists.',
        });
      }
    }
  };

  return (
    <MainContainer>
      {renderTemplateId ? (
        <FontsList fonts={fonts} renderTemplateId={renderTemplateId} />
      ) : (
        <Import templateToImport={templateToImport} processTemplate={processTemplate} />
      )}
    </MainContainer>
  );
};
