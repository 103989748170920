import { Select, SelectOption, styled } from '@cofenster/web-components';

import { type EasingName, Easings } from '../Easings';

import type { Option, OptionEditor } from './Option';

const EasingEditor: OptionEditor<EasingName> = ({ value = 'Linear', onChange }) => {
  return (
    <Select name="easing" label="Easing" value={value} onChange={(event) => onChange(event.target.value as EasingName)}>
      {(Object.keys(Easings) as EasingName[]).map((name) => (
        <SelectOption key={name} value={name}>
          <StyledSvg viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}>
            <rect x={0} y={0} rx={2} ry={2} width={SVG_WIDTH} height={SVG_HEIGHT} fill="#CCC" />
            <path stroke="black" fill="none" d={easingToSvgPath(name)} />
          </StyledSvg>
          {name}
        </SelectOption>
      ))}
    </Select>
  );
};

const SVG_WIDTH = 24;
const SVG_HEIGHT = 24;

const StyledSvg = styled('svg')(({ theme }) => ({
  height: SVG_HEIGHT,
  overflow: 'visible',
  verticalAlign: 'middle',
  marginRight: theme.spacing(1),
}));

const cubicBezier = (x1: number, y1: number, x2: number, y2: number) => {
  return `M0 ${SVG_HEIGHT} C${x1 * SVG_WIDTH} ${(1 - y1) * SVG_HEIGHT} ${x2 * SVG_WIDTH} ${
    (1 - y2) * SVG_HEIGHT
  } ${SVG_WIDTH} 0`;
};

const CUBIC_BEZIER_PATTERN = /^cubic-bezier\((.+),(.+),(.+),(.+)\)$/;
const PARAMETERIZED_LINEAR_PATTERN = /^linear(.+)$/;

const easingToSvgPath = (easing: EasingName) => {
  const css = Easings[easing];

  if (!css) return '';
  if (css === 'ease-in') return cubicBezier(0.42, 0, 1, 1);
  if (css === 'ease-out') return cubicBezier(0, 0, 0.58, 1);
  if (css === 'ease-in-out') return cubicBezier(0.42, 0, 0.58, 1);

  if (CUBIC_BEZIER_PATTERN.test(css)) {
    const [_, x1, y1, x2, y2] = CUBIC_BEZIER_PATTERN.exec(css) ?? [];

    if (!x1 || !y1 || !x2 || !y2) {
      throw new Error(`Invalid cubic-bezier pattern found ‘${css}’`);
    }

    return cubicBezier(Number.parseFloat(x1), Number.parseFloat(y1), Number.parseFloat(x2), Number.parseFloat(y2));
  }

  if (PARAMETERIZED_LINEAR_PATTERN.test(css)) {
    // @TODO WHEN LINEAR_FUNCTION_SUPPORTED=true
    return `M0 ${SVG_HEIGHT} L${SVG_WIDTH} 0`;
  }

  // linear
  return `M0 ${SVG_HEIGHT} L${SVG_WIDTH} 0`;
};

export const EasingOption: Option<EasingName> = {
  Editor: EasingEditor,
  serialize: (value) => value,
  deserialize: (value) => value as EasingName,
};
