import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Card, LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { type Music as MusicType, useMusic } from '../../../api/hooks/music/useMusic';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { MusicForm } from '../../../components/musics/MusicForm';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useGotoMusics } from '../../../hooks/useGotoMusics';
import { NoAccessLayout } from '../../../pages/NoAccess';
import type { MusicRouteParams } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';

import { DeleteMusicButton } from './DeleteMusicButton';

export type Music = MusicType;

export const Music: FC = () => {
  const { musicId } = useParams() as MusicRouteParams;
  const { music, loading, error } = useMusic(musicId);
  const gotoMusics = useGotoMusics();
  const title = ['Music', music?.name].filter(Boolean).join(' — ');

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="MusicUpdate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={gotoMusics} />,
          topRight: music && (
            <PermissionRestriction has="MusicDelete">
              <DeleteMusicButton music={music} />
            </PermissionRestriction>
          ),
        }}
      >
        {music ? (
          <Card>
            <MusicForm music={music} />
          </Card>
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <ErrorContent />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
