import { useCallback } from 'react';

import { type CreateTemplateDefinitionMutationVariables, useCreateTemplateDefinitionMutation } from '../../generated';

export const useCreateTemplateDefinition = () => {
  const [createTemplateDefinition] = useCreateTemplateDefinitionMutation();

  return useCallback(
    (input: CreateTemplateDefinitionMutationVariables['input']) =>
      createTemplateDefinition({
        refetchQueries: ['CurrentTemplateDefinitionByTemplate'],
        variables: { input },
      }),
    [createTemplateDefinition]
  );
};
