import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { EmptyState, LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useTranscriptionByAudioAssetId } from '../../api/hooks/transcription/useTranscriptionByAudioAssetId';
import { useTranscriptionByVideoAssetId } from '../../api/hooks/transcription/useTranscriptionByVideoAssetId';
import { CodeBlock } from '../../components/display/CodeBlock';
import { AdminLayout } from '../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../contexts/staffUser/PermissionRestriction';
import type { TranscriptionReviewAssetRootParams } from '../../routes';
import { ErrorLayout } from '../Error';
import { NoAccessLayout } from '../NoAccess';

export const TranscriptionReviewAsset: FC = () => {
  const { assetId, type } = useParams() as TranscriptionReviewAssetRootParams;
  const title = 'Transcription Review';
  const useTranscription = type === 'video' ? useTranscriptionByVideoAssetId : useTranscriptionByAudioAssetId;
  const { loading, error, transcription } = useTranscription(assetId);

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="AssetRead" fallback={<NoAccessLayout documentTitle={title} />}>
      <AdminLayout header={{ topLeft: <PageHeadline mb={0} title={title} /> }} documentTitle={title}>
        {loading ? (
          <LoadingSpinner />
        ) : !transcription?.content ? (
          <EmptyState
            iconType="WarningIcon"
            title={`${type} asset not found`}
            description={`Could not find a ${type} asset with ID ${assetId}`}
          />
        ) : (
          <CodeBlock code={JSON.parse(transcription.content)} title={title} />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
