import { useCallback } from 'react';

import { type AssignFeatureFlagsMutationVariables, useAssignFeatureFlagsMutation } from '../../generated';

export const useAssignFeatureFlags = () => {
  const [assignFeatureFlags] = useAssignFeatureFlagsMutation({
    refetchQueries: ['Account'],
  });

  return useCallback(
    (accountId: AssignFeatureFlagsMutationVariables['id'], input: AssignFeatureFlagsMutationVariables['input']) =>
      assignFeatureFlags({ variables: { id: accountId, input } }),
    [assignFeatureFlags]
  );
};
