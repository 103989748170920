import { useField } from 'formik';
import { type FC, type MouseEventHandler, useCallback, useState } from 'react';

import { MimeTypes } from '@cofenster/constants';
import {
  AbsoluteDeleteIcon,
  AssetDropzone,
  type AssetDropzoneProps,
  Box,
  DropzoneContainer,
  DropzoneStatus,
  Typography,
  useUploadHint,
} from '@cofenster/web-components';

import { usePollingTemplateFontAsset } from '../../../../api/hooks/templateFontAsset/usePollingTemplateFontAsset';
import { useDialogs } from '../../../../contexts/dialogs/useDialogs';
import { useConfirmNavigationDuringUpload } from '../../../../hooks/useConfirmNavigationDuringUpload';

import { useFontAssetUpload } from './useFontAssetUpload';

type Props = {
  name: string;
  renderTemplateId: string;
  assetDropzoneProps?: Partial<AssetDropzoneProps>;
};

export const FontAssetUpload: FC<Props> = ({ name, renderTemplateId, assetDropzoneProps }) => {
  const [{ value: fontAssetId }, meta, { setTouched, setValue, setError }] = useField(name);
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const templateFontAsset = usePollingTemplateFontAsset(fontAssetId);
  const [fontUpload, progress] = useFontAssetUpload(renderTemplateId, templateFontAsset?.name ?? fileName ?? '');
  const { openDialog } = useDialogs();
  const hintText = useUploadHint('font');

  useConfirmNavigationDuringUpload(progress !== undefined);

  const uploadFile = useCallback(
    async (file: File) => {
      const fontAssetId = await fontUpload(file);
      setValue(fontAssetId);
      setFileName(file.name);
      setTouched(true);
    },
    [fontUpload, setValue, setTouched]
  );

  const deleteFile: MouseEventHandler = useCallback(
    () =>
      openDialog('ConfirmDialog', {
        title: 'Delete asset',
        content: 'Are you sure you want to permanently delete this asset?',
        confirm: 'Delete',
        variant: 'destructive',
        onConfirm: () => {
          setValue(undefined);
          setTouched(true);
        },
      }),
    [setValue, setTouched, openDialog]
  );

  const onError = useCallback(
    (error: string) => {
      setError(error);
      setTouched(true);
    },
    [setError, setTouched]
  );

  if (templateFontAsset?.fontUrl) {
    return (
      <DropzoneContainer size="medium">
        <Box fullHeight backgroundColor="grey50">
          <Typography>Your font was uploaded.</Typography>
          <AbsoluteDeleteIcon onClick={deleteFile} />
        </Box>
      </DropzoneContainer>
    );
  }

  if (progress !== undefined) {
    return (
      <DropzoneContainer size="medium">
        <DropzoneStatus
          status="uploading"
          text={fileName}
          hintText="Your font is uploading."
          progress={progress || 0}
        />
      </DropzoneContainer>
    );
  }

  return (
    <DropzoneContainer size="medium">
      <AssetDropzone
        error={meta.error}
        backgroundColor="grey50"
        mime={MimeTypes.font}
        maxSize={100 * 1024 * 1024}
        onFile={uploadFile}
        icon="CloudUploadIcon"
        text="Drop your font here or browse"
        hintText={hintText}
        onError={onError}
        {...assetDropzoneProps}
      />
    </DropzoneContainer>
  );
};
