import { type FC, type PropsWithChildren, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { type AppHeaderProps, EmptyState, PageHeadline, useGoto } from '@cofenster/web-components';

import { AdminLayout } from '../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../contexts/staffUser/PermissionRestriction';
import { type InspectRouteParams, routes } from '../../routes';

import { InspectSubNavigation } from './InspectSubNavigation';

export const InspectLayout: FC<PropsWithChildren<{ title: string; header?: AppHeaderProps }>> = ({
  children,
  title,
  header,
}) => {
  const { id } = useParams() as InspectRouteParams;
  const goto = useGoto();
  const goBack = useCallback(() => goto(routes.inspectIndex), [goto]);

  return (
    <AdminLayout
      documentTitle={title}
      header={{
        topLeft: <PageHeadline mb={0} title={title} onBack={goBack} />,
        bottomLeft: <InspectSubNavigation id={id} />,
        ...header,
      }}
    >
      <PermissionRestriction
        has="AssetRead"
        fallback={
          <EmptyState
            iconType="NoOptionIcon"
            title="Missing permissions"
            description="Your current role does not allow you to access assets (lacking permission “AssetRead”). If you need access, please reach out to #team-product on Slack to be granted temporary access or for someone to support with your query."
          />
        }
      >
        {children}
      </PermissionRestriction>
    </AdminLayout>
  );
};
