import type { FC } from 'react';

import { CSSGridTable, type CSSGridTableConfig, type ColumnConfig, Typography } from '@cofenster/web-components';

import type { FeatureFlag } from '../../../../api/hooks/featureFlag/useFeatureFlags';
import { ExpandedRouterLink } from '../../../../components/navigation/ExpandedRouterLink';

const HeaderCell: FC<{ column: ColumnConfig<FeatureFlag> }> = ({ column }) => (
  <Typography color="grey600" variant="h6" component="span">
    {column.name}
  </Typography>
);

const columns: CSSGridTableConfig<FeatureFlag>['columns'] = [
  {
    id: 'flagName',
    name: 'Name',
    cell: ({ item }) =>
      item.manageable ? (
        <ExpandedRouterLink to="featureFlag" params={{ name: item.name }}>
          <Typography variant="h6" color="carbon">
            {item.label ?? item.name}
          </Typography>
        </ExpandedRouterLink>
      ) : (
        <Typography variant="h6" color="grey700">
          {item.label ?? item.name}
        </Typography>
      ),
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },
  {
    id: 'id',
    name: 'ID',
    cell: ({ item }) => (
      <Typography variant="m" color="carbon">
        {item.name}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },

  { id: 'space', extra: { size: 'minmax(0, auto)' } },
  {
    id: 'behavior',
    name: 'Default',
    cell: ({ item }) => (
      <Typography variant="m" color="carbon">
        {item.enabledOnNewAccounts ? 'Enabled' : 'Disabled'}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'min-content' },
  },
  {
    id: 'accounts',
    name: 'Accounts',
    cell: ({ item }) => (
      <Typography variant="m" color="carbon">
        {item.accounts.length} {item.accounts.length === 1 ? 'account' : 'accounts'}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
];

export const FeatureFlagsList: FC<{ featureFlags: FeatureFlag[] }> = ({ featureFlags }) => {
  return <CSSGridTable data={featureFlags} columns={columns} />;
};
