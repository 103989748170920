import type { useBlocker } from 'react-router-dom';

import { useConfirmNavigation } from '@cofenster/web-components';

import { useConfirmDialog } from './useConfirmDialog';

export const useConfirmNavigationDuringUpload = (shouldBlock: Parameters<typeof useBlocker>[0]) => {
  const confirm = useConfirmDialog({
    title: 'Upload in progress',
    content:
      'A file is currently being uploaded. Navigating away will cause the upload to be aborted. Are you sure you want to proceed?',
    confirm: 'Navigate anyway',
    cancel: 'Stay here',
  });

  useConfirmNavigation(shouldBlock, confirm);
};
