import type { FC } from 'react';

import {
  CSSGridTable,
  type CSSGridTableConfig,
  Card,
  type ColumnConfig,
  Headline,
  Icon,
  LoadingSpinner,
  PageHeadline,
  Spacing,
  TextLink,
  Typography,
  VisuallyHidden,
  Wrappers,
  styled,
} from '@cofenster/web-components';

import { type Role, useRolesWithPermissions } from '../../../api/hooks/role/useRolesWithPermissions';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { RolesSubNavigation } from '../../../components/roles/RolesSubNavigation';
import { ErrorLayout } from '../../Error';

type Permission = Role['permissions'][number];
type RoleName = Role['name'];
type Column = {
  permission: Permission;
  roles: Record<RoleName, boolean>;
};

const HeaderCell = styled(Wrappers.HeaderCell)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.above,
  backgroundColor: theme.palette.brand.linen,
  padding: theme.spacing(1, 0),
}));

const HeaderCellInner: FC<{ column: ColumnConfig<Column> }> = ({ column }) => (
  <Typography color="grey600" variant="h6" component="span">
    {column.name}
  </Typography>
);

export const UserRoles: FC = () => {
  const title = 'Customer roles';
  const { roles, loading } = useRolesWithPermissions();
  const adminRole = roles.find((role) => role.name === 'Admin');

  if (!loading && !adminRole) {
    return <ErrorLayout title={title} />;
  }

  const roleNames = roles.map((role) => role.name);
  const permissions = adminRole?.permissions ?? [];
  const allPermissions = permissions.slice(0).sort((a, b) => a.localeCompare(b));
  const data = allPermissions.map((permission) => ({
    permission,
    roles: roles.reduce(
      (acc, role) => ({
        ...acc,
        [role.name]: role.permissions.includes(permission),
      }),
      {} as Column['roles']
    ),
  }));

  const columns: CSSGridTableConfig<Column>['columns'] = [
    {
      id: 'permission',
      name: 'Permission',
      cell: ({ item }) => (
        <Typography variant="m" component="code">
          {item.permission}
        </Typography>
      ),
      header: HeaderCellInner,
    },
    ...roleNames.map((name) => ({
      id: name,
      name: name,
      cell: ({ item }: { item: Column }) =>
        item.roles[name] ? (
          <>
            <Icon type="CheckIcon" color="positiveDark" />
            <VisuallyHidden>With permission</VisuallyHidden>
          </>
        ) : (
          <>
            <Icon type="CloseIcon" color="negativeDark" />
            <VisuallyHidden>Without permission</VisuallyHidden>
          </>
        ),
      header: HeaderCellInner,
    })),
  ];

  return (
    <AdminLayout
      documentTitle={title}
      header={{
        topLeft: <PageHeadline mb={0} title={title} />,
        bottomLeft: <RolesSubNavigation />,
      }}
    >
      <Spacing bottom={4}>
        <Card>
          <Spacing bottom={1}>
            <Headline variant="h5" component="h2">
              About roles & permissions
            </Headline>
          </Spacing>

          <Typography variant="l" component="p">
            The CoManager application uses a concept of <em>roles</em> and <em>permissions</em>. Each user is assigned a
            role (such as <em>Admin</em>, <em>Contributor</em> or <em>Translator</em>), which has a certain set of
            permissions (like creating projects, updating themes or inviting team members).
          </Typography>
          <Typography variant="l" component="p">
            You can find more information about the technical implementation in the{' '}
            <TextLink
              href="https://www.notion.so/cofenster/Roles-and-permissions-9bf7c179b5534cdb820c413060f82df3"
              target="_blank"
              rel="noopener noreferrer"
              underline
              variant="l"
            >
              Notion documentation
            </TextLink>
            .
          </Typography>
        </Card>
      </Spacing>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <CSSGridTable data={data} columns={columns} wrappers={{ ...Wrappers, HeaderCell }} />
      )}
    </AdminLayout>
  );
};
