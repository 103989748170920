import { useMemo } from 'react';

import {
  type DefaultRenderTemplateQueryResult,
  type DefaultRenderTemplateQueryVariables,
  useDefaultRenderTemplateQuery,
} from '../../generated';

export type RenderTemplate = NonNullable<DefaultRenderTemplateQueryResult['data']>['defaultRenderTemplate'];

export const useDefaultRenderTemplate = (accountId: DefaultRenderTemplateQueryVariables['id']) => {
  const { loading, error, data } = useDefaultRenderTemplateQuery({
    variables: { id: accountId },
  });
  const defaultRenderTemplate = data?.defaultRenderTemplate ?? undefined;

  return useMemo(() => ({ defaultRenderTemplate, loading, error }), [defaultRenderTemplate, loading, error]);
};
