import type { FC } from 'react';

import { Button } from '@cofenster/web-components';

import type { ExtendedRenderJob } from '../../contexts/exports';
import { useRestartJob } from '../../hooks/useRestartJob';

export const RestartButton: FC<ExtendedRenderJob> = (extendedRenderJob) => {
  const restartJob = useRestartJob(extendedRenderJob);

  return restartJob ? (
    <Button variant="destructive" type="button" onClick={restartJob}>
      {extendedRenderJob.restartMode === 'RETRY' ? 'Retry job' : 'Renew job'}
    </Button>
  ) : null;
};
