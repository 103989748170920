import { type FC, useCallback } from 'react';

import {
  CSSGridTable,
  type CSSGridTableConfig,
  type ColumnConfig,
  EmptyState,
  Icon,
  Tooltip,
  Typography,
  formatTime,
  useGoto,
} from '@cofenster/web-components';

import type { ProjectTemplate } from '../../../../api/hooks/projectTemplate/useCofensterProjectTemplates';
import { ExpandedRouterLink } from '../../../../components/navigation/ExpandedRouterLink';
import { PermissionRestriction, useHasPermission } from '../../../../contexts/staffUser/PermissionRestriction';
import { routes } from '../../../../routes';

const HeaderCell: FC<{ column: ColumnConfig<ProjectTemplate> }> = ({ column }) => (
  <Typography color="grey600" variant="h6" component="span">
    {column.name}
  </Typography>
);

const formatToIcon = {
  Horizontal: 'MonitorIcon',
  Vertical: 'MobileIcon',
  Square: 'SquareIcon',
  SocialMedia: 'SocialMediaIcon',
} as const;

const columns: CSSGridTableConfig<ProjectTemplate>['columns'] = [
  {
    id: 'videoFormat',
    name: 'Format',
    extra: { size: 'min-content' },
    cell: ({ item }) => (
      <Tooltip title={item.project.videoFormat}>
        <span>
          <Icon type={formatToIcon[item.project.videoFormat]} />
        </span>
      </Tooltip>
    ),
    header: HeaderCell,
  },
  {
    id: 'name',
    name: 'Name',
    cell: ({ item }) => (
      <PermissionRestriction
        has="StoryUpdate"
        fallback={
          <Typography variant="h6" color="carbon">
            {item.project.name}
          </Typography>
        }
      >
        <ExpandedRouterLink to="projectTemplate" params={{ projectTemplateId: item.id }}>
          <Typography variant="h6" color="carbon">
            {item.project.name}
          </Typography>
        </ExpandedRouterLink>
      </PermissionRestriction>
    ),
    header: HeaderCell,
  },
  {
    id: 'createdAt',
    name: 'Created at',
    cell: ({ item }) => (
      <Typography variant="m" component="time">
        {formatTime(item.project.createdAt)}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },
  {
    id: 'updatedAt',
    name: 'Updated at',
    cell: ({ item }) => (
      <Typography variant="m" component={item.project.updatedAt ? 'time' : 'span'}>
        {item.project.updatedAt ? formatTime(item.project.updatedAt) : 'n/a'}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },
];

export const ProjectTemplatesList: FC<{ projectTemplates: ProjectTemplate[] }> = ({ projectTemplates }) => {
  const goto = useGoto();
  const handleRowClick = useCallback(
    (item: ProjectTemplate) => goto(routes.projectTemplate, { projectTemplateId: item.id }),
    [goto]
  );
  const canUpdateProjectTemplates = useHasPermission({ has: 'StoryUpdate' });

  if (!projectTemplates.length) {
    return (
      <EmptyState
        iconType="WarningIcon"
        title="No project templates"
        description="There are no shared project templates"
      />
    );
  }

  return (
    <CSSGridTable<ProjectTemplate>
      columns={columns}
      data={projectTemplates}
      onRowClick={canUpdateProjectTemplates ? handleRowClick : undefined}
    />
  );
};
