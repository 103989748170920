import { useDebounceCallback } from '@react-hook/debounce';
import type { FC } from 'react';

import { BlankButton, type ColumnConfig, Icon, Typography, VisuallyHidden, styled } from '@cofenster/web-components';

import { type ExtendedRenderJob, type Order, useExportSettings } from '../../../../contexts/exports';

const Button = styled(BlankButton)(() => ({
  whiteSpace: 'nowrap',
}));

export const HeaderCell: FC<{ column: ColumnConfig<ExtendedRenderJob>; withOrdering?: boolean }> = ({
  column,
  withOrdering = true,
}) => {
  const { order, setOrder } = useExportSettings();
  const throttledSetOrder = useDebounceCallback(setOrder, 500, false);

  if (!withOrdering) {
    return (
      <Typography variant="h6" component="span" color="grey600">
        {column.name}
      </Typography>
    );
  }

  return (
    <Button
      type="button"
      onClick={() =>
        throttledSetOrder((order) => ({
          direction: order.property === column.id ? (order.direction === 'ASC' ? 'DESC' : 'ASC') : order.direction,
          property: column.id as Order['property'],
        }))
      }
    >
      <Typography variant="h6" component="span" color={order.property === column.id ? 'carbon' : 'grey600'}>
        <VisuallyHidden>Sort by</VisuallyHidden>
        {column.name}{' '}
        {order.property === column.id && (
          <>
            <Icon size="xs" type={order.direction === 'ASC' ? 'ArrowUpIcon' : 'ArrowDownIcon'} />
            <VisuallyHidden>(sorted {order.direction}; click to reverse)</VisuallyHidden>
          </>
        )}
      </Typography>
    </Button>
  );
};
