const STARTER_FLAGS = ['LONG_VIDEO_EXPORTS'];
const PROFESSIONAL_FLAGS = ['SCENE_OVERLAYS', 'STOCK_LIBRARY', 'BACKGROUND_NOISE_REMOVAL'];
const ENTREPRISE_FLAGS = ['SPECIAL_FORMATS', 'CONSENT', 'VIDEO_ANALYTICS', 'CUSTOM_MUSICS', 'MULTIPLE_TEAMS'];

export const PREMIUM_FLAGS = [...PROFESSIONAL_FLAGS, ...ENTREPRISE_FLAGS];
export const ENGINEERING_FLAGS = ['USE_REMOTION_NG', 'EMERGENCY_DISABLE_RENDER', 'AUTOTRANSCRIBE_UPLOADS'];

export const BUNDLES = {
  STARTER: STARTER_FLAGS,
  PROFESSIONAL: [...STARTER_FLAGS, ...PROFESSIONAL_FLAGS],
  ENTREPRISE: [...STARTER_FLAGS, ...PROFESSIONAL_FLAGS, ...ENTREPRISE_FLAGS],
};
