import type { FC, PropsWithChildren } from 'react';

import { Typography, styled } from '@cofenster/web-components';

export const DefinitionList = styled('dl')(() => ({ margin: 0 }));

const StyledDt = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  fontWeight: 'bold',
}));

export const DefinitionTerm: FC<PropsWithChildren> = ({ children }) => (
  <StyledDt component="dt" variant="l" color="carbon">
    {children}
  </StyledDt>
);

const StyledDd = styled(Typography)(({ theme }) => ({
  marginLeft: 0,
  marginBottom: theme.spacing(2),
}));

export const DefinitionContent: FC<PropsWithChildren> = ({ children, ...rest }) => (
  <StyledDd component="dd" variant="l" {...rest}>
    {children}
  </StyledDd>
);

export const Definition = styled('div')(() => ({
  '&[data-half]': {
    width: '50%',
    float: 'left',
  },
  '& + *:not([data-half])': {
    clear: 'left',
  },
}));
