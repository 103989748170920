import { useCallback, useMemo } from 'react';

import { type BackUpAccountMutationVariables, useBackUpAccountMutation } from '../../generated';

export const useBackUpAccount = (sourceAccountId: BackUpAccountMutationVariables['id']) => {
  const [backUpAccount, metadata] = useBackUpAccountMutation();

  const backUp = useCallback(
    (targetInput: BackUpAccountMutationVariables['input']) =>
      backUpAccount({ variables: { id: sourceAccountId, input: targetInput } }),
    [backUpAccount, sourceAccountId]
  );

  return useMemo(() => [backUp, metadata] as const, [backUp, metadata]);
};
