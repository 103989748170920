import { arrayMoveImmutable as arrayMove } from 'array-move';
import { type FC, type PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import {
  EmptyState,
  LightTable,
  LoadingSpinner,
  Responsive,
  SortableHandle,
  SortableTable,
  SortableTableRow,
  TableColumnName,
  Typography,
  useSortable,
} from '@cofenster/web-components';

import { useSortStories } from '../../../../api/hooks/story/useSortStories';
import { type Story, useStories } from '../../../../api/hooks/story/useStories';
import { ExpandedRouterLink } from '../../../../components/navigation/ExpandedRouterLink';
import { PermissionRestriction, useHasPermission } from '../../../../contexts/staffUser/PermissionRestriction';
import { ErrorContent } from '../../../Error';

const useSortableStories = () => {
  const { stories: apiStories, loading, error } = useStories();
  const sortStories = useSortStories();
  const [stories, setStories] = useState(apiStories || []);

  useEffect(() => {
    apiStories && setStories(apiStories);
  }, [apiStories]);

  const onSort = useCallback(
    async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const sortedStories = arrayMove(stories, oldIndex, newIndex);
      setStories(sortedStories);
      const data = sortedStories.map((story) => story.id);
      sortStories(data);
    },
    [stories, sortStories]
  );
  return useMemo(() => ({ stories, loading, error, onSort }) as const, [stories, loading, error, onSort]);
};

const StoryTableRowHeader = () => (
  <tr>
    <th>
      <TableColumnName>Name</TableColumnName>
    </th>
    <th>
      <TableColumnName>Categories</TableColumnName>
    </th>
    <th>
      <TableColumnName>Scenes</TableColumnName>
    </th>
    <th>
      <TableColumnName>Visibility</TableColumnName>
    </th>
  </tr>
);

const StoryRow: FC<PropsWithChildren<{ item: Story; disabled?: boolean }>> = ({ item, disabled }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
    disabled,
  });

  return (
    <SortableTableRow ref={setNodeRef} transform={transform} isDragging={isDragging} transition={transition}>
      <td data-label="Name">
        {!disabled && (
          <Responsive up="sm">
            <SortableHandle {...attributes} {...listeners} />
          </Responsive>
        )}
        <PermissionRestriction
          has="StoryUpdate"
          fallback={
            <Typography variant="h6" color="carbon">
              {item.nameEN}
            </Typography>
          }
        >
          <ExpandedRouterLink to="story" params={{ storyId: item.id }}>
            <Typography variant="h6" color="carbon">
              {item.nameEN}
            </Typography>
          </ExpandedRouterLink>
        </PermissionRestriction>
      </td>
      <td data-label="Categories">{item.categories.map((category) => category.nameEN).join(', ')}</td>
      <td data-label="Scenes">{item.scenes.length} scenes</td>
      <td data-label="Visibility">{item.private ? 'private' : 'public'}</td>
    </SortableTableRow>
  );
};

export const StoriesList: FC = () => {
  const { stories, loading, error, onSort } = useSortableStories();
  const canUpdateStories = useHasPermission({ has: 'StoryUpdate' });

  if (error) {
    return <ErrorContent />;
  }

  if (loading && !stories.length) {
    return <LoadingSpinner />;
  }

  if (!stories.length) {
    return <EmptyState iconType="NoOptionIcon" title="No stories" description="No stories were found." />;
  }

  return (
    <SortableTable
      disabled={!canUpdateStories}
      onSort={onSort}
      items={stories}
      components={{
        Table: LightTable,
        TableRowHeader: StoryTableRowHeader,
        TableRow: StoryRow,
      }}
    />
  );
};
