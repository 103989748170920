import { type ChangeEvent, useCallback, useId } from 'react';

import { StyledFormControl, Switch, styled } from '@cofenster/web-components';

import type { Option, OptionEditor } from './Option';

const FormControl = styled(StyledFormControl)(({ theme }) => ({
  justifyContent: 'center',
  paddingLeft: theme.spacing(1),
}));

const OpacityEditor: OptionEditor<boolean> = ({ value = true, onChange }) => {
  const id = useId();
  const internalOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked),
    [onChange]
  );

  return (
    <FormControl fullWidth>
      <Switch id={id} onChange={internalOnChange} checked={value}>
        Opacity
      </Switch>
    </FormControl>
  );
};

export const OpacityOption: Option<boolean> = {
  Editor: OpacityEditor,
  serialize: (value) => String(value),
  deserialize: (value) => value === 'true',
};
