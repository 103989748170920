import { useMemo } from 'react';

import {
  type RenderTemplatesQueryResult,
  type RenderTemplatesQueryVariables,
  useRenderTemplatesQuery,
} from '../../generated';

export type RenderTemplate = NonNullable<RenderTemplatesQueryResult['data']>['renderTemplates']['items'][number];
export type Filter = NonNullable<RenderTemplatesQueryVariables['filter']>;
export type PaginatedTemplates = NonNullable<RenderTemplatesQueryResult['data']>['renderTemplates'];

export const useRenderTemplates = (filter?: Filter) => {
  const { loading, error, data } = useRenderTemplatesQuery({
    variables: { filter },
  });
  const renderTemplates = data?.renderTemplates;
  return useMemo(
    () => ({
      paginatedRenderTemplates: renderTemplates,
      loading,
      error,
    }),
    [renderTemplates, loading, error]
  );
};
