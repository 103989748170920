import { useMemo } from 'react';

import { useStopPollingWhenUnauthenticated } from '../../../hooks/useStopPollingWhenUnauthenticated';
import { type ExtendedRenderJobQueryResult, useExtendedRenderJobQuery } from '../../generated';

export type ExtendedRenderJob = NonNullable<NonNullable<ExtendedRenderJobQueryResult['data']>['extendedRenderJob']>;
export type RenderJob = ExtendedRenderJob['job'];
export type Project = NonNullable<ExtendedRenderJob['project']>;

export const useExtendedRenderJob = (id?: string) => {
  const { loading, error, data, startPolling, stopPolling } = useExtendedRenderJobQuery({
    variables: { id: id ?? '' },
    skip: !id,
    skipPollAttempt: () => document.hidden,
  });
  const extendedRenderJob = data?.extendedRenderJob;

  useStopPollingWhenUnauthenticated(stopPolling, error);

  return useMemo(
    () => ({ extendedRenderJob, loading, error, startPolling, stopPolling }),
    [extendedRenderJob, loading, error, startPolling, stopPolling]
  );
};
