import type { FC } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { GridContainer, GridItem, Typography } from '@cofenster/web-components';

import { CSSInputComponent } from '../CSSInputComponent';
import { fromCssProperties, toCssProperties, useStaticStyleState } from '../utils';

import { AnimationsInput } from './AnimationsInput';
import type { StylableJsonPartInputProps } from './CSSInput';

type ResponsiveInputProps = StylableJsonPartInputProps & {
  videoFormat: VideoFormat;
  path?: string;
  noAnimations?: boolean;
};

export const ResponsiveInput: FC<ResponsiveInputProps> = ({
  state,
  setState,
  videoFormat,
  noAnimations,
  rows = 10,
}) => {
  const title = `Stylesheet (${videoFormat})`;

  const [staticStyle, setStaticStyle] = useStaticStyleState([state, setState]);

  return (
    <GridContainer direction="column">
      <GridItem>
        <Typography variant="h5">{title}</Typography>
      </GridItem>
      <GridItem>
        <CSSInputComponent
          value={toCssProperties(staticStyle)}
          onChange={(value) => setStaticStyle(fromCssProperties(value))}
          rows={rows}
        />
      </GridItem>
      {!noAnimations && (
        <GridItem>
          <AnimationsInput state={state} setState={setState} />
        </GridItem>
      )}
    </GridContainer>
  );
};
