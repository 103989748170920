import { useContext } from 'react';

import { StaffUserContext } from '../staffUser/StaffUserContext';

export type * from '../staffUser/StaffUserContext';

export const useStaffUser = (): StaffUserContext => {
  const staffUserContext = useContext(StaffUserContext);

  if (!staffUserContext) throw new Error('Missing StaffUserProvider in tree');

  return staffUserContext;
};
