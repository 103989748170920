import { type FC, useRef } from 'react';
import { RouterProvider } from 'react-router-dom';

import { type ApolloClient, ApolloProvider, createApiClient } from '@cofenster/api-client-apollo';
import { ADMIN_API_URL } from '@cofenster/constants';
import { SnackbarsProvider, ThemeProvider, UploadProvider, useAxe, useVersionLog } from '@cofenster/web-components';

import pkg from '../package.json';

import { router } from './Router';
import { ErrorBoundary } from './components/ErrorBoundary';
import { DialogsProvider } from './contexts/dialogs';
import { StaffUserProvider } from './contexts/staffUser/StaffUserProvider';
import { usePublicAssetUpload } from './hooks/usePublicAssetUpload';
import { I18nProvider } from './i18n';

const STAGE = process.env.STAGE as string;

export const App: FC = () => {
  const client = useRef<ApolloClient<unknown>>();

  if (!client.current) {
    client.current = createApiClient({
      // Locally, the backend admin API is proxied through the /graphql route
      // via the webpack dev server configuration
      url: process.env.STAGE === 'local' ? '/graphql' : ADMIN_API_URL,
    });
  }

  useVersionLog(pkg);
  useAxe(STAGE);

  return (
    <ThemeProvider>
      <ApolloProvider client={client.current}>
        <StaffUserProvider>
          <I18nProvider>
            <ErrorBoundary>
              <UploadProvider clearUploadOnFinish useUpload={usePublicAssetUpload}>
                <SnackbarsProvider>
                  <DialogsProvider>
                    <RouterProvider router={router} />
                  </DialogsProvider>
                </SnackbarsProvider>
              </UploadProvider>
            </ErrorBoundary>
          </I18nProvider>
        </StaffUserProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
};
