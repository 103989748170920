import type { AnimatedProperties } from '../../remotion';

import type { Option, OptionValue } from './Options';
import { type PresetOptionsEditor, createPresetOptionsEditor } from './PresetOptionsEditor';

export type PresetOptions = { [name: string]: OptionValue };
type AnimationFactory<O extends PresetOptions> = (options: O) => AnimatedProperties;

export type Preset<O extends PresetOptions> = {
  name: string;
  apply: AnimationFactory<O>;
  Editor: PresetOptionsEditor<O>;
  serialize: (options: O) => string;
  deserialize: (serialized: string) => O | null;
  defaults: O;
};

export const createPreset = <O extends PresetOptions>(
  name: string,
  factory: AnimationFactory<O>,
  options: { [K in keyof O]: Option<O[K]> },
  defaults: O
): Preset<O> => {
  return {
    name,
    apply: (options) => factory({ ...defaults, ...options }),
    Editor: createPresetOptionsEditor<O>(options),
    defaults: Object.seal(defaults),
    serialize: (value) => {
      return `${name} ${Object.keys(options)
        .map((name: keyof O) => options[name].serialize(value[name] ?? defaults[name]))
        .join(' ')}`;
    },
    deserialize: (serialized) => {
      if (!serialized.startsWith(name)) return null;
      const [, ...serializedOptions] = serialized.split(/\s/);
      try {
        const value = Object.fromEntries(
          Object.keys(options).map((name: keyof O, index) => {
            try {
              const value = options[name].deserialize(serializedOptions[index] ?? '') ?? defaults[name];
              return [name, value] as const;
            } catch (error) {
              console.info(`Failed to deserialize preset ${String(name)} from "${serialized}"`, error);
              return [name, defaults[name]];
            }
          })
        );
        return value as unknown as O;
      } catch (error) {
        console.warn(`Failed to deserialize preset ${name} from "${serialized}"`, error);
        return null;
      }
    },
  };
};
