import { useDebounceCallback } from '@react-hook/debounce';
import { type FC, useCallback } from 'react';

import { Select, type SelectChangeEvent, SelectOption } from '@cofenster/web-components';

import { useExportSettings } from '../../../../contexts/exports';

export const PageSizeSelect: FC = () => {
  const { pageSize, setPageSize } = useExportSettings();
  const throttledSetPageSize = useDebounceCallback(setPageSize, 500, false);

  const handlePageSizeChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as typeof pageSize;
      throttledSetPageSize(value);
    },
    [throttledSetPageSize]
  );

  return (
    <Select
      pb={0}
      label="Page size"
      id="pageSize"
      name="pageSize"
      value={pageSize}
      onChange={handlePageSizeChange}
      data-testid="page-size-select"
    >
      {[25, 50, 100, 200].map((pageSize) => (
        <SelectOption key={pageSize} value={pageSize}>
          {pageSize}
        </SelectOption>
      ))}
    </Select>
  );
};
