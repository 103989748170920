import { useCallback } from 'react';

import { type UpdateUserMutationVariables, useUpdateUserMutation } from '../../generated';
export const useUpdateUser = () => {
  const [updateUser] = useUpdateUserMutation({ refetchQueries: ['User'] });

  return useCallback(
    (userId: UpdateUserMutationVariables['id'], input: UpdateUserMutationVariables['input']) =>
      updateUser({ variables: { id: userId, input } }),
    [updateUser]
  );
};
