import { type ChangeEventHandler, type FC, useCallback, useMemo } from 'react';

import {
  Icon,
  IconButton,
  type IconType,
  PopoverMenuItem,
  TextField,
  withPopoverMenu,
} from '@cofenster/web-components';

import { isNegative, parseIntEnsure, serializeInt } from '../utils';

import type { Option, OptionEditor } from './Option';

export type Timing = { startTime: number; duration: number; fillMode: Exclude<FillMode, 'auto'> };

export const DEFAULT_TIMING_IN: Timing = {
  startTime: 0,
  duration: 1000,
  fillMode: 'backwards',
};

export const DEFAULT_TIMING_OUT: Timing = {
  startTime: -1000,
  duration: 1000,
  fillMode: 'forwards',
};

const IconSelect = <T extends string>({
  value,
  onChange,
  options,
}: {
  value: T;
  onChange: (value: T) => void;
  options: Record<T, { label: string; icon: IconType }>;
}): ReturnType<FC> => {
  const { label, icon } = useMemo(() => options[value], [value, options]);

  const IconButtonWithMenu = withPopoverMenu(IconButton, {
    children: (
      <>
        {(Object.entries(options) as [T, { label: string; icon: IconType }][]).map(([value, { label, icon }]) => (
          <PopoverMenuItem key={value} onClick={() => onChange(value as T)}>
            <Icon type={icon} /> {label}
          </PopoverMenuItem>
        ))}
      </>
    ),
  });

  return <IconButtonWithMenu label={label} icon={icon} />;
};

const fillModeToIconNameAndLabel = {
  backwards: {
    icon: 'ArrowLeftIcon',
    label: 'Animation will apply initial values before starting',
  },
  forwards: {
    icon: 'ArrowRightIcon',
    label: 'Animation will keep final values after ending',
  },
  both: {
    icon: 'ArrowsOutLineHorizontalIcon',
    label: 'Animation will apply initial values before starting and keep final values after ending',
  },
  none: {
    icon: 'ClockIcon',
    label: 'Animation will apply values only during runtime',
  },
} as const;

const FillModeCompactSelect: OptionEditor<Timing['fillMode']> = ({ value, onChange }) => {
  return <IconSelect value={value} onChange={onChange} options={fillModeToIconNameAndLabel} />;
};

const negativeAnchorToIconAndLabel = {
  true: {
    icon: 'ArrowLeftIcon',
    label: 'Counted from the end',
  },
  false: {
    icon: 'ArrowRightIcon',
    label: 'Counted from the start',
  },
} as const;

const NegativeAnchorCompactSelect: OptionEditor<boolean> = ({ value, onChange }) => {
  return (
    <IconSelect
      value={String(value)}
      onChange={(value) => onChange(value === String(true))}
      options={negativeAnchorToIconAndLabel}
    />
  );
};

const TimingEditor: OptionEditor<Timing> = ({ value = DEFAULT_TIMING_IN, onChange }) => {
  const [startTime, negative] = useMemo(() => {
    const startTime = Math.abs(value.startTime);
    const negative = isNegative(value.startTime);
    return [startTime, negative];
  }, [value]);

  const { duration, fillMode } = value;

  const onChangeWithValidation = useCallback(
    ({ startTime, duration, fillMode }: Timing) => {
      if (isNegative(startTime)) {
        onChange({ startTime: Math.min(startTime, -duration), duration, fillMode });
      } else {
        onChange({ startTime, duration, fillMode });
      }
    },
    [onChange]
  );

  const onStartTimeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    try {
      const signedStartTime = (negative ? -1 : 1) * Math.abs(parseIntEnsure(event.target.value));
      onChangeWithValidation({ startTime: signedStartTime, duration, fillMode });
    } catch {
      // ignore because value is wrong
    }
  };

  const onNegativeAnchorChange = (negative: boolean) => {
    const signedStartTime = (negative ? -1 : 1) * startTime;
    onChangeWithValidation({ startTime: signedStartTime, duration, fillMode });
  };

  const onDurationChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    try {
      const duration = parseIntEnsure(event.target.value);
      const signedStartTime = (negative ? -1 : 1) * startTime;
      onChangeWithValidation({ startTime: signedStartTime, duration, fillMode });
    } catch {
      // ignore because value is wrong
    }
  };

  const onFillModeChange = (fillMode: Exclude<FillMode, 'auto'>) => {
    const signedStartTime = (negative ? -1 : 1) * startTime;
    onChangeWithValidation({ startTime: signedStartTime, duration, fillMode });
  };

  return (
    <>
      <TextField
        type="number"
        label="Start time"
        value={startTime}
        fullWidth
        min={0}
        step={100}
        onChange={onStartTimeChange}
        InputProps={{
          endAdornment: 'ms',
          startAdornment: <NegativeAnchorCompactSelect value={negative} onChange={onNegativeAnchorChange} />,
        }}
      />
      <TextField
        type="number"
        label="Duration"
        fullWidth
        value={duration}
        min={0}
        step={100}
        onChange={onDurationChange}
        InputProps={{
          endAdornment: 'ms',
          startAdornment: <FillModeCompactSelect value={fillMode} onChange={onFillModeChange} />,
        }}
      />
    </>
  );
};
export const TimingOption: Option<Timing> = {
  Editor: TimingEditor,
  serialize: (t) => `${serializeInt(t.startTime)}|${serializeInt(t.duration)}|${t.fillMode}`,
  deserialize: (s) => {
    const [startTime, duration, fillMode] = s.split('|');

    if (!startTime) throw new Error(`No start time found in value ‘${s}’`);
    if (!duration) throw new Error(`No duration found in value ‘${s}’`);
    if (!fillMode) throw new Error(`No fill mode found in value ‘${s}’`);

    return {
      startTime: parseIntEnsure(startTime),
      duration: parseIntEnsure(duration),
      fillMode: fillMode as Timing['fillMode'],
    };
  },
};
