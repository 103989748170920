import { useCallback } from 'react';

import { type UpdateAccountMutationVariables, useUpdateAccountMutation } from '../../generated';

export const useUpdateAccount = () => {
  const [updateAccount] = useUpdateAccountMutation();
  return useCallback(
    (accountId: UpdateAccountMutationVariables['id'], input: UpdateAccountMutationVariables['input']) => {
      return updateAccount({
        variables: {
          id: accountId,
          input,
        },
        refetchQueries: ['Accounts', 'Account', 'DefaultRenderTemplate'],
      });
    },
    [updateAccount]
  );
};
