import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useRenderTemplate } from '../../../api/hooks/renderTemplate/useRenderTemplate';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { TemplateSubNavigation } from '../../../components/templates/TemplateSubNavigation';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useGotoRenderTemplates } from '../../../hooks/useGotoRenderTemplates';
import type { TemplateRouteParams } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';
import { NoAccessLayout } from '../../NoAccess';

import { TemplateExportContent } from './TemplateExportContent';

export const TemplateExport: FC = () => {
  const { renderTemplateId } = useParams() as TemplateRouteParams;
  const { renderTemplate, loading, error } = useRenderTemplate(renderTemplateId);
  const gotoTemplates = useGotoRenderTemplates();
  const title = ['Export', renderTemplate?.name].filter(Boolean).join(' — ');

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="TemplateRead" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={gotoTemplates} />,
          bottomLeft: <TemplateSubNavigation renderTemplateId={renderTemplateId} />,
        }}
      >
        {renderTemplate ? <TemplateExportContent /> : loading ? <LoadingSpinner /> : <ErrorContent />}
      </AdminLayout>
    </PermissionRestriction>
  );
};
