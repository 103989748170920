import { useMemo } from 'react';

import {
  type RenderTemplateLatestProjectsQueryResult,
  type RenderTemplateLatestProjectsQueryVariables,
  useRenderTemplateLatestProjectsQuery,
} from '../../generated';

export type TemplateLatestProject = NonNullable<
  RenderTemplateLatestProjectsQueryResult['data']
>['renderTemplateLatestProjects'][number];

export const useRenderTemplateLatestProjects = (renderTemplateId: RenderTemplateLatestProjectsQueryVariables['id']) => {
  const { loading, error, data } = useRenderTemplateLatestProjectsQuery({ variables: { id: renderTemplateId } });
  const latestProjects = data?.renderTemplateLatestProjects ?? [];

  return useMemo(() => ({ latestProjects, loading, error }), [latestProjects, loading, error]);
};
