import { useCallback } from 'react';

import { type SortTextInputsMutationVariables, useSortTextInputsMutation } from '../../generated';

export const useSortTextInputs = () => {
  const [sortTextInputs] = useSortTextInputsMutation();
  return useCallback(
    (ids: SortTextInputsMutationVariables['ids']) => sortTextInputs({ variables: { ids } }),
    [sortTextInputs]
  );
};
