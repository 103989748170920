import { type EasingName, Easings, getOppositeEasing } from './Easings';
import {
  type Direction,
  DirectionOption,
  type Distance,
  DistanceOption,
  EasingOption,
  MotionBlurOption,
  NO_MOTION_BLUR,
  OpacityOption,
  toCssStringValue,
} from './Options';
import { DEFAULT_TIMING_IN, TimingOption } from './Options/Timing';
import { createPreset } from './Preset';
import { createTimeline } from './utils';

export const SlideIn = createPreset(
  'SlideIn',
  ({ timing, easing, direction, opacity, motionBlur, distance }) => {
    const valueAsString = toCssStringValue(distance, direction === 'Up' || direction === 'Down');

    const leftDirection = direction === 'Left';
    const rightDirection = direction === 'Right';
    const upDirection = direction === 'Up';
    const downDirection = direction === 'Down';

    const transformFunction = leftDirection || rightDirection ? 'translateX' : 'translateY';
    // calc cannot contain whitespaces because of how the `createPreset` function works
    const transformOffset = leftDirection || upDirection ? `calc(-1*${valueAsString})` : valueAsString;

    const blurHorizontal = leftDirection ? '-8px' : rightDirection ? '8px' : '0px';
    const blurVertical = upDirection ? '8px' : downDirection ? '-8px' : '0px';
    const blurOffset = '4px';
    const blurFunction = `drop-shadow(${blurHorizontal} ${blurVertical} 0px ${motionBlur}) blur(${blurOffset})`;

    return {
      ...(opacity && {
        opacity: createTimeline(timing, {
          0: { v: 0, e: Easings[easing] },
          1: { v: 1 },
        }),
      }),
      transform: createTimeline(timing, {
        0: { v: `${transformFunction}(${transformOffset})`, e: Easings[easing] },
        1: { v: 'none' },
      }),
      ...(motionBlur !== NO_MOTION_BLUR && {
        filter: createTimeline(timing, {
          0: { v: `${blurFunction}`, e: Easings[easing] },
          0.5: { v: `${blurFunction}`, e: Easings[getOppositeEasing(easing)] },
          1: { v: 'none' },
        }),
      }),
    };
  },
  {
    timing: TimingOption,
    easing: EasingOption,
    direction: DirectionOption,
    opacity: OpacityOption,
    distance: DistanceOption,
    motionBlur: MotionBlurOption,
  },
  {
    timing: DEFAULT_TIMING_IN,
    easing: 'EaseOut' as EasingName,
    direction: 'Left' as Direction,
    opacity: true as boolean,
    distance: '540|Pixel' as Distance,
    motionBlur: NO_MOTION_BLUR,
  }
);
