import { useCallback } from 'react';

import { type UpdateMusicMutationVariables, useUpdateMusicMutation } from '../../generated';

export const useUpdateMusic = () => {
  const [updateMusic] = useUpdateMusicMutation({ refetchQueries: ['Music'] });
  return useCallback(
    (musicId: UpdateMusicMutationVariables['id'], input: UpdateMusicMutationVariables['input']) => {
      return updateMusic({
        variables: {
          id: musicId,
          input,
        },
      });
    },
    [updateMusic]
  );
};
