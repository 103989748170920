import type { CSSProperties } from 'react';

import {
  type AnimatedProperties,
  type AnimatedProperty,
  type AnimatedValue,
  type CSSProperty,
  type StaticStyle,
  type Timeline,
  asTimelines,
  isAllowedProperty,
} from '../remotion';

export const fromCssProperties = (css: CSSProperties): StaticStyle => {
  return Object.fromEntries(Object.entries(css).filter(([property]) => isAllowedProperty(property as CSSProperty)));
};

export const toCssProperties = (style: StaticStyle): CSSProperties => {
  return style as CSSProperties;
};

export const joinAnimatedProperties = (
  prev: AnimatedProperties = {},
  next: AnimatedProperties = {}
): AnimatedProperties => {
  const commonProperties = (Object.keys(prev) as AnimatedProperty[]).filter((key) => key in next);

  return {
    ...prev,
    ...next,
    ...Object.fromEntries(
      commonProperties
        .filter((property) => prev[property])
        .filter((property) => next[property])
        .map((property) => {
          const prevValue = prev[property] as AnimatedValue;
          const nextValue = next[property] as AnimatedValue;

          return [property, [...asTimelines(prevValue), ...asTimelines(nextValue)].filter(Boolean)];
        })
    ),
  };
};

const mapAnimatedProperties = (
  animatedProperties: AnimatedProperties,
  transform: (value: AnimatedValue, property: AnimatedProperty) => AnimatedValue
) => {
  return Object.fromEntries(
    (Object.entries(animatedProperties) as [AnimatedProperty, AnimatedValue][]).map(([property, value]) => [
      property,
      value ? transform(value, property) : value,
    ])
  );
};

export const mapAnimationsInAnimatedProperties = (
  animatedProperties: AnimatedProperties,
  transform: (value: Timeline, property: AnimatedProperty) => Timeline
) => {
  return mapAnimatedProperties(animatedProperties, (value, property) => {
    return Array.isArray(value) ? value.map((timeline) => transform(timeline, property)) : transform(value, property);
  });
};

export const filterAnimationsInAnimatedProperties = (
  animatedProperties: AnimatedProperties,
  predicate: (value: Timeline, property: AnimatedProperty) => boolean
) => {
  return mapAnimatedProperties(animatedProperties, (value, property) => {
    return Array.isArray(value)
      ? value.filter((timeline) => predicate(timeline, property))
      : predicate(value, property)
        ? value
        : [];
  });
};
