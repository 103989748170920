import { useMemo } from 'react';

import {
  type TemplateFontAssetsQueryResult,
  type TemplateFontAssetsQueryVariables,
  useTemplateFontAssetsQuery,
} from '../../generated';

export type TemplateFontAsset = NonNullable<TemplateFontAssetsQueryResult['data']>['templateFontAssets'][0];

export const useTemplateFontAssets = (renderTemplateId: TemplateFontAssetsQueryVariables['renderTemplateId']) => {
  const { loading, error, data } = useTemplateFontAssetsQuery({
    variables: { renderTemplateId },
    fetchPolicy: 'cache-and-network',
  });
  const templateFontAssets = data?.templateFontAssets ?? [];
  return useMemo(
    () => ({
      templateFontAssets,
      loading,
      error,
    }),
    [templateFontAssets, loading, error]
  );
};
