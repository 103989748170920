import type { FC } from 'react';

import { LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useFeatureFlags } from '../../../api/hooks/featureFlag/useFeatureFlags';
import { AdminLayout } from '../../../components/layout/AdminLayout';

import { ErrorContent, ErrorLayout } from '../../Error';
import { FeatureFlagsList } from './FeatureFlagsList';

export const FeatureFlags: FC = () => {
  const title = 'Feature Flags';
  const { featureFlags, loading, error } = useFeatureFlags();

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <AdminLayout
      documentTitle={title}
      header={{
        topLeft: <PageHeadline mb={0} title={title} />,
      }}
    >
      {featureFlags ? (
        <FeatureFlagsList featureFlags={featureFlags} />
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <ErrorContent />
      )}
    </AdminLayout>
  );
};
