import { useCallback, useMemo } from 'react';

import { type ToggleAbilityToRenderMutationVariables, useToggleAbilityToRenderMutation } from '../../generated';

export const useToggleAbilityToRender = () => {
  const [toggleAbilityToRender, metadata] = useToggleAbilityToRenderMutation({
    refetchQueries: ['AbilityToRender'],
  });
  const toggle = useCallback(
    (enabled: ToggleAbilityToRenderMutationVariables['enabled']) => toggleAbilityToRender({ variables: { enabled } }),
    [toggleAbilityToRender]
  );

  return useMemo(() => [toggle, metadata] as const, [toggle, metadata]);
};
