import { useMemo } from 'react';

import { type AccountQueryResult, type AccountQueryVariables, useAccountQuery } from '../../generated';

export type Account = NonNullable<AccountQueryResult['data']>['account'];
export type User = Account['users'][number];
export type FeatureFlag = Account['featureFlags'][number];
export type AuthProvider = Account['authProvider'];

export const useAccount = (accountId?: AccountQueryVariables['id']) => {
  const { loading, error, data } = useAccountQuery({
    variables: { id: accountId ?? '' },
    fetchPolicy: 'cache-and-network',
    skip: !accountId,
  });
  const account = data?.account ?? undefined;
  return useMemo(() => ({ account, loading, error }), [account, loading, error]);
};
