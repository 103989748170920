import { useCallback } from 'react';

import { useSignoutMutation } from '../../generated';

export const useSignout = () => {
  const [signout] = useSignoutMutation({ refetchQueries: ['Me'] });
  return useCallback(async () => {
    const result = await signout();
    return result?.data?.signout ?? false;
  }, [signout]);
};
