import { Navigate, Outlet, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { ErrorBoundary } from './components/ErrorBoundary';
import { ImpersonationBadge } from './components/layout/ImpersonationBadge';
import { EnsureStaffUser } from './contexts/staffUser/EnsureStaffUser';
import { AccountAuthProvider } from './pages/accounts/Account/AccountAuthProvider';
import { AccountBaseData } from './pages/accounts/Account/AccountBaseData';
import { AccountFlags } from './pages/accounts/Account/AccountFlags';
import { AccountStories } from './pages/accounts/Account/AccountStories';
import { AccountTemplates } from './pages/accounts/Account/AccountTemplates';
import { AccountUsers } from './pages/accounts/Account/AccountUsers';
import { AccountCreate } from './pages/accounts/AccountCreate';
import { Accounts } from './pages/accounts/Accounts';
import { Export } from './pages/exports/Export';
import { ExportPreview } from './pages/exports/ExportPreview';
import { ExportRenderDescription } from './pages/exports/ExportRenderDescription';
import { Exports } from './pages/exports/Exports';
import { FeatureFlag } from './pages/featureFlags/FeatureFlag';
import { FeatureFlags } from './pages/featureFlags/FeatureFlags';
import { InspectIndex } from './pages/inspect/InspectIndex';
import { InspectVideoAsset } from './pages/inspect/InspectVideoAsset';
import { InspectVideoAssetOriginal } from './pages/inspect/InspectVideoAssetOriginal';
import { SignInError } from './pages/login/SignInError';
import { SignOut } from './pages/login/SignOut';
import { Music } from './pages/musics/Music';
import { MusicCreate } from './pages/musics/MusicCreate';
import { Musics } from './pages/musics/Musics';
import { ProjectTemplate } from './pages/projectTemplates/ProjectTemplate';
import { ProjectTemplates } from './pages/projectTemplates/ProjectTemplates';
import { Project } from './pages/projects/Project';
import { StaffUserRoles } from './pages/roles/StaffUserRoles';
import { UserRoles } from './pages/roles/UserRoles';
import { StaffUserCreate } from './pages/staffUsers/StaffUserCreate';
import { StaffUserUpdate } from './pages/staffUsers/StaffUserUpdate';
import { StaffUsersList } from './pages/staffUsers/StaffUsersList';
import { Stories } from './pages/stories/Stories';
import { Story } from './pages/stories/Story';
import { StoryAccounts } from './pages/stories/StoryAccounts';
import { StoryCategories } from './pages/stories/StoryCategories';
import { StoryCategory } from './pages/stories/StoryCategory';
import { StoryCategoryCreate } from './pages/stories/StoryCategoryCreate';
import { StoryCreate } from './pages/stories/StoryCreate';
import { StoryScenes } from './pages/stories/StoryScenes';
import { StoryStoryCategories } from './pages/stories/StoryStoryCategories';
import { Template } from './pages/templates/Template';
import { TemplateAccounts } from './pages/templates/TemplateAccounts';
import { TemplateCreate } from './pages/templates/TemplateCreate';
import { TemplateDefinition } from './pages/templates/TemplateDefinition';
import { TemplateExport } from './pages/templates/TemplateExport';
import { TemplateFonts } from './pages/templates/TemplateFonts';
import { TemplateImport } from './pages/templates/TemplateImport';
import { TemplateLatestProjects } from './pages/templates/TemplateLatestProjects';
import { TemplateTextCompositions } from './pages/templates/TemplateTextCompositions';
import { Templates } from './pages/templates/Templates';
import { TranscriptionReviewAsset } from './pages/transcriptionReview/TranscriptionReviewAsset';
import { TranscriptionReviewIndex } from './pages/transcriptionReview/TranscriptionReviewIndex';
import { UserCreate } from './pages/users/UserCreate';
import { UserUpdate } from './pages/users/UserUpdate';
import { routes } from './routes';
import { Sentry } from './sentry';

const Root = () => (
  <ErrorBoundary>
    <Outlet />
    <ImpersonationBadge />
  </ErrorBoundary>
);

export const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route path={routes.signInError} element={<SignInError />} />
      <Route path={routes.signout} element={<SignOut />} />

      <Route path="/" element={<EnsureStaffUser />}>
        <Route path={routes.home} element={<Navigate to={routes.accounts} replace />} />
        <Route path={routes.userCreate} element={<UserCreate />} />
        <Route path={routes.user} element={<UserUpdate />} />

        <Route path={routes.accounts} element={<Accounts />} />
        <Route path={routes.accountCreate} element={<AccountCreate />} />
        <Route path={routes.account} element={<AccountBaseData />} />
        <Route path={routes.accountUsers} element={<AccountUsers />} />
        <Route path={routes.accountFlags} element={<AccountFlags />} />
        <Route path={routes.accountStories} element={<AccountStories />} />
        <Route path={routes.accountTemplates} element={<AccountTemplates />} />
        <Route path={routes.accountAuthProvider} element={<AccountAuthProvider />} />

        <Route path={routes.templates} element={<Templates />} />
        <Route path={routes.template} element={<Template />} />
        <Route path={routes.templateCreate} element={<TemplateCreate />} />
        <Route path={routes.templateDefinition} element={<TemplateDefinition />} />
        <Route path={routes.templateExport} element={<TemplateExport />} />
        <Route path={routes.templateFonts} element={<TemplateFonts />} />
        <Route path={routes.templateImport} element={<TemplateImport />} />
        <Route path={routes.templateLatestProjects} element={<TemplateLatestProjects />} />
        <Route path={routes.templateTextCompositions} element={<TemplateTextCompositions />} />
        <Route path={routes.templateUsers} element={<TemplateAccounts />} />

        <Route path={routes.transcriptionReviewIndex} element={<TranscriptionReviewIndex />} />
        <Route path={routes.transcriptionReviewAsset} element={<TranscriptionReviewAsset />} />

        <Route path={routes.musics} element={<Musics />} />
        <Route path={routes.musicCreate} element={<MusicCreate />} />
        <Route path={routes.music} element={<Music />} />

        <Route path={routes.userRoles} element={<UserRoles />} />
        <Route path={routes.staffUserRoles} element={<StaffUserRoles />} />

        <Route path={routes.staffUsers} element={<StaffUsersList />} />
        <Route path={routes.staffUser} element={<StaffUserUpdate />} />
        <Route path={routes.staffUserCreate} element={<StaffUserCreate />} />

        <Route path={routes.stories} element={<Stories />} />
        <Route path={routes.storyCreate} element={<StoryCreate />} />
        <Route path={routes.story} element={<Story />} />
        <Route path={routes.storyScenes} element={<StoryScenes />} />
        <Route path={routes.storyStoryCategories} element={<StoryStoryCategories />} />
        <Route path={routes.storyAccounts} element={<StoryAccounts />} />
        <Route path={routes.storyCategories} element={<StoryCategories />} />
        <Route path={routes.storyCategoryCreate} element={<StoryCategoryCreate />} />
        <Route path={routes.storyCategory} element={<StoryCategory />} />

        <Route path={routes.featureFlags} element={<FeatureFlags />} />
        <Route path={routes.featureFlag} element={<FeatureFlag />} />

        <Route path={routes.project} element={<Project />} />

        <Route path={routes.projectTemplates} element={<ProjectTemplates />} />
        <Route path={routes.projectTemplate} element={<ProjectTemplate />} />

        <Route path={routes.inspectIndex} element={<InspectIndex />} />
        <Route path={routes.inspectVideoAsset} element={<InspectVideoAsset />} />
        <Route path={routes.inspectVideoAssetOriginal} element={<InspectVideoAssetOriginal />} />

        <Route path={routes.exports} element={<Exports />} />
        <Route path={routes.export} element={<Export />} />
        <Route path={routes.exportPreview} element={<ExportPreview />} />
        <Route path={routes.exportRenderDescription} element={<ExportRenderDescription />} />

        <Route path="*" element={<Navigate to={routes.home} replace />} />
      </Route>

      <Route path="*" element={<Navigate to={routes.home} replace />} />
    </Route>
  )
);
