import { type FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { EmptyState, LoadingSpinner, PageHeadline, useGoto } from '@cofenster/web-components';

import { useExtendedRenderJob } from '../../../api/hooks/renderJob/useExtendedRenderJob';
import { ExportSubNavigation } from '../../../components/exports/ExportSubNavigation';
import { RestartButton } from '../../../components/exports/RestartButton';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { routes } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';

import { Metadata } from './Metadata';

export const Export: FC = () => {
  const { id } = useParams();
  const { extendedRenderJob, loading, error, startPolling, stopPolling } = useExtendedRenderJob(id);
  const title = [extendedRenderJob?.project?.name, 'Export'].filter(Boolean).join(' — ');
  const goto = useGoto();
  const status = extendedRenderJob?.job.status;

  useEffect(() => {
    if (status === 'InProgress' || status === 'Waiting') startPolling(5000);
    return () => stopPolling();
  }, [startPolling, stopPolling, status]);

  const nonNetworkError = error && !error.networkError;

  if (nonNetworkError) {
    return <ErrorLayout title={title} />;
  }

  return (
    <AdminLayout
      documentTitle={title}
      header={{
        topLeft: <PageHeadline mb={0} title={title} onBack={() => goto(routes.exports)} />,
        topRight: extendedRenderJob && (
          <PermissionRestriction has="ExportCreate">
            <RestartButton {...extendedRenderJob} />
          </PermissionRestriction>
        ),
        bottomLeft: id && <ExportSubNavigation id={id} />,
      }}
    >
      <PermissionRestriction
        has="ExportRead"
        fallback={
          <EmptyState
            iconType="NoOptionIcon"
            title="Missing permissions"
            description="Your current role does not allow you to access exports (lacking permission “ExportRead”). If you need access, please reach out to #team-product on Slack to be granted temporary access or for someone to support with your query."
          />
        }
      >
        {extendedRenderJob ? <Metadata {...extendedRenderJob} /> : loading ? <LoadingSpinner /> : <ErrorContent />}
      </PermissionRestriction>
    </AdminLayout>
  );
};
