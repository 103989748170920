import type { FC } from 'react';

import {
  type BasicOption,
  FormSelect,
  FormTextField,
  GridContainer,
  GridItem,
  renderBasicOptions,
} from '@cofenster/web-components';

import type { StorySceneTypes } from '..';
import type { StoryScene } from '../..';

const STORY_SCENE_TYPE_OPTIONS: BasicOption<StorySceneTypes>[] = [
  { value: 'video', children: 'Video' },
  { value: 'screenRecording', children: 'Screen recording' },
  { value: 'image', children: 'Image' },
  { value: 'color', children: 'Color' },
];

type StorySceneFormContentProps = {
  storyScene?: StoryScene | undefined;
};

export const StorySceneFormContent: FC<StorySceneFormContentProps> = ({ storyScene }) => {
  const id = storyScene?.id ?? 'new';

  return (
    <>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <FormSelect id={`type-${id}`} name="type" placeholder="Scene type" label="Scene type">
            {renderBasicOptions(STORY_SCENE_TYPE_OPTIONS)}
          </FormSelect>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <FormTextField id={`storySceneTitle-${id}`} name="title" label="Title" placeholder="Title" />
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormTextField id={`storySceneTitleEN-${id}`} name="titleEN" label="Title (EN)" placeholder="Title (EN)" />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <FormTextField
            id={`storySceneInstruction-${id}`}
            name="instruction"
            label="Instruction"
            placeholder="Instruction"
            multiline
            rows={2}
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormTextField
            id={`storySceneInstructionEn-${id}`}
            name="instructionEN"
            label="Instruction (EN)"
            placeholder="Instruction (EN)"
            multiline
            rows={2}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};
