import { useCallback } from 'react';

import { type SetUserActiveMutationVariables, useSetUserActiveMutation } from '../../generated';

export const useSetUserActive = () => {
  const [setUserActive] = useSetUserActiveMutation({
    refetchQueries: ['Users', 'User'],
    awaitRefetchQueries: true,
  });

  return useCallback(
    (id: SetUserActiveMutationVariables['id'], input: SetUserActiveMutationVariables['input']) =>
      setUserActive({ variables: { id, input } }),
    [setUserActive]
  );
};
