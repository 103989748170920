import { useCallback } from 'react';

import { type DeleteMusicMutationVariables, useDeleteMusicMutation } from '../../generated';

export const useDeleteMusic = (confirm?: string) => {
  const [deleteMusic] = useDeleteMusicMutation({ refetchQueries: ['Musics'] });
  return useCallback(
    (musicId: DeleteMusicMutationVariables['id']) => {
      if (confirm && !window.confirm(confirm)) return Promise.resolve();
      return deleteMusic({ variables: { id: musicId } });
    },
    [deleteMusic, confirm]
  );
};
