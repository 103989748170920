import { type FC, useCallback, useState } from 'react';

import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Spacing,
  Text,
  TextField,
  useSnackbars,
} from '@cofenster/web-components';
import { useAccounts } from '../../../api/hooks/account/useAccounts';
import { useBackUpAccount } from '../../../api/hooks/account/useBackUpAccount';
import { useTeams } from '../../../api/hooks/team/useTeams';
import { Sentry } from '../../../sentry';

export type BackUpAccountDialog = {
  accountId: string;
  accountName: string;
  isOpen: boolean;
  closeDialog: () => unknown;
};

export const BackUpAccountDialog: FC<BackUpAccountDialog> = ({ isOpen, accountId, accountName, closeDialog }) => {
  const [targetAccountId, setTargetAccountId] = useState<string | null>(null);
  const [targetTeamId, setTargetTeamId] = useState<string | null>(null);

  const { openSnackbar } = useSnackbars();
  const [backUpAccount, { loading }] = useBackUpAccount(accountId);
  const { paginatedAccounts } = useAccounts({ limit: 1000 });
  const { teams } = useTeams(targetAccountId ?? undefined);

  const onAction = useCallback(async () => {
    if (!targetAccountId || !targetTeamId) return;

    try {
      const result = await backUpAccount({ targetAccountId, targetTeamId });
      const count = result.data?.backUpAccount ?? 'existing';
      const noun = count === 1 ? 'project' : 'projects';

      openSnackbar({
        children: `The ${count} ${noun} from “${accountName}” were successfully backed up.`,
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      openSnackbar({ children: `There was a problem backing up account “${accountName}”.`, variant: 'error' });
    } finally {
      closeDialog();
    }
  }, [accountName, targetAccountId, targetTeamId, backUpAccount, openSnackbar, closeDialog]);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="Back up the account" data-testid="back-up-account-dialog">
      <DialogContent>
        <Text variant="l" color="grey600" component="p" paragraph>
          You are about to back up <strong>all the projects</strong> from “{accountName}”. This will create a new folder
          inside the selected team of the selected account and copy all projects into that folder.
        </Text>

        <Spacing top={2}>
          <Autocomplete
            id="targetAccount"
            options={paginatedAccounts?.items ?? []}
            getOptionDisabled={(option) => option.id === accountId}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            onChange={(_event, value) => {
              if (value) setTargetAccountId(value.id);
              else {
                setTargetAccountId(null);
                setTargetTeamId(null);
              }
            }}
            renderInput={(params) => <TextField {...params} name="targetAccount" label="Target account" autoFocus />}
          />

          <Autocomplete
            id="targetTeam"
            disabled={!targetAccountId}
            options={teams ?? []}
            getOptionKey={(option) => option.id}
            getOptionLabel={(option) => option.name}
            onChange={(_event, value) => {
              if (value) setTargetTeamId(value.id);
              else setTargetTeamId(null);
            }}
            renderInput={(params) => <TextField {...params} name="targetTeam" label="Target Team" />}
          />
        </Spacing>

        <Alert severity="warning">
          Make sure you have adequate authorization before backing up this account as cofenster is subject to GDPR and
          ISO-27001 compliance and should not preserve customer data beyond the expiration of their contract.
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant="destructive"
          fullWidth
          data-testid="back-up-account-dialog-confirm"
          disabled={!targetAccountId || !targetTeamId}
          onClick={onAction}
          loading={loading}
        >
          Back up
        </Button>
      </DialogActions>
    </Dialog>
  );
};
