import { useMemo } from 'react';

import { type RolesFilter, type RolesQueryResult, useRolesQuery } from '../../generated';

export type Roles = NonNullable<RolesQueryResult['data']>['roles'];
export type Role = NonNullable<Roles[number]>;

export const useRoles = (filter?: RolesFilter) => {
  const { loading, error, data } = useRolesQuery({
    variables: { filter },
  });

  return useMemo(
    () => ({
      roles: data?.roles ?? [],
      loading,
      error,
    }),
    [data?.roles, loading, error]
  );
};
