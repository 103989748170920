import { type FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Button, LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useDeleteStory } from '../../../api/hooks/story/useDeleteStory';
import { type Story as StoryType, useStory } from '../../../api/hooks/story/useStory';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { StorySubNavigation } from '../../../components/stories/story/StorySubNavigation';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useGotoStories } from '../../../hooks/useGotoStories';
import { NoAccessLayout } from '../../../pages/NoAccess';
import type { StoryRouteParams } from '../../../routes';
import { ErrorLayout } from '../../Error';

import { StoryCard } from './StoryCard';

const useDelete = (story: StoryType | undefined) => {
  const deleteStory = useDeleteStory('Delete story?');
  const gotoStories = useGotoStories();
  return useCallback(async () => {
    if (!story) return;
    const result = await deleteStory(story.id);
    result && gotoStories();
  }, [story, deleteStory, gotoStories]);
};

export const Story: FC = () => {
  const { storyId } = useParams() as StoryRouteParams;
  const { story, error } = useStory(storyId);
  const deleteStory = useDelete(story);
  const gotoStories = useGotoStories();
  const title = ['Story', story?.nameEN].filter(Boolean).join(' — ');

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="StoryUpdate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={gotoStories} />,
          topRight: story && (
            <PermissionRestriction has="StoryDelete">
              <Button variant="destructive" onClick={deleteStory}>
                Delete Story
              </Button>
            </PermissionRestriction>
          ),
          bottomLeft: <StorySubNavigation storyId={storyId} />,
        }}
      >
        {story ? <StoryCard story={story} /> : <LoadingSpinner />}
      </AdminLayout>
    </PermissionRestriction>
  );
};
