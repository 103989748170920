import { useCallback } from 'react';

import { type ImpersonateMutationVariables, useImpersonateMutation } from '../../generated';

export const useImpersonate = () => {
  const [impersonate] = useImpersonateMutation({
    // Refetch the `Me` query to update the `isImpersonating` field
    refetchQueries: ['Me'],
  });

  return useCallback((id: ImpersonateMutationVariables['id']) => impersonate({ variables: { id } }), [impersonate]);
};
