import { useCallback } from 'react';

import { type ImportRenderTemplateMutationVariables, useImportRenderTemplateMutation } from '../../generated';

export type ImportRenderTemplate = (input: ImportRenderTemplateMutationVariables['templateJson']) => Promise<void>;

export const useImportRenderTemplate = () => {
  const [importRenderTemplate] = useImportRenderTemplateMutation({ refetchQueries: ['RenderTemplate'] });
  return useCallback(
    (templateJson: ImportRenderTemplateMutationVariables['templateJson']) =>
      importRenderTemplate({ variables: { templateJson } }),
    [importRenderTemplate]
  );
};
