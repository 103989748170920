import type { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { SubNavigation } from '@cofenster/web-components';

import { SubNavigationItem } from '../../../components/navigation/SubNavigationItem';
import { routes } from '../../../routes';

type Props = {
  storyId: string;
};

export const StorySubNavigation: FC<Props> = ({ storyId }) => {
  return (
    <SubNavigation>
      <SubNavigationItem
        to="story"
        params={{ storyId }}
        active={!!useMatch({ path: routes.story, caseSensitive: true, end: true })}
      >
        Story
      </SubNavigationItem>
      <SubNavigationItem to="storyScenes" params={{ storyId }} active={!!useMatch(routes.storyScenes)}>
        Scenes
      </SubNavigationItem>
      <SubNavigationItem
        to="storyStoryCategories"
        params={{ storyId }}
        active={!!useMatch(routes.storyStoryCategories)}
      >
        Categories
      </SubNavigationItem>
      <SubNavigationItem to="storyAccounts" params={{ storyId }} active={!!useMatch(routes.storyAccounts)}>
        Accounts
      </SubNavigationItem>
    </SubNavigation>
  );
};
