import { type EasingName, Easings } from './Easings';
import { type Direction, DirectionOption, EasingOption } from './Options';
import { DEFAULT_TIMING_IN, TimingOption } from './Options/Timing';
import { createPreset } from './Preset';
import { createTimeline } from './utils';

export const BlindIn = createPreset(
  'BlindIn',
  ({ timing, easing, direction }) => {
    const value = {
      Right: 'inset(0 0 0 100%)',
      Left: 'inset(0 100% 0 0)',
      Down: 'inset(100% 0 0 0)',
      Up: 'inset(0  0 100% 0)',
    }[direction];

    return {
      clipPath: createTimeline(timing, {
        0: { v: value, e: Easings[easing] },
        1: { v: 'inset(0)' },
      }),
    };
  },
  {
    timing: TimingOption,
    easing: EasingOption,
    direction: DirectionOption,
  },
  {
    timing: DEFAULT_TIMING_IN,
    easing: 'EaseOut' as EasingName,
    direction: 'Left' as Direction,
  }
);
