import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useRenderTemplate } from '../../../api/hooks/renderTemplate/useRenderTemplate';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { TemplateSubNavigation } from '../../../components/templates/TemplateSubNavigation';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useGotoRenderTemplates } from '../../../hooks/useGotoRenderTemplates';
import type { TemplateRouteParams } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';
import { NoAccessLayout } from '../../NoAccess';

import { TemplateFontsList } from './TemplateFontsList';

export const TemplateFonts: FC = () => {
  const { renderTemplateId } = useParams() as TemplateRouteParams;
  const { renderTemplate, loading, error } = useRenderTemplate(renderTemplateId);
  const gotorenderTemplateTemplates = useGotoRenderTemplates();
  const title = ['Fonts', renderTemplate?.name].filter(Boolean).join(' — ');

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="TemplateUpdate" fallback={<NoAccessLayout documentTitle={title} />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={gotorenderTemplateTemplates} />,
          bottomLeft: <TemplateSubNavigation renderTemplateId={renderTemplateId} />,
        }}
      >
        {renderTemplate ? (
          <TemplateFontsList renderTemplateId={renderTemplate.id} />
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <ErrorContent />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
