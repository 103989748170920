import type { FC } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { Button, Icon, PopoverMenuItem, withPopoverMenu } from '@cofenster/web-components';

type FormatOptionsProps = {
  setVideoFormat: (format: VideoFormat) => void;
  format: VideoFormat;
};

const formatToIcon = {
  Horizontal: 'MonitorIcon',
  Vertical: 'MobileIcon',
  Square: 'SquareIcon',
  SocialMedia: 'SocialMediaIcon',
} as const;

export const FormatOptions: FC<FormatOptionsProps> = ({ setVideoFormat, format }) => {
  const iconName = formatToIcon[format];
  const selectedItem = format === 'SocialMedia' ? 'Portrait' : format;

  const InnerCompositionSelect = withPopoverMenu(Button, {
    children: (
      <>
        <PopoverMenuItem icon={formatToIcon.Horizontal} onClick={() => setVideoFormat('Horizontal')}>
          Horizontal
        </PopoverMenuItem>
        <PopoverMenuItem icon={formatToIcon.Vertical} onClick={() => setVideoFormat('Vertical')}>
          Vertical
        </PopoverMenuItem>
        <PopoverMenuItem icon={formatToIcon.Square} onClick={() => setVideoFormat('Square')}>
          Square
        </PopoverMenuItem>
        <PopoverMenuItem icon={formatToIcon.SocialMedia} onClick={() => setVideoFormat('SocialMedia')}>
          Portrait
        </PopoverMenuItem>
      </>
    ),
  });

  return (
    <InnerCompositionSelect variant="tertiary" startIcon={<Icon type={iconName} />}>
      {selectedItem}
    </InnerCompositionSelect>
  );
};
