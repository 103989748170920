import { arrayMoveImmutable as arrayMove } from 'array-move';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Button, EmptyState, GridContainer, GridItem, SortableList, useBooleanState } from '@cofenster/web-components';

import type { Story, StoryScene } from '..';
import { useDeleteStoryScene } from '../../../../api/hooks/storyScene/useDeleteStoryScene';
import { useSortStoryScene } from '../../../../api/hooks/storyScene/useSortStoryScenes';
import { StorySceneCard } from './StorySceneCard';

type SortableItemProps = {
  story: Story;
  value: StoryScene;
};

const useDelete = (storyScene: StoryScene | undefined) => {
  const deleteStoryScene = useDeleteStoryScene('Delete story scene?');
  return useCallback(async () => {
    if (storyScene) await deleteStoryScene(storyScene.id);
  }, [storyScene, deleteStoryScene]);
};

const SortableItem = ({ value: storyScene, story }: SortableItemProps) => {
  const deleteStoryScene = useDelete(storyScene);

  return <StorySceneCard story={story} storyScene={storyScene} deleteStoryScene={deleteStoryScene} />;
};

const useFormVisible = (scenes: StoryScene[]) => {
  const [formVisible, , , setFormVisible, toggleFormVisible] = useBooleanState(false);
  const sceneCount = scenes.length;
  useEffect(() => {
    sceneCount && setFormVisible(false);
  }, [setFormVisible, sceneCount]);
  return useMemo(() => [formVisible, toggleFormVisible] as const, [formVisible, toggleFormVisible]);
};

type Props = {
  story: Story;
};

export type StorySceneTypes = 'video' | 'screenRecording' | 'image' | 'color';

export const StorySceneList: FC<Props> = ({ story }) => {
  const sortStoryScenes = useSortStoryScene();
  const [formVisible, toggleFormVisible] = useFormVisible(story.scenes);
  const [cachedScenes, setCachedScenes] = useState(story.scenes);

  useEffect(() => {
    story.scenes.length && setCachedScenes(story.scenes);
  }, [story.scenes]);

  const onSort = useCallback(
    async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const sortedStoryScenes = arrayMove(cachedScenes, oldIndex, newIndex);
      setCachedScenes(sortedStoryScenes);
      const data = sortedStoryScenes.map((scene) => scene.id);
      sortStoryScenes(data);
    },
    [cachedScenes, sortStoryScenes]
  );

  return (
    <GridContainer>
      <GridItem xs={12}>
        {cachedScenes.length ? (
          <SortableList
            items={cachedScenes.map((value) => <SortableItem key={value.id} story={story} value={value} />)}
            ids={cachedScenes.map((value) => value.id)}
            onSortEnd={onSort}
            restrictTo="y"
          />
        ) : (
          <EmptyState iconType="NoOptionIcon" title="No story scenes" description="No story scenes were found." />
        )}
      </GridItem>

      {formVisible && (
        <GridItem xs={12}>
          <StorySceneCard story={story} deleteStoryScene={toggleFormVisible} />
        </GridItem>
      )}

      {!formVisible && (
        <GridItem>
          <Button onClick={toggleFormVisible}>Add Scene</Button>
        </GridItem>
      )}
    </GridContainer>
  );
};
