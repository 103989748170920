import type { FC } from 'react';

import { Card, PageHeadline } from '@cofenster/web-components';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { StoryCategoryForm } from '../../../components/stories/storyCategory/StoryCategoryForm';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useGotoStoryCategories } from '../../../hooks/useGotoStoryCategories';
import { NoAccessLayout } from '../../NoAccess';

export const StoryCategoryCreate: FC = () => {
  const gotoStoryCategories = useGotoStoryCategories();

  return (
    <PermissionRestriction has="StoryCreate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle="New Story Category"
        header={{
          topLeft: <PageHeadline mb={0} title="New Story Category" onBack={gotoStoryCategories} />,
        }}
      >
        <Card>
          <StoryCategoryForm />
        </Card>
      </AdminLayout>
    </PermissionRestriction>
  );
};
