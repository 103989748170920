import { useCallback } from 'react';

import { type UpdateStorySceneMutationVariables, useUpdateStorySceneMutation } from '../../generated';

export const useUpdateStoryScene = () => {
  const [updateStoryScene] = useUpdateStorySceneMutation({
    refetchQueries: ['Story'],
  });
  return useCallback(
    (id: UpdateStorySceneMutationVariables['id'], input: UpdateStorySceneMutationVariables['input']) =>
      updateStoryScene({ variables: { id: id, input } }),
    [updateStoryScene]
  );
};
