import { useCallback } from 'react';

import { type CreateRenderTemplateMutationVariables, useCreateRenderTemplateMutation } from '../../generated';

export const useCreateRenderTemplate = () => {
  const [createRenderTemplate] = useCreateRenderTemplateMutation({ refetchQueries: ['RenderTemplates'] });
  return useCallback(
    (input: CreateRenderTemplateMutationVariables['input']) => createRenderTemplate({ variables: { input } }),
    [createRenderTemplate]
  );
};
