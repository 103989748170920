import type { FC } from 'react';

import { IconButton, useSnackbars } from '@cofenster/web-components';

import { type TemplateDefinitionPath, useStylePropertiesState } from './TemplateDefinitionStateProvider';

export const CopyPasteStyleDefinition: FC<{
  path: TemplateDefinitionPath;
}> = ({ path }) => {
  const { openSnackbar } = useSnackbars();
  const [style, setStyle] = useStylePropertiesState(path);
  const [horizontalStyle, setHorizontalStyle] = useStylePropertiesState(path, 'Horizontal');
  const [verticalStyle, setVerticalStyle] = useStylePropertiesState(path, 'Vertical');
  const [squareStyle, setSquareStyle] = useStylePropertiesState(path, 'Square');
  const [socialMediaStyle, setSocialMediaStyle] = useStylePropertiesState(path, 'SocialMedia');

  const onCopy = async () => {
    const styles = JSON.stringify({
      style,
      horizontalStyle,
      verticalStyle,
      squareStyle,
      socialMediaStyle,
      copiedFrom: 'templateEditor',
    });

    await navigator.clipboard.writeText(styles);

    openSnackbar({
      variant: 'success',
      children: 'Copy successful.',
    });
  };

  const onPaste = async () => {
    try {
      const text = await navigator.clipboard.readText();

      const styles = JSON.parse(text);

      if (styles?.copiedFrom !== 'templateEditor') {
        throw new Error('Wrong style format');
      }

      const { style, horizontalStyle, verticalStyle, squareStyle, socialMediaStyle } = styles;
      setStyle(style);
      setHorizontalStyle(horizontalStyle);
      setVerticalStyle(verticalStyle);
      setSquareStyle(squareStyle);
      setSocialMediaStyle(socialMediaStyle);
    } catch {
      openSnackbar({
        variant: 'warning',
        children: `Data in your clipboard doesn't match style schema.`,
      });
    }
  };

  return (
    <>
      <IconButton icon="CopyIcon" label="Copy Styles & Animations" onClick={onCopy} />
      <IconButton icon="ClipboardIcon" label="Paste Styles & Animations" onClick={onPaste} />
    </>
  );
};
