import { type FC, useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text, useSnackbars } from '@cofenster/web-components';
import { useSetUserActive } from '../../../api/hooks/user/useSetUserActive';
import type { User } from '../../../api/hooks/user/useUser';

const useSetActive = (user: User, closeDialog: () => unknown) => {
  const setUserActive = useSetUserActive();
  const { openSnackbar } = useSnackbars();

  return useCallback(async () => {
    await setUserActive(user.id, { isActive: true });
    closeDialog();
    openSnackbar({
      variant: 'success',
      children: '🎉 The user was succesfully reactivated!',
    });
  }, [user, setUserActive, closeDialog, openSnackbar]);
};

export type ReactivateUserDialogProps = {
  user: User;
  isOpen: boolean;
  closeDialog: () => unknown;
};

export const ReactivateUserDialog: FC<ReactivateUserDialogProps> = ({ isOpen, closeDialog, user }) => {
  const onAction = useSetActive(user, closeDialog);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="Reactivate member">
      <DialogContent>
        <Text variant="l" color="grey600" component="p">
          Are you sure you want to reactivate this member? They will be able to access cofenster again.
        </Text>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          Cancel
        </Button>
        <Button variant="primary" fullWidth onClick={onAction}>
          Reactivate
        </Button>
      </DialogActions>
    </Dialog>
  );
};
