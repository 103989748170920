import { createContext } from 'react';

import type { StaffUser } from '../../api/hooks/staffUser/useMe';

export type * from '../../api/hooks/staffUser/useMe';

export type StaffUserContext = {
  staffUser: StaffUser | undefined;
  signout: VoidFunction;
};

export const StaffUserContext = createContext<StaffUserContext | undefined>(undefined);
