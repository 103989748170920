import { type FC, useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text, useSnackbars } from '@cofenster/web-components';
import { useSetUserActive } from '../../../api/hooks/user/useSetUserActive';
import type { User } from '../../../api/hooks/user/useUser';

const useSetActive = (user: User, closeDialog: () => unknown) => {
  const setUserActive = useSetUserActive();
  const { openSnackbar } = useSnackbars();

  return useCallback(async () => {
    await setUserActive(user.id, { isActive: false });
    closeDialog();
    openSnackbar({
      children: '😴 The member was succesfully deactivated!',
    });
  }, [user, setUserActive, closeDialog, openSnackbar]);
};

export type SetUserActiveDialogProps = {
  user: User;
  isOpen: boolean;
  closeDialog: () => unknown;
};

export const DeactivateUserDialog: FC<SetUserActiveDialogProps> = ({ isOpen, closeDialog, user }) => {
  const onAction = useSetActive(user, closeDialog);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="Deactivate member">
      <DialogContent>
        <Text variant="l" color="grey600" component="p">
          This person won’t have access to cofenster anymore. Any teams they owned will not be manageable until this
          person gets reactivated.
        </Text>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          Cancel
        </Button>
        <Button variant="destructive" fullWidth onClick={onAction}>
          Deactivate
        </Button>
      </DialogActions>
    </Dialog>
  );
};
