import { type FC, useRef } from 'react';

import { BlankButton, Button, Icon, LazyLoadedColorInput, Popover, TextField, styled } from '@cofenster/web-components';

const isHexColor = (color = ''): color is `#${string}` => /^#[0-9A-F]{3}|[0-9A-F]{6}$/i.test(color);
const PrimaryColor = 'var(--primary-color)';
const SecondaryColor = 'var(--secondary-color)';

const PopoverContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)<{ active: boolean }>(({ theme, active }) => ({
  justifyContent: 'flex-start',
  border: `1px solid ${active ? theme.palette.brand.blue : theme.palette.brand.grey400} !important`,
  backgroundColor: active ? theme.palette.brand.blue50 : undefined,
  color: active ? theme.palette.brand.blue : undefined,
  '&:hover, &:focus': {
    color: theme.palette.brand.blue,
    border: `1px solid ${theme.palette.brand.blue} !important`,
    backgroundColor: theme.palette.brand.blue50,
  },
}));

const ButtonContent = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

const Toggle = styled(BlankButton)<{ color?: string }>(({ theme, color }) => ({
  backgroundColor: color,
  flex: '1 0 auto',
  width: '1.75em',
  height: '1.75em',
  borderRadius: '0.35em',
  border: '1px solid rgb(0 0 0 / 0.2)',

  '&:focus-visible': theme.mixins.focusRing,
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.brand.blue,
}));

type ColorPickerProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onOpen: VoidFunction;
  onChange: (value: string) => void;
  color?: string;
  showActionButtons?: boolean;
  label: string;
};

export const PresetColorPicker: FC<ColorPickerProps> = ({
  isOpen,
  onClose,
  onOpen,
  color,
  onChange,
  showActionButtons,
  label,
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const isPrimaryColor = color === PrimaryColor;
  const isSecondaryColor = color === SecondaryColor;
  const displayValue = isPrimaryColor ? 'Primary Color' : isSecondaryColor ? 'Secondary Color' : color;
  const ToggleTrigger = (
    <Toggle
      aria-expanded={isOpen}
      onClick={isOpen ? onClose : onOpen}
      data-testid="preset-color-picker-trigger"
      color={color}
      type="button"
    />
  );

  return (
    <div ref={anchorRef}>
      <TextField InputProps={{ readOnly: true, startAdornment: ToggleTrigger }} value={displayValue} label={label} />
      <Popover
        open={isOpen}
        onClose={onClose}
        anchorEl={anchorRef.current}
        anchorOriginX="center"
        anchorOriginY="top"
        transformOriginX="center"
        transformOriginY="bottom"
        offsetY={-20}
        data-testid="preset-color-picker"
      >
        <PopoverContainer>
          {showActionButtons && (
            <Actions>
              <StyledButton variant="secondary" onClick={() => onChange(PrimaryColor)} active={isPrimaryColor}>
                <ButtonContent>
                  Primary Color
                  {isPrimaryColor && <StyledIcon type="CheckIcon" size="s" />}
                </ButtonContent>
              </StyledButton>
              <StyledButton variant="secondary" onClick={() => onChange(SecondaryColor)} active={isSecondaryColor}>
                <ButtonContent>
                  Secondary Color
                  {isSecondaryColor && <StyledIcon type="CheckIcon" size="s" />}
                </ButtonContent>
              </StyledButton>
              <StyledButton
                variant="secondary"
                onClick={() => onChange('#FFF')}
                active={!isSecondaryColor && !isPrimaryColor}
              >
                <ButtonContent>
                  Custom
                  {!isSecondaryColor && !isPrimaryColor && <StyledIcon type="CheckIcon" size="s" />}
                </ButtonContent>
              </StyledButton>
            </Actions>
          )}
          <LazyLoadedColorInput
            value={isHexColor(color) ? color : '#000'}
            onChange={onChange}
            disabled={color === PrimaryColor || color === SecondaryColor}
          />
        </PopoverContainer>
      </Popover>
    </div>
  );
};
