import type { FC } from 'react';

import { FormSubmitButton, GridContainer, GridItem, Headline, styled } from '@cofenster/web-components';

import { CheckboxController } from '../controls/CheckboxController';
import { CheckboxDisplay, type CheckboxDisplayType } from '../controls/CheckboxDisplay';

const Header = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.brand.white,
  zIndex: theme.zIndex.above,
  marginBottom: theme.spacing(2),
}));

export const AccountListHeader: FC<{
  accountIds: string[];
  display: CheckboxDisplayType;
  setDisplay: (value: CheckboxDisplayType) => unknown;
  fieldName?: string;
  disabled?: boolean;
}> = ({ accountIds, display, setDisplay, fieldName = 'accountIds', disabled }) => (
  <Header>
    <GridContainer alignItems="center" justifyContent="space-between">
      <GridItem>
        <Headline color="carbon" variant="h3" component="h2">
          Accounts
        </Headline>
      </GridItem>
      <GridItem display="flex" gap={1}>
        <CheckboxDisplay setDisplay={setDisplay} display={display} />
        <CheckboxController ids={accountIds} fieldName={fieldName} />
        <FormSubmitButton autoDisable disabled={disabled}>
          Save
        </FormSubmitButton>
      </GridItem>
    </GridContainer>
  </Header>
);
