import { useCallback } from 'react';

import { type UpdateStoryCategoryMutationVariables, useUpdateStoryCategoryMutation } from '../../generated';

export const useUpdateStoryCategory = () => {
  const [updateStoryCategory] = useUpdateStoryCategoryMutation({
    refetchQueries: ['StoryCategory', 'StoryCategories'],
  });
  return useCallback(
    (id: UpdateStoryCategoryMutationVariables['id'], input: UpdateStoryCategoryMutationVariables['input']) =>
      updateStoryCategory({ variables: { id: id, input } }),
    [updateStoryCategory]
  );
};
