import { useCallback } from 'react';

import { type UpdateStoryMutationVariables, useUpdateStoryMutation } from '../../generated';

export const useUpdateStory = () => {
  const [updateStory] = useUpdateStoryMutation({
    refetchQueries: ['Story', 'Stories'],
  });
  return useCallback(
    (id: UpdateStoryMutationVariables['id'], input: UpdateStoryMutationVariables['input']) =>
      updateStory({ variables: { id: id, input } }),
    [updateStory]
  );
};
