import { useCallback } from 'react';

import { type CreateTextInputMutationVariables, useCreateTextInputMutation } from '../../generated';

export const useCreateTextInput = () => {
  const [createTextInput] = useCreateTextInputMutation({ refetchQueries: ['RenderTemplate'] });
  return useCallback(
    (
      textCompositionId: CreateTextInputMutationVariables['textCompositionId'],
      input: CreateTextInputMutationVariables['input']
    ) => createTextInput({ variables: { textCompositionId, input } }),
    [createTextInput]
  );
};
