import { type FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner, PageHeadline, useGoto } from '@cofenster/web-components';

import { useFeatureFlags } from '../../../api/hooks/featureFlag/useFeatureFlags';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { useHasPermission } from '../../../contexts/staffUser/PermissionRestriction';
import { routes } from '../../../routes';
import { NoAccessLayout } from '../../NoAccess';

import { ErrorContent, ErrorLayout } from '../../Error';
import { AccountsList } from './AccountsList';

export const FeatureFlag: FC = () => {
  const { featureFlags, loading, error } = useFeatureFlags();
  const { name: selectedFeatureFlagName } = useParams();
  const selectedFeatureFlag = featureFlags?.find((flag) => flag.name === selectedFeatureFlagName);
  const canAssignFeatureFlags = useHasPermission({ has: 'FeatureFlagAssign' });
  const goto = useGoto();
  const gotoFeatureFlags = useCallback(() => goto(routes.featureFlags), [goto]);
  const title = selectedFeatureFlag?.label ?? selectedFeatureFlag?.name ?? 'Feature flag';

  if (selectedFeatureFlag && !selectedFeatureFlag.manageable) {
    return <NoAccessLayout documentTitle={title} />;
  }

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <AdminLayout
      documentTitle={title}
      header={{
        topLeft: <PageHeadline mb={0} title={title} onBack={gotoFeatureFlags} />,
      }}
    >
      {selectedFeatureFlag ? (
        <AccountsList selectedFeatureFlag={selectedFeatureFlag} disabled={!canAssignFeatureFlags} />
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <ErrorContent />
      )}
    </AdminLayout>
  );
};
