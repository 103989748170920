import type { FC } from 'react';

import { GridContainer, GridItem, Typography } from '@cofenster/web-components';

import { AnimationEditor } from '../AnimationEditor';
import { useAnimatedStyleState } from '../utils';

import type { CSSInputProps } from './CSSInput';

export const AnimationsInput: FC<CSSInputProps> = ({ state, setState }) => {
  const [animatedStyle, setAnimatedStyle] = useAnimatedStyleState([state, setState]);

  return (
    <GridContainer direction="column">
      <GridItem>
        <Typography variant="h5">Animations</Typography>
      </GridItem>
      <GridItem>
        <AnimationEditor state={animatedStyle} setState={setAnimatedStyle} />
      </GridItem>
    </GridContainer>
  );
};
