import type { ChangeEvent } from 'react';

import { GridContainer, GridItem, TextField } from '@cofenster/web-components';

import type { Option, OptionEditor } from './Option';

type RotationOption = 'start' | 'end';

export type Rotation = {
  start: number;
  end: number;
};

const RotationEditor: OptionEditor<Rotation> = ({ value, onChange }) => {
  const { start, end } = value;

  const handleOnChange = (option: RotationOption) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    onChange({
      start: option === 'start' ? Number.parseFloat(value) : start,
      end: option === 'end' ? Number.parseFloat(value) : end,
    });
  };

  return (
    <GridContainer>
      <GridItem xs={6}>
        <TextField type="number" label="Start" value={start} onChange={handleOnChange('start')} />
      </GridItem>
      <GridItem xs={6}>
        <TextField type="number" label="End" value={end} onChange={handleOnChange('end')} />
      </GridItem>
    </GridContainer>
  );
};

export const RotationOption: Option<Rotation> = {
  Editor: RotationEditor,
  serialize: (value) => JSON.stringify(value),
  deserialize: (value) => JSON.parse(value) as Rotation,
};
