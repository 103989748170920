import type { FC } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { Select, SelectOption, type SelectProps } from '@cofenster/web-components';

export type RetranscodeVideoFormat = 'Automatic' | VideoFormat;

export const VideoFormatSelect: FC<Partial<SelectProps & { withAutomatic?: boolean }>> = ({
  withAutomatic,
  ...rest
}) => (
  <Select {...rest} pb={0.5} id="videoFormat" name="videoFormat" label="Video format">
    {withAutomatic && <SelectOption value="Automatic">Automatic</SelectOption>}
    <SelectOption value="Horizontal">Horizontal</SelectOption>
    <SelectOption value="Vertical">Vertical</SelectOption>
    <SelectOption value="Square">Square</SelectOption>
    <SelectOption value="SocialMedia">SocialMedia</SelectOption>
  </Select>
);
