import { useMemo } from 'react';

import { type StaffUsersQuery, useStaffUsersQuery } from '../../generated';

export type StaffUser = StaffUsersQuery['staffUsers'][number];

export const useStaffUsers = () => {
  const { loading, error, data } = useStaffUsersQuery({ fetchPolicy: 'cache-and-network' });
  const staffUsers = data?.staffUsers;
  return useMemo(() => ({ staffUsers: staffUsers ?? [], loading, error }), [staffUsers, loading, error]);
};
