import { IconButton, type IconType, Text, styled, withPopover } from '@cofenster/web-components';

import type { Option, OptionEditor } from './Option';

const AnchorValues = [
  'TopLeft',
  'Top',
  'TopRight',
  'Left',
  'Center',
  'Right',
  'BottomLeft',
  'Bottom',
  'BottomRight',
] as const;

export type Anchor = (typeof AnchorValues)[number];

const AnchorToIcon: Record<Anchor, IconType> = {
  TopLeft: 'ArrowBoxedUpLeftIcon',
  Top: 'ArrowUpIcon',
  TopRight: 'ArrowBoxedUpRightIcon',
  Left: 'ArrowLeftIcon',
  Center: 'CircleIcon',
  Right: 'ArrowRightIcon',
  BottomLeft: 'ArrowBoxedDownLeftIcon',
  Bottom: 'ArrowDownIcon',
  BottomRight: 'ArrowBoxedDownRightIcon',
};

export const anchorToTransform = (scale: number, anchor: Anchor = 'Center') => {
  if (scale === 1) return '';

  if (anchor === 'Center') {
    return `scale(${scale})`;
  }

  const offsetPercent = (100 * Math.abs(1 - scale)) / 2;

  const nonZeroScale = Math.max(scale, Number.EPSILON);

  switch (anchor) {
    case 'TopLeft':
      return `translate(-${offsetPercent}%, -${offsetPercent}%) scale(${nonZeroScale})`;
    case 'Top':
      return `translate(0, -${offsetPercent}%) scale(${nonZeroScale})`;
    case 'TopRight':
      return `translate(${offsetPercent}%, -${offsetPercent}%) scale(${nonZeroScale})`;
    case 'Left':
      return `translate(-${offsetPercent}%, -0) scale(${nonZeroScale})`;
    case 'Right':
      return `translate(${offsetPercent}%, 0) scale(${nonZeroScale})`;
    case 'BottomLeft':
      return `translate(-${offsetPercent}%, ${offsetPercent}%) scale(${nonZeroScale})`;
    case 'Bottom':
      return `translate(0, ${offsetPercent}%) scale(${nonZeroScale})`;
    case 'BottomRight':
      return `translate(${offsetPercent}%, ${offsetPercent}%) scale(${nonZeroScale})`;
  }
};

const GridThreeByThree = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(3, 1fr)',
});

const AnchorEditor: OptionEditor<Anchor> = ({ value = 'Center', onChange }) => {
  const Trigger = withPopover(IconButton, {
    children: (
      <GridThreeByThree>
        {AnchorValues.map((anchor) => (
          <IconButton
            key={anchor}
            color={value === anchor ? 'primary' : 'default'}
            icon={AnchorToIcon[anchor]}
            label={anchor}
            onClick={() => onChange(anchor)}
          />
        ))}
      </GridThreeByThree>
    ),
  });

  return (
    <Text>
      <Trigger icon={AnchorToIcon[value]} label={value} /> Anchor
    </Text>
  );
};

export const AnchorOption: Option<Anchor> = {
  Editor: AnchorEditor,
  serialize: (value) => value,
  deserialize: (value) => value as Anchor,
};
