import { useCallback } from 'react';

import { useInitTemplateFontAssetUploadMutation } from '../../generated';

export const useInitTemplateFontAssetUpload = () => {
  const [initTemplateFontAssetUpload] = useInitTemplateFontAssetUploadMutation();
  return useCallback(
    (renderTemplateId: string, name: string) => initTemplateFontAssetUpload({ variables: { renderTemplateId, name } }),
    [initTemplateFontAssetUpload]
  );
};
