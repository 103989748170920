import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Form,
  FormCheckbox,
  FormSubmitButton,
  FormTextField,
  GridContainer,
  GridItem,
} from '@cofenster/web-components';
import { useCreateStory } from '../../../api/hooks/story/useCreateStory';
import type { Story } from '../../../api/hooks/story/useStory';
import { useUpdateStory } from '../../../api/hooks/story/useUpdateStory';

import { useGotoStory } from '../../../hooks/useGotoStory';
import { ImageAssetUpload } from '../../upload/ImageAssetUpload';
import { VideoAssetUpload } from '../../upload/VideoAssetUpload';

type Values = {
  name: string;
  nameEN: string;
  description: string;
  descriptionEN: string;
  previewImageAssetId: string;
  previewVideoAssetId: string | undefined;
  private: boolean;
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  name: Yup.string().trim().required('Please fill in this field'),
  nameEN: Yup.string().trim().required('Please fill in this field'),
  description: Yup.string().trim().required('Please fill in this field'),
  descriptionEN: Yup.string().trim().required('Please fill in this field'),
  previewImageAssetId: Yup.string().trim().required('Please fill in this field'),
  previewVideoAssetId: Yup.string().optional(),
  private: Yup.boolean().required('Please fill in this field'),
});

const useInitialValues = (story: Story | undefined) => {
  return useMemo<Values>(
    () => ({
      name: story?.name ?? '',
      nameEN: story?.nameEN ?? '',
      description: story?.description ?? '',
      descriptionEN: story?.descriptionEN ?? '',
      previewImageAssetId: story?.previewImage.id ?? '',
      private: !!story?.private,
      previewVideoAssetId: story?.previewVideo?.id,
    }),
    [story]
  );
};

const useCreate = () => {
  const createStory = useCreateStory();
  const gotoStory = useGotoStory();
  return useCallback(
    async (values: Values) => {
      try {
        const result = await createStory(values);
        const newStory = result.data?.createStory;
        newStory && gotoStory(newStory.id);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [createStory, gotoStory]
  );
};

const useUpdate = () => {
  const updateStory = useUpdateStory();
  return useCallback(
    async (story: Story, values: Values) => {
      try {
        await updateStory(story.id, values);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [updateStory]
  );
};

const useSubmit = (story: Story | undefined) => {
  const createStory = useCreate();
  const updateStory = useUpdate();
  return useCallback(
    async (values: Values) => {
      try {
        if (story) {
          return await updateStory(story, values);
        }
        return await createStory(values);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [story, createStory, updateStory]
  );
};

type Props = {
  story?: Story | undefined;
};

export const StoryForm: FC<Props> = ({ story }) => {
  const initialValues = useInitialValues(story);
  const onSubmit = useSubmit(story);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <FormTextField id="storyName" name="name" label="Name (DE)" placeholder="Name (DE)" />
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormTextField id="storyNameEN" name="nameEN" label="Name (EN)" placeholder="Name (EN)" />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <FormTextField
            multiline
            id="storyDescription"
            name="description"
            label="Description (DE)"
            placeholder="Description (DE)"
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormTextField
            multiline
            id="storyDescriptionEN"
            name="descriptionEN"
            label="Description (EN)"
            placeholder="Description (EN)"
          />
        </GridItem>
      </GridContainer>
      <GridContainer mb={3}>
        <GridItem xs={12} sm={6}>
          <ImageAssetUpload name="previewImageAssetId" />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <VideoAssetUpload name="previewVideoAssetId" />
        </GridItem>
      </GridContainer>
      <GridContainer mb={3}>
        <GridItem xs={12} md={6}>
          <FormCheckbox id="privateStory" name="private" label="Private" />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <FormSubmitButton>{story ? 'Save' : 'Create'}</FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
