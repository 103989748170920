import { Button } from '@cofenster/web-components';

import { useEffect, useMemo } from 'react';
import { useAbilityToRender } from '../../../../api/hooks/featureFlag/useAbilityToRender';
import { useToggleAbilityToRender } from '../../../../api/hooks/featureFlag/useToggleAbilityToRender';
import { useDialogs } from '../../../../contexts/dialogs/useDialogs';

export const ExportsPanicButton = () => {
  const [toggleAbilityToRender, { loading: mutationLoading }] = useToggleAbilityToRender();
  const { abilityToRender, loading: queryLoading } = useAbilityToRender();
  const { openDialog, isOpen } = useDialogs();
  const loading = mutationLoading || queryLoading;

  const dialogProps = useMemo(
    () => ({ loading, toggleAbilityToRender, abilityToRender }),
    [loading, toggleAbilityToRender, abilityToRender]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: if we pass `isOpen`, the dialog reopens immediately when closed
  useEffect(() => {
    // For the content of the dialog to update after the render status gets
    // updated, we need to re-open it with different props, which is a little
    // silly but is the drawback of our context implementation like this.
    if (isOpen('ExportsPanicDialog')) {
      openDialog('ExportsPanicDialog', dialogProps);
    }
  }, [openDialog, dialogProps]);

  return (
    <Button type="button" onClick={() => openDialog('ExportsPanicDialog', dialogProps)} variant="destructive">
      Emergency
    </Button>
  );
};
