import { useMemo } from 'react';

import {
  type StoryCategoryQueryResult,
  type StoryCategoryQueryVariables,
  useStoryCategoryQuery,
} from '../../generated';

export type StoryCategory = NonNullable<StoryCategoryQueryResult['data']>['storyCategory'];

export const useStoryCategory = (id: StoryCategoryQueryVariables['id']) => {
  const { loading, error, data } = useStoryCategoryQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });
  const storyCategory = data?.storyCategory ?? undefined;
  return useMemo(() => ({ storyCategory, loading, error }), [storyCategory, loading, error]);
};
