import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { GridItem, SubNavigation, SubNavigationItem, useTabNavigation } from '@cofenster/web-components';

import type { TemplateDefinitionPath } from '../TemplateDefinitionStateProvider';
import { AnimationsInput, CSSInput, ResponsiveInput } from '../inputs';
import { SvgViewBoxInput } from '../inputs/SvgViewBoxInput';
import { TextElementOptionsInput } from '../inputs/TextElementOptionsInput';
import type { StyleProperties } from '../remotion';

export const TabbedEditor: FC<{
  state: StyleProperties;
  setState: Dispatch<SetStateAction<StyleProperties>>;
  responsiveState: StyleProperties;
  setResponsiveState: Dispatch<SetStateAction<StyleProperties>>;
  editableTextContent: ReactNode;
  videoFormat: VideoFormat;
  path: TemplateDefinitionPath;
}> = ({ state, setState, responsiveState, setResponsiveState, editableTextContent, videoFormat, path }) => {
  const tabs = ['Design', 'Animate', 'Placeholder', 'Format override'];
  const { currentTab, getTablistProps, getTabProps, getTabSectionProps } = useTabNavigation(tabs, 'Design');

  return (
    <>
      <GridItem>
        <SubNavigation {...getTablistProps()}>
          {tabs.map((tab) => (
            <SubNavigationItem key={tab} {...getTabProps(tab)} active={currentTab === tab}>
              {tab}
            </SubNavigationItem>
          ))}
        </SubNavigation>
      </GridItem>
      <GridItem {...getTabSectionProps('Design')}>
        <CSSInput state={state} setState={setState} rows={25} />
        <SvgViewBoxInput path={path} />
        <TextElementOptionsInput path={path} />
      </GridItem>
      <GridItem {...getTabSectionProps('Format override')}>
        <ResponsiveInput videoFormat={videoFormat} setState={setResponsiveState} state={responsiveState} rows={10} />
      </GridItem>
      <GridItem {...getTabSectionProps('Animate')}>
        <AnimationsInput state={state} setState={setState} />
      </GridItem>
      <GridItem {...getTabSectionProps('Placeholder')}>{editableTextContent}</GridItem>
    </>
  );
};
