import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  type BasicOption,
  Button,
  Form,
  FormSelect,
  FormSubmitButton,
  FormTextField,
  GridContainer,
  GridItem,
  renderBasicOptions,
  useSnackbars,
} from '@cofenster/web-components';
import { type Role, useRoles } from '../../api/hooks/role/useRoles';
import { useCreateUser } from '../../api/hooks/user/useCreateUser';
import { useRecoverPassword } from '../../api/hooks/user/useRecoverPassword';
import { useUpdateUser } from '../../api/hooks/user/useUpdateUser';
import type { Locale, User } from '../../api/hooks/user/useUser';

import { useGotoAccountUsers } from '../../hooks/useGotoAccountUsers';

type Values = {
  firstname: string;
  lastname: string;
  email: string;
  locale: Locale;
  roleId: string;
};

const LOCALE_OPTIONS: BasicOption<Locale>[] = [
  { value: 'DE', children: 'German' },
  { value: 'EN', children: 'English' },
];

const useValidationSchema = () => {
  const validations = {
    firstname: Yup.string().trim().required('Please fill in this field'),
    lastname: Yup.string().trim().required('Please fill in this field'),
    email: Yup.string().trim().email('Please enter a valid email address').required('Please fill in this field'),
    locale: Yup.mixed<Locale>().required('Please set the language'),
    roleId: Yup.string().required('Please choose role'),
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);
  return baseSchema;
};

const useInitialValues = (user: User | undefined, roles: Role[]) => {
  return useMemo<Values>(
    () => ({
      firstname: user?.firstname ?? '',
      lastname: user?.lastname ?? '',
      email: user?.email ?? '',
      locale: user?.locale ?? LOCALE_OPTIONS[0]?.value ?? 'DE',
      roleId: user?.roles.account.roleId ?? roles[0]?.roleId ?? '',
    }),
    [user, roles]
  );
};

const useCreate = (accountId: string) => {
  const { openSnackbar } = useSnackbars();
  const createUser = useCreateUser();
  const gotoAccountUsers = useGotoAccountUsers(accountId);

  return useCallback(
    async (values: Values) => {
      const result = await createUser({
        accountId,
        ...values,
      });
      const createUserResult = result.data?.createUser;

      if (createUserResult?.__typename === 'EmailExistsError') {
        throw new Error('This email address is already in use.');
      }

      openSnackbar({
        variant: 'success',
        children: `✉️ An invitation was sent to ${values.email}.`,
      });
      gotoAccountUsers();
    },
    [createUser, openSnackbar, gotoAccountUsers, accountId]
  );
};

const useUpdate = (accountId: string) => {
  const updateUser = useUpdateUser();
  const goToAccountUsers = useGotoAccountUsers(accountId);
  return useCallback(
    async (user: User, values: Values) => {
      const result = await updateUser(user.id, values);
      const updateUserResult = result.data?.updateUser;
      if (updateUserResult?.__typename === 'EmailExistsError') {
        throw new Error('A member with that email already exists');
      }
      if (updateUserResult?.__typename === 'LastAdminError') {
        throw new Error(updateUserResult.message);
      }
      if (updateUserResult?.__typename === 'User') {
        goToAccountUsers();
      }
    },
    [updateUser, goToAccountUsers]
  );
};

const useSubmit = (user: User | undefined, accountId: string) => {
  const createUser = useCreate(accountId);
  const updateUser = useUpdate(accountId);

  return useCallback(
    (values: Values) => {
      if (user) {
        return updateUser(user, values);
      }

      return createUser(values);
    },
    [user, createUser, updateUser]
  );
};

const useSubmitPasswordReset = (user: User | undefined) => {
  const sendPasswordReset = useRecoverPassword();
  const { openSnackbar } = useSnackbars();

  return useCallback(async () => {
    const response = await sendPasswordReset(user?.email as string);
    if (response.data?.recoverPassword.__typename === 'DeactivatedUserError') {
      openSnackbar({ variant: 'error', children: 'Member deactivated' });
    } else if (response) {
      openSnackbar({ variant: 'success', children: 'Password successfully reset' });
    }
  }, [user, sendPasswordReset, openSnackbar]);
};

type Props = { user?: User; accountId: string };

export const UserForm: FC<Props> = ({ user, accountId }) => {
  const { roles } = useRoles();
  const initialValues = useInitialValues(user, roles);
  const validationSchema = useValidationSchema();

  const userRolesOptions = roles?.map((userRole) => ({
    value: userRole.roleId,
    children: userRole.name,
  }));

  const onSubmit = useSubmit(user, accountId);

  const onSubmitPasswordReset = useSubmitPasswordReset(user);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <FormTextField
            id="userFirstName"
            name="firstname"
            label="First name"
            placeholder="First name"
            data-testid="first-name-input"
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormTextField
            id="userLastName"
            name="lastname"
            label="Last name"
            placeholder="Last name"
            data-testid="last-name-input"
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <FormTextField
            id="userEmail"
            name="email"
            label="Email"
            placeholder="Email address"
            autoCapitalize="none"
            data-testid="email-input"
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormSelect name="locale" label="Language">
            {renderBasicOptions(LOCALE_OPTIONS)}
          </FormSelect>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <FormSelect name="roleId" label="User role" data-testid="user-roles-select">
            {userRolesOptions && renderBasicOptions(userRolesOptions)}
          </FormSelect>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={6} md={6} textAlign="left">
          {!accountId && (
            <Button variant="secondary" onClick={onSubmitPasswordReset}>
              Send Password Reset
            </Button>
          )}
        </GridItem>
        <GridItem xs={6} md={6} textAlign="right">
          <FormSubmitButton autoDisable>{user ? 'Save' : 'Invite'}</FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
