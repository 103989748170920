import { useCallback } from 'react';

import { type CreateStoryCategoryMutationVariables, useCreateStoryCategoryMutation } from '../../generated';

export const useCreateStoryCategory = () => {
  const [createStoryCategory] = useCreateStoryCategoryMutation({ refetchQueries: ['StoryCategories'] });
  return useCallback(
    (input: CreateStoryCategoryMutationVariables['input']) => createStoryCategory({ variables: { input } }),
    [createStoryCategory]
  );
};
