import type { FC } from 'react';

import { PageHeadline } from '@cofenster/web-components';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useGotoRenderTemplates } from '../../../hooks/useGotoRenderTemplates';
import { NoAccessLayout } from '../../NoAccess';

import { TemplateImportContent } from './TemplateImportContent';

export const TemplateImport: FC = () => {
  const gotoTemplates = useGotoRenderTemplates();

  return (
    <PermissionRestriction has="TemplateCreate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle="Import template"
        header={{
          topLeft: <PageHeadline mb={0} title="Import template" onBack={gotoTemplates} />,
        }}
      >
        <TemplateImportContent />
      </AdminLayout>
    </PermissionRestriction>
  );
};
