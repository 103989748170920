import type { Dispatch, FC, SetStateAction } from 'react';

import { GridContainer, GridItem, Typography } from '@cofenster/web-components';

import { CSSInputComponent } from '../CSSInputComponent';
import type { StyleProperties } from '../remotion';
import { fromCssProperties, toCssProperties, useStaticStyleState } from '../utils';

export type StylableJsonPartInputProps = {
  state: StyleProperties;
  setState: Dispatch<SetStateAction<StyleProperties>>;
  rows?: number;
};

export type CSSInputProps = StylableJsonPartInputProps;

export const CSSInput: FC<CSSInputProps> = ({ state, setState, rows = 10 }) => {
  const title = 'Stylesheet';

  const [staticStyle, setStaticStyle] = useStaticStyleState([state, setState]);

  return (
    <GridContainer direction="column">
      <GridItem>
        <Typography variant="h5">{title}</Typography>
      </GridItem>
      <GridItem>
        <CSSInputComponent
          value={toCssProperties(staticStyle)}
          onChange={(value) => setStaticStyle(fromCssProperties(value))}
          rows={rows}
        />
      </GridItem>
    </GridContainer>
  );
};
