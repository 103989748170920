import { styled } from '@mui/material';
import type { FC } from 'react';

import { Icon } from '../../assets/icons/Icon';
import { VisuallyHidden } from '../../utilities/VisuallyHidden';

const Handle = styled('div')(({ theme }) => ({
  cursor: 'move',
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  transition: 'background-color 250ms',

  marginRight: theme.spacing(1),
  position: 'absolute',
  right: '100%',
  top: '50%',
  transform: 'translateY(-50%)',

  svg: {
    display: 'block',
  },

  '&:hover': { backgroundColor: theme.palette.brand.linen50 },
  '&:focus-visible': theme.mixins.focusRing,
}));

export const SortableHandle: FC = (props) => (
  <Handle {...props} data-testid="drag-handle" tabIndex={0} role="button">
    <Icon type="DragHandleIcon" />
    <VisuallyHidden>i18n.global.drag.hint</VisuallyHidden>
  </Handle>
);
