import type { FC } from 'react';

import { Alert, formatTime, styled } from '@cofenster/web-components';

import { RouterLink } from '../../../../components/navigation/RouterLink';
import { EXPORT_STATUSES, type ExtendedRenderJob } from '../../../../contexts/exports';

const Link = styled(RouterLink)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  textDecoration: 'underline',
  '&:focus-visible': theme.mixins.focusRing,
}));

export const RestartInfo: FC<ExtendedRenderJob> = ({ project, account, subtitle, restartMode }) => {
  if (restartMode) return null;
  const search = project?.name ?? account?.name ?? '';
  const query = `?search=${encodeURIComponent(search)}&statuses=${EXPORT_STATUSES.join('-')}`;

  return (
    <Alert severity="warning">
      It is not possible to restart this job because{' '}
      {project?.deletedAt ? (
        `the project was deleted (on ${formatTime(project.deletedAt)}).`
      ) : account?.deletedAt ? (
        `the account was deleted (on ${formatTime(account.deletedAt)}).`
      ) : (
        <>
          there might be{' '}
          <Link to="exports" query={query}>
            a more recent job for that {subtitle ? 'subtitle' : 'project'}
          </Link>
          .
        </>
      )}
    </Alert>
  );
};
