import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { EmptyState, Form, FormCheckbox, FormSubmitButton, GridContainer, GridItem } from '@cofenster/web-components';
import { useUpdateAccount } from '../../../../../api/hooks/account/useUpdateAccount';
import type { Story } from '../../../../../api/hooks/story/useStories';
import { useHasPermission } from '../../../../../contexts/staffUser/PermissionRestriction';

type Values = {
  stories: string[];
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  stories: Yup.array().of(Yup.string().required()).required(),
});

const useInitialValues = (stories: Story[]) => {
  return useMemo<Values>(() => ({ stories: stories.map((story) => story.id) }), [stories]);
};

const useOnSubmit = (accountId: string) => {
  const updateAccount = useUpdateAccount();
  return useCallback(
    async (values: Values) => {
      const { stories } = values;
      await updateAccount(accountId, {
        stories: stories || [],
      });
    },
    [accountId, updateAccount]
  );
};

type AccountStoriesProps = {
  accountId: string;
  stories: Story[];
};

export const AccountStoriesContent: FC<AccountStoriesProps> = ({ accountId, stories }) => {
  const initialValues = useInitialValues(stories);
  const onSubmit = useOnSubmit(accountId);
  const canUpdateAccounts = useHasPermission({ has: 'AccountUpdate' });

  if (!stories.length) {
    return (
      <EmptyState
        iconType="NoOptionIcon"
        title="No custom stories"
        description="Besides the stories available to all accounts, there are no stories that are available specifically for that account."
      />
    );
  }

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {stories.map((story) => (
        <GridContainer key={story.id}>
          <GridItem flex="1" mb={2.5}>
            <FormCheckbox
              id={`stories-${story.id}`}
              name="stories"
              value={story.id}
              label={story.name}
              disabled={!canUpdateAccounts}
            />
          </GridItem>
        </GridContainer>
      ))}

      <GridContainer justifyContent="flex-end">
        <GridItem xs={12} md={12} textAlign="right">
          <FormSubmitButton autoDisable disabled={!canUpdateAccounts}>
            Save
          </FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
