import { useCallback } from 'react';

import { type UpdateRenderTemplateMutationVariables, useUpdateRenderTemplateMutation } from '../../generated';

export const useUpdateRenderTemplate = () => {
  const [updateRenderTemplate] = useUpdateRenderTemplateMutation({ refetchQueries: ['RenderTemplate'] });
  return useCallback(
    (id: UpdateRenderTemplateMutationVariables['id'], input: UpdateRenderTemplateMutationVariables['input']) =>
      updateRenderTemplate({ variables: { id, input } }),
    [updateRenderTemplate]
  );
};
