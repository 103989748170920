import { type FC, useCallback } from 'react';

import { PopoverMenuItem, PopoverMenuTriggerIcon, withPopoverMenu } from '@cofenster/web-components';

import type { ExtendedRenderJob } from '../../../../contexts/exports';
import { PermissionRestriction } from '../../../../contexts/staffUser/PermissionRestriction';
import { useImpersonateProject } from '../../../../hooks/useImpersonateProject';
import { useRestartJob } from '../../../../hooks/useRestartJob';

export const JobOptions: FC<ExtendedRenderJob> = (exendedRenderJob) => {
  const restartJob = useRestartJob(exendedRenderJob);
  const { job, project, account, restartMode } = exendedRenderJob;
  const copyId = useCallback(() => navigator.clipboard.writeText(job.id), [job.id]);
  const impersonate = useImpersonateProject(project?.id, `/project/${project?.id}/video`);
  const softDeleted = project?.deletedAt ?? account?.deletedAt;

  const PopoverMenuIcon = withPopoverMenu(PopoverMenuTriggerIcon, {
    children: (
      <>
        <PopoverMenuItem icon="CopyIcon" onClick={copyId}>
          Copy job ID
        </PopoverMenuItem>

        {project && !softDeleted && (
          <PermissionRestriction has="UserImpersonate">
            <PopoverMenuItem icon="UserSwitchIcon" color="negative" onClick={impersonate}>
              Impersonate
            </PopoverMenuItem>
          </PermissionRestriction>
        )}

        {restartJob && (
          <PermissionRestriction has="ExportCreate">
            <PopoverMenuItem icon="ArrowClockwiseIcon" color="negative" onClick={restartJob}>
              {restartMode === 'RETRY' ? 'Retry job' : 'Renew job'}
            </PopoverMenuItem>
          </PermissionRestriction>
        )}
      </>
    ),
  });

  return <PopoverMenuIcon />;
};
