import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../routes';

export const useGotoAccountUsers = (accountId: string) => {
  const goto = useGoto();

  return useCallback(() => goto(routes.accountUsers, { accountId }), [accountId, goto]);
};
