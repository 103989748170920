import { styled } from '@mui/material';

export const LightTable = styled('table')(({ theme }) => ({
  '&': {
    width: '100%',
    borderSpacing: theme.spacing(0, 2),

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },

  '& > thead': {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  '& > tbody': {
    [theme.breakpoints.down('sm')]: {
      display: 'contents',
    },
  },

  '& > tbody > tr': {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.brand.white,
      borderRadius: theme.shape['borderRadius-l'],
      marginBottom: theme.spacing(2),
      position: 'relative',
      padding: theme.spacing(2),
    },
  },

  // 1. Reset the default alignment from <th> elements
  // 2. Skip vertical spacing which is done with th `border-spacing` declaration
  //    on the <table> element
  // 3. Grant additional spacing on both edges of the table
  '& > thead > tr > th': {
    textAlign: 'start', // 1
    padding: theme.spacing(0, 2), // 2

    '&:first-of-type': { paddingLeft: theme.spacing(4) }, // 3
    '&:last-of-type': { paddingRight: theme.spacing(4) }, // 3
  },

  // 1. Grant relative positioning context for the drag handle inserted in the
  //    first table cell of each row
  // 2. Grant additional spacing on both edges of the table
  // 3. Apply rounded corners to each row
  // 4. Add a label to each “cell” on mobile
  '& > tbody > tr > td': {
    [theme.breakpoints.up('sm')]: {
      position: 'relative', // 1
      backgroundColor: theme.palette.brand.white,
      padding: theme.spacing(2),

      '&:first-of-type': {
        paddingLeft: theme.spacing(4), // 2
        borderRadius: theme.spacing(2, 0, 0, 2), // 3
      },

      '&:last-of-type': {
        paddingRight: theme.spacing(4), // 2
        borderRadius: theme.spacing(0, 2, 2, 0), // 3
      },
    },

    [theme.breakpoints.down('sm')]: {
      '&::before': {
        content: 'attr(data-label)', // 4
        fontWeight: 'bold',
        display: 'block',
      },

      '&:not(:first-of-type)::before': {
        marginTop: theme.spacing(1),
      },
    },
  },
}));
