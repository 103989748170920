import { useEffect, useState } from 'react';

import { TextField } from '@cofenster/web-components';

import { parseIntEnsure } from '../utils';

import type { Option, OptionEditor } from './Option';

const createBoundIntegerEditor = (name: string, min: number, max: number, unit: string) => {
  const Editor: OptionEditor<number> = ({ value = min, onChange }) => {
    const [local, setLocal] = useState(value.toString());
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
      setLocal(value.toString());
    }, [value]);

    return (
      <TextField
        label={name}
        helperText={error}
        error={error !== null}
        type="number"
        min={min}
        max={max}
        step={1}
        value={local}
        onChange={(event) => {
          setLocal(event.currentTarget.value);
          const isEmpty = event.currentTarget.value === '';
          const isValid = event.currentTarget.checkValidity();

          if (!isEmpty && isValid) {
            const value = Number.parseInt(event.currentTarget.value, 10);
            onChange(value);
            setError(null);
          } else {
            setError(event.currentTarget.validationMessage || 'Invalid value');
          }
        }}
        InputProps={{
          endAdornment: <span>{unit}</span>,
        }}
      />
    );
  };

  return Editor;
};

export const createBoundIntegerOption = (name: string, min: number, max: number, unit: string): Option<number> => {
  return {
    Editor: createBoundIntegerEditor(name, min, max, unit),
    serialize: (value) => Math.round(value).toString(),
    deserialize: (value) => parseIntEnsure(value),
  };
};
