import type { FC } from 'react';

import {
  EmptyState,
  PageHeadline,
  SubNavigation,
  SubNavigationItem,
  styled,
  useTabNavigation,
} from '@cofenster/web-components';

import { AdminLayout } from '../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../contexts/staffUser/PermissionRestriction';

import { TranscriptionSearch } from './TranscriptionSearch';

const AssetSection = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export const TranscriptionReviewIndex: FC = () => {
  const title = 'Transcription Review';
  const { currentTab, getTablistProps, getTabProps, getTabSectionProps } = useTabNavigation(
    ['video', 'audio'],
    'video'
  );

  return (
    <AdminLayout
      header={{
        topLeft: <PageHeadline mb={0} title={title} />,
        bottomLeft: (
          <SubNavigation {...getTablistProps()}>
            <SubNavigationItem iconType="VideoIcon" active={currentTab === 'video'} {...getTabProps('video')}>
              Video
            </SubNavigationItem>
            <SubNavigationItem iconType="MusicNoteIcon" active={currentTab === 'audio'} {...getTabProps('audio')}>
              Audio
            </SubNavigationItem>
          </SubNavigation>
        ),
      }}
      documentTitle={title}
    >
      <PermissionRestriction
        has="AssetRead"
        fallback={
          <EmptyState
            iconType="NoOptionIcon"
            title="Missing permissions"
            description="Your current role does not allow you to access assets (lacking permission “AssetRead”). If you need access, please reach out to #team-product on Slack to be granted temporary access or for someone to support with your query."
          />
        }
      >
        <AssetSection {...getTabSectionProps('video')}>
          <TranscriptionSearch type="video" />
        </AssetSection>
        <AssetSection {...getTabSectionProps('audio')}>
          <TranscriptionSearch type="audio" />
        </AssetSection>
      </PermissionRestriction>
    </AdminLayout>
  );
};
