import { type FC, memo, useEffect, useState } from 'react';

import {
  Autocomplete,
  Button,
  Card,
  GridContainer,
  GridItem,
  LoadingSpinner,
  TextField,
  Typography,
  styled,
} from '@cofenster/web-components';

import type { RenderTemplate } from '../../../../../api/hooks/renderTemplate/useRenderTemplate';
import { useDuplicateTextCompositionForRenderTemplate } from '../../../../../api/hooks/textComposition/useDuplicateTextCompositionForRenderTemplate';
import { useSearchTextComposition } from '../../../../../api/hooks/textComposition/useSearchTextComposition';

const StyledHeadline = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
}));

const OptionContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

type Option = {
  id: string;
  label: string;
  subLabel: string;
  subSubLabel: number;
};

export const CopyTextCompositionCard: FC<{ renderTemplate: RenderTemplate }> = memo(({ renderTemplate }) => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [selectedTextCompositionId, setSelectedTextCompositionId] = useState<string | null>(null);
  const { data } = useSearchTextComposition(searchPhrase);
  const [options, setOptions] = useState<Option[]>([]);
  useEffect(() => {
    if (data) {
      const newOptions = data.searchTextComposition.map((textComposition) => ({
        id: textComposition.id,
        label: textComposition.name,
        subLabel: `${textComposition.templateIdentifier} (${textComposition.templateName})`,
        subSubLabel: textComposition.numberOfInputs,
      }));
      setOptions(newOptions);
    }
  }, [data]);

  const { duplicateTextCompositionForRenderTemplate, loading } = useDuplicateTextCompositionForRenderTemplate();

  return (
    <Card>
      <GridContainer>
        <GridItem display="flex" alignItems="center">
          <StyledHeadline variant="h4" component="h2" color="carbon">
            Library
          </StyledHeadline>
        </GridItem>
        <GridItem flex={1}>
          <Autocomplete
            onChange={(_event, values) => setSelectedTextCompositionId(values?.id ?? null)}
            renderInput={(props) => {
              return (
                <TextField
                  {...props}
                  variant="outlined"
                  label="Search for existing text compositions"
                  onChange={(e) => setSearchPhrase(e.target.value)}
                  autoFocus
                />
              );
            }}
            filterOptions={(x) => x}
            options={options}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <OptionContent>
                  <Typography variant="h6" component="p">
                    {option.label}
                  </Typography>
                  <Typography component="p">Template: {option.subLabel}</Typography>
                  <Typography variant="xs" component="p">
                    Number of inputs: {option.subSubLabel}
                  </Typography>
                </OptionContent>
              </li>
            )}
          />
        </GridItem>
        <GridItem>
          <Button
            variant="secondary"
            onClick={() => {
              if (selectedTextCompositionId) {
                duplicateTextCompositionForRenderTemplate(renderTemplate.id, selectedTextCompositionId);
              }
            }}
          >
            Add text composition
          </Button>
        </GridItem>
        {loading && (
          <GridItem width="100%">
            <LoadingSpinner />
          </GridItem>
        )}
      </GridContainer>
    </Card>
  );
});
CopyTextCompositionCard.displayName = 'CopyTextCompositionCard';
