import { useMemo } from 'react';

import { type TranscriptionByVideoAssetIdQueryVariables, useTranscriptionByVideoAssetIdQuery } from '../../generated';

export const useTranscriptionByVideoAssetId = (
  videoAssetId: TranscriptionByVideoAssetIdQueryVariables['videoAssetId']
) => {
  const { loading, error, data } = useTranscriptionByVideoAssetIdQuery({
    variables: { videoAssetId },
    fetchPolicy: 'cache-and-network',
  });
  const transcription = data?.transcriptionByVideoAssetId || undefined;
  return useMemo(() => ({ transcription, loading, error }), [transcription, loading, error]);
};
