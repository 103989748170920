import type { FC } from 'react';

import { Button, type ButtonProps, Icon } from '@cofenster/web-components';

import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useImpersonateUser } from '../../../hooks/useImpersonateUser';

export const ImpersonateButton: FC<ButtonProps & { userId: string }> = ({ userId, ...rest }) => {
  const impersonateUser = useImpersonateUser(userId);

  return (
    <PermissionRestriction has="UserImpersonate">
      <Button
        {...rest}
        onClick={impersonateUser}
        data-testid="impersonate-button"
        startIcon={<Icon type="UserSwitchIcon" size="s" />}
      >
        Impersonate
      </Button>
    </PermissionRestriction>
  );
};
