import { useCallback } from 'react';

import { type UpdateTextCompositionMutationVariables, useUpdateTextCompositionMutation } from '../../generated';

export type SupportedSceneType = UpdateTextCompositionMutationVariables['input']['supportedSceneTypes'][number];

export const useUpdateTextComposition = () => {
  const [updateTextComposition] = useUpdateTextCompositionMutation({
    refetchQueries: ['RenderTemplate'],
  });
  return useCallback(
    (id: UpdateTextCompositionMutationVariables['id'], input: UpdateTextCompositionMutationVariables['input']) =>
      updateTextComposition({ variables: { id, input } }),
    [updateTextComposition]
  );
};
