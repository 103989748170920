import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Autocomplete,
  Form,
  FormSubmitButton,
  FormTextField,
  GridContainer,
  GridItem,
  useGoto,
} from '@cofenster/web-components';

import type { ProjectTemplate } from '../../../api/hooks/projectTemplate/useCofensterProjectTemplate';
import { useUpdateProjectTemplate } from '../../../api/hooks/projectTemplate/useUpdateProjectTemplate';
import { routes } from '../../../routes';

type Values = {
  description: string;
  tags: string[];
};

const useValidationSchema = () => {
  const validations = {
    description: Yup.string().trim().required('Please fill in this field'),
    tags: Yup.array(Yup.string().trim().required('Please fill in this field')).required('Please fill in this field'),
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);
  return baseSchema;
};

const useInitialValues = (projectTemplate: ProjectTemplate) => {
  return useMemo<Values>(
    () => ({
      description: projectTemplate.description ?? '',
      tags: projectTemplate.tags ?? [],
    }),
    [projectTemplate]
  );
};

const useSubmit = (projectTemplateId: string) => {
  const updateProjectTemplate = useUpdateProjectTemplate();
  const goto = useGoto();

  return useCallback(
    async (values: Values) => {
      await updateProjectTemplate(projectTemplateId, values);
      goto(routes.projectTemplates);
    },
    [projectTemplateId, updateProjectTemplate, goto]
  );
};

export const ProjectTemplateForm: FC<{ projectTemplate: ProjectTemplate }> = ({ projectTemplate }) => {
  const initialValues = useInitialValues(projectTemplate);
  const validationSchema = useValidationSchema();

  const onSubmit = useSubmit(projectTemplate.id);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formikProps) => (
        <>
          <GridContainer>
            <GridItem xs={12} md={6}>
              <FormTextField
                id="projectTemplateDescription"
                name="description"
                label="Description"
                multiline
                rows={3}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Autocomplete
                id="projectTemplateTags"
                multiple
                freeSolo
                options={[]}
                value={formikProps.values.tags}
                onChange={(_event, values) =>
                  formikProps.setFieldValue('tags', values.map((value) => value.trim()).filter(Boolean))
                }
                renderInput={(params) => <FormTextField name="tagsField" {...params} label="Tags" />}
              />
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={6} md={6} textAlign="left">
              <FormSubmitButton autoDisable>Save</FormSubmitButton>
            </GridItem>
          </GridContainer>
        </>
      )}
    </Form>
  );
};
