import type { UniqueIdentifier } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import { type CSSProperties, type ComponentProps, type FC, type PropsWithChildren, forwardRef, useMemo } from 'react';

import { SortableContext, useDraggableStyles } from '../../controls/Sortable';

type SortableTableRowProps = PropsWithChildren<
  ComponentProps<'table'> & {
    isDragging: boolean;
    transform: Transform | null;
    transition: CSSProperties['transition'];
  }
>;

export const SortableTableRow = forwardRef<HTMLTableRowElement, SortableTableRowProps>(function SortableTableRow(
  { children, isDragging, transform, transition },
  ref
) {
  const draggableStyles = useDraggableStyles({ isDragging, transform, transition });

  return (
    <tr ref={ref} style={draggableStyles}>
      {children}
    </tr>
  );
});

export const SortableTable = <Item extends { id: string }>({
  items,
  onSort,
  components: { Table = 'table', TableRow, TableRowHeader },
  disabled,
  ...rest
}: ComponentProps<'table'> & {
  items: Item[];
  onSort: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
  disabled?: boolean;
  components: {
    Table?: 'table' | FC<ComponentProps<'table'>>;
    TableRow: FC<{ item: Item; disabled?: boolean }>;
    TableRowHeader: FC;
  };
}) => {
  const ids: UniqueIdentifier[] = useMemo(() => items.map((item) => item.id), [items]);

  return (
    <Table {...rest}>
      <thead>
        <TableRowHeader />
      </thead>
      <tbody>
        <SortableContext ids={ids} onSortEnd={onSort} restrictTo="y" disabled={disabled}>
          {items.map((item) => (
            <TableRow key={item.id} item={item} disabled={disabled} />
          ))}
        </SortableContext>
      </tbody>
    </Table>
  );
};
