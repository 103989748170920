import { type FC, Suspense, lazy, useCallback } from 'react';

import { Card, Headline, IconButton, LoadingSpinner, styled, useSnackbars } from '@cofenster/web-components';

const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}));

const CodeBlockContainer = styled('div')(({ theme }) => ({
  '--background-color': theme.palette.toRgba(theme.palette.brand.grey50, 0.7),
  backgroundColor: 'var(--background-color)',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  maxWidth: '100%',
  minWidth: 0,
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
}));

const Editor = lazy(() => import(/* webpackChunkName: "json-edit-react" */ './Editor'));

export const CodeBlock: FC<{ title: string; code: object }> = ({ title, code }) => {
  const { openSnackbar } = useSnackbars();
  const content = JSON.stringify(code, undefined, 2);

  const copyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(content);

    openSnackbar({
      variant: 'success',
      children: 'Copied to the clipboard!',
    });
  }, [content, openSnackbar]);

  return (
    <Card>
      <CardHeader>
        <Headline variant="h4" component="h2">
          {title}
        </Headline>
        <IconButton type="button" onClick={copyToClipboard} icon="CopyIcon" label="Copy to clipboard" />
      </CardHeader>

      <CodeBlockContainer>
        <Suspense fallback={<LoadingSpinner />}>
          <Editor data={code} indent={2} restrictAdd restrictEdit restrictDelete maxWidth="100%" />
        </Suspense>
      </CodeBlockContainer>
    </Card>
  );
};
