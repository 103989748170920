import { useCallback } from 'react';

import { useFileUpload } from '@cofenster/web-components';

import {
  type VideoCaptureSource,
  type VideoFit,
  type VideoFormat,
  useInitPublicVideoUpload,
} from './useInitPublicVideoUpload';

export const useUploadPublicVideoAsset = () => {
  const initPublicVideoUpload = useInitPublicVideoUpload();
  const uploadFile = useFileUpload();

  return useCallback(
    async (
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      abortSignal: AbortController['signal'],
      options?: {
        videoFormat?: VideoFormat;
        videoFit?: VideoFit;
        videoCaptureSource?: VideoCaptureSource;
      }
    ) => {
      const { videoFormat, videoFit, videoCaptureSource } = options ?? {};
      const result = await initPublicVideoUpload(videoFormat, videoFit, videoCaptureSource);

      if (!result.data?.initPublicVideoUpload) {
        throw new Error('Failed to initialize video upload');
      }

      const { uploadUrl, videoAssetId } = result.data.initPublicVideoUpload;
      const uploadingResult = await uploadFile(uploadUrl, file, uploadId, onProgress, abortSignal);
      if (!uploadingResult) {
        // Upload failed or was aborted
        return undefined;
      }
      return videoAssetId;
    },
    [initPublicVideoUpload, uploadFile]
  );
};
