import { type Dispatch, type FC, type PropsWithChildren, type SetStateAction, createContext } from 'react';

import type { RenderJob } from '../../api/hooks/renderJob/useExtendedRenderJob';

import { EXPORT_STATUSES } from './constants';
import { useSettings } from './useSettings';

export type {
  RenderJob,
  ExtendedRenderJob,
  Project,
} from '../../api/hooks/renderJob/useExtendedRenderJob';

export type { ExtendedRenderJobsPaginationResult } from '../../api/hooks/renderJob/useExtendedRenderJobs';

export type Order = {
  direction: 'ASC' | 'DESC';
  property: 'createdAt' | 'updatedAt' | 'id' | 'status';
};

export type Dates = {
  after: string | undefined;
  before: string | undefined;
};

export type ExportSettingsContext = {
  search: string;
  setSearch: (search: string) => void;
  dates: Dates;
  setDates: (getNextDates: (curr: Dates) => Dates) => void;
  order: Order;
  setOrder: (getNextOrder: (curr: Order) => Order) => void;
  statuses: RenderJob['status'][];
  setStatuses: Dispatch<SetStateAction<RenderJob['status'][]>>;
  pageSize: number;
  setPageSize: (page: number) => void;
  page: number;
  setPage: (page: number) => void;
  pagination: { limit: number; offset: number };
  withPolling: boolean;
  setWithPolling: Dispatch<SetStateAction<boolean>>;
};

export const ExportsSettingsContext = createContext<ExportSettingsContext>({
  search: '',
  setSearch: () => undefined,
  dates: { after: undefined, before: undefined },
  setDates: () => undefined,
  order: { direction: 'DESC', property: 'createdAt' },
  setOrder: () => undefined,
  statuses: EXPORT_STATUSES,
  setStatuses: () => [],
  pageSize: 25,
  setPageSize: () => undefined,
  page: 1,
  setPage: () => 1,
  pagination: { limit: 25, offset: 0 },
  withPolling: false,
  setWithPolling: () => false,
});

export const ExportSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const settings = useSettings();

  return <ExportsSettingsContext.Provider value={settings}>{children}</ExportsSettingsContext.Provider>;
};
