import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { Form, FormSubmitButton, FormTextField, GridContainer, GridItem } from '@cofenster/web-components';
import { useCreateStoryCategory } from '../../../api/hooks/storyCategory/useCreateStoryCategory';
import type { StoryCategory } from '../../../api/hooks/storyCategory/useStoryCategory';
import { useUpdateStoryCategory } from '../../../api/hooks/storyCategory/useUpdateStoryCategory';

import { useGotoStoryCategories } from '../../../hooks/useGotoStoryCategories';

type Values = {
  name: string;
  nameEN: string;
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  name: Yup.string().trim().required('Please fill in this field'),
  nameEN: Yup.string().trim().required('Please fill in this field'),
});

const useInitialValues = (storyCategory: StoryCategory | undefined) => {
  return useMemo<Values>(
    () => ({
      name: storyCategory?.name ?? '',
      nameEN: storyCategory?.nameEN ?? '',
    }),
    [storyCategory]
  );
};

const useCreate = () => {
  const createStoryCategory = useCreateStoryCategory();
  const gotoStoryCategories = useGotoStoryCategories();
  return useCallback(
    async (values: Values) => {
      try {
        const result = await createStoryCategory(values);
        const newStoryCategory = result.data?.createStoryCategory;
        newStoryCategory && gotoStoryCategories();
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [createStoryCategory, gotoStoryCategories]
  );
};

const useUpdate = () => {
  const updateStoryCategory = useUpdateStoryCategory();
  return useCallback(
    async (storyCategory: StoryCategory, values: Values) => {
      try {
        await updateStoryCategory(storyCategory.id, values);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [updateStoryCategory]
  );
};

const useSubmit = (story: StoryCategory | undefined) => {
  const createStoryCategory = useCreate();
  const updateStoryCategory = useUpdate();
  return useCallback(
    async (values: Values) => {
      try {
        if (story) return await updateStoryCategory(story, values);
        return await createStoryCategory(values);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [story, createStoryCategory, updateStoryCategory]
  );
};

type Props = {
  storyCategory?: StoryCategory | undefined;
};

export const StoryCategoryForm: FC<Props> = ({ storyCategory }) => {
  const initialValues = useInitialValues(storyCategory);
  const onSubmit = useSubmit(storyCategory);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <FormTextField id="storyCategoryName" name="name" label="Name (DE)" placeholder="Name (DE)" />
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormTextField id="storyCategoryNameEN" name="nameEN" label="Name (EN)" placeholder="Name (EN)" />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <FormSubmitButton>{storyCategory ? 'Save' : 'Create'}</FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
