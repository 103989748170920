import { arrayMoveImmutable as arrayMove } from 'array-move';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
  Alert,
  Button,
  GridContainer,
  GridItem,
  Headline,
  SortableList,
  Spacing,
  useBooleanState,
} from '@cofenster/web-components';

import type { RenderTemplate, TextComposition } from '../../../../api/hooks/renderTemplate/useRenderTemplate';
import { useDeleteTextComposition } from '../../../../api/hooks/textComposition/useDeleteTextComposition';
import { useSortTextCompositions } from '../../../../api/hooks/textComposition/useSortTextCompositions';
import { CopyTextCompositionCard } from './CopyTextCompositionCard';
import { TextCompositionCard } from './TextCompositionCard';

type SortableItemProps = {
  renderTemplate: RenderTemplate;
  value: TextComposition;
};

const useDelete = (textComposition: TextComposition | undefined) => {
  const deleteTextComposition = useDeleteTextComposition('Delete Text Composition?');

  return useCallback(() => {
    if (textComposition) deleteTextComposition(textComposition.id);
  }, [textComposition, deleteTextComposition]);
};

const SortableItem = ({ value: textComposition, renderTemplate }: SortableItemProps) => {
  const deleteTextComposition = useDelete(textComposition);

  return (
    <TextCompositionCard
      renderTemplate={renderTemplate}
      textComposition={textComposition}
      deleteTextComposition={deleteTextComposition}
    />
  );
};

const useSortableTextCompositions = (renderTemplate: RenderTemplate) => {
  const sortTextCompositions = useSortTextCompositions();
  const templateTextCompositions = useMemo(
    () => renderTemplate.textCompositions ?? [],
    [renderTemplate.textCompositions]
  );
  const [textCompositions, setTextCompositions] = useState(templateTextCompositions);

  useEffect(() => {
    setTextCompositions(templateTextCompositions);
  }, [templateTextCompositions]);

  const onSort = useCallback(
    async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const sortedTextCompositions = arrayMove(textCompositions, oldIndex, newIndex);
      setTextCompositions(sortedTextCompositions);
      const data = sortedTextCompositions.map((composition) => composition.id);
      sortTextCompositions(data);
    },
    [textCompositions, sortTextCompositions]
  );
  return useMemo(() => ({ textCompositions, onSort }) as const, [textCompositions, onSort]);
};

const useFormVisible = (textCompositions: TextComposition[]) => {
  const [formVisible, , , setFormVisible, toggleFormVisible] = useBooleanState(false);
  const textCompositionCount = textCompositions.length;
  useEffect(() => {
    textCompositionCount && setFormVisible(false);
  }, [setFormVisible, textCompositionCount]);
  return useMemo(() => [formVisible, toggleFormVisible] as const, [formVisible, toggleFormVisible]);
};

type Props = {
  renderTemplate: RenderTemplate;
};

export const TextCompositionsList: FC<Props> = ({ renderTemplate }) => {
  const { textCompositions, onSort } = useSortableTextCompositions(renderTemplate);
  const [formVisible, toggleFormVisible] = useFormVisible(textCompositions);

  const hasDuplicates = useMemo(() => {
    return (
      textCompositions
        .map((textComposition) => textComposition.aeCompositionName)
        .filter((v, i, a) => a.lastIndexOf(v) !== i).length > 0
    );
  }, [textCompositions]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <CopyTextCompositionCard renderTemplate={renderTemplate} />
      </GridItem>
      {hasDuplicates && (
        <GridItem xs={12}>
          <Alert severity="warning">Some text compositions have the same Remotion text element name.</Alert>
        </GridItem>
      )}
      <GridItem xs={12}>
        <Spacing vertical={2}>
          <Headline variant="h4" component="h2">
            Text compositions ({textCompositions.length})
          </Headline>
        </Spacing>
        <SortableList
          items={textCompositions.map((value) => (
            <SortableItem key={value.id} renderTemplate={renderTemplate} value={value} />
          ))}
          ids={textCompositions.map((value) => value.id)}
          onSortEnd={onSort}
          restrictTo="y"
        />
      </GridItem>

      {formVisible && (
        <GridItem xs={12}>
          <TextCompositionCard renderTemplate={renderTemplate} deleteTextComposition={toggleFormVisible} />
        </GridItem>
      )}

      {!formVisible && (
        <GridItem>
          <Button onClick={toggleFormVisible} variant="primary">
            Add text composition
          </Button>
        </GridItem>
      )}
    </GridContainer>
  );
};
