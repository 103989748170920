import { useMemo } from 'react';

import { type FeatureFlagsQueryResult, useFeatureFlagsQuery } from '../../generated';

export type FeatureFlag = NonNullable<FeatureFlagsQueryResult['data']>['featureFlags'][number];

export type Account = FeatureFlag['accounts'][number];

export const useFeatureFlags = () => {
  const { loading, error, data } = useFeatureFlagsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const featureFlags = data?.featureFlags ?? undefined;
  return useMemo(() => ({ featureFlags, loading, error }), [featureFlags, loading, error]);
};
