import { type FC, useCallback, useMemo } from 'react';

import { Button, Card, GridContainer, GridItem, Headline, Typography, styled } from '@cofenster/web-components';

import type { FeatureFlag } from '../../../../api/hooks/account/useAccount';
import { useAssignFeatureFlags } from '../../../../api/hooks/account/useAssignFeatureFlags';
import { useDialogs } from '../../../../contexts/dialogs';
import { useHasPermission } from '../../../../contexts/staffUser/PermissionRestriction';
import { useGotoAccounts } from '../../../../hooks/useGotoAccounts';

const CardHeadline = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  border: `3px solid ${theme.palette.brand.negative}`,
}));

const Divider = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brand.grey200,
  height: 1,
  width: '100%',
}));

const useDelete = (accountId: string, accountName: string) => {
  const { openDialog } = useDialogs();
  const gotoAccounts = useGotoAccounts();

  return useCallback(
    () => openDialog('DeleteAccountDialog', { accountId, accountName, gotoAccounts }),
    [openDialog, accountId, accountName, gotoAccounts]
  );
};

const useBackUp = (accountId: string, accountName: string) => {
  const { openDialog } = useDialogs();

  return useCallback(
    () => openDialog('BackUpAccountDialog', { accountId, accountName }),
    [openDialog, accountId, accountName]
  );
};

const useDisableRender = (accountId: string, featureFlags: FeatureFlag[]) => {
  const assignFeatureFlags = useAssignFeatureFlags();
  const isRenderDisabled = featureFlags.find((f) => f.name === 'EMERGENCY_DISABLE_RENDER');
  const nextFlags = useMemo(
    () =>
      isRenderDisabled
        ? featureFlags.map((flag) => flag.name).filter((name) => name !== 'EMERGENCY_DISABLE_RENDER')
        : featureFlags.map((flag) => flag.name).concat('EMERGENCY_DISABLE_RENDER'),
    [featureFlags, isRenderDisabled]
  );

  return useCallback(
    () => assignFeatureFlags(accountId, { featureFlagIds: nextFlags }),
    [accountId, assignFeatureFlags, nextFlags]
  );
};

export const DangerZone: FC<{ accountId: string; accountName: string; featureFlags: FeatureFlag[] }> = ({
  accountId,
  accountName,
  featureFlags,
}) => {
  const deleteAccount = useDelete(accountId, accountName);
  const backUpAccount = useBackUp(accountId, accountName);
  const disableRender = useDisableRender(accountId, featureFlags);
  const isRenderDisabled = featureFlags.find((f) => f.name === 'EMERGENCY_DISABLE_RENDER');
  const canDeleteAccounts = useHasPermission({ has: 'AccountDelete' });
  const canExportAccounts = useHasPermission({ has: 'AccountExport' });
  const canDisableExports = useHasPermission({ has: 'ExportDisable' });

  return (
    <StyledCard>
      <CardHeadline color="carbon" variant="h3" component="h2">
        Danger zone
      </CardHeadline>

      <GridContainer mb={3}>
        <GridItem xs={12} md={9}>
          <Typography variant="l" component="h3">
            Mark the account for deletion
          </Typography>
          <Typography variant="l" component="p">
            Pressing the “Delete account” button will trigger a final confirmation.
          </Typography>
        </GridItem>
        <GridItem
          xs={12}
          md={3}
          display="flex"
          justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
          alignItems="flex-start"
        >
          <Button
            variant="destructive"
            onClick={deleteAccount}
            data-testid="delete-account-button"
            disabled={!canDeleteAccounts}
          >
            Delete account
          </Button>
        </GridItem>
      </GridContainer>

      <Divider />

      <GridContainer mt={0} mb={3}>
        <GridItem xs={12} md={9}>
          <Typography variant="l" component="h3">
            Disable the ability to render
          </Typography>
          <Typography variant="l">
            Temporarily prevents the customer from rendering videos. Ongoing exports will not be interrupted.
          </Typography>
        </GridItem>
        <GridItem
          xs={12}
          md={3}
          display="flex"
          justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
          alignItems="flex-start"
        >
          <Button
            variant={isRenderDisabled ? 'primary' : 'secondary'}
            onClick={disableRender}
            data-testid="disable-render-button"
            disabled={!canDisableExports}
          >
            {isRenderDisabled ? 'Enable render' : 'Disable render'}
          </Button>
        </GridItem>
      </GridContainer>

      <Divider />

      <GridContainer mt={0}>
        <GridItem xs={12} md={9}>
          <Typography variant="l" component="h3">
            Back up the account projects
          </Typography>
          <Typography variant="l">Copy all the projects from this account into another one.</Typography>
        </GridItem>
        <GridItem
          xs={12}
          md={3}
          display="flex"
          justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
          alignItems="flex-start"
        >
          <Button onClick={backUpAccount} data-testid="back-up-button" disabled={!canExportAccounts}>
            Back up account
          </Button>
        </GridItem>
      </GridContainer>
    </StyledCard>
  );
};
