import { type FC, type FocusEventHandler, useCallback, useMemo, useState } from 'react';

import {
  Card,
  Form,
  FormSubmitButton,
  FormTextField,
  GridContainer,
  GridItem,
  Headline,
  Text,
  useSnackbars,
} from '@cofenster/web-components';

import { useGenerateResetPasswordLink } from '../../../../api/hooks/user/useGenerateResetPasswordLink';

export const ResetPasswordLinkForm: FC<{ userId: string }> = ({ userId }) => {
  const [resetPasswordLink, setResetPasswordLink] = useState<string | undefined>('');
  const generateResetPasswordLink = useGenerateResetPasswordLink();
  const { openSnackbar } = useSnackbars();
  const initialValues = useMemo(() => ({ resetPasswordLink }), [resetPasswordLink]);

  const copyCodeToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(resetPasswordLink as string);
    openSnackbar({ variant: 'success', children: 'Copied to clipboard!' });
  }, [resetPasswordLink, openSnackbar]);

  const handleSubmit = useCallback(async () => {
    const link = await generateResetPasswordLink(userId);
    setResetPasswordLink(link.data?.generateResetPasswordLink.link as string);
    await copyCodeToClipboard();
  }, [copyCodeToClipboard, generateResetPasswordLink, userId]);

  const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    async (event) => {
      event.target.select();
      await copyCodeToClipboard();
    },
    [copyCodeToClipboard]
  );

  return (
    <Card>
      <Headline variant="h4" component="h2">
        Reset password
      </Headline>
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <GridContainer mt={1} spacing={1}>
          <GridItem xs={12} mb={1}>
            <Text variant="l" color="grey600" component="p">
              Pressing the button below will generate a link for that user to reset their password,{' '}
              <strong>valid for 4 hours</strong>.
            </Text>
          </GridItem>
          <GridItem xs={12}>
            <FormTextField
              id="resetPasswordLink"
              name="resetPasswordLink"
              label="Password reset link"
              onFocus={handleFocus}
              disabled={!resetPasswordLink}
              inputProps={{ readOnly: true }}
              data-testid="reset-link-input"
            />
          </GridItem>
          <GridItem xs={12} display="flex" justifyContent="flex-end">
            <FormSubmitButton variant="secondary" data-testid="reset-link-generate-button">
              Generate reset link
            </FormSubmitButton>
          </GridItem>
        </GridContainer>
      </Form>
    </Card>
  );
};
