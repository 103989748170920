import { LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useCofensterProjectTemplates } from '../../../api/hooks/projectTemplate/useCofensterProjectTemplates';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { ErrorContent, ErrorLayout } from '../../Error';

import { ProjectTemplatesList } from './ProjectTemplatesList';

export const ProjectTemplates = () => {
  const title = 'Project templates';
  const { cofensterProjectTemplates, loading, error } = useCofensterProjectTemplates();

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <AdminLayout documentTitle={title} header={{ topLeft: <PageHeadline mb={0} title={title} /> }}>
      {cofensterProjectTemplates ? (
        <ProjectTemplatesList projectTemplates={cofensterProjectTemplates} />
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <ErrorContent />
      )}
    </AdminLayout>
  );
};
