import type { FC } from 'react';

import { TextField } from '@cofenster/web-components';

import { type TemplateDefinitionPath, usePrimitivePropertyState } from '../TemplateDefinitionStateProvider';

export const SvgViewBoxInput: FC<{ path: TemplateDefinitionPath }> = ({ path }) => {
  const { composition, component, subComponent } = path;
  const pathString = `${composition}.${component}.${subComponent}.viewBox`;
  const [state, setState] = usePrimitivePropertyState<string>(pathString);

  if (!subComponent?.startsWith('svg.element')) {
    return null;
  }

  return <TextField label="SVG Viewbox" value={state} onChange={(e) => setState(e.target.value)} />;
};
