import { type FC, useCallback } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSubmitButton,
  FormTextField,
  Spacing,
  Text,
  useSnackbars,
} from '@cofenster/web-components';

import { useDeleteUser } from '../../../api/hooks/user/useDeleteUser';
import type { User } from '../../../api/hooks/user/useUser';

type Values = {
  name: string;
};

const initialValues: Values = { name: '' };

const useValidationSchema = (userName: string) => {
  const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Please type the person’s name')
      .equals([userName], 'Please enter the name of the person correctly') as Yup.StringSchema<string>,
  });
  return validationSchema;
};

export type DeleteUserDialogProps = {
  user: User;
  isOpen: boolean;
  closeDialog: () => unknown;
  gotoUsers: () => unknown;
};

export const DeleteUserDialog: FC<DeleteUserDialogProps> = ({ isOpen, user, closeDialog, gotoUsers }) => {
  const { openSnackbar } = useSnackbars();
  const deleteUser = useDeleteUser();

  const validationSchema = useValidationSchema(user.name);

  const onAction = useCallback(async () => {
    try {
      await deleteUser(user.id);
      openSnackbar({ children: `The member “${user.name}” was deleted.` });
      closeDialog();
      gotoUsers();
    } catch (error) {
      // Rethrow the error as an `Error` so we display the actual message from
      // the backend instead of having it being replaced with the generic error
      // message by the `Form` component
      throw new Error((error as Error)?.message ?? 'An unexpected error happened while deleting the user.');
    }
  }, [user, deleteUser, openSnackbar, closeDialog, gotoUsers]);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="Remove this user">
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onAction}>
        <DialogContent>
          <Text variant="l" color="grey600" component="p" paragraph>
            <strong>{user.name}</strong> will no longer have access to teams and projects in cofenster.
          </Text>

          <Text variant="l" color="grey600" component="p" paragraph>
            Any teams they owned will lose their owner.
          </Text>

          <Text variant="l" color="grey600" component="p" paragraph>
            This action cannot be reversed. Type the person’s name below to confirm.
          </Text>
          <Spacing top={2}>
            <FormTextField
              id="deleteUserName"
              name="name"
              label="Person name"
              placeholder="Person name"
              autoComplete="off"
            />
          </Spacing>
        </DialogContent>

        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog}>
            Cancel
          </Button>
          <FormSubmitButton variant="destructive" fullWidth>
            Remove
          </FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
