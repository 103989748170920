import { type FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Button, LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useDeleteStoryCategory } from '../../../api/hooks/storyCategory/useDeleteStoryCategory';
import {
  type StoryCategory as StoryCategoryType,
  useStoryCategory,
} from '../../../api/hooks/storyCategory/useStoryCategory';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useGotoStoryCategories } from '../../../hooks/useGotoStoryCategories';
import { NoAccessLayout } from '../../../pages/NoAccess';
import type { StoryCategoryRouteParams } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';

import { StoryCategoryCard } from './StoryCategoryCard';

const useDelete = (storyCategory: StoryCategoryType | undefined) => {
  const deleteStoryCategory = useDeleteStoryCategory('Delete story category?');
  const gotoStoryCategories = useGotoStoryCategories();
  return useCallback(async () => {
    if (!storyCategory) return;
    const result = await deleteStoryCategory(storyCategory.id);
    result && gotoStoryCategories();
  }, [storyCategory, deleteStoryCategory, gotoStoryCategories]);
};

export const StoryCategory: FC = () => {
  const { storyCategoryId } = useParams() as StoryCategoryRouteParams;
  const { storyCategory, loading, error } = useStoryCategory(storyCategoryId);
  const deleteStoryCategory = useDelete(storyCategory);
  const gotoStoryCategories = useGotoStoryCategories();
  const title = ['Story Category', storyCategory?.nameEN].filter(Boolean).join(' — ');

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="StoryUpdate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={gotoStoryCategories} />,
          topRight: (
            <PermissionRestriction has="StoryDelete">
              <Button variant="destructive" onClick={deleteStoryCategory}>
                Delete Story Category
              </Button>
            </PermissionRestriction>
          ),
        }}
      >
        {storyCategory ? (
          <StoryCategoryCard storyCategory={storyCategory} />
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <ErrorContent />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
