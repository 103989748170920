import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { Form, FormCheckbox, Spacing, useUrlParameter } from '@cofenster/web-components';

import type { Story } from '..';
import type { Account } from '../../../../api/hooks/account/useAccountsLite';
import { useUpdateStory } from '../../../../api/hooks/story/useUpdateStory';
import { AccountListHeader } from '../../../../components/accounts/AccountListHeader';
import { type CheckboxDisplayType, filterItemsByDisplay } from '../../../../components/controls/CheckboxDisplay';

type Values = {
  accountIds: string[];
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  accountIds: Yup.array().of(Yup.string().required()).required(),
});

const useInitialValues = (story: Story) => {
  return useMemo<Values>(
    () => ({
      accountIds: story.accounts.map((account) => account.id),
    }),
    [story]
  );
};

const useOnSubmit = (story: Story) => {
  const updateStory = useUpdateStory();
  return useCallback(
    async ({ accountIds }: Values) => {
      try {
        await updateStory(story.id, {
          accounts: accountIds || [],
        });
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [story, updateStory]
  );
};

type AccountsFormProps = {
  story: Story;
  accounts: Account[];
};

export const AccountsForm: FC<AccountsFormProps> = ({ story, accounts }) => {
  const [display, setDisplay] = useUrlParameter<CheckboxDisplayType>('ALL', 'display');
  const initialValues = useInitialValues(story);
  const onSubmit = useOnSubmit(story);
  const accountIds = accounts.map((account) => account.id);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <AccountListHeader accountIds={accountIds} setDisplay={setDisplay} display={display} />

      {accounts.filter(filterItemsByDisplay(initialValues.accountIds, display)).map((account) => (
        <Spacing vertical={1} key={account.id}>
          <FormCheckbox id={`accountIds-${account.id}`} name="accountIds" value={account.id} label={account.name} />
        </Spacing>
      ))}
    </Form>
  );
};
