import { arrayMoveImmutable as arrayMove } from 'array-move';
import { type Dispatch, type FC, type SetStateAction, useCallback, useMemo } from 'react';

import {
  GridContainer,
  GridItem,
  Headline,
  IconButton,
  LoadingSpinner,
  SortableList,
  styled,
} from '@cofenster/web-components';

import type { RenderTemplate } from '../../../../api/hooks/renderTemplate/useRenderTemplates';

import { RouterLink } from '../../../../components/navigation/RouterLink';

export const SortableSelectTemplates: FC<{
  renderTemplates: RenderTemplate[];
  selectedIds: string[];
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
  disabled?: boolean;
}> = ({ renderTemplates, selectedIds, setSelectedIds, disabled }) => {
  const assign = useCallback(
    (id: string) => () => setSelectedIds((selectedIds) => Array.from(new Set([...selectedIds, id]))),
    [setSelectedIds]
  );

  const unassign = useCallback(
    (id: string) => () => setSelectedIds((selectedIds) => selectedIds.filter((selectedId) => selectedId !== id)),
    [setSelectedIds]
  );

  const sortableItems = useMemo(() => {
    if (!renderTemplates.length) return [];
    return selectedIds
      .map((id) => {
        const renderTemplate = renderTemplates.find((renderTemplate) => renderTemplate.id === id);
        if (!renderTemplate) {
          throw new Error(`Template with id ${id} not found`);
        }
        return renderTemplate;
      })
      .map((renderTemplate) => {
        return (
          <SortableItem
            key={renderTemplate.id}
            renderTemplate={renderTemplate}
            unassign={unassign(renderTemplate.id)}
            disabled={disabled}
          />
        );
      });
  }, [selectedIds, renderTemplates, unassign, disabled]);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      setSelectedIds((selectedIds) => arrayMove(selectedIds, oldIndex, newIndex));
    },
    [setSelectedIds]
  );

  if (!renderTemplates.length) return <LoadingSpinner />;

  return (
    <GridContainer>
      <GridItem xs={6}>
        <Headline color="carbon" variant="h4" component="h3">
          Assigned render templates
        </Headline>
        <SortableContainer data-testid="assigned-templates-list" disabled={disabled}>
          <SortableList
            items={sortableItems}
            ids={selectedIds}
            onSortEnd={onSortEnd}
            spacing={0}
            restrictTo="y"
            disabled={disabled}
          />
        </SortableContainer>
      </GridItem>
      <GridItem xs={6}>
        <Headline color="carbon" variant="h4" component="h3">
          Available render templates
        </Headline>
        <List data-testid="available-templates-list">
          {renderTemplates
            .filter((renderTemplate) => !selectedIds.includes(renderTemplate.id))
            .map((renderTemplate) => (
              <AvailableTemplate
                key={renderTemplate.id}
                renderTemplate={renderTemplate}
                assign={assign(renderTemplate.id)}
                disabled={disabled}
              />
            ))}
        </List>
      </GridItem>
    </GridContainer>
  );
};

const SortableItem: FC<{ renderTemplate: RenderTemplate; unassign: VoidFunction; disabled?: boolean }> = ({
  renderTemplate,
  unassign,
  disabled,
}) => (
  <ListItem as="div" data-testid="template-item">
    <RouterLink to="templates" params={{ renderTemplateId: renderTemplate.id }}>
      {renderTemplate.name}
    </RouterLink>
    <IconButton
      icon="MinusCircleIcon"
      label="Unassign"
      onClick={unassign}
      data-testid="remove-template-button"
      disabled={disabled}
    />
  </ListItem>
);

const SortableContainer = styled('div')<{ disabled?: boolean }>(({ theme, disabled }) => ({
  marginLeft: theme.spacing(disabled ? 1 : 4),
  marginTop: theme.spacing(1),
}));

const List = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  paddingLeft: 0,
  margin: 0,
  marginTop: theme.spacing(1),
}));

const ListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const AvailableTemplate: FC<{ renderTemplate: RenderTemplate; assign: VoidFunction; disabled?: boolean }> = ({
  renderTemplate,
  assign,
  disabled,
}) => (
  <ListItem>
    <IconButton
      icon={'PlusCircleIcon'}
      label={`Assign ${renderTemplate.name}`}
      onClick={assign}
      data-testid="add-template-button"
      disabled={disabled}
    />
    <RouterLink to="template" params={{ renderTemplateId: renderTemplate.id }}>
      {renderTemplate.name}
    </RouterLink>
  </ListItem>
);
