import { type FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner } from '@cofenster/web-components';

import { useVideoAsset } from '../../../api/hooks/videoAsset/useVideoAsset';
import type { InspectRouteParams } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';
import { InspectLayout } from '../InspectLayout';
import { InspectRetranscodeButton } from '../InspectRetranscodeButton';

import { InspectVideoAssetContent } from './InspectVideoAssetContent';

export const InspectVideoAsset: FC = () => {
  const { id } = useParams() as InspectRouteParams;
  const title = 'Transcoded asset';
  const { videoAsset, error, loading, startPolling, stopPolling } = useVideoAsset(id);

  useEffect(() => {
    if (videoAsset?.status === 'Ready') stopPolling();
    else startPolling(2000);
    return () => stopPolling();
  }, [startPolling, stopPolling, videoAsset?.status]);

  const nonNetworkError = error && !error.networkError;

  if (nonNetworkError) {
    return <ErrorLayout title={title} />;
  }

  return (
    <InspectLayout title={title} header={{ topRight: <InspectRetranscodeButton id={id} /> }}>
      {videoAsset ? (
        <InspectVideoAssetContent videoAsset={videoAsset} />
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <ErrorContent />
      )}
    </InspectLayout>
  );
};
