import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Card, LoadingSpinner, PageHeadline, Text } from '@cofenster/web-components';

import { useAccountsLite } from '../../../api/hooks/account/useAccountsLite';
import { useStory } from '../../../api/hooks/story/useStory';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { StorySubNavigation } from '../../../components/stories/story/StorySubNavigation';
import { useGotoStories } from '../../../hooks/useGotoStories';
import type { StoryRouteParams } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';

import { AccountsForm } from './AccountsForm';

export type { StoryCategory } from '../../../api/hooks/storyCategory/useStoryCategories';
export type { Story } from '../../../api/hooks/story/useStory';

export const StoryAccounts: FC = () => {
  const { storyId } = useParams() as StoryRouteParams;
  const { story, loading, error: storyError } = useStory(storyId);
  const { paginatedAccounts: accounts, error: accountsError } = useAccountsLite({ limit: 1000 });
  const gotoStories = useGotoStories();
  const title = ['Accounts', story?.name].filter(Boolean).join(' — ');

  if (storyError || accountsError) {
    return <ErrorLayout title={title} />;
  }

  return (
    <AdminLayout
      documentTitle={title}
      header={{
        topLeft: <PageHeadline mb={0} title={title} onBack={gotoStories} />,
        bottomLeft: <StorySubNavigation storyId={storyId} />,
      }}
    >
      {story ? (
        <Card>
          {story.private ? (
            <AccountsForm story={story} accounts={accounts?.items ?? []} />
          ) : (
            <Text>This story is available to all customers</Text>
          )}
        </Card>
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <ErrorContent />
      )}
    </AdminLayout>
  );
};
