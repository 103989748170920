import { type EasingName, Easings } from './Easings';
import { EasingOption } from './Options';
import { DEFAULT_TIMING_IN, TimingOption } from './Options/Timing';
import { createPreset } from './Preset';
import { createTimeline } from './utils';

export const FadeIn = createPreset(
  'FadeIn',
  ({ timing, easing }) => {
    return {
      opacity: createTimeline(timing, {
        0: { v: 0, e: Easings[easing] },
        1: { v: 1 },
      }),
    };
  },
  {
    timing: TimingOption,
    easing: EasingOption,
  },
  {
    timing: DEFAULT_TIMING_IN,
    easing: 'EaseOut' as EasingName,
  }
);
