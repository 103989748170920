import type { FC } from 'react';

import { Select, SelectOption } from '@cofenster/web-components';

import { type Preset, type PresetName, PresetNames, type PresetOptions, getPreset } from './Presets';

export const PresetPicker: FC<{
  id: string;
  value: Preset<PresetOptions> | null;
  onChange: (value: Preset<PresetOptions> | null) => unknown;
}> = ({ value, onChange, id }) => {
  const presetName = value?.name ?? 'NO_ANIMATION';
  const onPresetChange = (presetName: PresetName) => {
    onChange(getPreset(presetName));
  };

  return (
    <>
      <Select
        id={id}
        name="animation-preset"
        label="Preset"
        value={presetName}
        onChange={(event) => onPresetChange(event.target.value as PresetName)}
      >
        <SelectOption value="NO_ANIMATION">No animation</SelectOption>
        {PresetNames.map((name) => (
          <SelectOption key={name} value={name}>
            {name}
          </SelectOption>
        ))}
      </Select>
    </>
  );
};
