import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useStory } from '../../../api/hooks/story/useStory';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { StorySubNavigation } from '../../../components/stories/story/StorySubNavigation';
import { useGotoStories } from '../../../hooks/useGotoStories';
import type { StoryRouteParams } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';

import { StorySceneList } from './StorySceneList';

export type { Story, StoryScene } from '../../../api/hooks/story/useStory';

export const StoryScenes: FC = () => {
  const { storyId } = useParams() as StoryRouteParams;
  const { story, error, loading } = useStory(storyId);
  const gotoStories = useGotoStories();
  const title = ['Scenes', story?.name].filter(Boolean).join(' — ');

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <AdminLayout
      documentTitle={title}
      header={{
        topLeft: <PageHeadline mb={0} title={title} onBack={gotoStories} />,
        bottomLeft: <StorySubNavigation storyId={storyId} />,
      }}
    >
      {story ? <StorySceneList story={story} /> : loading ? <LoadingSpinner /> : <ErrorContent />}
    </AdminLayout>
  );
};
