import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { EmptyState, LoadingSpinner } from '@cofenster/web-components';

import { type RenderTemplate, useRenderTemplate } from '../../../api/hooks/renderTemplate/useRenderTemplate';
import {
  type TemplateFontAsset,
  useTemplateFontAssets,
} from '../../../api/hooks/templateFontAsset/useTemplateFontAssets';
import { CodeBlock } from '../../../components/display/CodeBlock';
import type { TemplateRouteParams } from '../../../routes';
import { ErrorContent } from '../../Error';

const MainContent: FC<{
  renderTemplate: RenderTemplate;
  templateFontAssets?: TemplateFontAsset[];
}> = ({ renderTemplate, templateFontAssets }) => {
  const { accounts, ...templateRest } = renderTemplate;

  return <CodeBlock title="Template" code={{ ...templateRest, fonts: templateFontAssets }} />;
};

export const TemplateExportContent: FC = () => {
  const { renderTemplateId } = useParams() as TemplateRouteParams;
  const { renderTemplate, loading: templateLoading, error: templateError } = useRenderTemplate(renderTemplateId);
  const {
    templateFontAssets,
    loading: templateFontAssetsLoading,
    error: templateFontAssetsError,
  } = useTemplateFontAssets(renderTemplateId);
  const loading = templateFontAssetsLoading || templateLoading;
  const error = templateError ?? templateFontAssetsError;

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorContent resource="template" />;
  }

  if (!renderTemplate) {
    return (
      <EmptyState
        iconType="SearchIcon"
        title="Missing template"
        description={`Could not find the render template with ID ${renderTemplateId}.`}
      />
    );
  }

  return <MainContent renderTemplate={renderTemplate} templateFontAssets={templateFontAssets} />;
};
