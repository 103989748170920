import type { FC } from 'react';

import { Card, PageHeadline } from '@cofenster/web-components';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { RouterButton } from '../../../components/navigation/RouterButton';
import { TemplateForm } from '../../../components/templates/TemplateForm';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useGotoRenderTemplates } from '../../../hooks/useGotoRenderTemplates';
import { NoAccessLayout } from '../../NoAccess';

export const TemplateCreate: FC = () => {
  const gotoTemplates = useGotoRenderTemplates();

  return (
    <PermissionRestriction has="TemplateCreate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle="New template"
        header={{
          topLeft: <PageHeadline mb={0} onBack={gotoTemplates} title="New template" />,
          topRight: <RouterButton to="templateImport">Import</RouterButton>,
        }}
      >
        <Card>
          <TemplateForm />
        </Card>
      </AdminLayout>
    </PermissionRestriction>
  );
};
