import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { Button, Form, FormSubmitButton, GridContainer, GridItem } from '@cofenster/web-components';

import type { CreateStorySceneInput, UpdateStorySceneInput } from '../../../../../api/generated';
import { useCreateStoryScene } from '../../../../../api/hooks/storyScene/useCreateStoryScene';
import { useUpdateStoryScene } from '../../../../../api/hooks/storyScene/useUpdateStoryScene';

import type { StorySceneTypes } from '..';
import type { Story, StoryScene } from '../..';

import { StorySceneFormContent } from './StorySceneFormContent';

type Values = {
  title: string;
  titleEN: string;
  instruction: string | undefined;
  instructionEN: string | undefined;
  type: StorySceneTypes;
};

const validationSchema = Yup.object().shape({
  title: Yup.string().trim(),
  titleEN: Yup.string().trim(),
  instruction: Yup.string().trim(),
  instructionEN: Yup.string().trim(),
});

const useInitialValues = (storyScene: StoryScene | undefined) => {
  return useMemo<Values>(
    () => ({
      title: storyScene?.title ?? '',
      titleEN: storyScene?.titleEN ?? '',
      instruction: storyScene?.instruction ?? undefined,
      instructionEN: storyScene?.instructionEN ?? undefined,
      type: (storyScene?.type as StorySceneTypes) ?? 'video',
    }),
    [storyScene]
  );
};

const useCreate = (story: Story) => {
  const createStoryScene = useCreateStoryScene();
  return useCallback(
    async (values: Values) => {
      const valuesStoryWithId = { ...values, storyId: story.id } as CreateStorySceneInput;
      try {
        await createStoryScene(valuesStoryWithId);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [story, createStoryScene]
  );
};

const useUpdate = () => {
  const updateStoryScene = useUpdateStoryScene();
  return useCallback(
    async (storyScene: StoryScene, values: UpdateStorySceneInput) => {
      try {
        await updateStoryScene(storyScene.id, values);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [updateStoryScene]
  );
};

const useSubmit = (story: Story, storyScene: StoryScene | undefined) => {
  const createStoryScene = useCreate(story);
  const updateStoryScene = useUpdate();
  return useCallback(
    async (values: Values) => {
      try {
        if (storyScene) return await updateStoryScene(storyScene, values);
        return await createStoryScene(values);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [storyScene, createStoryScene, updateStoryScene]
  );
};

type StorySceneFormProps = {
  story: Story;
  storyScene?: StoryScene | undefined;
  deleteStoryScene: VoidFunction;
};

export const StorySceneForm: FC<StorySceneFormProps> = ({ story, storyScene, deleteStoryScene }) => {
  const initialValues = useInitialValues(storyScene);
  const onSubmit = useSubmit(story, storyScene);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <StorySceneFormContent storyScene={storyScene} />

      <GridContainer>
        <GridItem>
          <FormSubmitButton>{storyScene ? 'Save' : 'Create'}</FormSubmitButton>
        </GridItem>
        <GridItem>
          <Button variant="destructive" onClick={deleteStoryScene}>
            Delete
          </Button>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
