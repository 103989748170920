import type { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { SubNavigation } from '@cofenster/web-components';
import { useSupportsTemplateDefinition } from '../../hooks/useSupportsTemplateDefinition';
import { routes } from '../../routes';
import { SubNavigationItem } from '../navigation/SubNavigationItem';

type Props = {
  renderTemplateId: string;
};

export const TemplateSubNavigation: FC<Props> = ({ renderTemplateId }) => {
  const supportsTemplateDefinition = useSupportsTemplateDefinition(renderTemplateId);
  const templateDefinitionActive = !!useMatch(routes.templateDefinition);

  return (
    <SubNavigation>
      <SubNavigationItem
        to="template"
        params={{ renderTemplateId }}
        active={!!useMatch({ path: routes.template, end: true, caseSensitive: true })}
      >
        Template
      </SubNavigationItem>
      <SubNavigationItem
        to="templateTextCompositions"
        params={{ renderTemplateId }}
        active={!!useMatch(routes.templateTextCompositions)}
      >
        Text Compositions
      </SubNavigationItem>
      <SubNavigationItem to="templateUsers" params={{ renderTemplateId }} active={!!useMatch(routes.templateUsers)}>
        Accounts
      </SubNavigationItem>
      <SubNavigationItem to="templateFonts" params={{ renderTemplateId }} active={!!useMatch(routes.templateFonts)}>
        Fonts
      </SubNavigationItem>
      <SubNavigationItem to="templateExport" params={{ renderTemplateId }} active={!!useMatch(routes.templateExport)}>
        Export
      </SubNavigationItem>
      <SubNavigationItem
        to="templateLatestProjects"
        params={{ renderTemplateId }}
        active={!!useMatch(routes.templateLatestProjects)}
      >
        Latest projects
      </SubNavigationItem>
      {supportsTemplateDefinition === 'SUPPORTED' && (
        <SubNavigationItem to="templateDefinition" params={{ renderTemplateId }} active={templateDefinitionActive}>
          JSON Definition
        </SubNavigationItem>
      )}
    </SubNavigation>
  );
};
