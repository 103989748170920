import { useCallback } from 'react';

import { type UpdateAuthProviderMutationVariables, useUpdateAuthProviderMutation } from '../../generated';

export const useUpdateAuthProvider = () => {
  const [updateAuthProvider] = useUpdateAuthProviderMutation({
    refetchQueries: ['Account'],
  });
  return useCallback(
    (id: UpdateAuthProviderMutationVariables['id'], input: UpdateAuthProviderMutationVariables['input']) => {
      return updateAuthProvider({
        variables: { id, input },
      });
    },
    [updateAuthProvider]
  );
};
