import { type FC, useCallback } from 'react';

import { Button, useSnackbars } from '@cofenster/web-components';

import { useTranscodeVideoAsset } from '../../api/hooks/videoAsset/useTranscodeVideoAsset';
import type { RetranscodeVideoFit } from '../../components/dialog/RetranscodeAssetDialog/VideoFitSelect';
import type { RetranscodeVideoFormat } from '../../components/dialog/RetranscodeAssetDialog/VideoFormatSelect';
import { useDialogs } from '../../contexts/dialogs/useDialogs';
import { PermissionRestriction } from '../../contexts/staffUser/PermissionRestriction';

const useRetranscodeAsset = (id: string) => {
  const transcodeVideoAsset = useTranscodeVideoAsset();
  const { openSnackbar } = useSnackbars();
  const { openDialog, closeDialog } = useDialogs();
  const retranscode = useCallback(
    async ({ videoFit, videoFormat }: { videoFit: RetranscodeVideoFit; videoFormat: RetranscodeVideoFormat }) => {
      try {
        const hasSuccessfullyRetranscoded = await transcodeVideoAsset(id, {
          videoFit: videoFit === 'Automatic' ? undefined : videoFit,
          videoFormat: videoFormat === 'Automatic' ? undefined : videoFormat,
        });
        openSnackbar({
          variant: hasSuccessfullyRetranscoded ? 'success' : 'error',
          children: hasSuccessfullyRetranscoded
            ? `Successfully retranscoded video asset with id ${id}.`
            : `Failed to retranscode video asset with id ${id}.`,
        });
      } catch (error) {
        console.error(error);
        openSnackbar({
          variant: 'error',
          children: `An error occurred while attempting to retranscode video asset with id ${id}.`,
        });
      } finally {
        closeDialog('RetranscodeAssetDialog');
      }
    },
    [transcodeVideoAsset, closeDialog, id, openSnackbar]
  );

  const retranscodeAsset = useCallback(
    () => openDialog('RetranscodeAssetDialog', { retranscode }),
    [openDialog, retranscode]
  );

  return retranscodeAsset;
};

export const InspectRetranscodeButton: FC<{ id: string }> = ({ id }) => {
  const retranscodeAsset = useRetranscodeAsset(id);

  return (
    <PermissionRestriction has="AssetCreate">
      <Button type="button" onClick={retranscodeAsset} variant="destructive">
        Retranscode
      </Button>
    </PermissionRestriction>
  );
};
