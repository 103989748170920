import { type FC, useCallback } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Form,
  FormSubmitButton,
  FormTextField,
  Spacing,
  Text,
  useSnackbars,
} from '@cofenster/web-components';
import { useDeleteAccount } from '../../../api/hooks/account/useDeleteAccount';

type Values = {
  name: string;
};

const initialValues: Values = { name: '' };

const useValidationSchema = (accountName: string) => {
  const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Please type the account name')
      .equals([accountName], 'Please enter the name of the account correctly') as Yup.StringSchema<string>,
  });
  return validationSchema;
};

export type DeleteAccountDialogProps = {
  accountId: string;
  accountName: string;
  isOpen: boolean;
  closeDialog: () => unknown;
  gotoAccounts: () => unknown;
};

export const DeleteAccountDialog: FC<DeleteAccountDialogProps> = ({
  isOpen,
  accountId,
  accountName,
  closeDialog,
  gotoAccounts,
}) => {
  const { openSnackbar } = useSnackbars();
  const deleteAccount = useDeleteAccount();
  const validationSchema = useValidationSchema(accountName);

  const onAction = useCallback(async () => {
    try {
      await deleteAccount(accountId);
      openSnackbar({ children: `The account “${accountName}” was deleted.` });
      closeDialog();
      gotoAccounts();
    } catch (error) {
      // Rethrow the error as an `Error` so we display the actual message from
      // the backend instead of having it being replaced with the generic error
      // message by the `Form` component
      throw new Error((error as Error)?.message ?? 'An unexpected error happened while deleting the account.');
    }
  }, [accountId, accountName, deleteAccount, openSnackbar, closeDialog, gotoAccounts]);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="Delete the account" data-testid="delete-account-dialog">
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onAction}>
        <DialogContent>
          <Text variant="l" color="grey600" component="p" paragraph>
            You are about to delete the “<strong>{accountName}</strong>” account. In 14 days, all data associated to
            this account, including its assets such as images and videos will be deleted from the database, after which
            there will be no possible data recovery.
          </Text>

          <Text variant="l" color="grey600" component="p" paragraph>
            This action can only be reversed by an engineer, and only during the 14 day grace period. Type the account
            name below to confirm.
          </Text>

          <Spacing top={2}>
            <FormTextField
              id="deleteAccountName"
              name="name"
              label="Account name"
              placeholder="Account name"
              autoComplete="off"
              data-testid="delete-account-name-input"
            />
          </Spacing>
        </DialogContent>

        <DialogActions>
          <Button variant="tertiary" fullWidth onClick={closeDialog}>
            Cancel
          </Button>
          <FormSubmitButton variant="destructive" fullWidth data-testid="delete-account-dialog-confirm">
            Delete
          </FormSubmitButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};
