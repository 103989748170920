import type { FC } from 'react';

import { Card } from '@cofenster/web-components';

import type { StoryCategory } from '../../../api/hooks/storyCategory/useStoryCategory';
import { StoryCategoryForm } from '../../../components/stories/storyCategory/StoryCategoryForm';

type Props = {
  storyCategory: StoryCategory;
};

export const StoryCategoryCard: FC<Props> = ({ storyCategory }) => {
  return (
    <Card>
      <StoryCategoryForm storyCategory={storyCategory} />
    </Card>
  );
};
