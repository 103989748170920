import { type ChangeEvent, useMemo } from 'react';

import {
  GridContainer,
  GridItem,
  Select,
  type SelectChangeEvent,
  SelectOption,
  TextField,
} from '@cofenster/web-components';

import type { Option, OptionEditor } from './Option';

type SupportedUnits = 'Pixel' | 'Self%' | 'Video%';

export type Distance = `${number}|${SupportedUnits}`;

const distanceToObject = (distance: Distance) => {
  const [value, unit] = distance.split('|');

  if (!value) {
    throw new Error(`No value found in distance ‘${distance}’`);
  }

  return { value: Number.parseFloat(value), unit: unit as SupportedUnits };
};

const objectToDistance = (distance: { value: number; unit: SupportedUnits }): Distance => {
  return `${distance.value}|${distance.unit}`;
};

export const toCssStringValue = (distance: Distance, height: boolean) => {
  const { value, unit } = distanceToObject(distance);
  // 1. Everything there has to be without white spaces because of how the `createPreset` function works

  switch (unit) {
    case 'Pixel':
      return `${value}px`;
    case 'Self%':
      return `${value}%`;
    case 'Video%':
      if (height) {
        return `calc(${value / 100}*var(--video-height))`; // 1
      }

      return `calc(${value / 100}*var(--video-width))`; // 1
    default:
      throw new Error(`Unsupported unit: ${unit}`);
  }
};

const DistanceEditor: OptionEditor<Distance> = ({ value, onChange }) => {
  const { numericValue, unit } = useMemo(() => {
    const { value: numericValue, unit } = distanceToObject(value);
    return { numericValue, unit };
  }, [value]);

  const numericValueOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(
      objectToDistance({
        value: Number.parseFloat(event.target.value),
        unit: distanceToObject(value).unit,
      })
    );
  };

  const unitValueOnChange = (event: SelectChangeEvent<unknown>) => {
    onChange(
      objectToDistance({
        value: distanceToObject(value).value,
        unit: event.target.value as SupportedUnits,
      })
    );
  };

  return (
    <GridContainer>
      <GridItem xs={6}>
        <TextField type="number" min={0} label="Value" value={numericValue} onChange={numericValueOnChange} />
      </GridItem>
      <GridItem xs={6}>
        <Select name="distance-unit" label="Unit" value={unit} onChange={unitValueOnChange}>
          <SelectOption value="Pixel">px</SelectOption>
          <SelectOption value="Self%">Self %</SelectOption>
          <SelectOption value="Video%">Video %</SelectOption>
        </Select>
      </GridItem>
    </GridContainer>
  );
};

export const DistanceOption: Option<Distance> = {
  Editor: DistanceEditor,
  serialize: (value) => value,
  deserialize: (value) => value as Distance,
};
