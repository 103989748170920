import { type EasingName, Easings } from './Easings';
import {
  type Anchor,
  AnchorOption,
  EasingOption,
  OpacityOption,
  anchorToTransform,
  createBoundIntegerOption,
} from './Options';
import { DEFAULT_TIMING_OUT, TimingOption } from './Options/Timing';
import { createPreset } from './Preset';
import { createTimeline } from './utils';

export const GrowOut = createPreset(
  'GrowOut',
  ({ timing, easing, opacity, anchor, growTo }) => {
    return {
      ...(opacity && {
        opacity: createTimeline(timing, {
          0: { v: 1, e: Easings[easing] },
          1: { v: 0 },
        }),
      }),
      transform: createTimeline(timing, {
        0: { v: 'scale(1)', e: Easings[easing] },
        1: { v: anchorToTransform(growTo / 100, anchor) },
      }),
    };
  },
  {
    timing: TimingOption,
    easing: EasingOption,
    opacity: OpacityOption,
    anchor: AnchorOption,
    growTo: createBoundIntegerOption('Scale to', 101, 500, '%'),
  },
  {
    timing: DEFAULT_TIMING_OUT,
    easing: 'EaseIn' as EasingName,
    opacity: true as boolean,
    anchor: 'Center' as Anchor,
    growTo: 200 as number,
  }
);
