import { useCallback } from 'react';

import { type CreateUserMutationVariables, useCreateUserMutation } from '../../generated';

export const useCreateUser = () => {
  const [createUser] = useCreateUserMutation({ refetchQueries: ['Users'] });

  return useCallback(
    (input: CreateUserMutationVariables['input']) => createUser({ variables: { input } }),
    [createUser]
  );
};
