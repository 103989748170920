import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { GridContainer, GridItem, LoadingSpinner, PageHeadline, useGoto } from '@cofenster/web-components';

import { useAccount } from '../../../api/hooks/account/useAccount';
import { useUser } from '../../../api/hooks/user/useUser';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { NoAccessLayout } from '../../../pages/NoAccess';
import { type UserRouteParams, routes } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';

import { ImpersonateButton } from './ImpersonateButton';
import { UserUpdateContent } from './UserUpdateContent';
import { InviteLinkForm } from './UserUpdateContent/InviteLinkForm';
import { ResetPasswordLinkForm } from './UserUpdateContent/ResetPasswordLinkForm';

export const UserUpdate: FC = () => {
  const { userId } = useParams() as UserRouteParams;
  const { user, loading, error } = useUser(userId);
  const accountId = user?.account.id;
  const { account } = useAccount(accountId);
  const title = user?.name ?? 'Member';
  const goto = useGoto();
  const usesPassword = account && !account.authProvider;
  const isDeactivated = Boolean(user?.deactivatedAt);

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="UserUpdate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: (
            <PageHeadline
              mb={0}
              title={title}
              onBack={accountId ? () => goto(routes.accountUsers, { accountId }) : undefined}
            />
          ),
          topRight: user && !user?.deactivatedAt && <ImpersonateButton userId={user.id} />,
        }}
      >
        {user ? (
          <GridContainer>
            <GridItem xs={12}>
              <UserUpdateContent user={user} />
            </GridItem>
            {!isDeactivated && user.isPending && (
              <GridItem xs={12} md={6}>
                <InviteLinkForm userId={user.id} />
              </GridItem>
            )}
            {!isDeactivated && usesPassword && (
              <GridItem xs={12} md={6}>
                <ResetPasswordLinkForm userId={user.id} />
              </GridItem>
            )}
          </GridContainer>
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <ErrorContent />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
