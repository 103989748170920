import { type FC, type FocusEventHandler, useCallback, useMemo, useState } from 'react';

import {
  Card,
  Form,
  FormSubmitButton,
  FormTextField,
  GridContainer,
  GridItem,
  Headline,
  Text,
  useSnackbars,
} from '@cofenster/web-components';

import { useGenerateInviteLink } from '../../../../api/hooks/user/useGenerateInviteLink';

export const InviteLinkForm: FC<{ userId: string }> = ({ userId }) => {
  const [inviteLink, setInviteLink] = useState<string | undefined>('');
  const generateInviteLink = useGenerateInviteLink();
  const { openSnackbar } = useSnackbars();
  const initialValues = useMemo(() => ({ inviteLink }), [inviteLink]);

  const copyCodeToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(inviteLink as string);
    openSnackbar({ variant: 'success', children: 'Copied to clipboard!' });
  }, [inviteLink, openSnackbar]);

  const handleSubmit = useCallback(async () => {
    const link = await generateInviteLink(userId);
    setInviteLink(link.data?.generateInviteLink.link as string);
    await copyCodeToClipboard();
  }, [copyCodeToClipboard, generateInviteLink, userId]);

  const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    async (event) => {
      event.target.select();
      await copyCodeToClipboard();
    },
    [copyCodeToClipboard]
  );

  return (
    <Card>
      <Headline variant="h4" component="h2">
        Invite link
      </Headline>
      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        <GridContainer mt={1} spacing={1}>
          <GridItem xs={12} mb={1}>
            <Text variant="l" color="grey600" component="p">
              Pressing the button below will generate a new invite link for that user, valid for 5 days.{' '}
              <strong>Previous invitation links will no longer be valid.</strong>
            </Text>
          </GridItem>
          <GridItem xs={12}>
            <FormTextField
              id="inviteLink"
              name="inviteLink"
              label="Invite link"
              disabled={!inviteLink}
              onFocus={handleFocus}
              inputProps={{ readOnly: true }}
              data-testid="invite-link-input"
            />
          </GridItem>
          <GridItem xs={12} display="flex" justifyContent="flex-end">
            <FormSubmitButton variant="secondary" data-testid="invite-link-generate-button">
              Generate invite link
            </FormSubmitButton>
          </GridItem>
        </GridContainer>
      </Form>
    </Card>
  );
};
