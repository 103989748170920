import { useMemo } from 'react';

import { useSearchTextCompositionQueryQuery } from '../../generated';

export const useSearchTextComposition = (searchText: string) => {
  const { loading, error, data } = useSearchTextCompositionQueryQuery({
    variables: { text: searchText },
    fetchPolicy: 'network-only',
  });
  return useMemo(
    () => ({
      data,
      loading,
      error,
    }),
    [loading, error, data]
  );
};
