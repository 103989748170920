import type { FC } from 'react';

import { Card, PageHeadline } from '@cofenster/web-components';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { StoryForm } from '../../../components/stories/story/StoryForm';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useGotoStories } from '../../../hooks/useGotoStories';
import { NoAccessLayout } from '../../NoAccess';

export const StoryCreate: FC = () => {
  const gotoStories = useGotoStories();

  return (
    <PermissionRestriction has="StoryCreate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle="New Story"
        header={{
          topLeft: <PageHeadline mb={0} title="New Story" onBack={gotoStories} />,
        }}
      >
        <Card>
          <StoryForm />
        </Card>
      </AdminLayout>
    </PermissionRestriction>
  );
};
