import { useCallback } from 'react';

import {
  type DeleteStaffUserMutationResult,
  type DeleteStaffUserMutationVariables,
  useDeleteStaffUserMutation,
} from '../../generated';

type Result = NonNullable<DeleteStaffUserMutationResult['data']>['deleteStaffUser'];
export type StaffUser = Extract<Result, { __typename?: 'StaffUser' }>;
export type StaffUserError = Extract<Result, { __typename?: 'StaffUserError' }>;

export const useDeleteStaffUser = () => {
  const [deleteStaffUser] = useDeleteStaffUserMutation({
    refetchQueries: ['StaffUsers'],
  });

  return useCallback(
    (id: DeleteStaffUserMutationVariables['id']) => deleteStaffUser({ variables: { id } }),
    [deleteStaffUser]
  );
};
