import { useMemo } from 'react';

import { type CofensterProjectTemplatesQueryResult, useCofensterProjectTemplatesQuery } from '../../generated';

export type ProjectTemplate = NonNullable<
  CofensterProjectTemplatesQueryResult['data']
>['cofensterProjectTemplates'][number];

export const useCofensterProjectTemplates = () => {
  const { loading, error, data } = useCofensterProjectTemplatesQuery();
  const cofensterProjectTemplates = data?.cofensterProjectTemplates ?? undefined;
  return useMemo(() => ({ cofensterProjectTemplates, loading, error }), [cofensterProjectTemplates, loading, error]);
};
