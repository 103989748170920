import {
  type CSSProperties,
  type FC,
  Fragment,
  type PropsWithChildren,
  type ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import type { VideoFormat } from '@cofenster/constants';
import {
  BaseLayout,
  Button,
  GridContainer,
  GridItem,
  Headline,
  Icon,
  IconButton,
  LoadingSpinner,
  TextField,
  styled,
  useSnackbars,
  useUndoableState,
} from '@cofenster/web-components';

import { useMeQuery } from '../../../api/generated';
import {
  type RenderTemplate,
  type TextComposition,
  useRenderTemplate,
} from '../../../api/hooks/renderTemplate/useRenderTemplate';
import { useCreateTemplateDefinition } from '../../../api/hooks/templateDefinition/useCreateTemplateDefinition';
import { useTemplateFontAssets } from '../../../api/hooks/templateFontAsset/useTemplateFontAssets';
import { RouterIconButton } from '../../../components/navigation/RouterIconButton';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useSupportsTemplateDefinition } from '../../../hooks/useSupportsTemplateDefinition';
import type { TemplateRouteParams } from '../../../routes';
import { NoAccessLayout } from '../../NoAccess';

import { ErrorLayout } from '../../Error';
import { CopyPasteStyleDefinition } from './CopyPasteStyleDefinition';
import { FormatOptions } from './FormatOptions';
import { PathSelect, usePathSelectState } from './PathSelect';
import { Placeholders } from './Placeholders';
import { TemplateDefinitionPreview } from './TemplateDefinitionPreview/TemplateDefinitionPreview';
import { useRenderDescriptionCustomColor } from './TemplateDefinitionPreview/useRenderDescriptionCustomColor';
import { useRenderDescriptionCustomText } from './TemplateDefinitionPreview/useRenderDescriptionCustomText';
import { useRenderDescriptionForPreview } from './TemplateDefinitionPreview/useRenderDescriptionForPreview';
import {
  TemplateDefinitionStateContext,
  TemplateDefinitionStateProvider,
  isTemplateDefinitionTextElementStyleDefinitionPath,
  useSetTemplateDefinition,
  useStylePropertiesState,
  useTemplateDefinition,
} from './TemplateDefinitionStateProvider';
import { TabbedEditor } from './compositions';
import { SelectorsEditor } from './compositions/SelectorsEditor';
import { jsonAsyncParse, jsonSafeStringify } from './utils';

const HeaderContainer = styled('div')(({ theme }) => ({
  alignSelf: 'stretch',
  backgroundColor: theme.palette.brand.white,
  borderBottom: `1px solid ${theme.palette.brand.linen50}`,
}));

type EditorLayoutProps = {
  documentTitle: string;
  topLeft: ReactNode;
  topRight: ReactNode;
  bottom: ReactNode;
};

const OverallContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  maxHeight: '100%',
  backgroundColor: theme.palette.brand.linen,
}));

const OverallContainerWithStyle: FC<PropsWithChildren> = ({ children }) => {
  return <OverallContainer style={{ '--page-padding-bottom': 0 } as CSSProperties}>{children}</OverallContainer>;
};

const EditorLayout: FC<PropsWithChildren<EditorLayoutProps>> = ({
  documentTitle,
  topLeft,
  topRight,
  bottom,
  children,
}) => {
  return (
    <BaseLayout
      documentTitle={documentTitle}
      navigation={null}
      OverallContainerComponent={OverallContainerWithStyle}
      MainContentAreaComponent={Fragment}
    >
      <HeaderContainer>
        <GridContainer direction="row" justifyContent="space-between" alignItems="flex-start" p={2} spacing={1}>
          <GridItem xs={12}>
            <GridContainer direction="row" alignItems="space-between">
              <GridItem xs={6} display="flex" gap={1} justifyContent="start" alignItems="center">
                {topLeft}
              </GridItem>
              <GridItem xs={6} display="flex" gap={1} justifyContent="end" alignItems="center">
                {topRight}
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} display="flex" gap={1} justifyContent="start">
            {bottom}
          </GridItem>
        </GridContainer>
      </HeaderContainer>
      {children}
    </BaseLayout>
  );
};

export const TemplateDefinition: FC = () => {
  const { renderTemplateId } = useParams() as TemplateRouteParams;
  const { renderTemplate, error } = useRenderTemplate(renderTemplateId);
  const supportsTemplateDefinition = useSupportsTemplateDefinition(renderTemplateId);

  if (error) return <ErrorLayout title="Template editor" />;
  if (!renderTemplate || supportsTemplateDefinition === 'UNKNOWN') return <LoadingSpinner />;
  if (supportsTemplateDefinition === 'UNSUPPORTED') return <NoAccessLayout />;

  return (
    <TemplateDefinitionStateProvider renderTemplateId={renderTemplateId}>
      <TemplateDefinitionEditor renderTemplate={renderTemplate} />
    </TemplateDefinitionStateProvider>
  );
};

const GoBackButton: FC<{ renderTemplateId: string }> = ({ renderTemplateId }) => (
  <RouterIconButton
    icon="CloseIcon"
    iconSize="m"
    to="template"
    params={{ renderTemplateId }}
    label="i18n.global.close"
    key="close"
  />
);

const SmallButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0, 6),
}));

const SaveButton: FC<{ renderTemplateId: string }> = ({ renderTemplateId }) => {
  const templateDefinition = useTemplateDefinition();
  const createTemplateDefinition = useCreateTemplateDefinition();
  const { openSnackbar } = useSnackbars();

  return (
    <SmallButton
      onClick={async () => {
        try {
          await createTemplateDefinition({
            renderTemplateId,
            definition: templateDefinition,
            definitionVersion: 0,
          });
          openSnackbar({
            variant: 'success',
            children: 'Saved successfully',
          });
        } catch {
          openSnackbar({ variant: 'error', children: 'Failed to save' });
        }
      }}
    >
      Save
    </SmallButton>
  );
};

const JSONEditor: FC = () => {
  const templateDefinition = useTemplateDefinition();
  const setTemplateDefinition = useSetTemplateDefinition();
  return (
    <GridItem overflow="hidden">
      <TextField
        label="Developer Mode"
        fullWidth
        multiline
        value={jsonSafeStringify(templateDefinition)}
        onChange={(e) => jsonAsyncParse(e.target.value).then(setTemplateDefinition, console.error)}
      />
    </GridItem>
  );
};

const EditorSidebar = styled(GridItem)(({ theme }) => ({
  background: theme.palette.brand.white,
  padding: theme.spacing(1, 3),
  overflowY: 'auto',
  flexDirection: 'column',
}));

const EditorContentContainer = styled(GridContainer)(() => ({
  overflow: 'auto',
  height: 'calc(100vh - 80px)',
}));

const GlobalUndoRedo: FC = () => {
  const { state } = useContext(TemplateDefinitionStateContext);
  const setState = useSetTemplateDefinition();
  const { undo, redo, canUndo, canRedo, reset } = useUndoableState([state, setState]);
  return (
    <>
      <IconButton icon="UndoIcon" label="Undo" onClick={undo} disabled={!canUndo} />
      <IconButton icon="RedoIcon" label="Redo" onClick={redo} disabled={!canRedo} />
      <IconButton icon="BroomIcon" label="Reset" onClick={reset} />
    </>
  );
};

const TemplateDefinitionEditor: FC<{ renderTemplate: RenderTemplate }> = ({ renderTemplate }) => {
  const [videoFormat, setVideoFormat] = useState<VideoFormat>('Horizontal');
  const { path, setPath } = usePathSelectState();
  const [pathState, setPathState] = useStylePropertiesState(path);
  const [responsivePathState, setResponsivePathState] = useStylePropertiesState(path, videoFormat);
  const [developer, setDeveloper] = useState(false);

  // check for authentication every 30s
  useMeQuery({ pollInterval: 30000, initialFetchPolicy: 'cache-only', nextFetchPolicy: 'network-only' });

  const textComposition = useMemo<TextComposition | undefined>(() => {
    if (isTemplateDefinitionTextElementStyleDefinitionPath(path)) {
      const name = path.composition.split('.')[1];
      return renderTemplate.textCompositions.find((it) => it.aeCompositionName === name);
    }
    return renderTemplate.textCompositions?.[0];
  }, [path, renderTemplate.textCompositions]);
  const previewTextCompositions = useMemo<TextComposition[]>(
    () => (textComposition ? [textComposition] : []),
    [textComposition]
  );

  const templateName = renderTemplate.name;
  const paddingResponsive = { xs: 1, sm: 2, md: 3, lg: 4 };
  const renderTemplateId = renderTemplate.id;

  const { setText, texts } = useRenderDescriptionCustomText(renderTemplateId);

  const { templateFontAssets } = useTemplateFontAssets(renderTemplateId);
  const templateDefinition = useTemplateDefinition();

  // Placeholder Setup
  const [placeholderLogoUrl, setPlaceholderLogoUrl] = useState<string>();
  const [uploadedSceneBackgroundUrl, setUploadedSceneBackgroundUrl] = useState<string | undefined>();
  const { customColor, setCustomColor } = useRenderDescriptionCustomColor('customColorPlaceholder');
  const customPrimaryColor = customColor?.[renderTemplateId]?.Primary ?? '#EA1111';
  const customSecondaryColor = customColor?.[renderTemplateId]?.Secondary ?? '#0ED863';

  const renderDescriptionBase = useRenderDescriptionForPreview(
    previewTextCompositions,
    videoFormat,
    templateFontAssets,
    texts,
    path.composition === 'transition' || path.composition === 'logo',
    placeholderLogoUrl,
    customPrimaryColor,
    customSecondaryColor,
    uploadedSceneBackgroundUrl
  );

  const renderDescription = useMemo(
    () =>
      renderDescriptionBase && {
        ...renderDescriptionBase,
        templateDefinition,
      },
    [renderDescriptionBase, templateDefinition]
  );

  const editableTextContent = (
    <Placeholders
      textComposition={textComposition}
      texts={texts}
      renderTemplateId={renderTemplateId}
      customPrimaryColor={customPrimaryColor}
      customSecondaryColor={customSecondaryColor}
      setText={setText}
      setCustomColor={setCustomColor}
      setPlaceholderLogoUrl={setPlaceholderLogoUrl}
      format={videoFormat}
      setUploadedSceneBackgroundUrl={setUploadedSceneBackgroundUrl}
    />
  );

  return (
    <PermissionRestriction has="TemplateUpdate" fallback={<NoAccessLayout />}>
      <EditorLayout
        documentTitle={templateName}
        topLeft={
          <Headline variant="h4" component="h1">
            {templateName}
          </Headline>
        }
        topRight={
          <>
            <DeveloperModeButton value={developer} onClick={() => setDeveloper((prev) => !prev)} />
            <SaveButton renderTemplateId={renderTemplateId} />
            <GoBackButton renderTemplateId={renderTemplateId} />
          </>
        }
        bottom={
          <GridContainer>
            <GridItem display="flex" alignItems="center" justifyContent="center">
              <GlobalUndoRedo />
            </GridItem>
            <GridItem>
              <Separator />
            </GridItem>
            <GridItem>
              <FormatOptions setVideoFormat={setVideoFormat} format={videoFormat} />
            </GridItem>
            <GridItem>
              <Separator />
            </GridItem>
            <GridItem display="flex" alignItems="center" justifyContent="center">
              <CopyPasteStyleDefinition path={path} />
            </GridItem>
          </GridContainer>
        }
      >
        <EditorContentContainer spacing={0} mt={0}>
          <EditorSidebar xs={2}>
            <PathSelect path={path} setPath={setPath} textCompositions={renderTemplate.textCompositions} />
          </EditorSidebar>
          <GridItem xs={6}>
            <GridContainer pt={6} pb={3} pl={paddingResponsive} pr={paddingResponsive} position="sticky" top={0}>
              {renderDescription && (
                <GridItem xs={12}>
                  <TemplateDefinitionPreview
                    previewBundleUrl={renderTemplate.previewBundleUrl}
                    templateIdentifier={renderTemplate.templateIdentifier}
                    videoFormat={videoFormat}
                    renderDescription={renderDescription}
                  />
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
          <EditorSidebar xs={4} display="flex">
            <GridContainer direction="column" mt={0}>
              {developer ? (
                <JSONEditor />
              ) : (
                <>
                  {path.subComponent?.endsWith('selectors') ? (
                    <SelectorsEditor videoFormat={videoFormat} path={path} />
                  ) : (
                    <TabbedEditor
                      state={pathState}
                      setState={setPathState}
                      key={`${path.composition}.${path.component}`}
                      responsiveState={responsivePathState}
                      setResponsiveState={setResponsivePathState}
                      editableTextContent={editableTextContent}
                      videoFormat={videoFormat}
                      path={path}
                    />
                  )}
                </>
              )}
            </GridContainer>
          </EditorSidebar>
        </EditorContentContainer>
      </EditorLayout>
    </PermissionRestriction>
  );
};

const DeveloperModeButton: FC<{
  value: boolean;
  onClick: VoidFunction;
}> = ({ value, onClick }) => {
  return (
    <SmallButton
      variant="tertiary"
      onClick={onClick}
      startIcon={value ? <Icon type="CodeIcon" size="s" /> : <Icon type="PaletteIcon" size="s" />}
    >
      {value ? 'Developer mode' : 'Designer mode'}
    </SmallButton>
  );
};

const Separator = styled('div')(({ theme }) => {
  const spacing = 1;
  return {
    width: 1,
    height: `calc(100% - ${theme.spacing(spacing)})`,
    marginTop: theme.spacing(spacing / 2),
    backgroundColor: theme.palette.divider,
  };
});
