import { arrayMoveImmutable as arrayMove } from 'array-move';
import { type FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Button, SortableList, styled, useDisclosure } from '@cofenster/web-components';

import type { TextComposition, TextInput } from '../../../../../../api/hooks/renderTemplate/useRenderTemplate';
import { useDeleteTextInput } from '../../../../../../api/hooks/textInput/useDeleteTextInput';
import { useSortTextInputs } from '../../../../../../api/hooks/textInput/useSortTextInputs';

import { TextCompositionTextInput } from './TextCompositionTextInput';

type SortableItemProps = {
  value: TextInput;
  textComposition: TextComposition;
};

const useDelete = (textInput: TextInput | undefined) => {
  const deleteTextInput = useDeleteTextInput('Delete Text Input?');
  return useCallback(async () => {
    if (textInput) await deleteTextInput(textInput.id);
  }, [textInput, deleteTextInput]);
};

const SortableItem = ({ value: layer, textComposition }: SortableItemProps) => {
  const deleteTextInput = useDelete(layer);
  return (
    <TextCompositionTextInput textComposition={textComposition} textInput={layer} deleteTextInput={deleteTextInput} />
  );
};

const useSortableTextInputs = (textComposition: TextComposition | undefined) => {
  const sortTextInputs = useSortTextInputs();
  const compositionTextInputs = useMemo(() => textComposition?.textInputs ?? [], [textComposition?.textInputs]);
  const [textInputs, setTextInputs] = useState(compositionTextInputs);

  useEffect(() => setTextInputs(compositionTextInputs), [compositionTextInputs]);

  const onSort = useCallback(
    async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const sortedTextInputs = arrayMove(textInputs, oldIndex, newIndex);
      setTextInputs(sortedTextInputs);
      const data = sortedTextInputs.map((layer) => layer.id);
      sortTextInputs(data);
    },
    [textInputs, sortTextInputs]
  );

  return useMemo(() => ({ textInputs, onSort }) as const, [textInputs, onSort]);
};

const Content = styled('div')(() => ({
  '&[aria-hidden="true"]': {
    display: 'none',
  },
}));

type Props = {
  textComposition: TextComposition;
};

function usePrevious<T>(value: T) {
  const ref = useRef<T | undefined>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const TextCompositionTextInputs: FC<Props> = ({ textComposition }) => {
  const { textInputs, onSort } = useSortableTextInputs(textComposition);
  const { toggleProps, contentProps, isExpanded, toggle } = useDisclosure({
    id: 'new-tc-ti',
    isExpanded: textInputs.length === 0,
  });
  const amountOfTextInputs = usePrevious(textInputs.length);

  useEffect(() => {
    // If the previous amount of text inputs is not yet defined, do not do
    // anything as this means we just mounted and do not want to expand the form
    if (typeof amountOfTextInputs === 'undefined') return;
    // If we just added a new text input, collapse the form so its values get
    // flushed properly
    if (amountOfTextInputs < textInputs.length) toggle();
  }, [amountOfTextInputs, textInputs.length, toggle]);

  return (
    <>
      <SortableList
        items={textInputs.map((value) => (
          <SortableItem key={value.id} value={value} textComposition={textComposition} />
        ))}
        ids={textInputs.map((value) => value.id)}
        onSortEnd={onSort}
        restrictTo="y"
      />

      <Content {...contentProps}>
        {
          // Unmount the text input form when the section is collapsed so its
          // inputs get emptied, otherwise their value remain filled which leads
          // to a confusing experience
          isExpanded && <TextCompositionTextInput textComposition={textComposition} deleteTextInput={toggle} />
        }
      </Content>

      {!isExpanded && (
        <Button {...toggleProps} variant="primary">
          Add text input
        </Button>
      )}
    </>
  );
};
