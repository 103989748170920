import { useCallback } from 'react';

import { type CreateTextCompositionMutationVariables, useCreateTextCompositionMutation } from '../../generated';

export const useCreateTextComposition = () => {
  const [createTextComposition] = useCreateTextCompositionMutation({
    refetchQueries: ['RenderTemplate'],
  });
  return useCallback(
    (
      renderTemplateId: CreateTextCompositionMutationVariables['renderTemplateId'],
      input: CreateTextCompositionMutationVariables['input']
    ) => createTextComposition({ variables: { renderTemplateId, input } }),
    [createTextComposition]
  );
};
