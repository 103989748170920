import { type FC, type PropsWithChildren, useCallback, useMemo } from 'react';

import { LoadingSpinner } from '@cofenster/web-components';
import { useMe } from '../../api/hooks/staffUser/useMe';
import { routes } from '../../routes';
import { StaffUserContext } from './StaffUserContext';

export const StaffUserProvider: FC<PropsWithChildren> = ({ children }) => {
  const { staffUser, loading } = useMe();

  const signout = useCallback(() => {
    // We are outside the context of the `Router`.
    // So we can't use `useNavigate()` here.
    window.location.href = routes.signout;
  }, []);

  const context = useMemo(() => ({ staffUser, signout }), [staffUser, signout]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return <StaffUserContext.Provider value={context}>{children}</StaffUserContext.Provider>;
};
