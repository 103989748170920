import { useField } from 'formik';
import { type FC, type MouseEventHandler, useCallback, useState } from 'react';

import { MimeTypes } from '@cofenster/constants';
import {
  AbsoluteDeleteIcon,
  AssetDropzone,
  type AssetDropzoneProps,
  Box,
  DropzoneContainer,
  DropzoneStatus,
  NativeVideoPlayer,
  useUploadHint,
} from '@cofenster/web-components';
import { usePollingVideoAsset } from '../../api/hooks/videoAsset/usePollingVideoAsset';

import { useDialogs } from '../../contexts/dialogs/useDialogs';
import { useConfirmNavigationDuringUpload } from '../../hooks/useConfirmNavigationDuringUpload';
import { usePublicVideoUpload } from '../../hooks/usePublicVideoUpload';

type Props = {
  name: string;
  assetDropzoneProps?: Partial<AssetDropzoneProps>;
};

export const VideoAssetUpload: FC<Props> = ({ name, assetDropzoneProps }) => {
  const [{ value: videoAssetId }, meta, { setTouched, setValue, setError }] = useField(name);
  const videoAsset = usePollingVideoAsset(videoAssetId);
  const [videoUpload, progress] = usePublicVideoUpload();
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const { openDialog } = useDialogs();
  const hintText = useUploadHint('video', 'Square');

  useConfirmNavigationDuringUpload(progress !== undefined);

  const uploadFile = useCallback(
    async (file: File) => {
      const videoAssetId = await videoUpload(file, { videoFit: 'Fit' });
      setValue(videoAssetId);
      setFileName(file.name);
      setTouched(true);
    },
    [videoUpload, setValue, setTouched]
  );

  const deleteFile: MouseEventHandler = useCallback(
    () =>
      openDialog('ConfirmDialog', {
        title: 'Delete asset',
        content: 'Are you sure you want to permanently delete this asset?',
        confirm: 'Delete',
        variant: 'destructive',
        onConfirm: () => {
          setValue(undefined);
          setTouched(true);
        },
      }),
    [setValue, setTouched, openDialog]
  );

  const onError = useCallback(
    (error: string) => {
      setError(error);
      setTouched(true);
    },
    [setError, setTouched]
  );

  if (videoAsset?.videoUrl) {
    return (
      <DropzoneContainer size="medium">
        <Box fullHeight backgroundColor="grey50">
          <NativeVideoPlayer
            src={videoAsset.videoUrl}
            poster={videoAsset.thumbnailUrl ?? undefined}
            actions={['DOWNLOAD']}
          />
          <AbsoluteDeleteIcon onClick={deleteFile} disabled={assetDropzoneProps?.disabled} />
        </Box>
      </DropzoneContainer>
    );
  }

  if (progress !== undefined) {
    return (
      <DropzoneContainer size="medium">
        <DropzoneStatus
          status="uploading"
          text={fileName}
          hintText="Your video is uploading."
          progress={progress || 0}
        />
      </DropzoneContainer>
    );
  }

  if (videoAsset?.status === 'Waiting') {
    return (
      <DropzoneContainer size="medium">
        <DropzoneStatus status="processing" text={fileName} hintText="Almost done, your video will be ready soon!" />
      </DropzoneContainer>
    );
  }

  return (
    <DropzoneContainer size="medium">
      <AssetDropzone
        error={meta.error}
        backgroundColor="grey50"
        mime={MimeTypes.video}
        maxSize={100 * 1024 * 1024}
        onFile={uploadFile}
        icon="CloudUploadIcon"
        text="Drop your video here or browse"
        hintText={hintText}
        onError={onError}
        {...assetDropzoneProps}
      />
    </DropzoneContainer>
  );
};
