import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Card, LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useStory } from '../../../api/hooks/story/useStory';
import { useStoryCategories } from '../../../api/hooks/storyCategory/useStoryCategories';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { StorySubNavigation } from '../../../components/stories/story/StorySubNavigation';
import { useGotoStories } from '../../../hooks/useGotoStories';
import type { StoryRouteParams } from '../../../routes';

import { ErrorContent, ErrorLayout } from '../../Error';
import { StoryCategoriesForm } from './StoryCategoriesForm';

export type { StoryCategory } from '../../../api/hooks/storyCategory/useStoryCategories';
export type { Story } from '../../../api/hooks/story/useStory';

export const StoryStoryCategories: FC = () => {
  const { storyId } = useParams() as StoryRouteParams;
  const { story, loading: storyLoading, error: storyError } = useStory(storyId);
  const { storyCategories, loading: storyCategoriesLoading, error: storyCategoriesError } = useStoryCategories();
  const gotoStories = useGotoStories();
  const title = ['Categories', story?.name].filter(Boolean).join(' — ');
  const loading = storyLoading || storyCategoriesLoading;
  const error = storyError || storyCategoriesError;

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <AdminLayout
      documentTitle={title}
      header={{
        topLeft: <PageHeadline mb={0} title={title} onBack={gotoStories} />,
        bottomLeft: <StorySubNavigation storyId={storyId} />,
      }}
    >
      {story ? (
        <Card>
          <StoryCategoriesForm story={story} storyCategories={storyCategories} />
        </Card>
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <ErrorContent />
      )}
    </AdminLayout>
  );
};
