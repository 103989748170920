import { type FC, useCallback, useId } from 'react';

import { Select, type SelectChangeEvent, SelectOption } from '@cofenster/web-components';

export type CheckboxDisplayType = 'ALL' | 'ENABLED' | 'DISABLED';

export const filterItemsByDisplay = (values: string[], display: CheckboxDisplayType) => (item: { id: string }) => {
  if (display === 'ALL') return true;
  if (display === 'ENABLED' && values.includes(item.id)) return true;
  if (display === 'DISABLED' && !values.includes(item.id)) return true;
  return false;
};

export const CheckboxDisplay: FC<{
  display: CheckboxDisplayType;
  setDisplay: (value: CheckboxDisplayType) => void;
}> = ({ display, setDisplay }) => {
  const id = useId();
  const onChange = useCallback(
    (event: SelectChangeEvent<unknown>) => setDisplay(event.target.value as CheckboxDisplayType),
    [setDisplay]
  );

  return (
    <Select id={`display-${id}`} name={`display-${id}`} label="Display" pb={0} value={display} onChange={onChange}>
      <SelectOption value="ALL">All</SelectOption>
      <SelectOption value="ENABLED">Enabled</SelectOption>
      <SelectOption value="DISABLED">Disabled</SelectOption>
    </Select>
  );
};
