import { useCallback } from 'react';

import { type CreateAuthProviderMutationVariables, useCreateAuthProviderMutation } from '../../generated';

export const useCreateAuthProvider = () => {
  const [createAuthProvider] = useCreateAuthProviderMutation({ refetchQueries: ['Account'] });
  return useCallback(
    (input: CreateAuthProviderMutationVariables['input']) => createAuthProvider({ variables: { input } }),
    [createAuthProvider]
  );
};
