import type { FC, PropsWithChildren, ReactNode } from 'react';

import { type StaffUserPermission, useStaffUser } from './useStaffUser';

type AllNever = {
  has?: never;
  not?: never;
  and?: never;
  or?: never;
};
type WithHas = Omit<AllNever, 'has'> & { has: StaffUserPermission };
type WithNot = Omit<AllNever, 'not'> & { not: StaffUserPermission };
type WithAnd = Omit<AllNever, 'and'> & { and: StaffUserPermission[] };
type WithOr = Omit<AllNever, 'or'> & { or: StaffUserPermission[] };

export type PermissionOptions = WithHas | WithNot | WithAnd | WithOr;

export const useHasPermission = (options: PermissionOptions) => {
  const { has, not, and, or } = options;
  const { staffUser } = useStaffUser();
  const permissions = staffUser?.role?.permissions ?? [];

  if (has) return permissions.includes(has);

  if (not) return !permissions.includes(not);

  if (and) return and.every((permission) => permissions.includes(permission));

  if (or) return or.some((permission) => permissions.includes(permission));
};

export const PermissionRestriction: FC<PropsWithChildren<{ fallback?: ReactNode } & PermissionOptions>> = ({
  children,
  fallback = null,
  ...options
}) => {
  const hasPermission = useHasPermission(options);
  return hasPermission ? <>{children}</> : fallback;
};
