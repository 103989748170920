import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Card, Headline, LoadingSpinner, PageHeadline, styled } from '@cofenster/web-components';

import { useAccount } from '../../../../api/hooks/account/useAccount';
import { AccountSubNavigation } from '../../../../components/accounts/AccountSubNavigation';
import { AdminLayout } from '../../../../components/layout/AdminLayout';
import { PermissionRestriction, useHasPermission } from '../../../../contexts/staffUser/PermissionRestriction';
import { useGotoAccounts } from '../../../../hooks/useGotoAccounts';
import type { AccountRouteParams } from '../../../../routes';
import { ErrorContent, ErrorLayout } from '../../../Error';
import { NoAccessLayout } from '../../../NoAccess';

import { AuthProviderForm } from './AuthProviderForm';

const CardHeadline = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const AccountAuthProvider: FC = () => {
  const { accountId } = useParams() as AccountRouteParams;
  const { account, loading, error } = useAccount(accountId);
  const title = ['SSO', account?.name].filter(Boolean).join(' — ');
  const gotoAccounts = useGotoAccounts();
  const canUpdateAccounts = useHasPermission({ has: 'AccountUpdate' });

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="AccountRead" fallback={<NoAccessLayout documentTitle={title} />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={gotoAccounts} />,
          bottomLeft: <AccountSubNavigation accountId={accountId} />,
        }}
      >
        <Card>
          <CardHeadline color="carbon" variant="h3" component="h2">
            Auth provider
          </CardHeadline>
          {account ? (
            <AuthProviderForm
              accountId={account.id}
              authProvider={account.authProvider}
              disabled={!canUpdateAccounts}
            />
          ) : loading ? (
            <LoadingSpinner />
          ) : (
            <ErrorContent />
          )}
        </Card>
      </AdminLayout>
    </PermissionRestriction>
  );
};
