import { useCallback } from 'react';

import { type CreateStoryMutationVariables, useCreateStoryMutation } from '../../generated';

export const useCreateStory = () => {
  const [createStory] = useCreateStoryMutation({ refetchQueries: ['Stories'] });
  return useCallback(
    (input: CreateStoryMutationVariables['input']) => createStory({ variables: { input } }),
    [createStory]
  );
};
