import type { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { SubNavigation } from '@cofenster/web-components';

import { SubNavigationItem } from '../../components/navigation/SubNavigationItem';
import { routes } from '../../routes';

export const InspectSubNavigation: FC<{ id: string }> = ({ id }) => (
  <SubNavigation>
    <SubNavigationItem to="inspectVideoAsset" params={{ id }} active={!!useMatch({ path: routes.inspectVideoAsset })}>
      Transcoded asset
    </SubNavigationItem>
    <SubNavigationItem
      to="inspectVideoAssetOriginal"
      params={{ id }}
      active={!!useMatch({ path: routes.inspectVideoAssetOriginal })}
    >
      Original asset
    </SubNavigationItem>
  </SubNavigation>
);
