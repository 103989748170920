import { type EasingName, Easings } from './Easings';
import {
  type Anchor,
  AnchorOption,
  EasingOption,
  OpacityOption,
  anchorToTransform,
  createBoundIntegerOption,
} from './Options';
import { DEFAULT_TIMING_OUT, TimingOption } from './Options/Timing';
import { createPreset } from './Preset';
import { createTimeline } from './utils';

export const ShrinkOut = createPreset(
  'ShrinkOut',
  ({ timing, easing, opacity, anchor, shrinkTo }) => {
    return {
      ...(opacity && {
        opacity: createTimeline(timing, {
          0: { v: 1, e: Easings[easing] },
          1: { v: 0 },
        }),
      }),
      transform: createTimeline(timing, {
        0: { v: 'scale(1)', e: Easings[easing] },
        1: { v: anchorToTransform(shrinkTo / 100, anchor) },
      }),
    };
  },
  {
    timing: TimingOption,
    easing: EasingOption,
    opacity: OpacityOption,
    anchor: AnchorOption,
    shrinkTo: createBoundIntegerOption('Scale to', 0, 99, '%'),
  },
  {
    timing: DEFAULT_TIMING_OUT,
    easing: 'EaseIn' as EasingName,
    opacity: true as boolean,
    anchor: 'Center' as Anchor,
    shrinkTo: 0 as number,
  }
);
