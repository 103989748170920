import { type FC, useCallback } from 'react';

import { Button } from '@cofenster/web-components';

import { useDeleteMusic } from '../../../api/hooks/music/useDeleteMusic';
import { useGotoMusics } from '../../../hooks/useGotoMusics';

import type { Music } from '.';

const useDelete = (musicId: string) => {
  const deleteMusic = useDeleteMusic('Delete music?');
  const gotoMusics = useGotoMusics();
  return useCallback(async () => {
    if (await deleteMusic(musicId)) gotoMusics();
  }, [musicId, deleteMusic, gotoMusics]);
};

export const DeleteMusicButton: FC<{ music: Music }> = ({ music }) => {
  const deleteMusic = useDelete(music.id);

  return (
    <Button type="button" variant="destructive" onClick={deleteMusic}>
      Delete music
    </Button>
  );
};
