import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useRenderTemplateLatestProjects } from '../../../api/hooks/renderTemplate/useRenderTemplateLatestProjects';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { TemplateSubNavigation } from '../../../components/templates/TemplateSubNavigation';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import type { TemplateRouteParams } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';
import { NoAccessLayout } from '../../NoAccess';

import { TemplateProjects } from './TemplateProjects';

export const TemplateLatestProjects: FC = () => {
  const title = 'Template projects';
  const { renderTemplateId } = useParams() as TemplateRouteParams;
  const { latestProjects, loading, error } = useRenderTemplateLatestProjects(renderTemplateId);

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction and={['TemplateRead', 'AccountRead']} fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} />,
          bottomLeft: <TemplateSubNavigation renderTemplateId={renderTemplateId} />,
        }}
      >
        {latestProjects ? (
          <TemplateProjects renderTemplateId={renderTemplateId} />
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <ErrorContent />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
