import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Alert,
  Button,
  Form,
  FormSubmitButton,
  FormTextField,
  GridContainer,
  GridItem,
  Icon,
  TextLink,
} from '@cofenster/web-components';

import type { AuthProvider } from '../../../../api/hooks/account/useAccount';
import { useCreateAuthProvider } from '../../../../api/hooks/authProvider/useCreateAuthProvider';
import { useDeleteAuthProvider } from '../../../../api/hooks/authProvider/useDeleteAuthProvider';
import { useUpdateAuthProvider } from '../../../../api/hooks/authProvider/useUpdateAuthProvider';

type Values = {
  config: string;
};

const useValidationSchema = () => {
  const validations = {
    config: Yup.string().required(),
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);

  return baseSchema;
};

const useInitialValues = (authProvider: AuthProvider | undefined) => {
  return useMemo<Values>(
    () => ({
      config: JSON.stringify(authProvider?.config, null, 4) || '{}',
    }),
    [authProvider]
  );
};

const useOnCreate = (accountId: string) => {
  const createAuthProvider = useCreateAuthProvider();

  return useCallback(
    async (values: Values) => {
      await createAuthProvider({ accountId, type: 'SAML', config: JSON.parse(values.config) });
    },
    [accountId, createAuthProvider]
  );
};

const useOnUpdate = () => {
  const updateAccount = useUpdateAuthProvider();

  return useCallback(
    async (id: string, values: Values) => {
      await updateAccount(id, { type: 'SAML', config: JSON.parse(values.config) });
    },
    [updateAccount]
  );
};

const useOnDelete = (authProvider: AuthProvider | undefined) => {
  const deleteAuthProvider = useDeleteAuthProvider();

  return useCallback(async () => {
    if (!authProvider) return;
    await deleteAuthProvider(authProvider?.id);
  }, [authProvider, deleteAuthProvider]);
};

const useOnSubmit = (accountId: string, authProvider: AuthProvider | undefined) => {
  const onCreate = useOnCreate(accountId);
  const onUpdate = useOnUpdate();

  return useCallback(
    (values: Values) => {
      if (authProvider) {
        return onUpdate(authProvider.id, values);
      }
      return onCreate(values);
    },
    [authProvider, onCreate, onUpdate]
  );
};

type AuthProviderFormProps = { accountId: string; authProvider?: AuthProvider; disabled?: boolean };

export const AuthProviderForm: FC<AuthProviderFormProps> = ({ accountId, authProvider, disabled }) => {
  const validationSchema = useValidationSchema();
  const initialValues = useInitialValues(authProvider);

  const onSubmit = useOnSubmit(accountId, authProvider);
  const onDelete = useOnDelete(authProvider);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer>
        {authProvider && (
          <>
            <GridItem xs={12}>
              <FormTextField
                id="ssoConfig"
                name="config"
                label="SSO config"
                placeholder="SSO config"
                multiline
                disabled={disabled}
              />
            </GridItem>
            <GridItem>
              <Button variant="destructive" onClick={onDelete} disabled={disabled}>
                Delete SSO
              </Button>
            </GridItem>
          </>
        )}
        <GridItem>
          <FormSubmitButton disabled={disabled}>{authProvider ? 'Save' : 'Create'}</FormSubmitButton>
        </GridItem>
        <GridItem xs={12}>
          <Alert severity="warning" icon={<Icon type="LightbulbIcon" />}>
            To learn more about authentication providers for SSO, please refer to the{' '}
            <TextLink
              underline
              href="https://www.notion.so/cofenster/SSO-46c3c9f4970b4e429eeed1ded3ccc01b"
              target="_blank"
            >
              documentation page on Notion
            </TextLink>
            .
          </Alert>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
