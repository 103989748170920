import type { FC } from 'react';

import { Icon, type IconSize, SpinningIcon } from '@cofenster/web-components';

import { type RenderJob, getStatusProps } from '../../../../contexts/exports';

export const StatusIcon: FC<{ status: RenderJob['status']; size: IconSize; backgroundSize: IconSize }> = ({
  status,
  size,
  backgroundSize,
}) => {
  const IconComponent = status === 'InProgress' ? SpinningIcon : Icon;

  return (
    <IconComponent
      size={size}
      color="white"
      type={getStatusProps(status).icon}
      background={{ size: backgroundSize, color: getStatusProps(status).color }}
    />
  );
};
