import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Card,
  EmptyState,
  Form,
  FormCheckbox,
  LoadingSpinner,
  Spacing,
  useUrlParameter,
} from '@cofenster/web-components';

import { useAccountsLite } from '../../../../api/hooks/account/useAccountsLite';
import { useAssignAccountsToFeatureFlag } from '../../../../api/hooks/featureFlag/useAssignAccountsToFeatureFlag';
import type { Account, FeatureFlag } from '../../../../api/hooks/featureFlag/useFeatureFlags';
import { AccountListHeader } from '../../../../components/accounts/AccountListHeader';
import { type CheckboxDisplayType, filterItemsByDisplay } from '../../../../components/controls/CheckboxDisplay';

type Values = {
  accountIds: string[];
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  accountIds: Yup.array().required(),
});

const useInitialValues = (accounts: Account[]) => {
  return useMemo<Values>(
    () => ({
      accountIds: accounts.map((account) => account.id),
    }),
    [accounts]
  );
};

const useSubmit = (featureFlagName: string | undefined) => {
  const assignAccountsToFeatureFlag = useAssignAccountsToFeatureFlag();

  return useCallback(
    async (values: Values) => {
      if (!featureFlagName) return;
      const { accountIds } = values;
      await assignAccountsToFeatureFlag(featureFlagName, accountIds);
    },
    [assignAccountsToFeatureFlag, featureFlagName]
  );
};

export const AccountsList: FC<{ selectedFeatureFlag: FeatureFlag | undefined; disabled?: boolean }> = ({
  selectedFeatureFlag,
  disabled,
}) => {
  const { paginatedAccounts, loading, error } = useAccountsLite({ limit: 1000 });
  const [display, setDisplay] = useUrlParameter<CheckboxDisplayType>('ALL', 'display');
  const initialValues = useInitialValues(selectedFeatureFlag?.accounts ?? []);
  const onSubmit = useSubmit(selectedFeatureFlag?.name);
  const accountIds = paginatedAccounts?.items.map((account) => account.id) ?? [];

  const items = paginatedAccounts?.items.filter(filterItemsByDisplay(initialValues.accountIds, display)) ?? [];

  return (
    <Card>
      <Form validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
        <AccountListHeader accountIds={accountIds} setDisplay={setDisplay} display={display} disabled={disabled} />

        {loading ? (
          <LoadingSpinner />
        ) : error ? (
          <EmptyState
            iconType="WarningIcon"
            title="Loading error"
            description="There was an error loading the accounts. Try refreshing the page."
          />
        ) : items.length ? (
          items
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((account) => (
              <Spacing vertical={1} key={account.id}>
                <FormCheckbox
                  id={`accountIds-${account.id}`}
                  name="accountIds"
                  value={account.id}
                  label={account.name}
                  disabled={disabled}
                />
              </Spacing>
            ))
        ) : (
          <EmptyState
            iconType="SearchIcon"
            title="No accounts found"
            description="It looks like there are no accounts matching these search criteria."
          />
        )}
      </Form>
    </Card>
  );
};
