import { useCallback } from 'react';

import type { VideoCaptureSource, VideoFit, VideoFormat } from '@cofenster/constants';
import type { UploadType } from '@cofenster/web-components';

import { useUploadPublicAudioAsset } from '../api/hooks/audioAsset/useUploadPublicAudioAsset';
import { useUploadPublicImageAsset } from '../api/hooks/imageAsset/useUploadPublicImageAsset';
import { useUploadPublicVideoAsset } from '../api/hooks/videoAsset/useUploadPublicVideoAsset';

export const usePublicAssetUpload = () => {
  const uploadImage = useUploadPublicImageAsset();
  const uploadVideo = useUploadPublicVideoAsset();
  const uploadAudio = useUploadPublicAudioAsset();

  return useCallback(
    async (
      type: UploadType,
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      abortSignal: AbortController['signal'],
      options?: {
        videoFormat?: VideoFormat;
        videoFit?: VideoFit;
        videoCaptureSource?: VideoCaptureSource;
      }
    ): Promise<string | undefined> => {
      switch (type) {
        case 'video':
          return uploadVideo(file, uploadId, onProgress, abortSignal, options);
        case 'image':
          return uploadImage(file, uploadId, onProgress, abortSignal);
        case 'audio':
          return uploadAudio(file, uploadId, onProgress, abortSignal);
        default:
          return undefined;
      }
    },
    [uploadVideo, uploadImage, uploadAudio]
  );
};
