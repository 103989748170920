import { useMemo } from 'react';

import { type AbilityToRenderQueryResult, useAbilityToRenderQuery } from '../../generated';

export type AbilityToRender = NonNullable<AbilityToRenderQueryResult['data']>['abilityToRender'];

export const useAbilityToRender = () => {
  const { loading, error, data } = useAbilityToRenderQuery({
    fetchPolicy: 'cache-and-network',
  });
  const abilityToRender = data?.abilityToRender ?? undefined;
  return useMemo(() => ({ abilityToRender, loading, error }), [abilityToRender, loading, error]);
};
