import { useMemo } from 'react';

import { type StaffUserRolesWithPermissionsQueryResult, useStaffUserRolesWithPermissionsQuery } from '../../generated';

export type StaffUserRoles = NonNullable<StaffUserRolesWithPermissionsQueryResult['data']>['staffUserRoles'];
export type StaffUserRole = StaffUserRoles[number];

export const useStaffUserRolesWithPermissions = () => {
  const { loading, error, data } = useStaffUserRolesWithPermissionsQuery();

  return useMemo(
    () => ({ staffUserRoles: data?.staffUserRoles ?? [], loading, error }),
    [data?.staffUserRoles, loading, error]
  );
};
