import { usePersistedState } from '@cofenster/web-components';

export type CustomColorVariant = 'Primary' | 'Secondary';
export const useRenderDescriptionCustomColor = (key: string) => {
  const [customColor, setCustomColor] = usePersistedState<Record<string, Record<string, string>>>(key, {});

  return {
    customColor,
    setCustomColor: (textCompositionId: string, variant: CustomColorVariant, value: string) => {
      setCustomColor((prev) => ({
        ...prev,
        [textCompositionId]: {
          ...prev[textCompositionId],
          [variant]: value,
        },
      }));
    },
  };
};
