import { type FC, useState } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import {
  GridItem,
  Select,
  SelectOption,
  SubNavigation,
  SubNavigationItem,
  useTabNavigation,
} from '@cofenster/web-components';

import { templateDefinitionPathFromString } from '../PathSelect/PathSelect';
import { type TemplateDefinitionPath, useStylePropertiesState } from '../TemplateDefinitionStateProvider';
import { CSSInput, ResponsiveInput } from '../inputs';

const selectors = [
  'line:each',
  'line:first',
  'line:last',
  'line:odd',
  'line:even',
  'word:each',
  'word:first',
  'word:last',
  'word:odd',
  'word:even',
  'word-in-line:each',
  'word-in-line:first',
  'word-in-line:last',
  'word-in-line:odd',
  'word-in-line:even',
];

const SelectorsOptions: FC<{
  selectedSelector: string;
  setSelector: (value: string) => void;
}> = ({ selectedSelector, setSelector }) => {
  return (
    <Select
      pb={0}
      label="Selector"
      value={selectedSelector}
      name="lineSelector"
      onChange={(event) => setSelector(event.target.value as string)}
    >
      {selectors.map((selector) => (
        <SelectOption key={selector} value={selector}>
          {selector}
        </SelectOption>
      ))}
    </Select>
  );
};

const selectorPath = (path: TemplateDefinitionPath, selector: string, videoFormat?: VideoFormat) => {
  return videoFormat
    ? `${path.composition}.${path.component}.${videoFormat}.${path.subComponent}.${selector}`
    : `${path.composition}.${path.component}.${path.subComponent}.${selector}`;
};

export const SelectorsEditor: FC<{
  videoFormat: VideoFormat;
  path: TemplateDefinitionPath;
}> = ({ path, videoFormat }) => {
  const tabs = ['Design', 'Format override'];
  const { currentTab, getTablistProps, getTabProps, getTabSectionProps } = useTabNavigation(tabs, 'Design');

  const [selector, setSelector] = useState('line:each');

  const pathSelectorString = selectorPath(path, selector);
  const pathWithSelector = templateDefinitionPathFromString(pathSelectorString);
  const [pathState, setPathState] = useStylePropertiesState(pathWithSelector);

  const responsivePathSelectorString = selectorPath(path, selector, videoFormat);
  const responsivePathWithSelector = templateDefinitionPathFromString(responsivePathSelectorString);
  const [responsiveState, setResponsiveState] = useStylePropertiesState(responsivePathWithSelector);

  return (
    <>
      <GridItem>
        <SelectorsOptions setSelector={setSelector} selectedSelector={selector} />
      </GridItem>
      <GridItem>
        <SubNavigation {...getTablistProps()}>
          {tabs.map((tab) => (
            <SubNavigationItem key={tab} {...getTabProps(tab)} active={currentTab === tab}>
              {tab}
            </SubNavigationItem>
          ))}
        </SubNavigation>
      </GridItem>
      <GridItem {...getTabSectionProps('Design')}>
        <CSSInput state={pathState} setState={setPathState} rows={25} />
      </GridItem>
      <GridItem {...getTabSectionProps('Format override')}>
        <ResponsiveInput
          noAnimations
          videoFormat={videoFormat}
          setState={setResponsiveState}
          state={responsiveState}
          rows={25}
        />
      </GridItem>
    </>
  );
};
