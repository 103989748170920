import { useCallback } from 'react';

import { MANAGER_URL } from '@cofenster/constants';
import { type TrackDetails, useSnackbars, useTracking } from '@cofenster/web-components';

export const useImpersonate = (handler: () => Promise<TrackDetails>, redirectPath = '') => {
  const tracking = useTracking();
  const { openSnackbar } = useSnackbars();

  return useCallback(async () => {
    try {
      const details = await handler();
      tracking.trackEvent({ event: 'ImpersonatedUserSignIn', details });
      window.open(MANAGER_URL + redirectPath, '_blank');
    } catch (error) {
      openSnackbar({
        variant: 'error',
        children: error instanceof Error ? error.message : 'An unexpected error occurred during impersonation.',
      });
    }
  }, [tracking, redirectPath, handler, openSnackbar]);
};
