import type { FC } from 'react';

import { type BrandColor, formatTime, preventForwardProps, styled } from '@cofenster/web-components';

import type { Account } from '../../../../api/hooks/account/useAccount';
import {
  Definition,
  DefinitionContent,
  DefinitionList,
  DefinitionTerm,
} from '../../../../components/display/DefinitionList';

const Circle = styled(
  'div',
  preventForwardProps(['color'])
)<{ color: BrandColor }>(({ theme, color }) => ({
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: '50%',
  backgroundColor: theme.palette.brand[color],
}));

const Label = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  marginRight: theme.spacing(2),
}));

export const Metadata: FC<{ account: Account }> = ({ account }) => (
  <DefinitionList>
    <Definition data-half>
      <DefinitionTerm>Created at</DefinitionTerm>
      <DefinitionContent>{formatTime(account.createdAt)}</DefinitionContent>
    </Definition>

    <Definition data-half>
      <DefinitionTerm>Updated at</DefinitionTerm>
      <DefinitionContent>{account.updatedAt ? formatTime(account.updatedAt) : 'n/a'}</DefinitionContent>
    </Definition>

    <Definition data-half>
      <DefinitionTerm>SSO enabled</DefinitionTerm>
      <DefinitionContent>{account.authProvider ? 'Yes' : 'No'}</DefinitionContent>
    </Definition>

    <Definition data-half>
      <DefinitionTerm>Number of projects</DefinitionTerm>
      <DefinitionContent>
        {account.projectCount} {account.projectCount === 1 ? 'project' : 'projects'}
      </DefinitionContent>
    </Definition>

    <Definition>
      <DefinitionTerm>Number of users</DefinitionTerm>
      <DefinitionContent>
        <Label>
          <Circle color="positive" /> {account.accountUsersStats.active} active
        </Label>
        <Label>
          <Circle color="negative" /> {account.accountUsersStats.deactivated} deactivated
        </Label>
        <Label>
          <Circle color="warning" /> {account.accountUsersStats.pending} pending
        </Label>
      </DefinitionContent>
    </Definition>
  </DefinitionList>
);
