import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

export const useConfirmNavigation = (
  shouldBlock: Parameters<typeof useBlocker>[0],
  confirm: () => Promise<boolean>
) => {
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked') confirmNavigation();

    async function confirmNavigation() {
      if (await confirm()) await blocker.proceed?.();
      else await blocker.reset?.();
    }
  }, [blocker, confirm]);
};
