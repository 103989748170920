import { useCallback } from 'react';

import { type GenerateResetPasswordLinkMutationVariables, useGenerateResetPasswordLinkMutation } from '../../generated';

export const useGenerateResetPasswordLink = () => {
  const [generateResetPasswordLink] = useGenerateResetPasswordLinkMutation();

  return useCallback(
    (id: GenerateResetPasswordLinkMutationVariables['id']) => generateResetPasswordLink({ variables: { id } }),
    [generateResetPasswordLink]
  );
};
