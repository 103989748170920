import { type FC, useCallback, useMemo } from 'react';

import { PopoverMenuItem, PopoverMenuTriggerIcon, withPopoverMenu } from '@cofenster/web-components';

import type { User } from '../../../../api/hooks/user/useUser';
import { useDialogs } from '../../../../contexts/dialogs/useDialogs';
import { useHasPermission } from '../../../../contexts/staffUser/PermissionRestriction';
import { useGotoAccountUsers } from '../../../../hooks/useGotoAccountUsers';

const useOnDelete = (user: User) => {
  const { openDialog } = useDialogs();
  const gotoUsers = useGotoAccountUsers(user.account.id);

  return useCallback(() => openDialog('DeleteUserDialog', { user, gotoUsers }), [user, openDialog, gotoUsers]);
};

const useOnSetActive = (user: User) => {
  const { openDialog } = useDialogs();

  return useCallback(async () => {
    openDialog(user.deactivatedAt ? 'ReactivateUserDialog' : 'DeactivateUserDialog', { user });
  }, [user, openDialog]);
};

const useOnResend = (user: User) => {
  const { openDialog } = useDialogs();

  return useCallback(async () => {
    openDialog('SendUserInviteEmailDialog', { user });
  }, [user, openDialog]);
};

type PopoverMenuProps = { user: User };

export const PopoverMenu: FC<PopoverMenuProps> = ({ user }) => {
  const onDelete = useOnDelete(user);
  const onSetActive = useOnSetActive(user);
  const onResend = useOnResend(user);
  const canUpdateUser = useHasPermission({ has: 'UserUpdate' });
  const canDeleteUser = useHasPermission({ has: 'UserDelete' });

  const actions = useMemo(
    () =>
      [
        user.isPending && (
          <PopoverMenuItem key="resend-invite" icon="ArrowClockwiseIcon" onClick={onResend}>
            Resend invite
          </PopoverMenuItem>
        ),
        canUpdateUser && (
          <PopoverMenuItem
            key="activate"
            icon={user.deactivatedAt ? 'UserReactivateIcon' : 'UserDeactivateIcon'}
            onClick={onSetActive}
          >
            {user.deactivatedAt ? 'Reactivate' : 'Deactivate'}
          </PopoverMenuItem>
        ),
        canDeleteUser && (
          <PopoverMenuItem key="delete" icon="TrashIcon" color="negative" onClick={onDelete}>
            Delete
          </PopoverMenuItem>
        ),
      ].filter(Boolean),
    [user.isPending, onResend, user.deactivatedAt, canUpdateUser, onSetActive, canDeleteUser, onDelete]
  );

  const PopoverMenuIcon = useMemo(() => withPopoverMenu(PopoverMenuTriggerIcon, { children: actions }), [actions]);

  return actions.length === 0 ? null : <PopoverMenuIcon />;
};
