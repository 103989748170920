import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { Button, Form, FormSubmitButton, FormTextField, GridContainer, GridItem } from '@cofenster/web-components';

import type { TemplateFontAsset } from '../../../../api/hooks/templateFontAsset/useTemplateFontAssets';
import { useUpdateTemplateFontAsset } from '../../../../api/hooks/templateFontAsset/useUpdateTemplateFontAsset';
import { FontAssetUpload } from './FontAssetUpload';

type Values = {
  name: string;
  templateFontAssetId: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Please fill in this field')
    .notOneOf(
      ['Noto Sans', 'Noto Sans SC'],
      'Please use a different name as this one is reserved for the default subtitle font.'
    ),
  templateFontAssetId: Yup.string().trim().required('Please fill in this field'),
});

const useInitialValues = (templateFontAsset: TemplateFontAsset | undefined) => {
  return useMemo<Values>(
    () => ({
      name: templateFontAsset?.name ?? '',
      templateFontAssetId: templateFontAsset?.id ?? '',
    }),
    [templateFontAsset]
  );
};

const useSubmit = (renderTemplateId: string) => {
  const updateTemplateFontAsset = useUpdateTemplateFontAsset();

  return useCallback(
    async (values: Values) => {
      try {
        await updateTemplateFontAsset(values.templateFontAssetId, renderTemplateId, { name: values.name });
      } catch (error) {
        if (
          typeof error === 'object' &&
          error !== null &&
          'graphQLErrors' in error &&
          Array.isArray(error.graphQLErrors)
        ) {
          throw new Error(error.graphQLErrors[0].message);
        }
      }
    },
    [renderTemplateId, updateTemplateFontAsset]
  );
};

type Props = {
  renderTemplateId: string;
  templateFontAsset?: TemplateFontAsset;
  deleteTemplateFontAsset: VoidFunction;
};

export const TemplateFontsForm: FC<Props> = ({ templateFontAsset, renderTemplateId, deleteTemplateFontAsset }) => {
  const initialValues = useInitialValues(templateFontAsset);
  const onSubmit = useSubmit(renderTemplateId);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer>
        <GridItem xs={12}>
          <FormTextField id="templateName" name="name" label="Name" placeholder="Name" />
          <FontAssetUpload name="templateFontAssetId" renderTemplateId={renderTemplateId} />
        </GridItem>

        <GridItem xs={12} display="flex" gap="16px">
          {templateFontAsset?.fontUrl && (
            <Button variant="secondary" href={templateFontAsset.fontUrl} download>
              Download
            </Button>
          )}

          <FormSubmitButton autoDisable>Save</FormSubmitButton>

          <Button variant={templateFontAsset?.id ? 'destructive' : 'secondary'} onClick={deleteTemplateFontAsset}>
            {templateFontAsset?.id ? 'Delete' : 'Cancel'}
          </Button>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
