import { useMemo } from 'react';

import {
  type RenderTemplateQueryResult,
  type RenderTemplateQueryVariables,
  useRenderTemplateQuery,
} from '../../generated';

export type RenderTemplate = NonNullable<RenderTemplateQueryResult['data']>['renderTemplate'];
export type TextComposition = RenderTemplate['textCompositions'][number];
export type TextInput = TextComposition['textInputs'][number];

export const useRenderTemplate = (renderTemplateId: RenderTemplateQueryVariables['id']) => {
  const { loading, error, data } = useRenderTemplateQuery({
    variables: { id: renderTemplateId },
  });
  const renderTemplate = data?.renderTemplate ?? undefined;
  return useMemo(
    () => ({ renderTemplate: structuredClone(renderTemplate), loading, error }),
    [renderTemplate, loading, error]
  );
};
