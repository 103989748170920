import { useCallback } from 'react';

import { useFileUpload } from '@cofenster/web-components';

import { useInitPublicImageUpload } from './useInitPublicImageUpload';

export const useUploadPublicImageAsset = () => {
  const initPublicImageUpload = useInitPublicImageUpload();
  const uploadFile = useFileUpload();

  return useCallback(
    async (
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      signal: AbortController['signal']
    ) => {
      const result = await initPublicImageUpload();
      if (!result.data?.initPublicImageUpload) throw new Error('Failed to init image upload');
      const { uploadUrl, imageAssetId } = result.data.initPublicImageUpload;
      const uploadResult = await uploadFile(uploadUrl, file, uploadId, onProgress, signal);
      if (!uploadResult) {
        // Upload failed or was aborted
        return undefined;
      }
      return imageAssetId;
    },
    [initPublicImageUpload, uploadFile]
  );
};
