import { useCallback } from 'react';

import { type ImpersonateByProjectMutationVariables, useImpersonateByProjectMutation } from '../../generated';

export const useImpersonateByProject = () => {
  const [impersonateByProject] = useImpersonateByProjectMutation({
    // Refetch the `Me` query to update the `isImpersonating` field
    refetchQueries: ['Me'],
  });

  return useCallback(
    (input: ImpersonateByProjectMutationVariables['input']) => impersonateByProject({ variables: { input } }),
    [impersonateByProject]
  );
};
