import { useImpersonateByProject } from '../api/hooks/user/useImpersonateByProject';
import { useImpersonate } from './useImpersonate';

export const useImpersonateProject = (projectId?: string, redirectPath = '') => {
  const impersonateByProject = useImpersonateByProject();

  const impersonate = useImpersonate(async () => {
    if (!projectId) return {};
    const result = await impersonateByProject({ projectId });
    const userId = result.data?.impersonateByProject.id;
    return { userId };
  }, redirectPath);

  return projectId ? impersonate : undefined;
};
