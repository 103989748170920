import { useCallback, useMemo, useState } from 'react';

import { uploadFile } from '@cofenster/web-components';

import { useInitTemplateFontAssetUpload } from '../../../../api/hooks/templateFontAsset/useInitTemplateFontAssetUpload';

export const useFontAssetUpload = (renderTemplateId: string, name: string) => {
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const initTemplateFontAssetUpload = useInitTemplateFontAssetUpload();
  const upload = useCallback(
    async (file: File) => {
      setProgress(0);

      try {
        const result = await initTemplateFontAssetUpload(renderTemplateId, name);

        if (!result.data?.initTemplateFontAssetUpload) {
          throw new Error('Failed to initialize the font upload');
        }

        const { uploadUrl, templateFontAssetId } = result.data.initTemplateFontAssetUpload;

        await uploadFile(uploadUrl, file, {
          onProgress: ({ progress }) => setProgress(progress),
        });

        return templateFontAssetId;
      } finally {
        setProgress(undefined);
      }
    },
    [initTemplateFontAssetUpload, renderTemplateId, name]
  );
  return useMemo(() => [upload, progress] as const, [upload, progress]);
};
