export type TreeNodeInit<T> = {
  data: T;
  children?: TreeNodeInit<T>[];
};

export type TreeNode<T> = {
  readonly id: string;
  readonly data: T;
  readonly children?: TreeNode<T>[];
  readonly parent?: TreeNode<T>;
};

// builds simple tree structure with parent-child relations
export const buildTree = <T>(init: TreeNodeInit<T>, idFn: (value: T) => string) => {
  const dataById = new Map<string, T>();
  const childrenIdsById = new Map<string, string[]>();
  const parentIdById = new Map<string, string>();

  const iterate = (node: TreeNodeInit<T>) => {
    const id = idFn(node.data);
    if (dataById.has(id)) {
      throw new Error('Duplicate id');
    }
    dataById.set(id, node.data);
    const children = node.children?.map(iterate);
    if (children) {
      childrenIdsById.set(id, children);
      children.forEach((childId) => parentIdById.set(childId, id));
    }
    return id;
  };

  const rootId = iterate(init);
  const get = (id = rootId): TreeNode<T> =>
    Object.seal({
      id,
      // biome-ignore lint/style/noNonNullAssertion: safe?
      data: dataById.get(id)!,
      get parent() {
        return parentIdById.get(id) ? get(parentIdById.get(id)) : undefined;
      },
      get children() {
        return childrenIdsById.get(id)?.map(get);
      },
    });

  // generator is used to avoid recursion
  // iterates through tree, first node, then children
  function* preOrderDepthFirst(id: string): Generator<string> {
    yield id;
    const children = childrenIdsById.get(id);
    if (children)
      for (const child of children) {
        yield* preOrderDepthFirst(child);
      }
  }

  // generator is used to avoid recursion
  // iterates through tree, first children, then node
  function* postOrderDepthFirst(id: string): Generator<string> {
    const children = childrenIdsById.get(id);
    if (children)
      for (const child of children) {
        yield* postOrderDepthFirst(child);
      }
    yield id;
  }

  return {
    rootId,
    root: get(rootId),
    get,
    preOrderDepthFirst(id = rootId) {
      return Array.from(preOrderDepthFirst(id)).map(get);
    },
    postOrderDepthFirst(id = rootId) {
      return Array.from(postOrderDepthFirst(id)).map(get);
    },
  };
};
