import { useCallback } from 'react';

import { type InitPublicAudioUploadMutationVariables, useInitPublicAudioUploadMutation } from '../../generated';

export const useInitPublicAudioUpload = () => {
  const [initPublicAudioUpload] = useInitPublicAudioUploadMutation();
  return useCallback(
    (
      duration: InitPublicAudioUploadMutationVariables['duration'],
      filename: InitPublicAudioUploadMutationVariables['filename']
    ) => initPublicAudioUpload({ variables: { duration, filename } }),
    [initPublicAudioUpload]
  );
};
