import { useCallback } from 'react';
import { useUpdateProjectTemplateMutation } from '../../generated';

export const useUpdateProjectTemplate = () => {
  const [updateProjectTemplate] = useUpdateProjectTemplateMutation();

  return useCallback(
    (
      id: string,
      input: {
        tags?: string[];
        description?: string;
      }
    ) => {
      return updateProjectTemplate({
        variables: {
          id,
          input: {
            tags: input.tags ? Array.from(new Set(input.tags)).filter(Boolean) : undefined,
            description: input.description,
          },
        },
      });
    },
    [updateProjectTemplate]
  );
};
