import type { FC } from 'react';

import { GridItem, Switch } from '@cofenster/web-components';

import {
  type TemplateDefinitionPath,
  isTemplateDefinitionTextElementStyleDefinitionPath,
  usePrimitivePropertyState,
} from '../TemplateDefinitionStateProvider';

export const TextElementOptionsInput: FC<{ path: TemplateDefinitionPath }> = ({ path }) => {
  const isTextElement = isTemplateDefinitionTextElementStyleDefinitionPath(path);

  const [state, setState] = usePrimitivePropertyState<boolean>(`${path.composition}.options.hideLogo`);

  return isTextElement ? (
    <GridItem>
      <Switch id="hideLogo" checked={state} onChange={(event) => setState(event.target.checked || undefined)}>
        Hide logo
      </Switch>
    </GridItem>
  ) : null;
};
