import { useCallback } from 'react';

import { type DeleteAccountMutationVariables, useDeleteAccountMutation } from '../../generated';

export const useDeleteAccount = () => {
  const [deleteAccount] = useDeleteAccountMutation({
    refetchQueries: ['Accounts'],
    awaitRefetchQueries: true,
  });

  return useCallback(
    (accountId: DeleteAccountMutationVariables['id']) => deleteAccount({ variables: { id: accountId } }),
    [deleteAccount]
  );
};
