import type { FC, PropsWithChildren } from 'react';

import { Button, Icon } from '@cofenster/web-components';

import type { ExtendedRenderJob } from '../../contexts/exports';
import { PermissionRestriction } from '../../contexts/staffUser/PermissionRestriction';
import { useImpersonateProject } from '../../hooks/useImpersonateProject';

export const ImpersonateButton: FC<PropsWithChildren<ExtendedRenderJob>> = ({ children, account, project }) => {
  const impersonate = useImpersonateProject(project?.id, `/project/${project?.id}/video`);
  const softDeleted = project?.deletedAt ?? account?.deletedAt;

  return project && !softDeleted ? (
    <PermissionRestriction has="UserImpersonate">
      <Button
        variant="destructive"
        type="button"
        onClick={impersonate}
        startIcon={<Icon type="UserSwitchIcon" size="s" />}
      >
        {children}
      </Button>
    </PermissionRestriction>
  ) : null;
};
