import { useCallback } from 'react';

import { type RestartRenderJobMutationVariables, useRestartRenderJobMutation } from '../../generated';

export type RenderJobMode = RestartRenderJobMutationVariables['input']['mode'];

export const useRestartRenderJob = () => {
  const [restartRenderJob] = useRestartRenderJobMutation({
    refetchQueries: ['ExtendedRenderJobs', 'ExtendedRenderJob'],
  });

  return useCallback(
    (id: RestartRenderJobMutationVariables['id'], input: RestartRenderJobMutationVariables['input']) =>
      restartRenderJob({ variables: { id, input } }),
    [restartRenderJob]
  );
};
