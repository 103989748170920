import { type FC, useEffect } from 'react';

import { EmptyState, LoadingSpinner, PageHeadline, SearchResults } from '@cofenster/web-components';

import { useExtendedRenderJobs } from '../../../../api/hooks/renderJob/useExtendedRenderJobs';
import { AdminLayout } from '../../../../components/layout/AdminLayout';
import { AUTO_REFRESH_INTERVAL, useExportSettings } from '../../../../contexts/exports';
import { PermissionRestriction } from '../../../../contexts/staffUser/PermissionRestriction';
import { ErrorContent, ErrorLayout } from '../../../Error';
import { ExportsFilters } from '../ExportsFilters';
import { ExportsList } from '../ExportsList';
import { ExportsPanicButton } from '../ExportsPanicButton';

export const ExportsContent: FC = () => {
  const title = 'Exports';
  const settings = useExportSettings();
  const { pagination, loading, error, startPolling, stopPolling } = useExtendedRenderJobs({
    search: settings.search,
    statuses: settings.statuses,
    order: settings.order,
    dates: settings.dates,
    pagination: settings.pagination,
  });

  useEffect(() => {
    if (settings.withPolling) startPolling(AUTO_REFRESH_INTERVAL);
    else stopPolling();
    return () => stopPolling();
  }, [settings.withPolling, startPolling, stopPolling]);

  const nonNetworkError = error && !error.networkError;

  if (nonNetworkError) {
    return <ErrorLayout title={title} />;
  }

  return (
    <AdminLayout
      documentTitle={title}
      header={{
        topLeft: <PageHeadline mb={0} title={title} />,
        topRight: (
          <PermissionRestriction has="ExportDisable">
            <ExportsPanicButton />
          </PermissionRestriction>
        ),
      }}
    >
      <PermissionRestriction
        has="ExportRead"
        fallback={
          <EmptyState
            iconType="NoOptionIcon"
            title="Missing permissions"
            description="Your current role does not allow you to access exports (lacking permission “ExportRead”). If you need access, please reach out to #team-product on Slack to be granted temporary access or for someone to support with your query."
          />
        }
      >
        <ExportsFilters extendedJobs={pagination.items} />
        <SearchResults id="accounts" search={settings.statuses.join(', ')} count={pagination.total}>
          {pagination.items ? <ExportsList pagination={pagination} /> : loading ? <LoadingSpinner /> : <ErrorContent />}
        </SearchResults>
      </PermissionRestriction>
    </AdminLayout>
  );
};
