import type { FC } from 'react';

import { styled } from '@cofenster/web-components';

import type { Option } from './Options';
import type { PresetOptions } from './Preset';

export type PresetOptionsEditor<O extends PresetOptions> = FC<{
  value: O;
  onChange: (value: O) => unknown;
}>;

const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridColumnGap: theme.spacing(2),
}));

type Options<O extends PresetOptions> = {
  [K in keyof O]: Option<O[K]>;
};

export const createPresetOptionsEditor = <O extends PresetOptions>(options: Options<O>): PresetOptionsEditor<O> => {
  const PresetOptionsEditor: PresetOptionsEditor<O> = ({ value, onChange }) => {
    return (
      <Grid>
        {Object.entries(options).map(([name, option]) => (
          <option.Editor
            key={name}
            value={value[name]}
            onChange={(option: O[typeof name]) =>
              onChange({
                ...value,
                [name]: option,
              })
            }
          />
        ))}
      </Grid>
    );
  };
  return PresetOptionsEditor;
};
