import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Form,
  FormSelect,
  FormSubmitButton,
  FormTextField,
  GridContainer,
  GridItem,
  SelectOption,
  useGoto,
  useSnackbars,
} from '@cofenster/web-components';

import { type StaffUserError, useCreateStaffUser } from '../../api/hooks/staffUser/useCreateStaffUser';
import type { StaffUser } from '../../api/hooks/staffUser/useStaffUser';
import { useUpdateStaffUser } from '../../api/hooks/staffUser/useUpdateStaffUser';
import { useStaffUserRoles } from '../../api/hooks/staffUserRole/useStaffUserRoles';
import { routes } from '../../routes';

type Values = {
  firstname: string;
  lastname: string;
  email: string;
  roleId: string;
};

const useValidationSchema = () => {
  const validations = {
    firstname: Yup.string().trim().required('Please fill in this field'),
    lastname: Yup.string().trim().required('Please fill in this field'),
    email: Yup.string().trim().email('Please enter a valid email address').required('Please fill in this field'),
    roleId: Yup.string().trim().required('Please fill in this field'),
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);
  return baseSchema;
};

const useInitialValues = (staffUser?: StaffUser) => {
  return useMemo<Values>(
    () => ({
      firstname: staffUser?.firstname ?? '',
      lastname: staffUser?.lastname ?? '',
      email: staffUser?.email ?? '',
      roleId: staffUser?.role?.id ?? '',
    }),
    [staffUser]
  );
};

const useCreate = () => {
  const { openSnackbar } = useSnackbars();
  const createStaffUser = useCreateStaffUser();
  const goto = useGoto();

  return useCallback(
    async (values: Values) => {
      const result = await createStaffUser(values);
      const createStaffUserResult = result.data?.createStaffUser;

      if (!createStaffUserResult) {
        throw new Error('An unexpected error happened.');
      }

      if (isStaffUserError(createStaffUserResult)) {
        throw new Error('This email address is already in use.');
      }

      openSnackbar({
        variant: 'success',
        children: `Created staff user "${createStaffUserResult.name}".`,
      });

      goto(routes.staffUsers);
    },
    [createStaffUser, openSnackbar, goto]
  );
};

const useUpdate = () => {
  const updateStaffUser = useUpdateStaffUser();
  const goto = useGoto();

  return useCallback(
    async (staffUserId: string, values: Values) => {
      const result = await updateStaffUser(staffUserId, values);
      const updateStaffUserResult = result.data?.updateStaffUser;

      if (!updateStaffUserResult) {
        throw new Error('An unexpected error happened.');
      }

      if (isStaffUserError(updateStaffUserResult)) {
        throw new Error('This email address is already in use.');
      }

      goto(routes.staffUsers);
    },
    [updateStaffUser, goto]
  );
};

const useSubmit = (staffUserId: string | undefined) => {
  const createStaffUser = useCreate();
  const updateStaffUser = useUpdate();

  return useCallback(
    (values: Values) => {
      if (staffUserId) {
        return updateStaffUser(staffUserId, values);
      }

      return createStaffUser(values);
    },
    [staffUserId, createStaffUser, updateStaffUser]
  );
};

type StaffUserFormProps = { staffUser?: StaffUser };

export const StaffUserForm: FC<StaffUserFormProps> = ({ staffUser }) => {
  const initialValues = useInitialValues(staffUser);
  const validationSchema = useValidationSchema();
  const { roles } = useStaffUserRoles();

  const onSubmit = useSubmit(staffUser?.id);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <FormTextField
            id="staffUserFirstName"
            name="firstname"
            label="First name"
            placeholder="Jane"
            data-testid="staff-user-first-name-input"
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormTextField
            id="staffUserLastName"
            name="lastname"
            label="Last name"
            placeholder="Cofensti"
            data-testid="staff-user-last-name-input"
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} md={6}>
          <FormTextField
            id="staffUserEmail"
            name="email"
            label="Email"
            placeholder="jane.cofensti@cofenster.com"
            autoCapitalize="none"
            data-testid="staff-user-email-input"
          />
        </GridItem>
        <GridItem xs={12} md={6}>
          <FormSelect id="staffUserRole" name="roleId" label="Role" data-testid="staff-user-role-select">
            {roles.map((role) => (
              <SelectOption key={role.id} value={role.id}>
                {role.name.replace(/([A-Z])/g, ' $1')}
              </SelectOption>
            ))}
          </FormSelect>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={6} md={6} textAlign="left">
          <FormSubmitButton
            autoDisable
            data-testid={staffUser ? 'staff-user-update-button' : 'staff-user-create-button'}
          >
            {staffUser ? 'Save' : 'Create'}
          </FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};

const isStaffUserError = (staffUserOrError: StaffUser | StaffUserError): staffUserOrError is StaffUserError => {
  return staffUserOrError?.__typename === 'StaffUserError';
};
