import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { useTheme } from '@mui/material';
import type { FC, ReactNode } from 'react';

import type { SpacingProps } from '../../layout/Spacing';

import { SortableHandle } from './SortableHandle';
import { useDraggableStyles } from './useDraggableStyles';

export type SortableItemProps = {
  item: ReactNode;
  id: UniqueIdentifier;
  spacing?: SpacingProps['vertical'];
  disabled?: boolean;
};

export const SortableItem: FC<SortableItemProps> = ({ item, id, spacing = 1, disabled }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id, disabled });
  const theme = useTheme();
  const draggableStyles = useDraggableStyles({ isDragging, transform, transition });

  const style = {
    ...draggableStyles,
    margin: theme.spacing(spacing as number, 0),
    display: 'flex',
    flexDirection: 'row' as const,
    alignItems: 'center',
    width: '100%',
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div style={{ width: '100%', position: 'relative' }}>
        {!disabled && <SortableHandle {...attributes} {...listeners} />}
        {item}
      </div>
    </div>
  );
};
