import { type FC, useState } from 'react';

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  GridContainer,
  GridItem,
  Select,
  type SelectChangeEvent,
  SelectOption,
  Spacing,
  TextLink,
  Typography,
  formatTime,
} from '@cofenster/web-components';

import type { ExtendedRenderJob } from '../../../contexts/exports';
import type { RemotionVersionOption } from '../../../hooks/useRestartJob';

export type RestartJobDialog = ExtendedRenderJob & {
  isOpen: boolean;
  closeDialog: () => unknown;
  restart: (remotionVersion: RemotionVersionOption) => void;
};

export const RestartJobDialog: FC<RestartJobDialog> = ({
  isOpen,
  closeDialog,
  job,
  subtitle,
  restartMode,
  restart,
}) => {
  const [remotionVersion, setRemotionVersion] = useState<RemotionVersionOption>('automatic');
  const target = subtitle ? 'subtitle' : 'project';
  const action = restartMode === 'RENEW' ? 'renew' : restartMode === 'RETRY' ? 'retry' : 'restart';
  const capitalize = (value: string) => value.slice(0, 1).toUpperCase() + value.slice(1);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title={`${capitalize(action)} job`} size="m">
      <DialogContent>
        <Typography variant="l" component="p">
          Are you sure you want to {action} this job? It will discard all ongoing rendering progress and restart from
          scratch.
        </Typography>

        {job && restartMode === 'RENEW' && (
          <Spacing top={1}>
            <Typography variant="l" component="p">
              This job was created before today which means it cannot be retried and needs to be recreated from scratch.
              As a result, it will take the latest version of the {target} and <strong>not</strong> the version as it
              was when the {target} was rendered (on {formatTime(job.createdAt)}).
            </Typography>
          </Spacing>
        )}

        {/*
            Only show the Remotion version select when the __DISABLE_REMOTION_NG__ build flag is not set.
          */}
        {!__DISABLE_REMOTION_NG__ && (
          <GridContainer mt={1}>
            <GridItem md={6}>
              <Select
                pb={0.5}
                id="remotionVersion"
                name="remotionVersion"
                label="Remotion version"
                value={remotionVersion}
                onChange={(event: SelectChangeEvent<unknown>) =>
                  setRemotionVersion(event.target.value as RemotionVersionOption)
                }
              >
                <SelectOption value="automatic">Automatic</SelectOption>
                <SelectOption value="current">Use current renderer</SelectOption>
                <SelectOption value="ng">
                  Use&nbsp;<em>NextGen</em>&nbsp;renderer
                </SelectOption>
              </Select>
            </GridItem>
          </GridContainer>
        )}

        {/*
          Only show the Remotion version hint when the __DISABLE_REMOTION_NG__
          build flag is not set
        */}
        {!__DISABLE_REMOTION_NG__ && (
          <Spacing top={2}>
            <Alert severity="warning">
              Learn more about&nbsp;
              <TextLink
                href="https://www.notion.so/cofenster/Exports-tool-6bb77ee2d045457593e92a6142c8df7a?pvs=4#2968539d4b314fb0a1ff224fe7e102fe"
                underline
                target="_blank"
                rel="noopener noreferrer"
              >
                Remotion versions
              </TextLink>
              &nbsp;in the documentation. When in doubt, let the parameters be figured out automatically.
            </Alert>
          </Spacing>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant={restartMode === 'RENEW' ? 'destructive' : 'primary'}
          fullWidth
          onClick={() => restart(remotionVersion)}
        >
          {capitalize(action)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
