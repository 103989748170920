import { type FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner, PageHeadline, useGoto } from '@cofenster/web-components';

import { useExtendedRenderJob } from '../../../api/hooks/renderJob/useExtendedRenderJob';
import { ExportSubNavigation } from '../../../components/exports/ExportSubNavigation';
import { ImpersonateButton } from '../../../components/exports/ImpersonateButton';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { routes } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';
import { NoAccessLayout } from '../../NoAccess';

import { CustomerPreview } from './CustomerPreview';

export const ExportPreview: FC = () => {
  const { id } = useParams();
  const { extendedRenderJob, error, loading, startPolling, stopPolling } = useExtendedRenderJob(id);
  const title = [extendedRenderJob?.project?.name, 'Preview'].filter(Boolean).join(' — ');
  const goto = useGoto();
  const status = extendedRenderJob?.job.status;

  useEffect(() => {
    if (status === 'InProgress' || status === 'Waiting') startPolling(5000);
    return () => stopPolling();
  }, [startPolling, stopPolling, status]);

  const nonNetworkError = error && !error.networkError;

  if (nonNetworkError) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="ExportRead" fallback={<NoAccessLayout documentTitle={title} />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={() => goto(routes.exports)} />,
          topRight: extendedRenderJob && <ImpersonateButton {...extendedRenderJob}>Impersonate</ImpersonateButton>,
          bottomLeft: id && <ExportSubNavigation id={id} />,
        }}
      >
        {extendedRenderJob ? (
          <CustomerPreview {...extendedRenderJob} />
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <ErrorContent />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
