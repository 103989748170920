import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Button,
  Card,
  GridContainer,
  GridItem,
  Headline,
  LoadingSpinner,
  PageHeadline,
  styled,
} from '@cofenster/web-components';

import { useAccount } from '../../../../api/hooks/account/useAccount';
import { useUpdateAccount } from '../../../../api/hooks/account/useUpdateAccount';
import { useRenderTemplates } from '../../../../api/hooks/renderTemplate/useRenderTemplates';
import { AccountSubNavigation } from '../../../../components/accounts/AccountSubNavigation';
import { AdminLayout } from '../../../../components/layout/AdminLayout';
import { PermissionRestriction, useHasPermission } from '../../../../contexts/staffUser/PermissionRestriction';
import { useGotoAccounts } from '../../../../hooks/useGotoAccounts';
import type { AccountRouteParams } from '../../../../routes';
import { ErrorContent, ErrorLayout } from '../../../Error';
import { NoAccessLayout } from '../../../NoAccess';

import { DefaultTemplate } from './DefaultTemplate';
import { SortableSelectTemplates } from './SortableSelectTemplates';

const CardHeadline = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const AccountTemplates: FC = () => {
  const { accountId } = useParams() as AccountRouteParams;
  const { account, loading, error } = useAccount(accountId);
  const canUpdateAccounts = useHasPermission({ has: 'AccountUpdate' });
  const { paginatedRenderTemplates } = useRenderTemplates();
  const renderTemplates = useMemo(() => paginatedRenderTemplates?.items ?? [], [paginatedRenderTemplates]);
  const accountRenderTemplates = useMemo(() => account?.renderTemplates ?? [], [account]);
  const [selectedRenderTemplateIds, setSelectedRenderTemplateIds] = useState(
    accountRenderTemplates.map((renderTemplate) => renderTemplate.id)
  );
  const title = ['Render Templates', account?.name].filter(Boolean).join(' — ');
  const dirty = useMemo(
    () => selectedRenderTemplateIds.join() !== accountRenderTemplates.map(({ id }) => id).join(),
    [selectedRenderTemplateIds, accountRenderTemplates]
  );

  const gotoAccounts = useGotoAccounts();
  const updateAccount = useUpdateAccount();
  const save = useCallback(
    () => updateAccount(accountId, { renderTemplateIds: selectedRenderTemplateIds }),
    [accountId, selectedRenderTemplateIds, updateAccount]
  );

  useEffect(() => {
    setSelectedRenderTemplateIds(accountRenderTemplates.map((template) => template.id));
  }, [accountRenderTemplates]);

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="AccountRead" fallback={<NoAccessLayout documentTitle={title} />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={gotoAccounts} />,
          bottomLeft: <AccountSubNavigation accountId={accountId} />,
        }}
      >
        {account ? (
          <Card>
            <CardHeadline color="carbon" variant="h3" component="h2">
              Templates
            </CardHeadline>

            <GridContainer>
              <GridItem xs={9}>
                <DefaultTemplate accountId={accountId} />
              </GridItem>
              <GridItem xs={3}>
                <Button
                  fullWidth
                  disabled={!canUpdateAccounts || !dirty}
                  onClick={save}
                  data-testid="form-submit-button"
                >
                  Save
                </Button>
              </GridItem>
            </GridContainer>

            <SortableSelectTemplates
              renderTemplates={renderTemplates}
              selectedIds={selectedRenderTemplateIds}
              setSelectedIds={setSelectedRenderTemplateIds}
              disabled={!canUpdateAccounts}
            />
          </Card>
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <ErrorContent />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
