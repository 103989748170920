import { type FC, useCallback } from 'react';

import { Alert, Button, Dialog, DialogActions, DialogContent, Spacing, Typography } from '@cofenster/web-components';
import type { AbilityToRender } from '../../../api/hooks/featureFlag/useAbilityToRender';

export type ExportsPanicDialog = {
  isOpen: boolean;
  closeDialog: () => unknown;
  loading: boolean;
  toggleAbilityToRender: (enabled: boolean) => void;
  abilityToRender?: AbilityToRender;
};

export const ExportsPanicDialog: FC<ExportsPanicDialog> = ({
  isOpen,
  closeDialog,
  loading,
  toggleAbilityToRender,
  abilityToRender,
}) => {
  const enableRender = useCallback(() => toggleAbilityToRender(true), [toggleAbilityToRender]);
  const disableRender = useCallback(() => toggleAbilityToRender(false), [toggleAbilityToRender]);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="Export Panic Button" size="m">
      <DialogContent>
        <Spacing bottom={2}>
          <Typography variant="l" component="p">
            If there is an ongoing or suspected incident with video exports, it may be worth considering disabling the
            ability to render for all customers. Instead, they’ll be faced with a message letting them know there is an
            unscheduled maintenance. Ongoing exports will be unaffected.
          </Typography>
        </Spacing>

        {abilityToRender?.status === 'ENABLED' && (
          <Typography variant="l" component="p">
            The ability to render video is currently <strong>enabled for all customers</strong>. It can be temporarily
            disabled for all of them with the “Disable render” button below.
          </Typography>
        )}

        {abilityToRender?.status === 'DISABLED' && (
          <Typography variant="l" component="p">
            The ability to render video is currently <strong>disabled for all customers</strong>. It can be reenabled
            for all of them with the “Enable render” button below.
          </Typography>
        )}

        {abilityToRender?.status === 'MIXED' && (
          <Typography variant="l" component="p">
            The ability to render video is currently{' '}
            <strong>
              disabled for {abilityToRender.disabledCount} customer{abilityToRender.disabledCount === 1 ? '' : 's'}
            </strong>
            . It can be reenabled for all of them with the “Enable render” button below.
          </Typography>
        )}

        {process.env.STAGE === 'production' && (
          <Spacing top={1}>
            <Alert severity="warning">
              The <em>cofenster GmbH</em> account is excluded from disabling render so that it can be used to conduct
              tests and monitor health metrics.
            </Alert>
          </Spacing>
        )}
      </DialogContent>

      <DialogActions>
        <Button fullWidth onClick={enableRender} loading={loading}>
          Enable render
        </Button>
        <Button variant="destructive" fullWidth onClick={disableRender} loading={loading}>
          Disable render
        </Button>
      </DialogActions>
    </Dialog>
  );
};
