import type { FC } from 'react';

import { EmptyState, PageHeadline } from '@cofenster/web-components';

import { AdminLayout } from '../../components/layout/AdminLayout';

export const ErrorContent: FC<{ resource?: string }> = ({ resource = 'page' }) => (
  <EmptyState
    iconType="CloudErrorIcon"
    title={`Loading ${resource} failed`}
    description={`One or more requests intended to load the necessary data for this ${resource} failed. Try reloading the page in case it was a connectivity problem, or reach out to the engineering team to get support.`}
  />
);

export const ErrorLayout: FC<{ title: string; resource?: string }> = ({ title, resource = 'page' }) => (
  <AdminLayout documentTitle={title} header={{ topLeft: <PageHeadline title={title} mb={0} /> }}>
    <ErrorContent resource={resource} />
  </AdminLayout>
);
