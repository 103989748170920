import type { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { SubNavigation } from '@cofenster/web-components';

import { routes } from '../../routes';
import { SubNavigationItem } from '../navigation/SubNavigationItem';

export const RolesSubNavigation: FC = () => {
  return (
    <SubNavigation>
      <SubNavigationItem iconType="UsersIcon" to="userRoles" active={!!useMatch(routes.userRoles)}>
        Customer roles
      </SubNavigationItem>
      <SubNavigationItem iconType="UserListIcon" to="staffUserRoles" active={!!useMatch(routes.staffUserRoles)}>
        Employee roles
      </SubNavigationItem>
    </SubNavigation>
  );
};
