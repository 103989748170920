import { type FC, useCallback } from 'react';

import {
  CSSGridTable,
  type CSSGridTableConfig,
  type ColumnConfig,
  EmptyState,
  GridContainer,
  GridItem,
  ResultPagination,
  Typography,
  formatTime,
  useGoto,
} from '@cofenster/web-components';

import type { Account, Filter, PaginatedAccounts } from '../../../../api/hooks/account/useAccounts';
import { ExpandedRouterLink } from '../../../../components/navigation/ExpandedRouterLink';
import { routes } from '../../../../routes';

type AccountsListProps = {
  page: number;
  setPage: (page: number) => void;
  search: Filter['search'];
  accounts?: PaginatedAccounts;
};

const HeaderCell: FC<{ column: ColumnConfig<Account> }> = ({ column }) => (
  <Typography color="grey600" variant="h6" component="span">
    {column.name}
  </Typography>
);

const columns: CSSGridTableConfig<Account>['columns'] = [
  {
    id: 'accountName',
    name: 'Account name',
    cell: ({ item }) => (
      <ExpandedRouterLink to="account" params={{ accountId: item.id }} data-testid="admin-account-link">
        <Typography variant="h6" color="carbon">
          {item.name}
        </Typography>
      </ExpandedRouterLink>
    ),
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },
  { id: 'space', extra: { size: 'minmax(0, auto)' } },
  {
    id: 'createdAt',
    name: 'Created at',
    cell: ({ item }) => (
      <Typography variant="m" component="time">
        {formatTime(item.createdAt)}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'activity',
    name: 'Last signin',
    cell: ({ item }) => (
      <Typography variant="m" component={item.lastSignInAt ? 'time' : 'span'}>
        {item.lastSignInAt ? formatTime(item.lastSignInAt) : 'n/a'}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'projectCount',
    name: 'Projects',
    cell: ({ item }) => (
      <Typography variant="m" color="carbon">
        {item.projectCount} {item.projectCount === 1 ? 'project' : 'projects'}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
  {
    id: 'userCount',
    name: 'Active users',
    cell: ({ item }) => (
      <Typography variant="m" color="carbon">
        {item.accountUsersStats.active} {item.accountUsersStats.active === 1 ? 'user' : 'users'}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'max-content' },
  },
];

export const AccountsList: FC<AccountsListProps> = ({ page, setPage, search, accounts: paginatedAccounts }) => {
  const goto = useGoto();

  const handleRowClick = useCallback((item: Account) => item && goto(routes.account, { accountId: item.id }), [goto]);

  if (!paginatedAccounts || paginatedAccounts.items.length === 0) {
    return (
      <EmptyState
        iconType="SearchIcon"
        title="No accounts found"
        description={search ? <>The search for “{search}” returned no results.</> : <>No accounts found.</>}
      />
    );
  }

  return (
    <>
      <CSSGridTable data={paginatedAccounts.items} columns={columns} onRowClick={handleRowClick} />
      <GridContainer justifyContent="center" pt={3}>
        <GridItem>
          <ResultPagination
            total={paginatedAccounts.total}
            limit={paginatedAccounts.limit ?? paginatedAccounts.total}
            page={page}
            onChange={setPage}
          />
        </GridItem>
      </GridContainer>
    </>
  );
};
