import type { FC } from 'react';

import { Card, Headline, Spacing } from '@cofenster/web-components';

import type { Story, StoryScene } from '../..';

import { StorySceneForm } from './StorySceneForm';

type StorySceneCardProps = {
  story: Story;
  storyScene?: StoryScene;
  deleteStoryScene: VoidFunction;
};

export const StorySceneCard: FC<StorySceneCardProps> = ({ story, storyScene, deleteStoryScene }) => (
  <Card>
    {!storyScene && (
      <Spacing bottom={3}>
        <Headline variant="h4" component="h2">
          New Story Scene
        </Headline>
      </Spacing>
    )}

    <StorySceneForm story={story} storyScene={storyScene} deleteStoryScene={deleteStoryScene} />
  </Card>
);
