import { useDebounceCallback } from '@react-hook/debounce';
import { type CSSProperties, type FC, useCallback } from 'react';

import {
  Checkbox,
  Chip,
  ListItemText,
  Select,
  type SelectChangeEvent,
  SelectOption,
  styled,
  useTheme,
} from '@cofenster/web-components';

import { EXPORT_STATUSES, type RenderJob, getStatusProps, useExportSettings } from '../../../../contexts/exports';

const Chips = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  fontSize: '80%',
}));

export const StatusSelect: FC<{ countJobsWithStatus: (status: RenderJob['status']) => number }> = ({
  countJobsWithStatus,
}) => {
  const { statuses, setStatuses } = useExportSettings();
  const theme = useTheme();
  const throttledSetStatuses = useDebounceCallback(setStatuses, 500, false);

  const handleStatusChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as typeof statuses;
      throttledSetStatuses(typeof value === 'string' ? ((value as string).split(',') as typeof statuses) : value);
    },
    [throttledSetStatuses]
  );

  return (
    <Select
      pb={0}
      label="Statuses"
      id="statuses"
      name="statuses"
      multiple
      value={statuses}
      onChange={handleStatusChange}
      data-testid="statuses-select"
      renderValue={(statuses) => (
        <Chips>
          {(statuses as RenderJob['status'][]).map((status) => (
            <Chip
              key={status}
              color="dark"
              style={{ '--chip-color': theme.palette.brand[getStatusProps(status).color] } as CSSProperties}
              size="small"
            >
              {countJobsWithStatus(status)} {getStatusProps(status).label}
            </Chip>
          ))}
        </Chips>
      )}
    >
      {EXPORT_STATUSES.map((status) => (
        <SelectOption key={status} value={status} style={{ gap: 8 }}>
          <Checkbox checked={statuses.includes(status)} />
          <ListItemText primary={getStatusProps(status).label} />
        </SelectOption>
      ))}
    </Select>
  );
};
