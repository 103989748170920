import { useCallback, useMemo, useState } from 'react';

import { uploadFile } from '@cofenster/web-components';
import { useInitPublicAudioUpload } from '../../../api/hooks/audioAsset/useInitPublicAudioUpload';

export const usePublicAudioUpload = () => {
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const initPublicAudioUpload = useInitPublicAudioUpload();
  const upload = useCallback(
    async (file: File) => {
      setProgress(0);

      try {
        const audioElement = document.createElement('audio');

        const result = await initPublicAudioUpload(audioElement.duration, file.name);

        if (!result.data?.initPublicAudioUpload) {
          throw new Error('Failed to initialize audio upload');
        }

        const { uploadUrl, audioAssetId } = result.data.initPublicAudioUpload;

        await uploadFile(uploadUrl, file, {
          onProgress: ({ progress }) => setProgress(progress),
        });

        return audioAssetId;
      } finally {
        setProgress(undefined);
      }
    },
    [initPublicAudioUpload]
  );
  return useMemo(() => [upload, progress] as const, [upload, progress]);
};
