import { type FormikValues, useFormikContext } from 'formik';
import { type FC, useCallback } from 'react';

import { PopoverMenuItem, PopoverMenuTriggerIcon, withPopoverMenu } from '@cofenster/web-components';

type CheckboxControllerProps = {
  ids: string[];
  fieldName: string;
};

const CheckboxControls: FC<CheckboxControllerProps> = ({ ids, fieldName }) => {
  const { initialValues, setFieldValue } = useFormikContext<FormikValues>();

  const handleSelectAll = useCallback(() => setFieldValue(fieldName, ids), [fieldName, ids, setFieldValue]);
  const handleUnselectAll = useCallback(() => setFieldValue(fieldName, []), [fieldName, setFieldValue]);
  const handleReset = useCallback(
    () => setFieldValue(fieldName, initialValues[fieldName]),
    [fieldName, initialValues, setFieldValue]
  );

  return (
    <>
      <PopoverMenuItem icon="CheckIcon" onClick={handleSelectAll}>
        Select all
      </PopoverMenuItem>
      <PopoverMenuItem icon="SquareIcon" onClick={handleUnselectAll}>
        Unselect all
      </PopoverMenuItem>
      <PopoverMenuItem icon="ArrowClockwiseIcon" onClick={handleReset}>
        Reset selection
      </PopoverMenuItem>
    </>
  );
};

export const CheckboxController: FC<CheckboxControllerProps> = ({ ids, fieldName }) => {
  const PopoverMenu = withPopoverMenu(PopoverMenuTriggerIcon, {
    children: <CheckboxControls ids={ids} fieldName={fieldName} />,
  });

  return <PopoverMenu />;
};
