import { type FC, useCallback } from 'react';

import { Button, Card, GridContainer, GridItem, styled, useDisclosure } from '@cofenster/web-components';

import { useDeleteTemplateFontAsset } from '../../../../api/hooks/templateFontAsset/useDeleteTemplateFontAsset';
import type { TemplateFontAsset } from '../../../../api/hooks/templateFontAsset/useTemplateFontAssets';
import { TemplateFontsForm } from './TemplateFontsForm';

type TemplateFontsContentProps = {
  renderTemplateId: string;
  templateFontAssets: TemplateFontAsset[];
};

const useDelete = (templateFontAssetId: string | undefined) => {
  const deleteTemplateFontAsset = useDeleteTemplateFontAsset('Are you sure you want to delete this font?');

  return useCallback(() => {
    if (templateFontAssetId) deleteTemplateFontAsset(templateFontAssetId);
  }, [deleteTemplateFontAsset, templateFontAssetId]);
};

const TemplateFontCard: FC<{ renderTemplateId: string; templateFontAsset: TemplateFontAsset }> = ({
  renderTemplateId,
  templateFontAsset,
}) => {
  const deleteTemplateFontAsset = useDelete(templateFontAsset?.id);

  return (
    <Card>
      <TemplateFontsForm
        templateFontAsset={templateFontAsset}
        renderTemplateId={renderTemplateId}
        deleteTemplateFontAsset={deleteTemplateFontAsset}
      />
    </Card>
  );
};

const StyledGridItem = styled(GridItem)(() => ({
  '&[aria-hidden="true"]': {
    display: 'none',
  },
}));

export const TemplateFontsContent: FC<TemplateFontsContentProps> = ({ renderTemplateId, templateFontAssets }) => {
  const { toggleProps, contentProps, isExpanded, toggle } = useDisclosure({
    id: 'new-font',
    isExpanded: false,
  });

  return (
    <GridContainer>
      {templateFontAssets.map((templateFontAsset) => (
        <GridItem xs={12} md={6} key={templateFontAsset?.id}>
          <TemplateFontCard templateFontAsset={templateFontAsset} renderTemplateId={renderTemplateId} />
        </GridItem>
      ))}

      <StyledGridItem xs={12} md={6} {...contentProps}>
        <Card>
          <TemplateFontsForm renderTemplateId={renderTemplateId} deleteTemplateFontAsset={toggle} />
        </Card>
      </StyledGridItem>

      {!isExpanded && (
        <GridItem xs={12}>
          <Button {...toggleProps} variant="primary">
            Add font
          </Button>
        </GridItem>
      )}
    </GridContainer>
  );
};
