import { useCallback, useEffect, useMemo, useState } from 'react';

import { useUrlParameter } from '@cofenster/web-components';

import type { ExportSettingsContext } from './ExportSettingsProvider';
import { EXPORT_STATUSES } from './constants';

const useOrder = () => {
  const serializeOrder = useCallback(
    (order: ExportSettingsContext['order']) => `${order.property}-${order.direction}`,
    []
  );
  const deserializeOrder = useCallback((serializedOrder: string) => {
    const chunks = serializedOrder.split('-');

    return { property: chunks[0] ?? 'createdAt', direction: chunks[1] ?? 'DESC' } as ExportSettingsContext['order'];
  }, []);

  const defaultOrder: ExportSettingsContext['order'] = { property: 'createdAt', direction: 'DESC' };
  const [order, _setOrder] = useUrlParameter<typeof defaultOrder>(
    defaultOrder,
    'order',
    serializeOrder,
    deserializeOrder
  );

  const setOrder: ExportSettingsContext['setOrder'] = useCallback(
    (getNextOrder) => _setOrder(getNextOrder(order)),
    [order, _setOrder]
  );

  return useMemo(
    () => [order, setOrder] as [ExportSettingsContext['order'], ExportSettingsContext['setOrder']],
    [order, setOrder]
  );
};

const useDates = () => {
  const serializeDates = useCallback(
    (dates: ExportSettingsContext['dates']) => `${dates.after?.valueOf() ?? ''}_${dates.before?.valueOf() ?? ''}`,
    []
  );
  const deserializeDates = useCallback((serializedDates: string) => {
    const chunks = serializedDates.split('_');
    return { after: chunks[0] ?? undefined, before: chunks[1] ?? undefined };
  }, []);

  const defaultDates: ExportSettingsContext['dates'] = useMemo(() => ({ after: undefined, before: undefined }), []);
  const [dates, _setDates] = useUrlParameter<typeof defaultDates>(
    defaultDates,
    'dates',
    serializeDates,
    deserializeDates
  );

  const setDates: ExportSettingsContext['setDates'] = useCallback(
    (getNextDates) => _setDates(getNextDates(dates)),
    [dates, _setDates]
  );

  return useMemo(
    () => [dates, setDates] as [ExportSettingsContext['dates'], ExportSettingsContext['setDates']],
    [dates, setDates]
  );
};

const useStatuses = () => {
  const serializeStatuses = useCallback((statuses: ExportSettingsContext['statuses']) => statuses.join('-'), []);
  const deserializeStatuses = useCallback(
    (serializedStatuses: string) => serializedStatuses.split('-') as ExportSettingsContext['statuses'],
    []
  );

  return useUrlParameter<ExportSettingsContext['statuses']>(
    EXPORT_STATUSES.filter((status) => status !== 'Done'),
    'statuses',
    serializeStatuses,
    deserializeStatuses
  ) as [ExportSettingsContext['statuses'], ExportSettingsContext['setStatuses']];
};

export const useSettings = () => {
  const [page, setPage] = useUrlParameter(1, 'page', String, Number);
  const [pageSize, setPageSize] = useUrlParameter(25, 'pageSize', String, Number);
  const [search, setSearch] = useUrlParameter('', 'search');
  const [statuses, setStatuses] = useStatuses();
  const [order, setOrder] = useOrder();
  const [dates, setDates] = useDates();
  const [withPolling, setWithPolling] = useState(false);
  const filters = useMemo(
    () => ({
      search,
      setSearch,
      order,
      setOrder,
      dates,
      setDates,
      statuses,
      setStatuses,
      pageSize,
      setPageSize,
      page,
      setPage,
      pagination: {
        limit: pageSize,
        offset: Math.max(page - 1, 0) * pageSize,
      },
      withPolling,
      setWithPolling,
    }),
    [
      search,
      setSearch,
      order,
      setOrder,
      dates,
      setDates,
      statuses,
      setStatuses,
      pageSize,
      setPageSize,
      page,
      setPage,
      withPolling,
    ]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => setPage(1), [search, statuses, order, dates]);

  return filters;
};
