import { type FC, useCallback } from 'react';

import {
  CSSGridTable,
  type CSSGridTableConfig,
  type ColumnConfig,
  EmptyState,
  Icon,
  LoadingSpinner,
  Typography,
  VisuallyHidden,
  formatTime,
  useGoto,
} from '@cofenster/web-components';

import { type Music, useMusics } from '../../../../api/hooks/music/useMusics';
import { ExpandedRouterLink } from '../../../../components/navigation/ExpandedRouterLink';
import { PermissionRestriction, useHasPermission } from '../../../../contexts/staffUser/PermissionRestriction';
import { routes } from '../../../../routes';
import { ErrorContent } from '../../../Error';

const HeaderCell: FC<{ column: ColumnConfig<Music> }> = ({ column }) => (
  <Typography color="grey600" variant="h6" component="span">
    {column.name}
  </Typography>
);

const columns: CSSGridTableConfig<Music>['columns'] = [
  {
    id: 'name_EN',
    name: 'Name',
    cell: ({ item }) => (
      <PermissionRestriction
        has="MusicUpdate"
        fallback={
          <Typography variant="h6" color="carbon">
            {item.nameEN}
          </Typography>
        }
      >
        <ExpandedRouterLink to="music" params={{ musicId: item.id }}>
          <Typography variant="h6" color="carbon">
            {item.nameEN}
          </Typography>
        </ExpandedRouterLink>
      </PermissionRestriction>
    ),
    header: HeaderCell,
  },
  {
    id: 'available',
    name: 'Available',
    cell: ({ item }) => (
      <>
        <VisuallyHidden>{item.unavailable ? 'Unavailable' : 'Available'}</VisuallyHidden>
        {item.unavailable ? <Icon type="CloseIcon" color="negative" /> : <Icon type="CheckIcon" color="positive" />}
      </>
    ),
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },
  {
    id: 'createdAt',
    name: 'Created at',
    cell: ({ item }) => (
      <Typography variant="m" component="time">
        {formatTime(item.createdAt)}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },
  {
    id: 'updatedAt',
    name: 'Updated at',
    cell: ({ item }) => (
      <Typography variant="m" component={item.updatedAt ? 'time' : 'span'}>
        {item.updatedAt ? formatTime(item.updatedAt) : 'n/a'}
      </Typography>
    ),
    header: HeaderCell,
    extra: { size: 'minmax(0, auto)' },
  },
];

export const MusicList: FC = () => {
  const { musics, loading, error } = useMusics();
  const goto = useGoto();
  const handleRowClick = useCallback((item: Music) => item && goto(routes.music, { musicId: item.id }), [goto]);
  const canUpdateMusic = useHasPermission({ has: 'MusicUpdate' });

  if (error) {
    return <ErrorContent />;
  }

  if (loading && !musics.length) {
    return <LoadingSpinner />;
  }

  if (!musics.length) {
    return <EmptyState iconType="NoOptionIcon" title="No musics" description="No music tracks were found." />;
  }

  return <CSSGridTable data={musics} columns={columns} onRowClick={canUpdateMusic ? handleRowClick : undefined} />;
};
