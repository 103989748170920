import type { FC } from 'react';

import { Button, Card, GridContainer, GridItem, Headline, Text } from '@cofenster/web-components';

import type { TemplateFontAsset } from '../../../api/generated';
import { RouterLink } from '../../../components/navigation/RouterLink';

export const FontsList: FC<{ fonts: TemplateFontAsset[]; renderTemplateId: string }> = ({
  fonts,
  renderTemplateId,
}) => {
  return (
    <GridContainer mt={1}>
      <GridItem xs>
        <Headline variant="h3">Don't forget to download and reupload the fonts!</Headline>
      </GridItem>
      {fonts.map((font) => (
        <GridItem key={font.id} xs={12} marginBottom={3}>
          <Card>
            <GridContainer>
              <GridItem xs={8}>
                <Text>{font.name}</Text>
              </GridItem>
              <GridItem xs={4} textAlign="right">
                {font.fontUrl && (
                  <a href={font.fontUrl} download={`${font.name}`}>
                    Download
                  </a>
                )}
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      ))}
      <GridItem xs textAlign="right">
        <RouterLink to="templateFonts" params={{ renderTemplateId }} target="_blank">
          <Button>To template fonts</Button>
        </RouterLink>
      </GridItem>
    </GridContainer>
  );
};
