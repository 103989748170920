import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Card, PageHeadline, useGoto } from '@cofenster/web-components';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { UserForm } from '../../../components/users/UserForm';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { type UserRouteParams, routes } from '../../../routes';
import { NoAccessLayout } from '../../NoAccess';

export const UserCreate: FC = () => {
  const goto = useGoto();
  const { accountId } = useParams() as UserRouteParams;

  return (
    <PermissionRestriction has="UserCreate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle="Invite new member"
        header={{
          topLeft: (
            <PageHeadline mb={0} title="Invite new member" onBack={() => goto(routes.accountUsers, { accountId })} />
          ),
        }}
      >
        <Card>
          <UserForm accountId={accountId} />
        </Card>
      </AdminLayout>
    </PermissionRestriction>
  );
};
