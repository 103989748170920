import { useCallback } from 'react';

import { useGoto, useSnackbars } from '@cofenster/web-components';
import { useRestartRenderJob } from '../api/hooks/renderJob/useRestartRenderJob';

import { useDialogs } from '../contexts/dialogs/useDialogs';
import type { ExtendedRenderJob } from '../contexts/exports';
import { routes } from '../routes';

export type RemotionVersionOption = 'current' | 'ng' | 'automatic';

export const useRestartJob = (props: ExtendedRenderJob) => {
  const restartRenderJob = useRestartRenderJob();
  const { openSnackbar } = useSnackbars();
  const { openDialog, closeDialog } = useDialogs();
  const { restartMode, job, project, account } = props;
  const renderJobId = job.id;
  const projectName = project?.name;
  const accountName = account?.name;
  const goto = useGoto();

  const restart = useCallback(
    async (remotionVersion: RemotionVersionOption) => {
      if (!restartMode) return;

      // Enforce the current renderer when the __DISABLE_REMOTION_NG__ build flag is set.
      const useNg = __DISABLE_REMOTION_NG__
        ? false
        : remotionVersion === 'current'
          ? false
          : remotionVersion === 'ng'
            ? true
            : undefined;

      const projectDetail =
        projectName && accountName ? ` for project '${projectName}' from acount '${accountName}'` : '';

      try {
        const result = await restartRenderJob(renderJobId, {
          mode: restartMode,
          useNg,
        });
        const newRenderJobId = result.data?.restartRenderJob?.id;

        openSnackbar({
          variant: 'success',
          children: `Successfully restarted render job${projectDetail}`,
        });

        if (newRenderJobId && newRenderJobId !== renderJobId) {
          goto(routes.export, { id: newRenderJobId });
        }
      } catch (error) {
        console.error(error);
        openSnackbar({
          variant: 'error',
          children: `Failed to restart render job${projectDetail}`,
        });
      } finally {
        closeDialog('RestartJobDialog');
      }
    },
    [restartRenderJob, renderJobId, projectName, accountName, restartMode, openSnackbar, closeDialog, goto]
  );
  const restartJob = useCallback(
    () => openDialog('RestartJobDialog', { ...props, restart }),
    [openDialog, props, restart]
  );

  return restartMode ? restartJob : null;
};
