import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import {
  Card,
  GridContainer,
  GridItem,
  Headline,
  LoadingSpinner,
  PageHeadline,
  Spacing,
  styled,
} from '@cofenster/web-components';

import { useAccount } from '../../../../api/hooks/account/useAccount';
import { AccountForm } from '../../../../components/accounts/AccountForm';
import { AccountSubNavigation } from '../../../../components/accounts/AccountSubNavigation';
import { AdminLayout } from '../../../../components/layout/AdminLayout';
import { PermissionRestriction, useHasPermission } from '../../../../contexts/staffUser/PermissionRestriction';
import { useGotoAccounts } from '../../../../hooks/useGotoAccounts';
import type { AccountRouteParams } from '../../../../routes';
import { ErrorLayout } from '../../../Error';
import { NoAccessLayout } from '../../../NoAccess';

import { DangerZone } from './DangerZone';
import { Metadata } from './Metadata';

const CardHeadline = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const AccountBaseData: FC = () => {
  const { accountId } = useParams() as AccountRouteParams;
  const { account, error } = useAccount(accountId);
  const title = ['Info', account?.name].filter(Boolean).join(' — ');
  const gotoAccounts = useGotoAccounts();
  const featureFlags = account?.featureFlags ?? [];
  const canUpdateAccounts = useHasPermission({ has: 'AccountUpdate' });

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="AccountRead" fallback={<NoAccessLayout documentTitle={title} />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={gotoAccounts} />,
          bottomLeft: <AccountSubNavigation accountId={accountId} />,
        }}
      >
        <GridContainer>
          <GridItem xs={12} md={6}>
            <Card>
              <CardHeadline color="carbon" variant="h4" component="h2">
                Information
              </CardHeadline>
              {account ? (
                <AccountForm account={account} withTemplateManagement={false} disabled={!canUpdateAccounts} />
              ) : (
                <LoadingSpinner />
              )}
            </Card>
          </GridItem>
          <GridItem xs={12} md={6}>
            <Card>
              <CardHeadline color="carbon" variant="h4" component="h2">
                Metadata
              </CardHeadline>
              {account ? <Metadata account={account} /> : <LoadingSpinner />}
            </Card>
          </GridItem>
        </GridContainer>

        <Spacing top={3}>
          <DangerZone accountId={accountId} accountName={account?.name ?? ''} featureFlags={featureFlags} />
        </Spacing>
      </AdminLayout>
    </PermissionRestriction>
  );
};
