import { useMemo } from 'react';

import { type AccountsQueryResult, type AccountsQueryVariables, useAccountsQuery } from '../../generated';

export type Account = NonNullable<AccountsQueryResult['data']>['accounts']['items'][number];
export type Filter = NonNullable<AccountsQueryVariables['filter']>;
export type PaginatedAccounts = NonNullable<AccountsQueryResult['data']>['accounts'];
export type AccountsFilterOrder = NonNullable<Filter['order']>;

export const useAccounts = (filter?: Filter) => {
  const { loading, error, data } = useAccountsQuery({
    variables: { filter },
    // See: https://github.com/apollographql/apollo-client/issues/5419#issuecomment-598065442
    // See: https://github.com/apollographql/apollo-client/issues/5419#issuecomment-605832327
    fetchPolicy: 'cache-and-network',
  });
  const paginatedAccounts = data?.accounts;
  return useMemo(
    () => ({
      paginatedAccounts,
      loading,
      error,
    }),
    [paginatedAccounts, loading, error]
  );
};
