import { type FC, useCallback } from 'react';

import { Button, Card, GridContainer, GridItem } from '@cofenster/web-components';

import { useCloneRenderTemplate } from '../../../../api/hooks/renderTemplate/useCloneRenderTemplate';
import { TemplateForm } from '../../../../components/templates/TemplateForm';
import { PermissionRestriction } from '../../../../contexts/staffUser/PermissionRestriction';
import { useGotoRenderTemplates } from '../../../../hooks/useGotoRenderTemplates';
import type { RenderTemplate } from '../../TemplateAccounts';

const useClone = (renderTemplate: RenderTemplate) => {
  const cloneTemplate = useCloneRenderTemplate('Copy render template?');
  const gotoTemplates = useGotoRenderTemplates();

  return useCallback(async () => {
    if (!renderTemplate) return;
    const result = await cloneTemplate(renderTemplate.id);
    result && gotoTemplates();
  }, [renderTemplate, cloneTemplate, gotoTemplates]);
};

type Props = {
  renderTemplate: RenderTemplate;
  disabled?: boolean;
};

export const TemplateCard: FC<Props> = ({ renderTemplate, disabled }) => {
  const cloneTemplate = useClone(renderTemplate);

  return (
    <Card>
      <GridContainer>
        <GridItem xs={12}>
          <TemplateForm renderTemplate={renderTemplate} disabled={disabled} />
        </GridItem>
        <GridItem mb={2}>
          <PermissionRestriction has="TemplateCreate">
            <Button variant="secondary" onClick={cloneTemplate}>
              Copy RenderTemplate
            </Button>
          </PermissionRestriction>
        </GridItem>
      </GridContainer>
    </Card>
  );
};
