import { useMemo } from 'react';

import {
  type DuplicateTextCompositionForRenderTemplateMutationVariables,
  useDuplicateTextCompositionForRenderTemplateMutation,
} from '../../generated';

export const useDuplicateTextCompositionForRenderTemplate = () => {
  const [duplicateTextCompositionForRenderTemplate, { loading }] = useDuplicateTextCompositionForRenderTemplateMutation(
    { refetchQueries: ['RenderTemplate'] }
  );

  return useMemo(
    () => ({
      duplicateTextCompositionForRenderTemplate: (
        renderTemplateId: DuplicateTextCompositionForRenderTemplateMutationVariables['renderTemplateId'],
        sourceTextCompositionId: DuplicateTextCompositionForRenderTemplateMutationVariables['sourceTextCompositionId']
      ) => duplicateTextCompositionForRenderTemplate({ variables: { renderTemplateId, sourceTextCompositionId } }),
      loading,
    }),
    [duplicateTextCompositionForRenderTemplate, loading]
  );
};
