import { type FC, useCallback } from 'react';

import { Card, GridContainer, GridItem, Headline, SearchField, Spacing } from '@cofenster/web-components';

import { type ExtendedRenderJob, type RenderJob, useExportSettings } from '../../../../contexts/exports';

import { AutoRefreshSwitch } from './AutoRefreshSwitch';
import { DateFields } from './DateFields';
import { PageSizeSelect } from './PageSizeSelect';
import { StatusSelect } from './StatusSelect';

export const ExportsFilters: FC<{ extendedJobs: ExtendedRenderJob[] }> = ({ extendedJobs }) => {
  const { search, setSearch } = useExportSettings();
  const countJobsWithStatus = useCallback(
    (status: RenderJob['status']) => extendedJobs.filter((extendedJob) => extendedJob.job.status === status).length,
    [extendedJobs]
  );

  return (
    <Spacing bottom={4}>
      <Card>
        <Spacing bottom={2}>
          <Headline variant="h4" component="h2">
            Settings
          </Headline>
        </Spacing>
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <StatusSelect countJobsWithStatus={countJobsWithStatus} />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <SearchField
              label="ID / name search"
              id="search"
              name="search"
              search={search}
              onSearch={setSearch}
              alwaysExtended
              fullWidth
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <DateFields />
          </GridItem>
          <GridItem sm={3}>
            <PageSizeSelect />
          </GridItem>
          <GridItem sm={3} display="flex" alignItems="center">
            <AutoRefreshSwitch />
          </GridItem>
        </GridContainer>
      </Card>
    </Spacing>
  );
};
