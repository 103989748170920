import { useMemo } from 'react';

import { useStopPollingWhenUnauthenticated } from '../../../hooks/useStopPollingWhenUnauthenticated';
import { type VideoAssetQueryResult, type VideoAssetQueryVariables, useVideoAssetQuery } from '../../generated';

export type VideoAsset = NonNullable<VideoAssetQueryResult['data']>['videoAsset'];

export const useVideoAsset = (id?: VideoAssetQueryVariables['id']) => {
  const { loading, error, data, startPolling, stopPolling } = useVideoAssetQuery({
    variables: { id: id ?? '' },
    skip: !id,
    fetchPolicy: 'cache-and-network',
    skipPollAttempt: () => document.hidden,
  });
  const videoAsset = data?.videoAsset || undefined;

  useStopPollingWhenUnauthenticated(stopPolling, error);

  return useMemo(
    () => ({
      videoAsset,
      loading,
      error,
      startPolling,
      stopPolling,
    }),
    [videoAsset, loading, error, startPolling, stopPolling]
  );
};
