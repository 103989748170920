import { useCallback } from 'react';

import { type DeleteStorySceneMutationVariables, useDeleteStorySceneMutation } from '../../generated';

export const useDeleteStoryScene = (confirm?: string) => {
  const [deleteStoryScene] = useDeleteStorySceneMutation({ refetchQueries: ['Story'] });
  return useCallback(
    (id: DeleteStorySceneMutationVariables['id']) => {
      if (confirm && !window.confirm(confirm)) return Promise.resolve();
      return deleteStoryScene({ variables: { id } });
    },
    [deleteStoryScene, confirm]
  );
};
