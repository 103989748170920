import { useMemo } from 'react';

import { useStopPollingWhenUnauthenticated } from '../../../hooks/useStopPollingWhenUnauthenticated';
import {
  type ExtendedRenderJobsQueryResult,
  type ExtendedRenderJobsQueryVariables,
  useExtendedRenderJobsQuery,
} from '../../generated';

type QueryData = NonNullable<ExtendedRenderJobsQueryResult['data']>;
export type ExtendedRenderJobsPaginationResult = NonNullable<QueryData['extendedRenderJobs']>;
export type ExtendedRenderJob = NonNullable<ExtendedRenderJobsPaginationResult['items'][number]>;
export type RenderJob = ExtendedRenderJob['job'];

export const useExtendedRenderJobs = (filter: ExtendedRenderJobsQueryVariables['filter']) => {
  const { loading, error, data, startPolling, stopPolling } = useExtendedRenderJobsQuery({
    variables: { filter },
    skipPollAttempt: () => document.hidden,
  });
  const extendedRenderJobs = data?.extendedRenderJobs;

  useStopPollingWhenUnauthenticated(stopPolling, error);

  return useMemo(
    () => ({
      pagination: extendedRenderJobs ?? { items: [], offset: 0, total: 0, limit: 0 },
      loading,
      error,
      startPolling,
      stopPolling,
    }),
    [extendedRenderJobs, loading, error, startPolling, stopPolling]
  );
};
