import { type FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Card, LoadingSpinner, PageHeadline, useGoto, useSnackbars } from '@cofenster/web-components';

import { useDeleteStaffUser as useDeleteStaffUserHook } from '../../../api/hooks/staffUser/useDeleteStaffUser';
import { type StaffUser, useStaffUser } from '../../../api/hooks/staffUser/useStaffUser';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { StaffUserForm } from '../../../components/staffUsers/StaffUserForm';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';
import { type StaffUserRouteParams, routes } from '../../../routes';
import { ErrorLayout } from '../../Error';
import { NoAccessLayout } from '../../NoAccess';

const useDeleteStaffUser = (staffUser: StaffUser | undefined) => {
  const deleteStaffUser = useDeleteStaffUserHook();
  const confirmDialog = useConfirmDialog({
    title: 'Remove staff user',
    content: `Are you sure you want to remove “${staffUser?.name}” as a staff user? They will no longer be able to access CoAdmin.`,
    confirm: 'Remove',
  });
  const { openSnackbar } = useSnackbars();
  const goto = useGoto();

  return useCallback(async () => {
    if (!staffUser) return;

    try {
      if (await confirmDialog()) {
        await deleteStaffUser(staffUser.id);
        goto(routes.staffUsers);
      }
    } catch (_error) {
      openSnackbar({
        variant: 'error',
        children: `Failed to delete staff user "${staffUser.name}".`,
      });
    }
  }, [staffUser, confirmDialog, deleteStaffUser, openSnackbar, goto]);
};

export const StaffUserUpdate: FC = () => {
  const goto = useGoto();
  const { staffUserId } = useParams() as StaffUserRouteParams;
  const { staffUser, error } = useStaffUser(staffUserId);
  const deleteStaffUser = useDeleteStaffUser(staffUser);
  const title = staffUser?.name ?? 'Staff user';

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="StaffUserUpdate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={() => goto(routes.staffUsers)} />,
          topRight: (
            <PermissionRestriction has="StaffUserDelete">
              <Button variant="destructive" onClick={deleteStaffUser} data-testid="delete-staff-user-button">
                Remove staff user
              </Button>
            </PermissionRestriction>
          ),
        }}
      >
        <Card>{staffUser ? <StaffUserForm staffUser={staffUser} /> : <LoadingSpinner />}</Card>
      </AdminLayout>
    </PermissionRestriction>
  );
};
