import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Card, Headline, LoadingSpinner, PageHeadline, styled } from '@cofenster/web-components';

import { useAccount } from '../../../../api/hooks/account/useAccount';

import { useStories } from '../../../../api/hooks/story/useStories';
import { AccountSubNavigation } from '../../../../components/accounts/AccountSubNavigation';
import { AdminLayout } from '../../../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../../../contexts/staffUser/PermissionRestriction';
import { useGotoAccounts } from '../../../../hooks/useGotoAccounts';
import type { AccountRouteParams } from '../../../../routes';
import { ErrorContent, ErrorLayout } from '../../../Error';
import { NoAccessLayout } from '../../../NoAccess';

import { AccountStoriesContent } from './AccountStoriesContent';

const CardHeadline = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const AccountStories: FC = () => {
  const { accountId } = useParams() as AccountRouteParams;
  const { account } = useAccount(accountId);
  const { stories, loading, error } = useStories({ private: true });
  const title = ['Stories', account?.name].filter(Boolean).join(' — ');
  const gotoAccounts = useGotoAccounts();

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="AccountRead" fallback={<NoAccessLayout documentTitle={title} />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={gotoAccounts} />,
          bottomLeft: <AccountSubNavigation accountId={accountId} />,
        }}
      >
        {stories ? (
          <Card>
            <CardHeadline color="carbon" variant="h3" component="h2">
              Stories
            </CardHeadline>
            <AccountStoriesContent accountId={accountId} stories={stories} />
          </Card>
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <ErrorContent />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
