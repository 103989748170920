import { Select, SelectOption } from '@cofenster/web-components';

import type { Option, OptionEditor } from './Option';

export type Direction = 'Left' | 'Right' | 'Up' | 'Down';

const DirectionEditor: OptionEditor<Direction> = ({ value = 'Left', onChange }) => {
  return (
    <Select
      name="direction"
      label="Direction"
      value={value}
      onChange={(event) => onChange(event.target.value as Direction)}
    >
      <SelectOption value="Left">Left</SelectOption>
      <SelectOption value="Right">Right</SelectOption>
      <SelectOption value="Up">Up</SelectOption>
      <SelectOption value="Down">Down</SelectOption>
    </Select>
  );
};

export const DirectionOption: Option<Direction> = {
  Editor: DirectionEditor,
  serialize: (value) => value,
  deserialize: (value) => value as Direction,
};
