import { type ComponentProps, useCallback, useEffect, useState } from 'react';

export const useDisclosure = ({ id, isExpanded: isInitiallyExpanded }: { id: string; isExpanded?: boolean }) => {
  const [isExpanded, setIsExpanded] = useState(typeof isInitiallyExpanded !== 'undefined' ? isInitiallyExpanded : true);

  useEffect(() => {
    typeof isInitiallyExpanded !== 'undefined' && setIsExpanded(isInitiallyExpanded);
  }, [isInitiallyExpanded]);

  const toggle = useCallback(() => setIsExpanded((value) => !value), []);

  const toggleProps = {
    id: `${id}-toggle`,
    onClick: toggle,
    type: 'button' as ComponentProps<'button'>['type'],
    'aria-expanded': isExpanded,
    'aria-controls': `${id}-content`,
  };
  const contentProps = {
    id: `${id}-content`,
    'aria-hidden': !isExpanded,
    'aria-labelledby': `${id}-toggle`,
  };

  return { toggleProps, contentProps, isExpanded, toggle };
};
