import { type Dispatch, type SetStateAction, useCallback } from 'react';

import type { StaticStyle, StyleProperties } from '../remotion';

const EMPTY_OBJECT = {};
export const useStaticStyleState = ([state, setState]: [StyleProperties, Dispatch<SetStateAction<StyleProperties>>]): [
  StaticStyle,
  Dispatch<SetStateAction<StaticStyle>>,
] => {
  const subState = state.css ?? EMPTY_OBJECT;
  const setSubState = useCallback<Dispatch<SetStateAction<StaticStyle>>>(
    (setterOrValue) => {
      return setState(({ css: prevCss, ...rest }) => {
        const css = typeof setterOrValue === 'function' ? setterOrValue(prevCss ?? EMPTY_OBJECT) : setterOrValue;
        return {
          ...rest,
          css,
        };
      });
    },
    [setState]
  );

  return [subState, setSubState];
};
