import type { FC } from 'react';

import { Card, Headline, NativeVideoPlayer, Spacing } from '@cofenster/web-components';

export const InspectAssetPreview: FC<{ src: string }> = ({ src }) => {
  return (
    <Card>
      <Spacing bottom={2}>
        <Headline component="h2" variant="h4">
          Preview
        </Headline>
      </Spacing>

      <NativeVideoPlayer src={src} actions={['DOWNLOAD', 'PLAYBACK_RATE']} />
    </Card>
  );
};
