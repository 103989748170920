import { useEffect, useId, useState } from 'react';

import { GridContainer, GridItem, Switch, Typography } from '@cofenster/web-components';

import { PresetColorPicker } from '../../PresetColorPicker';

import type { Option, OptionEditor } from './Option';

const MotionBlurEditor: OptionEditor<string> = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showPicker, setShowPicker] = useState(false);
  const id = `color-picker-switch-${useId()}`;

  const handleSwitch = (checked: boolean) => {
    setShowPicker(checked);

    if (!checked) onChange(NO_MOTION_BLUR);
  };

  useEffect(() => {
    setShowPicker(value !== NO_MOTION_BLUR);
  }, [value]);

  return (
    <GridContainer spacing={2} pb={2} alignItems="center" pl={1}>
      <GridItem xs={12} display="flex" alignItems="center">
        <Switch id={id} onChange={(event) => handleSwitch(event.target.checked)} checked={showPicker} />
        <label htmlFor={id}>
          <Typography variant="m">Motion Blur</Typography>
        </label>
      </GridItem>
      <GridItem xs={12}>
        {showPicker && (
          <PresetColorPicker
            isOpen={isOpen}
            color={value === NO_MOTION_BLUR ? undefined : value}
            onChange={onChange}
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
            showActionButtons
            label="Blur color"
          />
        )}
      </GridItem>
    </GridContainer>
  );
};

export const MotionBlurOption: Option<string> = {
  Editor: MotionBlurEditor,
  serialize: (value) => value,
  deserialize: (value) => value,
};

export const NO_MOTION_BLUR = 'NoMotionBlur';
