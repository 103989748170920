import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { Form, FormSubmitButton, FormTextField, GridContainer, GridItem, Headline } from '@cofenster/web-components';
import { useCreateRenderTemplate } from '../../api/hooks/renderTemplate/useCreateRenderTemplate';
import type { RenderTemplate } from '../../api/hooks/renderTemplate/useRenderTemplate';
import { useUpdateRenderTemplate } from '../../api/hooks/renderTemplate/useUpdateRenderTemplate';

import { useHasPermission } from '../../contexts/staffUser/PermissionRestriction';
import { useGotoRenderTemplate } from '../../hooks/useGotoRenderTemplate';
import { ImageAssetUpload } from '../upload/ImageAssetUpload';
import { VideoAssetUpload } from '../upload/VideoAssetUpload';

const STAGE = process.env.STAGE as string;

type Values = {
  name: string;
  nameEN: string;
  previewVideoAssetId: string;
  previewImageAssetId: Yup.Maybe<string>;
  templateIdentifier: string;
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  name: Yup.string().trim().required('Please fill in this field'),
  nameEN: Yup.string().trim().required('Please fill in this field'),
  previewVideoAssetId: Yup.string().trim().required('Please fill in this field'),
  previewImageAssetId: Yup.string().trim().nullable(),
  templateIdentifier: Yup.string().trim().required('Please fill in this field'),
});

const useInitialValues = (renderTemplate: RenderTemplate | undefined) => {
  return useMemo<Values>(
    () => ({
      name: renderTemplate?.name ?? '',
      nameEN: renderTemplate?.nameEN ?? '',
      previewVideoAssetId: renderTemplate?.previewVideoAsset?.id ?? '',
      previewImageAssetId: renderTemplate?.previewImageAsset?.id ?? undefined,
      templateIdentifier: renderTemplate?.templateIdentifier ?? '',
    }),
    [renderTemplate]
  );
};

const useCreate = () => {
  const createTemplate = useCreateRenderTemplate();
  const gotoTemplate = useGotoRenderTemplate();
  return useCallback(
    async (data: Values) => {
      try {
        const result = await createTemplate(data);
        const newTemplate = result.data?.createRenderTemplate;
        newTemplate && gotoTemplate(newTemplate.id);
      } catch (error) {
        if (STAGE !== 'production') console.log(error);
        throw new Error('An unexpected error has occurred');
      }
    },
    [createTemplate, gotoTemplate]
  );
};

const useUpdate = () => {
  const updateTemplate = useUpdateRenderTemplate();
  return useCallback(
    async (renderTemplate: RenderTemplate, data: Values) => {
      try {
        await updateTemplate(renderTemplate.id, data);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [updateTemplate]
  );
};

const useSubmit = (renderTemplate: RenderTemplate | undefined) => {
  const createTemplate = useCreate();
  const updateTemplate = useUpdate();
  const canCreateTemplate = useHasPermission({ has: 'TemplateCreate' });
  const canUpdateTemplate = useHasPermission({ has: 'TemplateUpdate' });

  return useCallback(
    async (values: Values) => {
      try {
        if (renderTemplate && canUpdateTemplate) return await updateTemplate(renderTemplate, values);
        if (canCreateTemplate) return await createTemplate(values);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [renderTemplate, createTemplate, updateTemplate, canCreateTemplate, canUpdateTemplate]
  );
};

type Props = {
  renderTemplate?: RenderTemplate | undefined;
  disabled?: boolean;
};

export const TemplateForm: FC<Props> = ({ renderTemplate, disabled }) => {
  const initialValues = useInitialValues(renderTemplate);
  const onSubmit = useSubmit(renderTemplate);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer spacing={4}>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12}>
              <Headline variant="h4">Settings</Headline>
            </GridItem>
            <GridItem xs={6} mb={-2}>
              <FormTextField
                disabled={disabled}
                id="templateName"
                name="name"
                label="Name (DE)"
                placeholder="Name (DE)"
              />
            </GridItem>
            <GridItem xs={6} mb={-2}>
              <FormTextField
                disabled={disabled}
                id="templateNameEN"
                name="nameEN"
                label="Name (EN)"
                placeholder="Name (EN)"
              />
            </GridItem>
            <GridItem xs={6} mb={-2}>
              <FormTextField
                disabled={disabled}
                id="templateIdentifier"
                name="templateIdentifier"
                label="Template Identifier"
                placeholder="Template Identifier"
              />
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} md={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Headline variant="h4">Video thumbnail</Headline>
            </GridItem>
            <GridItem xs={12}>
              <VideoAssetUpload assetDropzoneProps={{ disabled }} name="previewVideoAssetId" />
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} md={6}>
          <GridContainer>
            <GridItem xs={12}>
              <Headline variant="h4">Override image thumbnail</Headline>
            </GridItem>
            <GridItem xs={12}>
              <ImageAssetUpload assetDropzoneProps={{ disabled }} name="previewImageAssetId" />
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12}>
          <FormSubmitButton disabled={disabled}>{renderTemplate ? 'Save' : 'Create'}</FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
