import { useField } from 'formik';
import { type FC, useCallback, useState } from 'react';

import { MimeTypes } from '@cofenster/constants';
import {
  AbsoluteDeleteIcon,
  AssetDropzone,
  AudioPlayer,
  DropzoneContainer,
  DropzoneStatus,
  useUploadHint,
} from '@cofenster/web-components';
import type { Music } from '../../../api/hooks/music/useMusic';
import { useUpdateMusic } from '../../../api/hooks/music/useUpdateMusic';

import { useConfirmNavigationDuringUpload } from '../../../hooks/useConfirmNavigationDuringUpload';

import { usePublicAudioUpload } from './usePublicAudioUpload';

type AudioAsset = NonNullable<Music['musicAsset']>;

const useMusicUpdate = (musicId: string | undefined) => {
  const updateMusic = useUpdateMusic();

  return useCallback(
    async (musicAssetId?: string) => {
      if (!musicId) return;
      try {
        await updateMusic(musicId, { musicAssetId });
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [musicId, updateMusic]
  );
};

type MusicUploadProps = {
  name: string;
  musicId: string | undefined;
  audioAsset: AudioAsset | null | undefined;
  setMusicAssetId?: (id?: string) => void;
};

export const MusicUpload: FC<MusicUploadProps> = ({ name, musicId, audioAsset, setMusicAssetId }) => {
  const [, { error }, { setValue }] = useField(name);
  const [hasUploaded, setHasUploaded] = useState<boolean>(false);
  const [publicAudioAssetUpload, progress] = usePublicAudioUpload();
  const [fileName, setFileName] = useState<string | undefined>(audioAsset?.filename ?? undefined);
  const musicUpdate = useMusicUpdate(musicId);
  const hintText = useUploadHint('audio');

  useConfirmNavigationDuringUpload(progress !== undefined);

  const onUpload = async (file: File) => {
    const upload = await publicAudioAssetUpload(file);
    setFileName(file.name);
    musicUpdate(upload);
    setMusicAssetId?.(upload);
    setHasUploaded(!!upload);
    setValue(upload);
    return upload;
  };
  const isUploading = progress !== undefined;

  const handleDeleteFile = useCallback(() => {
    musicUpdate();
    setMusicAssetId?.();
    setHasUploaded(false);
    setValue(undefined);
  }, [musicUpdate, setMusicAssetId, setValue]);

  if (isUploading) {
    return (
      <DropzoneContainer size="medium">
        <DropzoneStatus
          status="uploading"
          text={fileName}
          hintText="Your audio is uploading."
          progress={progress || 0}
        />
      </DropzoneContainer>
    );
  }

  if (audioAsset?.status === 'Waiting' || (hasUploaded && !audioAsset?.audioUrl)) {
    return (
      <DropzoneContainer size="medium">
        <DropzoneStatus status="processing" text={fileName} hintText="Almost done, your music will be ready soon!" />
      </DropzoneContainer>
    );
  }

  if (audioAsset?.audioUrl) {
    return (
      <DropzoneContainer size="medium" style={{ position: 'relative' }}>
        <AudioPlayer
          url={audioAsset.audioUrl}
          duration={audioAsset.duration}
          filename={audioAsset.filename ?? 'Missing file name'}
        />
        <AbsoluteDeleteIcon onClick={handleDeleteFile} data-testid="audio-delete-button" />
      </DropzoneContainer>
    );
  }

  return (
    <DropzoneContainer size="medium">
      <AssetDropzone
        size="small"
        error={error}
        mime={MimeTypes.audio}
        maxSize={100 * 1024 * 1024}
        onFile={onUpload}
        icon="CloudUploadIcon"
        text="Drop your audio here or browse"
        hintText={hintText}
        data-testid="audio-upload"
      />
    </DropzoneContainer>
  );
};
