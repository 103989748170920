import { useCallback } from 'react';
import {
  type CreateStaffUserMutationResult,
  type CreateStaffUserMutationVariables,
  useCreateStaffUserMutation,
} from '../../generated';

type Result = NonNullable<CreateStaffUserMutationResult['data']>['createStaffUser'];
export type StaffUser = Extract<Result, { __typename?: 'StaffUser' }>;
export type StaffUserError = Extract<Result, { __typename?: 'StaffUserError' }>;

export const useCreateStaffUser = () => {
  const [createStaffUser] = useCreateStaffUserMutation({
    refetchQueries: ['StaffUsers'],
  });

  return useCallback(
    (input: CreateStaffUserMutationVariables['input']) => createStaffUser({ variables: { input } }),
    [createStaffUser]
  );
};
