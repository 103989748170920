import { useMemo } from 'react';

import { type StaffUserQuery, type StaffUserQueryVariables, useStaffUserQuery } from '../../generated';

export type StaffUser = StaffUserQuery['staffUser'];

export const useStaffUser = (staffUserId: StaffUserQueryVariables['id']) => {
  const { loading, error, data } = useStaffUserQuery({
    variables: { id: staffUserId },
  });
  const staffUser = data?.staffUser ?? undefined;

  return useMemo(() => ({ staffUser, loading, error }), [staffUser, loading, error]);
};
