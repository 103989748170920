import { useCallback } from 'react';

import { type SortTextCompositionsMutationVariables, useSortTextCompositionsMutation } from '../../generated';

export const useSortTextCompositions = () => {
  const [sortTextCompositions] = useSortTextCompositionsMutation();
  return useCallback(
    (ids: SortTextCompositionsMutationVariables['ids']) => sortTextCompositions({ variables: { ids } }),
    [sortTextCompositions]
  );
};
