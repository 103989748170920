import type { FC, RefObject } from 'react';

import { Button, styled } from '@cofenster/web-components';

const ImportTextArea = styled('textarea')(({ theme }) => ({
  padding: 10,
  backgroundColor: 'white',
  borderRadius: theme.shape['borderRadius-l'],
  width: 600,
  height: 500,
  overflow: 'scroll',
  marginTop: 20,
  marginBottom: 20,
}));

export const Import: FC<{
  templateToImport: RefObject<HTMLTextAreaElement>;
  processTemplate: VoidFunction;
}> = ({ templateToImport, processTemplate }) => {
  return (
    <>
      <ImportTextArea ref={templateToImport} />
      <Button onClick={processTemplate}>Import Template</Button>
    </>
  );
};
