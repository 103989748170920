import type { FC } from 'react';

import { Card, Headline, Spacing, TextLink, styled } from '@cofenster/web-components';

import type { InspectVideoAsset } from '../../../api/hooks/videoAsset/useInspectVideoAsset';
import {
  Definition,
  DefinitionContent,
  DefinitionList,
  DefinitionTerm,
} from '../../../components/display/DefinitionList';
import { InspectAssetPreview } from '../InspectAssetPreview';

const NoWrapDefinitionContent = styled(DefinitionContent)(() => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

const StyledTextLink = styled(TextLink)(({ theme }) => ({
  color: theme.palette.brand.blue,
  borderRadius: 4,

  '&:focus-visible': theme.mixins.focusRing,
}));

export const InspectVideoAssetOriginalContent: FC<NonNullable<InspectVideoAsset>> = ({
  videoAssetId,
  originalUrl,
  originalAwsBucket,
  originalAwsKey,
  originalMetadata,
}) => {
  return (
    <>
      <Spacing bottom={3}>
        <Card>
          <Spacing bottom={2}>
            <Headline component="h2" variant="h4">
              Metadata
            </Headline>
          </Spacing>

          <DefinitionList>
            <Definition>
              <DefinitionTerm>Video asset ID</DefinitionTerm>
              <DefinitionContent>{videoAssetId}</DefinitionContent>
            </Definition>

            <Definition>
              <DefinitionTerm>Original URL</DefinitionTerm>
              <NoWrapDefinitionContent>
                <StyledTextLink href={originalUrl} target="_blank" variant="l">
                  {originalUrl}
                </StyledTextLink>
              </NoWrapDefinitionContent>
            </Definition>

            <Definition data-half>
              <DefinitionTerm>Original AWS bucket</DefinitionTerm>
              <DefinitionContent>{originalAwsBucket}</DefinitionContent>
            </Definition>

            <Definition data-half>
              <DefinitionTerm>Original AWS key</DefinitionTerm>
              <DefinitionContent>{originalAwsKey}</DefinitionContent>
            </Definition>

            <Definition data-half>
              <DefinitionTerm>Original format</DefinitionTerm>
              <DefinitionContent>{originalMetadata.videoFormat}</DefinitionContent>
            </Definition>

            <Definition data-half>
              <DefinitionTerm>Original fit</DefinitionTerm>
              <DefinitionContent>{originalMetadata.videoFit}</DefinitionContent>
            </Definition>
          </DefinitionList>
        </Card>
      </Spacing>

      <InspectAssetPreview src={originalUrl} />
    </>
  );
};
