import type { FC } from 'react';

import { Card, GridContainer, GridItem, Headline, UserStatus } from '@cofenster/web-components';

import type { User } from '../../../../api/hooks/user/useUser';
import { UserForm } from '../../../../components/users/UserForm';

import { PopoverMenu } from './PopoverMenu';

type UserUpdateContentProps = { user: User };

export const UserUpdateContent: FC<UserUpdateContentProps> = ({ user }) => (
  <Card>
    <GridContainer flexDirection="column">
      <GridItem xs>
        <GridContainer>
          <GridItem xs>
            <Headline variant="h4">User details</Headline>
          </GridItem>
          <GridItem display="flex" alignItems="center" gap={2}>
            <UserStatus createdAt={user.createdAt} deactivatedAt={user.deactivatedAt} isPending={user.isPending} />
            <PopoverMenu user={user} />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs>
        <UserForm user={user} accountId={user.account.id} />
      </GridItem>
    </GridContainer>
  </Card>
);
