import { type FC, useCallback } from 'react';

import {
  Button,
  Card,
  GridContainer,
  GridItem,
  Headline,
  Spacing,
  Typography,
  styled,
} from '@cofenster/web-components';

import { useDeleteRenderTemplate } from '../../../../api/hooks/renderTemplate/useDeleteRenderTemplate';
import { PermissionRestriction } from '../../../../contexts/staffUser/PermissionRestriction';
import { useGotoRenderTemplates } from '../../../../hooks/useGotoRenderTemplates';
import type { RenderTemplate } from '../../TemplateAccounts';

const useDelete = (renderTemplateId: string) => {
  const deleteRenderTemplate = useDeleteRenderTemplate('Should the template be deleted?');
  const gotoTemplates = useGotoRenderTemplates();

  return useCallback(async () => {
    const result = await deleteRenderTemplate(renderTemplateId);
    if (result) gotoTemplates();
  }, [renderTemplateId, deleteRenderTemplate, gotoTemplates]);
};

const StyledCard = styled(Card)(({ theme }) => ({
  border: `3px solid ${theme.palette.brand.negative}`,
}));

const CardHeadline = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const DangerZone: FC<{ renderTemplate: RenderTemplate }> = ({ renderTemplate }) => {
  const deleteRenderTemplate = useDelete(renderTemplate.id);
  const { projectCount, accountsUsageCount, templateIdentifier } = renderTemplate;
  const canBeDeleted = projectCount === 0;
  const isCodeTemplate = templateIdentifier !== 'json-template';

  // Not a very elegant strategy, but good enough to make sure we do not delete
  // the subtitle templates by mistake.
  if (templateIdentifier === 'subtitles' || templateIdentifier === 'subtitles_zh_CN') return null;

  return (
    <PermissionRestriction has="TemplateDelete">
      <Spacing top={3}>
        <StyledCard>
          <CardHeadline color="carbon" variant="h3" component="h2">
            Danger zone
          </CardHeadline>

          <GridContainer>
            <GridItem xs={12} md={9}>
              <Typography variant="l" component="h3">
                Delete the render template
              </Typography>
              {canBeDeleted ? (
                <Typography variant="l">
                  This render template is unused and can safely be deleted.{' '}
                  {isCodeTemplate ? (
                    <>
                      Do not forget to have an engineer delete the <strong>{templateIdentifier}</strong> render template
                      from the repository.
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
              ) : (
                <Typography variant="l">
                  This render template is used in {projectCount} {projectCount === 1 ? 'project' : 'projects'} in{' '}
                  {accountsUsageCount} {accountsUsageCount === 1 ? 'account' : 'accounts'}. Therefore it{' '}
                  <strong>cannot</strong> be deleted.
                </Typography>
              )}
            </GridItem>
            <GridItem
              xs={12}
              md={3}
              display="flex"
              justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
              alignItems="flex-start"
            >
              <Button variant="destructive" onClick={deleteRenderTemplate} disabled={!canBeDeleted}>
                Delete
              </Button>
            </GridItem>
          </GridContainer>
        </StyledCard>
      </Spacing>
    </PermissionRestriction>
  );
};
