import { useMemo } from 'react';

import { type MeQuery, useMeQuery } from '../../generated';

export type StaffUser = NonNullable<MeQuery['me']>;
export type StaffUserRole = StaffUser['role'];
export type StaffUserPermission = StaffUserRole['permissions'][number];

export const useMe = () => {
  const { loading, error, data } = useMeQuery();
  const staffUser = data?.me ?? undefined;
  return useMemo(() => ({ staffUser, loading, error }), [staffUser, loading, error]);
};
