import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Card, LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useAccount } from '../../../../api/hooks/account/useAccount';
import { AccountSubNavigation } from '../../../../components/accounts/AccountSubNavigation';
import { AdminLayout } from '../../../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../../../contexts/staffUser/PermissionRestriction';
import { useGotoAccounts } from '../../../../hooks/useGotoAccounts';
import { NoAccessLayout } from '../../../../pages/NoAccess';
import type { AccountRouteParams } from '../../../../routes';
import { ErrorContent, ErrorLayout } from '../../../Error';

import { FeatureFlags } from './FeatureFlags';

export const AccountFlags: FC = () => {
  const { accountId } = useParams() as AccountRouteParams;
  const { account, loading, error } = useAccount(accountId);
  const title = ['Flags', account?.name].filter(Boolean).join(' — ');
  const gotoAccounts = useGotoAccounts();

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="AccountRead" fallback={<NoAccessLayout documentTitle={title} />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={gotoAccounts} />,
          bottomLeft: <AccountSubNavigation accountId={accountId} />,
        }}
      >
        {account ? (
          <Card>
            <FeatureFlags accountId={accountId} featureFlagsPerAccount={account.featureFlags} />
          </Card>
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <ErrorContent />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
