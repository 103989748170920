import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Typography, styled } from '@cofenster/web-components';

import { AdminLayout } from '../../components/layout/AdminLayout';

const Center = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
}));

export type NoAccessResource = 'Project' | 'ProjectFolder' | 'Team' | 'Resource' | 'User';

export const NoAccessContent: FC<{ resource?: NoAccessResource }> = ({ resource = 'Resource' }) => (
  <Center>
    <Typography variant="h1" component="h1" i18nParams={{ resource }} data-testid="no-access-headline">
      {resource} not found
    </Typography>
    <Typography align="center" variant="xl" component="p" i18nParams={{ resource }}>
      Either this {resource.toLowerCase()} does not exist or you do not have the necessary permissions to access it. Ask
      the team owner to verify the link or add you to the team members.
    </Typography>
    <Button component="a" href="/" type={null}>
      Return home
    </Button>
  </Center>
);

export const NoAccessLayout: FC<{ documentTitle?: string; resource?: NoAccessResource }> = (props) => {
  const { state } = useLocation() as { state: { resource: NoAccessResource } };
  const resource = props.resource ?? state?.resource ?? 'Page';

  return (
    <AdminLayout documentTitle={props.documentTitle ?? `${resource} not found`}>
      <NoAccessContent resource={resource} />
    </AdminLayout>
  );
};
