import { useMemo } from 'react';

import { type TranscriptionByAudioAssetIdQueryVariables, useTranscriptionByAudioAssetIdQuery } from '../../generated';

export const useTranscriptionByAudioAssetId = (
  audioAssetId: TranscriptionByAudioAssetIdQueryVariables['audioAssetId']
) => {
  const { loading, error, data } = useTranscriptionByAudioAssetIdQuery({
    variables: { audioAssetId },
    fetchPolicy: 'cache-and-network',
  });
  const transcription = data?.transcriptionByAudioAssetId || undefined;
  return useMemo(() => ({ transcription, loading, error }), [transcription, loading, error]);
};
