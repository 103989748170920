import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { Form, FormCheckbox, GridContainer, GridItem, useUrlParameter } from '@cofenster/web-components';

import { useUpdateRenderTemplate } from '../../../../api/hooks/renderTemplate/useUpdateRenderTemplate';
import { AccountListHeader } from '../../../../components/accounts/AccountListHeader';
import { type CheckboxDisplayType, filterItemsByDisplay } from '../../../../components/controls/CheckboxDisplay';
import { useHasPermission } from '../../../../contexts/staffUser/PermissionRestriction';
import type { Account, RenderTemplate } from '../index';

type Values = {
  accountIds: string[];
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  accountIds: Yup.array().of(Yup.string().required()).required(),
});

const useInitialValues = (renderTemplate: RenderTemplate) => {
  return useMemo<Values>(
    () => ({ accountIds: renderTemplate.accounts.map((account) => account.id) }),
    [renderTemplate]
  );
};

const useOnSubmit = (renderTemplate: RenderTemplate) => {
  const updateTemplate = useUpdateRenderTemplate();
  const canAssignTemplateToAccounts = useHasPermission({ and: ['TemplateUpdate', 'AccountUpdate'] });

  return useCallback(
    async ({ accountIds }: Values) => {
      if (!canAssignTemplateToAccounts) return;
      try {
        await updateTemplate(renderTemplate.id, {
          accountIds: accountIds || [],
          templateIdentifier: renderTemplate.templateIdentifier,
        });
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [renderTemplate, updateTemplate, canAssignTemplateToAccounts]
  );
};

type Props = {
  renderTemplate: RenderTemplate;
  accounts: Account[];
};

export const TemplateAccountsForm: FC<Props> = ({ renderTemplate, accounts }) => {
  const [display, setDisplay] = useUrlParameter<CheckboxDisplayType>('ALL', 'display');
  const initialValues = useInitialValues(renderTemplate);
  const onSubmit = useOnSubmit(renderTemplate);
  const accountIds = accounts.map((account) => account.id);
  const canAssignTemplateToAccounts = useHasPermission({ and: ['TemplateUpdate', 'AccountUpdate'] });

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <AccountListHeader
        accountIds={accountIds}
        setDisplay={setDisplay}
        display={display}
        disabled={!canAssignTemplateToAccounts}
      />

      <GridContainer spacing={1}>
        {accounts.filter(filterItemsByDisplay(initialValues.accountIds, display)).map((account) => (
          <GridItem xs={12} md={6} key={account.id}>
            <FormCheckbox
              disabled={!canAssignTemplateToAccounts}
              id={`accountIds-${account.id}`}
              name="accountIds"
              value={account.id}
              label={account.name}
            />
          </GridItem>
        ))}
      </GridContainer>
    </Form>
  );
};
