import { useCallback, useMemo, useState } from 'react';

import type { TemplateDefinitionPath } from '../TemplateDefinitionStateProvider';

const TEXT_ELEMENTS_COMPONENT_REGEX = /^element$|^wrapper$|^background$|^texts$|text\.\w+/;
const LOGO_COMPONENT_REGEX = /^(element|wrapper)$/;
const TRANSITION_COMPONENT_REGEX = /wrapper|element_.+/;
export const usePathSelectState = () => {
  const [path, setPath] = useState<TemplateDefinitionPath>({
    composition: 'logo',
    component: 'wrapper',
    subComponent: undefined,
  });

  const setPathWithValidation = useCallback(
    (
      newPathOrFunction:
        | Partial<TemplateDefinitionPath>
        | ((path: TemplateDefinitionPath) => Partial<TemplateDefinitionPath>)
    ) => {
      const newPath = typeof newPathOrFunction === 'function' ? newPathOrFunction(path) : newPathOrFunction;
      setPath((prev) => {
        const newComposition = newPath.composition ?? prev.composition;
        let newComponent = newPath.component ?? prev.component;
        let newSubComponent = newPath.subComponent;

        if (newComposition.startsWith('textElements') && !TEXT_ELEMENTS_COMPONENT_REGEX.test(newComponent)) {
          newComponent = 'wrapper';
        }

        if (newComposition.startsWith('logo') && !LOGO_COMPONENT_REGEX.test(newComponent)) {
          newComponent = 'wrapper';
          newSubComponent = undefined;
        }

        if (newComposition.startsWith('transition') && !TRANSITION_COMPONENT_REGEX.test(newComponent)) {
          newComponent = 'element_0';
          newSubComponent = undefined;
        }

        return {
          composition: newComposition,
          component: newComponent,
          subComponent: newSubComponent,
        } as TemplateDefinitionPath;
      });
    },
    [path]
  );

  return useMemo(() => ({ path, setPath: setPathWithValidation }), [path, setPathWithValidation]);
};
