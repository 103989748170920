import type { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { SubNavigation } from '@cofenster/web-components';

import { SubNavigationItem } from '../../components/navigation/SubNavigationItem';
import { routes } from '../../routes';

export const StoriesSubNavigation: FC = () => {
  return (
    <SubNavigation>
      <SubNavigationItem to="stories" active={!!useMatch({ path: routes.stories, caseSensitive: false, end: true })}>
        Stories
      </SubNavigationItem>
      <SubNavigationItem
        to="storyCategories"
        active={!!useMatch({ path: routes.storyCategories, caseSensitive: false, end: true })}
      >
        Story Categories
      </SubNavigationItem>
    </SubNavigation>
  );
};
