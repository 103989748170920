import type { FC } from 'react';

import { EmptyState, PageHeadline } from '@cofenster/web-components';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { RouterButton } from '../../../components/navigation/RouterButton';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';

import { MusicList } from './MusicList';

export const Musics: FC = () => (
  <AdminLayout
    documentTitle="Music"
    header={{
      topLeft: <PageHeadline mb={0} title="Musics" />,
      topRight: (
        <PermissionRestriction has="MusicCreate">
          <RouterButton to="musicCreate">Create Music</RouterButton>
        </PermissionRestriction>
      ),
    }}
  >
    <PermissionRestriction
      has="MusicRead"
      fallback={
        <EmptyState
          iconType="NoOptionIcon"
          title="Missing permissions"
          description="Your current role does not allow you to access music tracks (lacking permission “MusicRead”). If you need access, please reach out to #team-product on Slack to be granted temporary access or for someone to support with your query."
        />
      }
    >
      <MusicList />
    </PermissionRestriction>
  </AdminLayout>
);
