import { type FC, useCallback, useState } from 'react';

import { MimeTypes, type VideoFormat } from '@cofenster/constants';
import {
  AssetDropzone,
  GridContainer,
  GridItem,
  Select,
  SelectOption,
  TextField,
  Typography,
  useUploadHint,
} from '@cofenster/web-components';

import type { TextComposition } from '../../../api/hooks/renderTemplate/useRenderTemplate';
import { PresetColorPicker } from './AnimationEditor/PresetColorPicker';
import type { CustomColorVariant } from './TemplateDefinitionPreview/useRenderDescriptionCustomColor';

const SceneBackground = {
  default: 'default-background',
  uploaded: 'uploaded-background',
};

type PlaceholdersProps = {
  textComposition?: TextComposition;
  texts: Record<string, Record<string, string>>;
  renderTemplateId: string;
  customPrimaryColor: string;
  customSecondaryColor: string;
  format: VideoFormat;
  setText: (textCompositionId: string, textInputId: string, text: string) => void;
  setCustomColor: (textCompositionId: string, variant: CustomColorVariant, value: string) => void;
  setPlaceholderLogoUrl: (url: string) => void;
  setUploadedSceneBackgroundUrl: (url: string | undefined) => void;
};

export const Placeholders: FC<PlaceholdersProps> = ({
  textComposition,
  texts,
  renderTemplateId,
  customSecondaryColor,
  customPrimaryColor,
  format,
  setText,
  setCustomColor,
  setPlaceholderLogoUrl,
  setUploadedSceneBackgroundUrl,
}) => {
  const [placeholderLogoName, setPlaceholderLogoName] = useState<string>();
  const [isOpenPrimary, setIsOpenPrimary] = useState<boolean>(false);
  const [isOpenSecondary, setIsOpenSecondary] = useState<boolean>(false);
  const [sceneBackgroundFileName, setSceneBackgroundFileName] = useState<string>();
  const [sceneBackground, setSceneBackground] = useState<string>(SceneBackground.default);
  const [sceneBackgroundUrl, setSceneBackgroundUrl] = useState<string | undefined>();
  const imageHintText = useUploadHint('image');
  const videoHintText = useUploadHint('video');

  const uploadLogo = useCallback(
    async (file: File) => {
      const logoUrl = URL.createObjectURL(file);
      setPlaceholderLogoUrl(logoUrl);
      setPlaceholderLogoName(file.name);
    },
    [setPlaceholderLogoUrl]
  );

  const uploadSceneBackground = useCallback(
    async (file: File) => {
      const sceneBackgroundUrl = URL.createObjectURL(file);

      setSceneBackgroundFileName(file.name);
      setSceneBackgroundUrl(sceneBackgroundUrl);
      setUploadedSceneBackgroundUrl(sceneBackgroundUrl);
      setSceneBackground(SceneBackground.uploaded);
    },
    [setUploadedSceneBackgroundUrl]
  );

  const selectOnChange = (value: string) => {
    setSceneBackground(value);
    const url = value === SceneBackground.default ? undefined : sceneBackgroundUrl;
    setUploadedSceneBackgroundUrl(url);
  };

  const changeColor = (variant: CustomColorVariant) => (value: string) =>
    setCustomColor(renderTemplateId, variant, value);

  return (
    <GridContainer direction="row" gap={0}>
      <GridItem>
        <Typography variant="h6">Text</Typography>
      </GridItem>
      <GridItem xs={12}>
        {textComposition?.textInputs.map((textInput) => (
          <TextField
            key={textInput.id}
            label={textInput.name}
            multiline
            fullWidth
            value={texts?.[textComposition.id]?.[textInput.id] ?? ''}
            onChange={(e) => setText(textComposition.id, textInput.id, e.target.value)}
          />
        ))}
      </GridItem>
      <GridItem>
        <Typography variant="h6">Logo</Typography>
      </GridItem>
      <GridItem xs={12} minHeight="30vh">
        <AssetDropzone
          backgroundColor="grey50"
          size="medium"
          mime={MimeTypes.image}
          onFile={uploadLogo}
          icon="CloudUploadIcon"
          text="Drop your image here or upload"
          hintText={imageHintText}
        >
          {placeholderLogoName}
        </AssetDropzone>
      </GridItem>
      <GridItem xs={12}>
        <Typography variant="h6">Scene background</Typography>
      </GridItem>
      <GridItem xs={12}>
        <Select
          id="Scene-Background"
          name="scene-background"
          label="Scene background"
          onChange={(event) => selectOnChange(event.target.value as string)}
          value={sceneBackground}
        >
          <SelectOption value="default-background">Default {format} Video</SelectOption>
          {sceneBackgroundFileName && (
            <SelectOption value="uploaded-background">{sceneBackgroundFileName}</SelectOption>
          )}
        </Select>
      </GridItem>
      <GridItem xs={12} minHeight="30vh">
        <AssetDropzone
          backgroundColor="grey50"
          size="medium"
          mime={MimeTypes.video}
          onFile={uploadSceneBackground}
          icon="CloudUploadIcon"
          text="Drop your video here or upload"
          hintText={videoHintText}
        >
          {sceneBackgroundFileName}
        </AssetDropzone>
      </GridItem>
      <GridItem xs={12}>
        <GridContainer>
          <GridItem xs={6}>
            <PresetColorPicker
              isOpen={isOpenPrimary}
              color={customPrimaryColor}
              onChange={changeColor('Primary')}
              onClose={() => setIsOpenPrimary(false)}
              onOpen={() => setIsOpenPrimary(true)}
              label="Primary color"
            />
          </GridItem>
          <GridItem xs={6}>
            <PresetColorPicker
              isOpen={isOpenSecondary}
              color={customSecondaryColor}
              onChange={changeColor('Secondary')}
              onClose={() => setIsOpenSecondary(false)}
              onOpen={() => setIsOpenSecondary(true)}
              label="Secondary color"
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};
