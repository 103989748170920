import type { FC, PropsWithChildren } from 'react';

import { GridContainer, GridItem, Headline, LegalNavigation, LogoWide, styled } from '@cofenster/web-components';

export const SignInHeadline = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.brand.linen,
  minHeight: '100%',
}));

const Content = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.brand.white,
  borderRadius: theme.shape['borderRadius-l'],
  padding: theme.spacing(5),
  margin: theme.spacing('auto', 2),
  height: '100%',

  [theme.breakpoints.up('sm')]: {
    margin: 0,
    padding: theme.spacing(17.5, 15, 10, 17.5),
  },
}));

const PhonePicture = styled('img')(() => ({
  display: 'block',
  height: 648,
  marginTop: -10,
  marginBottom: -10,
}));

const FormWrapper = styled('div')(() => ({
  maxWidth: 348,
}));

export const SignInLayout: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  return (
    <Container>
      <GridContainer
        justifyContent="space-between"
        flexDirection="column"
        alignItems="stretch"
        flex={1}
        pl={{ md: 6 }}
        pr={{ md: 6 }}
      >
        <GridItem mt={{ md: 3 }}>
          <LogoWide size={82} />
        </GridItem>
        <GridItem>
          <GridContainer justifyContent="center" spacing={4}>
            <GridItem display={{ xs: 'none', md: 'none', lg: 'flex' }} component="picture">
              <source srcSet="/assets/images/phone.avif" type="image/avif" />
              <source srcSet="/assets/images/phone.webp" type="image/webp" />
              <PhonePicture src="/assets/images/phone.png" alt="" />
            </GridItem>
            <GridItem>
              <Content>
                <GridContainer height="100%">
                  <GridItem>
                    <FormWrapper>{children}</FormWrapper>
                  </GridItem>
                </GridContainer>
              </Content>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem mt={4} mb={3}>
          <LegalNavigation />
        </GridItem>
      </GridContainer>
    </Container>
  );
};
