import { type EasingName, Easings } from './Easings';
import { EasingOption } from './Options';
import { DEFAULT_TIMING_OUT, TimingOption } from './Options/Timing';
import { createPreset } from './Preset';
import { createTimeline } from './utils';

export const FadeOut = createPreset(
  'FadeOut',
  ({ timing, easing }) => {
    return {
      opacity: createTimeline(timing, {
        0: { v: 1, e: Easings[easing] },
        1: { v: 0 },
      }),
    };
  },
  {
    timing: TimingOption,
    easing: EasingOption,
  },
  {
    timing: DEFAULT_TIMING_OUT,
    easing: 'EaseIn' as EasingName,
  }
);
