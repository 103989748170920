import { useMemo } from 'react';

import { type StoryCategoriesQueryResult, useStoryCategoriesQuery } from '../../generated';

export type StoryCategory = NonNullable<StoryCategoriesQueryResult['data']>['storyCategories'][number];

export const useStoryCategories = () => {
  const { loading, error, data } = useStoryCategoriesQuery();
  const storyCategories = data?.storyCategories;
  return useMemo(
    () => ({
      storyCategories: storyCategories ?? [],
      loading,
      error,
    }),
    [storyCategories, loading, error]
  );
};
