import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { EmptyState, LoadingSpinner, PageHeadline } from '@cofenster/web-components';

import { useAccount } from '../../../../api/hooks/account/useAccount';
import { AccountSubNavigation } from '../../../../components/accounts/AccountSubNavigation';
import { AdminLayout } from '../../../../components/layout/AdminLayout';
import { RouterButton } from '../../../../components/navigation/RouterButton';
import { PermissionRestriction } from '../../../../contexts/staffUser/PermissionRestriction';
import { useGotoAccounts } from '../../../../hooks/useGotoAccounts';
import { NoAccessLayout } from '../../../../pages/NoAccess';
import type { AccountRouteParams } from '../../../../routes';
import { ErrorContent, ErrorLayout } from '../../../Error';

import { AccountUserList } from './AccountUserList';

export const AccountUsers: FC = () => {
  const { accountId } = useParams() as AccountRouteParams;
  const { account, loading, error } = useAccount(accountId);
  const title = ['Members', account?.name].filter(Boolean).join(' — ');
  const gotoAccounts = useGotoAccounts();

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="AccountRead" fallback={<NoAccessLayout documentTitle={title} />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={gotoAccounts} />,
          topRight: account && (
            <PermissionRestriction has="UserCreate">
              <RouterButton to="userCreate" params={{ accountId: account.id }} data-testid="invite-member">
                Invite member
              </RouterButton>
            </PermissionRestriction>
          ),
          bottomLeft: <AccountSubNavigation accountId={accountId} />,
        }}
      >
        <PermissionRestriction
          has="UserRead"
          fallback={
            <EmptyState
              iconType="NoOptionIcon"
              title="Missing permissions"
              description="Your current role does not allow you to access account members (lacking permission “UserRead”). If you need access, please reach out to #team-product on Slack to be granted temporary access or for someone to support with your query."
            />
          }
        >
          {account ? <AccountUserList users={account.users} /> : loading ? <LoadingSpinner /> : <ErrorContent />}
        </PermissionRestriction>
      </AdminLayout>
    </PermissionRestriction>
  );
};
