import type { FC } from 'react';

import {
  BlankButton,
  FormCheckbox,
  GridContainer,
  GridItem,
  Icon,
  Tooltip,
  Typography,
  UpsellIcon,
  styled,
} from '@cofenster/web-components';

import type { FeatureFlag } from '../../../../../api/hooks/featureFlag/useFeatureFlags';

import { BUNDLES, ENGINEERING_FLAGS, PREMIUM_FLAGS } from './bundles';

// 1. Not very elegant code-wise, but the rows look significantly better and
//    easier to ready with a readjustment of the text.
const FeatureFlagInfo = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(-1.3), // 1
  marginLeft: theme.spacing(2),
}));

const PremiumHint: FC<{ name: string; label: string }> = ({ name, label }) => (
  <Tooltip
    title={
      <>
        The “{label}” feature belongs to the{' '}
        <strong>{BUNDLES.PROFESSIONAL.includes(name) ? 'Professional' : 'Entreprise'}</strong> bundle.
      </>
    }
  >
    <BlankButton>
      <UpsellIcon size="l" />
    </BlankButton>
  </Tooltip>
);

const EngineeringHint: FC<{ label: string }> = ({ label }) => (
  <Tooltip title={<>The “{label}” feature flag is typically managed by the engineering team.</>}>
    <BlankButton>
      <Icon type="WrenchIcon" size="l" />
    </BlankButton>
  </Tooltip>
);

const Container = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.brand.grey100}`,
  padding: theme.spacing(0, 4, 2),
  margin: theme.spacing(0, -4),
}));

export const FeatureFlagRow: FC<{ featureFlag: FeatureFlag; disabled?: boolean }> = ({ featureFlag, disabled }) => {
  const isPremium = PREMIUM_FLAGS.includes(featureFlag.name);
  const isEngineering = ENGINEERING_FLAGS.includes(featureFlag.name);
  const label = featureFlag.label ?? featureFlag.name;

  return (
    <Container>
      <GridContainer>
        <GridItem xs>
          <FormCheckbox
            id={`featureFlags-${featureFlag.name}`}
            name="featureFlags"
            value={featureFlag.name}
            label={
              <FeatureFlagInfo>
                <Typography variant="l" color="carbon" component="p">
                  {label}{' '}
                  <Typography component="span" color="grey600">
                    · {featureFlag.name}
                  </Typography>
                </Typography>
                <Typography variant="l" color="grey600" component="p">
                  {featureFlag.description}
                </Typography>
              </FeatureFlagInfo>
            }
            disabled={
              disabled ||
              !featureFlag.manageable ||
              // The `EMERGENCY_DISABLE_RENDER` feature flag has a shortcut in
              // the danger zone so it is moved at the bottom of the list and
              // made disabled.
              featureFlag.name === 'EMERGENCY_DISABLE_RENDER'
            }
          />
        </GridItem>

        {isPremium && (
          <GridItem>
            <PremiumHint name={featureFlag.name} label={label} />
          </GridItem>
        )}

        {isEngineering && (
          <GridItem>
            <EngineeringHint label={label} />
          </GridItem>
        )}
      </GridContainer>
    </Container>
  );
};
