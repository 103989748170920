import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Card, LoadingSpinner, PageHeadline } from '@cofenster/web-components';
import { useAccountsLite } from '../../../api/hooks/account/useAccountsLite';
import { useRenderTemplate } from '../../../api/hooks/renderTemplate/useRenderTemplate';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { TemplateSubNavigation } from '../../../components/templates/TemplateSubNavigation';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useGotoRenderTemplates } from '../../../hooks/useGotoRenderTemplates';
import type { TemplateRouteParams } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';
import { NoAccessLayout } from '../../NoAccess';

import { TemplateAccountsForm } from './TemplateAccountsForm';

export type { RenderTemplate } from '../../../api/hooks/renderTemplate/useRenderTemplate';
export type { Account } from '../../../api/hooks/account/useAccountsLite';

export const TemplateAccounts: FC = () => {
  const { renderTemplateId } = useParams() as TemplateRouteParams;
  const { renderTemplate, loading: templateLoading, error: templateError } = useRenderTemplate(renderTemplateId);
  const { paginatedAccounts, loading: accountsLoading, error: accountsError } = useAccountsLite({ limit: 1000 });
  const gotoTemplates = useGotoRenderTemplates();
  const title = ['Accounts', renderTemplate?.name].filter(Boolean).join(' — ');
  const loading = templateLoading || accountsLoading;
  const error = templateError || accountsError;

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction and={['TemplateRead', 'AccountRead']} fallback={<NoAccessLayout documentTitle={title} />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={gotoTemplates} />,
          bottomLeft: <TemplateSubNavigation renderTemplateId={renderTemplateId} />,
        }}
      >
        {renderTemplate ? (
          <Card>
            <TemplateAccountsForm renderTemplate={renderTemplate} accounts={paginatedAccounts?.items ?? []} />
          </Card>
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <ErrorContent />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
