import { type ComponentProps, useCallback } from 'react';

import type { ConfirmDialog } from '@cofenster/web-components';

import { useDialogs } from '../contexts/dialogs/useDialogs';

export const useConfirmDialog = ({
  title,
  titleI18nParams,
  content,
  cancel,
  confirm,
  variant,
}: Omit<ComponentProps<typeof ConfirmDialog>, 'isOpen' | 'closeDialog' | 'onConfirm' | 'onCancel'>) => {
  const { openDialog } = useDialogs();

  return useCallback(
    () =>
      new Promise<boolean>((resolve) => {
        openDialog('ConfirmDialog', {
          title,
          titleI18nParams,
          content,
          cancel,
          confirm,
          variant,
          onConfirm: () => resolve(true),
          onCancel: () => resolve(false),
        });
      }),
    [openDialog, title, titleI18nParams, content, cancel, confirm, variant]
  );
};
