import type { CSSValue, Easing, Keyframes, Timeline } from '../../remotion';

import type { Timing } from './Options/Timing';

export const isNegative = (value: number) => value < 0 || Object.is(value, -0);

export const serializeInt = (value: number) => (Object.is(value, -0) ? '-0' : `${value}`);

/**
 * @throws {Error} if value is NaN
 */
export const parseIntEnsure = (value: string) => {
  const parsed = Number.parseInt(value, 10);
  if (Number.isNaN(parsed)) {
    throw new Error(`Failed to parse int: ${value}`);
  }
  return parsed;
};

const toKeyframeTime = (start: number, offset: number) => {
  const time = start + offset;
  return 0 === time && isNegative(start) ? -0 : time;
};
export const createTimeline = <V extends CSSValue = CSSValue>(
  timing: Timing,
  percentBased: Keyframes<V>
): Timeline<V> => {
  const times = Object.keys(percentBased).sort(
    (a, b) => Number.parseInt(a, 10) - Number.parseInt(b, 10)
  ) as `${number}`[];
  const first = times[0] as keyof typeof percentBased;
  const last = times[times.length - 1] as keyof typeof percentBased;

  return {
    ...Object.fromEntries(
      Object.entries(percentBased).map(([key, value]) => [
        serializeInt(toKeyframeTime(timing.startTime, Number.parseFloat(key) * timing.duration)),
        value,
      ])
    ),
    // mimick animation-fill-mode
    // https://developer.mozilla.org/en-US/docs/Web/CSS/animation-fill-mode
    ...((timing.fillMode === 'forwards' || timing.fillMode === 'both') &&
      times.length >= 2 && {
        [serializeInt(-0)]: percentBased[last] as { v: V; e?: Easing },
      }),
    ...((timing.fillMode === 'backwards' || timing.fillMode === 'both') &&
      times.length >= 2 && {
        [serializeInt(0)]: percentBased[first] as { v: V; e?: Easing },
      }),
  };
};
