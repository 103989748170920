import type { FC } from 'react';

import { ExportSettingsProvider } from '../../../contexts/exports';

import { ExportsContent } from './ExportsContent';

export const Exports: FC = () => (
  <ExportSettingsProvider>
    <ExportsContent />
  </ExportSettingsProvider>
);
