import type { FC, PropsWithChildren } from 'react';

import { I18nProvider as GenericI18nProvider } from '@cofenster/web-components';

import { TRANSLATIONS } from './translations';

export const I18nProvider: FC<PropsWithChildren> = ({ children }) => (
  <GenericI18nProvider translations={TRANSLATIONS} userLocale="EN" defaultLocale="EN">
    {children}
  </GenericI18nProvider>
);
