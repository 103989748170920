import type { FC } from 'react';

import { Alert, Spacing, styled } from '@cofenster/web-components';

import { useDefaultRenderTemplate } from '../../../../api/hooks/renderTemplate/useDefaultRenderTemplate';

import { RouterLink } from '../../../../components/navigation/RouterLink';

const TemplateLink = styled(RouterLink)(({ theme }) => ({
  fontWeight: 'bold',
  textDecoration: 'underline',
  borderRadius: theme.shape.borderRadius,
}));

export const DefaultTemplate: FC<{ accountId: string }> = ({ accountId }) => {
  const { loading, defaultRenderTemplate, error } = useDefaultRenderTemplate(accountId);

  if (loading) return null;

  if (error) {
    return (
      <Spacing bottom={3}>
        <Alert severity="error" data-testid="no-template-warning">
          This account does not have access to any template: attempting to create a project will fail with a
          non-descriptive error. Please, make sure to assign at least 1 template.
        </Alert>
      </Spacing>
    );
  }

  if (!defaultRenderTemplate) return null;

  return (
    <Spacing bottom={3}>
      <Alert severity="warning" data-testid="default-template-info">
        The default template for new projects is{' '}
        <TemplateLink to="template" params={{ renderTemplateId: defaultRenderTemplate.id }}>
          {defaultRenderTemplate.name}
        </TemplateLink>
        .
      </Alert>
    </Spacing>
  );
};
