import type { FC } from 'react';

import { LoadingSpinner } from '@cofenster/web-components';

import { useTemplateFontAssets } from '../../../../api/hooks/templateFontAsset/useTemplateFontAssets';
import { TemplateFontsContent } from './TemplateFontsContent';

type TemplateFontsListProps = {
  renderTemplateId: string;
};

export const TemplateFontsList: FC<TemplateFontsListProps> = ({ renderTemplateId }) => {
  const { templateFontAssets, loading } = useTemplateFontAssets(renderTemplateId);

  if (!templateFontAssets) return null;

  return loading ? (
    <LoadingSpinner />
  ) : (
    <TemplateFontsContent renderTemplateId={renderTemplateId} templateFontAssets={templateFontAssets} />
  );
};
