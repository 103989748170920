import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { Button, Form, FormSubmitButton, FormTextField, GridContainer, GridItem } from '@cofenster/web-components';

import type { TextComposition, TextInput } from '../../../../../../../api/hooks/renderTemplate/useRenderTemplate';
import { useCreateTextInput } from '../../../../../../../api/hooks/textInput/useCreateTextInput';
import { useUpdateTextInput } from '../../../../../../../api/hooks/textInput/useUpdateTextInput';

type Values = {
  name: string;
  nameEN: string;
  lineLength: number;
  lineCount: number;
  aeLayerName: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Please fill in this field'),
  nameEN: Yup.string().trim().required('Please fill in this field'),
  aeLayerName: Yup.string().trim().required('Please fill in this field'),
  lineLength: Yup.number().required('Please fill in this field'),
  lineCount: Yup.number().required('Please fill in this field'),
});

const useInitialValues = (textInput: TextInput | undefined) => {
  return useMemo<Values>(
    () => ({
      name: textInput?.name ?? '',
      nameEN: textInput?.nameEN ?? '',
      lineLength: textInput?.lineLength ?? 30,
      lineCount: textInput?.lineCount ?? 1,
      aeLayerName: textInput?.aeLayerName ?? '',
    }),
    [textInput]
  );
};

const useCreate = (textComposition: TextComposition) => {
  const createTextInput = useCreateTextInput();
  return useCallback(
    async (data: Values) => {
      try {
        await createTextInput(textComposition.id, data);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [textComposition, createTextInput]
  );
};

const useUpdate = () => {
  const updateTextInput = useUpdateTextInput();

  return useCallback(
    async (textInput: TextInput, data: Values) => {
      try {
        await updateTextInput(textInput.id, data);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [updateTextInput]
  );
};

const useSubmit = (textComposition: TextComposition, textInput: TextInput | undefined) => {
  const createTextInput = useCreate(textComposition);
  const updateTextInput = useUpdate();

  return useCallback(
    async (values: Values) => {
      try {
        if (textInput) return await updateTextInput(textInput, values);
        return await createTextInput(values);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [textInput, createTextInput, updateTextInput]
  );
};

type Props = {
  textComposition: TextComposition;
  textInput?: TextInput | undefined;
  deleteTextInput: VoidFunction;
};

export const TextInputForm: FC<Props> = ({ textComposition, textInput, deleteTextInput }) => {
  const initialValues = useInitialValues(textInput);
  const onSubmit = useSubmit(textComposition, textInput);
  const id = textInput?.id ?? 'new';

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer>
        <GridItem xs={12} md={4} mb={-2}>
          <FormTextField
            id={`textCompositionName-${id}`}
            name="name"
            label="Name (DE)"
            placeholder="Name (DE)"
            autoFocus={!textInput}
          />
        </GridItem>
        <GridItem xs={12} md={4} mb={-2}>
          <FormTextField id={`textCompositionNameEN-${id}`} name="nameEN" label="Name (EN)" placeholder="Name (EN)" />
        </GridItem>
        <GridItem xs={12} md={4} mb={-2}>
          <FormTextField
            id={`aeLayerName-${id}`}
            name="aeLayerName"
            label="Remotion name (text1, text2, …)"
            placeholder="Remotion name (text1, text2, …)"
          />
        </GridItem>
        <GridItem xs={12} md={4} mb={-2}>
          <FormTextField
            id={`lineLength-${id}`}
            name="lineLength"
            type="number"
            label="Line length"
            placeholder="Line length"
            min={0}
            step={1}
          />
        </GridItem>
        <GridItem xs={12} md={4} mb={-2}>
          <FormTextField
            id={`lineCount-${id}`}
            name="lineCount"
            type="number"
            label="Line count"
            placeholder="Line count"
            min={0}
            step={1}
          />
        </GridItem>
        <GridItem xs={12} md={4} mb={-2} display="flex" gap={2} alignItems="flex-start" justifyContent="flex-end">
          <Button variant="destructive" onClick={deleteTextInput}>
            Delete
          </Button>
          <FormSubmitButton>{textInput ? 'Save' : 'Create'}</FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
