import { type FC, type FormEventHandler, useCallback, useState } from 'react';

import { Button, Card, Headline, Spacing, TextField, useGoto } from '@cofenster/web-components';

import { routes } from '../../routes';

export const TranscriptionSearch: FC<{
  type: 'video' | 'audio';
}> = ({ type }) => {
  const [assetId, setAssetId] = useState<null | string>(null);
  const goto = useGoto();

  const handleSubmit: FormEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      if (!assetId) return;
      goto(routes.transcriptionReviewAsset, { assetId, type });
    },
    [goto, assetId, type]
  );

  return (
    <Card>
      <Spacing bottom={2}>
        <Headline variant="h3" component="h2">
          Direct search
        </Headline>
      </Spacing>

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label={`${type} asset ID (UUID)`}
          placeholder="e.g. d75c5c31-f18a-4392-a1ac-d65df8e5fdc7"
          value={assetId}
          onChange={(event) => setAssetId(event.target.value)}
          inputProps={{
            pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
            required: true,
          }}
        />

        <Button type="submit" disabled={!assetId}>
          Inspect
        </Button>
      </form>
    </Card>
  );
};
