import { useMemo } from 'react';

import { type MusicsQueryResult, useMusicsQuery } from '../../generated';

export type Music = NonNullable<MusicsQueryResult['data']>['musics'][number];

export const useMusics = () => {
  const { loading, error, data } = useMusicsQuery();
  const musics = data?.musics;
  return useMemo(
    () => ({
      musics: musics ?? [],
      loading,
      error,
    }),
    [musics, loading, error]
  );
};
