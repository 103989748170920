import { useMemo } from 'react';

import { type UserQueryResult, type UserQueryVariables, useUserQuery } from '../../generated';

export type User = NonNullable<UserQueryResult['data']>['user'];
export type Locale = NonNullable<User['locale']>;

export const useUser = (userId?: UserQueryVariables['id']) => {
  const { loading, error, data } = useUserQuery({
    variables: { id: userId ?? '' },
    fetchPolicy: 'cache-and-network',
    skip: !userId,
  });
  const user = data?.user ?? undefined;
  return useMemo(() => ({ user, loading, error }), [user, loading, error]);
};
