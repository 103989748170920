import type { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { SubNavigation } from '@cofenster/web-components';

import { SubNavigationItem } from '../../components/navigation/SubNavigationItem';
import { routes } from '../../routes';

type Props = {
  accountId: string;
};

export const AccountSubNavigation: FC<Props> = ({ accountId }) => {
  return (
    <SubNavigation>
      <SubNavigationItem
        iconType="InfoIcon"
        to="account"
        params={{ accountId }}
        active={!!useMatch(routes.accountInfo)}
      >
        Info
      </SubNavigationItem>
      <SubNavigationItem
        iconType="FlagIcon"
        to="accountFlags"
        params={{ accountId }}
        active={!!useMatch(routes.accountFlags)}
        data-testid="admin-account-flags"
      >
        Flags
      </SubNavigationItem>
      <SubNavigationItem
        iconType="UsersIcon"
        to="accountUsers"
        params={{ accountId }}
        active={!!useMatch(routes.accountUsers)}
        data-testid="admin-account-users"
      >
        Members
      </SubNavigationItem>
      <SubNavigationItem
        iconType="FourSquaresIcon"
        to="accountTemplates"
        params={{ accountId }}
        active={!!useMatch(routes.accountTemplates)}
        data-testid="admin-account-templates"
      >
        Templates
      </SubNavigationItem>
      <SubNavigationItem
        iconType="MovieIcon"
        to="accountStories"
        params={{ accountId }}
        active={!!useMatch(routes.accountStories)}
      >
        Stories
      </SubNavigationItem>
      <SubNavigationItem
        iconType="GearIcon"
        to="accountAuthProvider"
        params={{ accountId }}
        active={!!useMatch(routes.accountAuthProvider)}
      >
        SSO
      </SubNavigationItem>
    </SubNavigation>
  );
};
