import type { Preset, PresetOptions } from './Preset';
import { BlindIn } from './blindIn';
import { BlindOut } from './blindOut';
import { FadeIn } from './fadeIn';
import { FadeOut } from './fadeOut';
import { GrowIn } from './growIn';
import { GrowOut } from './growOut';
import { ShrinkIn } from './shrinkIn';
import { ShrinkOut } from './shrinkOut';
import { SlideIn } from './slideIn';
import { SlideOut } from './slideOut';
import { SpinIn } from './spinIn';
import { SpinOut } from './spinOut';

const Presets = {
  BlindIn,
  BlindOut,
  FadeIn,
  FadeOut,
  ShrinkIn,
  ShrinkOut,
  GrowIn,
  GrowOut,
  SlideIn,
  SlideOut,
  SpinIn,
  SpinOut,
};

export type PresetName = keyof typeof Presets;

export const PresetNames = Object.keys(Presets) as PresetName[];

export const getPreset = (name: PresetName) => Presets[name] as unknown as Preset<PresetOptions>;

export const deserializePreset = (serialized: string | null | undefined) => {
  if (!serialized) return null;
  const name = serialized.split(/\s/)[0];
  const preset = getPreset(name as PresetName);
  if (preset) {
    const options = preset.deserialize(serialized);
    if (options) {
      return [preset, options] as const;
    }
  }
  return null;
};
