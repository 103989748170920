import { Outlet, useLocation } from 'react-router-dom';

import { ADMIN_API_URL } from '@cofenster/constants';
import { useStaffUser } from '../staffUser/useStaffUser';

export const EnsureStaffUser = () => {
  const { staffUser } = useStaffUser();
  const { pathname } = useLocation();

  if (!staffUser) {
    window.location.href = `${ADMIN_API_URL.replace('/graphql', '/sso')}?redirect=${pathname}`;
    return;
  }

  return <Outlet />;
};
