import { useCallback } from 'react';

import { type GenerateInviteLinkMutationVariables, useGenerateInviteLinkMutation } from '../../generated';

export const useGenerateInviteLink = () => {
  const [generateInviteLink] = useGenerateInviteLinkMutation();

  return useCallback(
    (id: GenerateInviteLinkMutationVariables['id']) => generateInviteLink({ variables: { id } }),
    [generateInviteLink]
  );
};
