import { type Dispatch, type FC, type SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import {
  Form,
  FormSubmitButton,
  FormTextField,
  GridContainer,
  GridItem,
  Headline,
  Spacing,
  TextField,
  useGoto,
} from '@cofenster/web-components';
import type { Account } from '../../api/hooks/account/useAccount';
import { useCreateAccount } from '../../api/hooks/account/useCreateAccount';
import { useUpdateAccount } from '../../api/hooks/account/useUpdateAccount';
import { useRenderTemplates } from '../../api/hooks/renderTemplate/useRenderTemplates';
import { SortableSelectTemplates } from '../../pages/accounts/Account/AccountTemplates/SortableSelectTemplates';
import { routes } from '../../routes';

type Values = {
  name: string;
  paidSeats: number;
  renderTemplateIds: string[];
};

const useValidationSchema = () => {
  const validations = {
    name: Yup.string().trim().required('Please enter the name of the account'),
    customerId: Yup.string().trim(),
    paidSeats: Yup.number()
      .required()
      .integer('Please enter a valid number of seats')
      .min(1, 'Please enter a valid number, at least 1')
      .max(999, 'Please enter a valid number, at most 999'),
    renderTemplateIds: Yup.array().of(Yup.string().trim().required()).required(),
  };

  const baseSchema: Yup.ObjectSchema<Values> = Yup.object().shape(validations);

  return baseSchema;
};

const useInitialValues = (account: Account | undefined) => {
  return useMemo<Values>(
    () => ({
      name: account?.name ?? '',
      customerId: account?.customerId ?? '',
      paidSeats: account?.paidSeats || 1,
      renderTemplateIds: account?.renderTemplates?.map(({ id }) => id) ?? [],
    }),
    [account]
  );
};

const useCreate = () => {
  const createAccount = useCreateAccount();
  const goto = useGoto();

  return useCallback(
    async (values: Values) => {
      const result = await createAccount(values);
      const newAccount = result.data?.createAccount;
      const destination = values.renderTemplateIds.length === 0 ? routes.accountTemplates : routes.account;

      if (newAccount) goto(destination, { accountId: newAccount.id });
    },
    [createAccount, goto]
  );
};

const useSubmit = (account: Account | undefined, selectedTemplateIds: string[]) => {
  const createAccount = useCreate();
  const updateAccount = useUpdateAccount();

  return useCallback(
    (values: Values) => {
      const data = { ...values, renderTemplateIds: selectedTemplateIds };
      if (account) return updateAccount(account.id, data);
      return createAccount(data);
    },
    [account, createAccount, selectedTemplateIds, updateAccount]
  );
};

const Templates: FC<{
  selectedIds: string[];
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
  disabled?: boolean;
}> = ({ selectedIds, setSelectedIds, disabled }) => {
  const { paginatedRenderTemplates } = useRenderTemplates();
  const renderTemplates = useMemo(() => paginatedRenderTemplates?.items ?? [], [paginatedRenderTemplates]);

  return (
    <Spacing top={2}>
      <SortableSelectTemplates
        renderTemplates={renderTemplates}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        disabled={disabled}
      />
    </Spacing>
  );
};

export const AccountForm: FC<{
  account?: Account;
  withTemplateManagement: boolean;
  disabled?: boolean;
}> = ({ account, withTemplateManagement, disabled }) => {
  const initialValues = useInitialValues(account);
  const validationSchema = useValidationSchema();
  const defaultRenderTemplateIds = useMemo(() => account?.renderTemplates.map(({ id }) => id) ?? [], [account]);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState(defaultRenderTemplateIds);

  useEffect(() => {
    setSelectedTemplateIds(defaultRenderTemplateIds);
  }, [defaultRenderTemplateIds]);
  const onSubmit = useSubmit(account, selectedTemplateIds);

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer>
        {withTemplateManagement && (
          <GridItem xs={12}>
            <Headline color="carbon" variant="h4" component="h3">
              Base info
            </Headline>
          </GridItem>
        )}

        <GridItem xs={12} md={6}>
          <FormTextField
            id="accountName"
            name="name"
            label="Account name"
            placeholder="Account name"
            data-testid="account-name-input"
            disabled={disabled}
          />
        </GridItem>

        <GridItem xs={12} md={6}>
          <FormTextField
            id="customerId"
            name="customerId"
            label="External customer ID"
            placeholder="External customer ID"
            data-testid="external-customer-id-input"
            disabled={disabled}
          />
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={6}>
          <FormTextField
            id="paidSeats"
            name="paidSeats"
            label="Paid seats"
            placeholder="e.g. 10"
            type="number"
            data-testid="paid-seats-input"
            disabled={disabled}
          />
        </GridItem>

        {account && (
          <GridItem xs={6}>
            <TextField
              inputProps={{
                readOnly: true,
                'data-testid': 'used-seats-input',
              }}
              label="Used seats"
              type="number"
              value={account.usedSeats ?? 0}
              fullWidth
              disabled={disabled}
            />
          </GridItem>
        )}
      </GridContainer>

      {withTemplateManagement && (
        <Templates selectedIds={selectedTemplateIds} setSelectedIds={setSelectedTemplateIds} disabled={disabled} />
      )}

      <GridContainer justifyContent="flex-end">
        <GridItem xs={12} md={12} textAlign="right">
          <FormSubmitButton autoDisable data-testid="account-create-button" disabled={disabled}>
            {account ? 'Save' : 'Create'}
          </FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
