import type { Rectangle } from '@cofenster/aws';
import { VIDEO_FORMATS, type VideoFit, type VideoFormat } from '@cofenster/constants';

export const resizeVideo = (
  width: number | undefined,
  height: number | undefined,
  format: VideoFormat | undefined,
  fit: VideoFit | undefined
):
  | {
      Source: Rectangle;
      Target: Rectangle;
    }
  | undefined => {
  if (typeof width !== 'number' || typeof height !== 'number') {
    return;
  }

  const constraint = format ? VIDEO_FORMATS[format] : MAX_SIZE;

  return downscale({ width, height }, constraint, fit ?? 'Crop');
};

const downscale = (
  size: { width: number; height: number },
  constraint: { width: number; height: number },
  fit: VideoFit
): {
  Source: Rectangle;
  Target: Rectangle;
} => {
  const ratio = size.width / size.height;
  const targetRatio = constraint.width / constraint.height;

  if (fit === 'Crop') {
    const cropped = cropToRatio(size, targetRatio);

    const sourceWidth = cropped.width;
    const sourceHeight = cropped.height;
    const sourceX = (size.width - sourceWidth) / 2;
    const sourceY = (size.height - sourceHeight) / 2;

    const targetWidth = Math.min(sourceWidth, constraint.width);
    const targetHeight = Math.min(sourceHeight, constraint.height);

    return {
      Source: {
        X: ensureEven(sourceX),
        Y: ensureEven(sourceY),
        Width: ensureEven(sourceWidth),
        Height: ensureEven(sourceHeight),
      },
      Target: { X: 0, Y: 0, Width: ensureEven(targetWidth), Height: ensureEven(targetHeight) },
    };
  }

  if (size.width <= constraint.width && size.height <= constraint.height) {
    // within constraint, do nothing
    return {
      Source: { X: 0, Y: 0, Width: ensureEven(size.width), Height: ensureEven(size.height) },
      Target: { X: 0, Y: 0, Width: ensureEven(size.width), Height: ensureEven(size.height) },
    };
  }

  // downscale, preserving aspect ratio
  const sourceWidth = size.width;
  const sourceHeight = size.height;
  const targetWidth = ratio > 1 ? constraint.width : constraint.height * ratio;
  const targetHeight = ratio > 1 ? constraint.width / ratio : constraint.height;

  return {
    Source: { X: 0, Y: 0, Width: ensureEven(sourceWidth), Height: ensureEven(sourceHeight) },
    Target: { X: 0, Y: 0, Width: ensureEven(targetWidth), Height: ensureEven(targetHeight) },
  };
};

const cropToRatio = (size: { width: number; height: number }, targetRatio: number) => {
  const ratio = size.width / size.height;
  if (ratio === targetRatio) {
    return size;
  }
  // source is wider than target
  if (ratio > targetRatio) {
    return {
      width: size.height * targetRatio,
      height: size.height,
    };
  }
  return {
    width: size.width,
    height: size.width / targetRatio,
  };
};

const ensureEven = (n: number) => Math.floor(n / 2) * 2;

const MAX_SIZE = {
  width: Math.max(...Object.values(VIDEO_FORMATS).map((format) => format.width)),
  height: Math.max(...Object.values(VIDEO_FORMATS).map((format) => format.height)),
};
