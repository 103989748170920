import { type CSSProperties, type FC, useState } from 'react';

import { VIDEO_FORMATS, type VideoFormat } from '@cofenster/constants';
import {
  Card,
  GridContainer,
  GridItem,
  Headline,
  type SelectChangeEvent,
  Spacing,
  Typography,
  styled,
} from '@cofenster/web-components';

import { resizeVideo } from '../../../../stack-transcodings/src/uploadReceived/resizeVideo';
import type { VideoAsset } from '../../api/hooks/videoAsset/useVideoAsset';
import { VideoFitSelect } from '../../components/dialog/RetranscodeAssetDialog/VideoFitSelect';
import { VideoFormatSelect } from '../../components/dialog/RetranscodeAssetDialog/VideoFormatSelect';

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(-1),
  marginBottom: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  gap: theme.spacing(1),
  padding: theme.spacing(3),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const PreviewContainer = styled('div')(() => ({
  outline: '3px solid',
  aspectRatio: 'var(--aspect-ratio)',
  position: 'relative',
}));

const Original = styled(PreviewContainer)(({ theme }) => ({
  color: theme.palette.brand.blue,

  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    backgroundImage: 'var(--image)',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    opacity: 0.2,
  },
}));

const ProjectContainer = styled(PreviewContainer)(({ theme }) => ({
  color: theme.palette.brand.positiveDark,
  display: 'flex',
}));

const CroppedArea = styled('div')(() => ({
  width: 'var(--width)',
  height: 'var(--height)',
  backgroundColor: 'var(--color)',
  backgroundImage: 'var(--image)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  transition: '500ms',
}));

const OpaqueOverlay = styled(CroppedArea)(() => ({
  position: 'absolute',
  left: 'var(--left)',
  top: 'var(--top)',
}));

// 1. Center the visible area within the project container.
const VisibleArea = styled(CroppedArea)(() => ({
  margin: 'auto', // 1
}));

const Width = styled('span')(({ theme }) => ({
  color: 'inherit',
  position: 'absolute',
  top: '100%',
  left: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

const Height = styled('span')(({ theme }) => ({
  color: 'inherit',
  position: 'absolute',
  right: '100%',
  bottom: theme.spacing(2),
  transform: 'rotate(-0.25turn)',
}));

const Format = styled('span')(({ theme }) => ({
  color: 'inherit',
  position: 'absolute',
  right: 0,
  bottom: '100%',
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));

export const InspectPlayground: FC<{ videoAsset: NonNullable<VideoAsset> }> = ({ videoAsset }) => {
  const [videoFit, setVideoFit] = useState<'Fit' | 'Crop'>('Fit');
  const [videoFormat, setVideoFormat] = useState<keyof typeof VIDEO_FORMATS>('Horizontal');

  const width = videoAsset.width;
  const height = videoAsset.height;
  const constraint = VIDEO_FORMATS[videoFormat];

  if (!width || !height) {
    return null;
  }

  const resize = resizeVideo(width, height, videoFormat, videoFit);

  if (!resize?.Source || !resize?.Target) {
    return null;
  }

  const getDynamicProperties = (
    rectangle: typeof resize.Source,
    { width, height }: { width: number; height: number }
  ) =>
    ({
      '--left': `${((rectangle.X ?? 0) / width) * 100}%`,
      '--top': `${((rectangle.Y ?? 0) / height) * 100}%`,
      '--width': `${((rectangle.Width ?? 0) / width) * 100}%`,
      '--height': `${((rectangle.Height ?? 0) / height) * 100}%`,
    }) as CSSProperties;

  return (
    <Spacing bottom={2}>
      <Card>
        <Header>
          <Headline component="h2" variant="h4">
            Playground
          </Headline>

          <GridContainer width="auto" minWidth={350}>
            <GridItem xs={6}>
              <VideoFitSelect
                pb={0}
                withAutomatic={false}
                value={videoFit}
                onChange={(event: SelectChangeEvent<unknown>) => setVideoFit(event.target.value as 'Fit' | 'Crop')}
              />
            </GridItem>
            <GridItem xs={6}>
              <VideoFormatSelect
                pb={0}
                withAutomatic={false}
                value={videoFormat}
                onChange={(event: SelectChangeEvent<unknown>) => setVideoFormat(event.target.value as VideoFormat)}
              />
            </GridItem>
          </GridContainer>
        </Header>
        <Spacing vertical={1}>
          <Typography variant="l">
            The first diagram shows how much of the original asset will be visible based on the transcoding parameters.
            The <span style={{ color: 'blue', fontWeight: 'bold' }}>blue</span> border represents the original asset
            dimensions, while the opaque overlay represents the visible part in the resulting video.
          </Typography>
        </Spacing>
        <Spacing vertical={1}>
          <Typography variant="l">
            The second diagram represents the difference in scaling between the transcoded asset and the video format
            itself. The position of the asset within the frame is <strong>not</strong> representative as we upscale to
            size. It’s purely meant as an illustration of the difference in scale and proportion.
          </Typography>
        </Spacing>
        <Wrapper style={{ '--image': `url(${videoAsset.thumbnailUrl})` } as CSSProperties}>
          <GridContainer spacing={6}>
            <GridItem xs={12} md={6}>
              <Original style={{ '--aspect-ratio': String(width / height) } as CSSProperties}>
                <OpaqueOverlay style={getDynamicProperties(resize.Source, { width, height })} />
                <Width>{width}px</Width>
                <Height>{height}px</Height>
              </Original>
            </GridItem>
            <GridItem xs={12} md={6}>
              <ProjectContainer style={{ '--aspect-ratio': String(constraint.aspectRatio) } as CSSProperties}>
                <VisibleArea style={getDynamicProperties(resize.Target, constraint)} />
                <Width>{constraint.width}px</Width>
                <Height>{constraint.height}px</Height>
                <Format>{videoFormat}</Format>
              </ProjectContainer>
            </GridItem>
          </GridContainer>
        </Wrapper>
      </Card>
    </Spacing>
  );
};
