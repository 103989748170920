import { useMemo } from 'react';

import { type AccountsLiteQueryResult, type AccountsLiteQueryVariables, useAccountsLiteQuery } from '../../generated';

export type Account = NonNullable<AccountsLiteQueryResult['data']>['accounts']['items'][number];
export type Filter = NonNullable<AccountsLiteQueryVariables['filter']>;
export type PaginatedAccounts = NonNullable<AccountsLiteQueryResult['data']>['accounts'];

export const useAccountsLite = (filter?: Filter) => {
  const { loading, error, data } = useAccountsLiteQuery({
    variables: { filter },
    // See: https://github.com/apollographql/apollo-client/issues/5419#issuecomment-598065442
    // See: https://github.com/apollographql/apollo-client/issues/5419#issuecomment-605832327
    fetchPolicy: 'cache-and-network',
  });
  const paginatedAccounts = data?.accounts;
  return useMemo(
    () => ({
      paginatedAccounts,
      loading,
      error,
    }),
    [paginatedAccounts, loading, error]
  );
};
