import { useCallback } from 'react';

import { type UpdateTextInputMutationVariables, useUpdateTextInputMutation } from '../../generated';

export const useUpdateTextInput = () => {
  const [updateTextInput] = useUpdateTextInputMutation({ refetchQueries: ['RenderTemplate'] });
  return useCallback(
    (id: UpdateTextInputMutationVariables['id'], input: UpdateTextInputMutationVariables['input']) =>
      updateTextInput({ variables: { id, input } }),
    [updateTextInput]
  );
};
