import get from 'just-safe-get';
import { type FC, type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { BlankButton, Icon, Select, SelectOption, preventForwardProps, styled } from '@cofenster/web-components';

import type { TextComposition } from '../../../../api/hooks/renderTemplate/useRenderTemplate';
import { type TemplateDefinitionPath, useTemplateDefinition } from '../TemplateDefinitionStateProvider';

import { templateDefinitionPathToString } from '../utils';
import { type TreeNode, type TreeNodeInit, buildTree } from './buildTree';

export const PathSelect: FC<{
  textCompositions: TextComposition[];
  path: TemplateDefinitionPath;
  setPath: (value: Partial<TemplateDefinitionPath>) => void;
}> = ({ textCompositions, path, setPath }) => {
  const parts = useMemo(() => {
    return [...textCompositions.map(textCompositionSubtree), transitionSubtree(), logoSubtree()];
  }, [textCompositions]);

  useHashPath(path, setPath);

  return (
    <>
      <Select
        pb={0}
        label="Part"
        value={path.composition}
        name="part"
        onChange={(event) =>
          setPath({ composition: event.target.value as `textElements.${string}` | `transition` | `logo` })
        }
      >
        {parts.map((part) => (
          <SelectOption key={part.data.path} value={part.data.path}>
            {part.data.label}
          </SelectOption>
        ))}
      </Select>
      {parts
        .filter((part) => part.data.path === path.composition)
        .map((part) => (
          <TreeView key={part.data.path} item={part} path={path} setPath={setPath} />
        ))}
    </>
  );
};

const useHashPath = (path: TemplateDefinitionPath, setPath: (value: Partial<TemplateDefinitionPath>) => void) => {
  const stringPath = templateDefinitionPathToString(path);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      if (window.location.hash) {
        setPath(templateDefinitionPathFromString(window.location.hash.slice(1)));
      }
      setInitialized(true);
    }
  }, [initialized, setPath]);

  useEffect(() => {
    window.location.hash = `#${stringPath}`;
  }, [stringPath]);
};

const TreeView: FC<{
  item: TreeNodeInit<PathTreeData>;
  path: TemplateDefinitionPath;
  setPath: (value: Partial<TemplateDefinitionPath>) => void;
}> = ({ item, path, setPath }) => {
  const pathAsString = templateDefinitionPathToString(path);
  const checkIsTouched = useCheckIsTouched();

  const tree = useMemo(() => {
    const tree = buildTree(item, (d) => d.path);
    for (const node of tree.postOrderDepthFirst()) {
      node.data.isSelected = pathAsString === node.data.path;
      node.data.isTouched = !!checkIsTouched(node.data.path);
      node.data.isChildSelected = node.children?.some((child) => child.data.isSelected ?? child.data.isChildSelected);
      node.data.isChildTouched = node.children?.some((child) => child.data.isTouched ?? child.data.isChildTouched);
    }
    return tree;
  }, [item, pathAsString, checkIsTouched]);

  return <PathTreeNodeView skipLabel node={tree.root} path={path} setPath={setPath} />;
};

export const templateDefinitionPathFromString = (path: string): TemplateDefinitionPath => {
  const fragments = path.split('.');

  const composition = fragments[0] === 'textElements' ? `${fragments.shift()}.${fragments.shift()}` : fragments.shift();
  const component = fragments[0] === 'text' ? `${fragments.shift()}.${fragments.shift()}` : fragments.shift();
  const subComponent = fragments.join('.');

  return { composition, component, subComponent } as TemplateDefinitionPath;
};

const useIsTouched = (path: string | null) => {
  const check = useCheckIsTouched();
  return useMemo(() => {
    if (!path) return null;
    const hasCss = check(`${path}.css`);
    const hasAnimations = check(`${path}.animations`);
    const hasFormatOverrides = [
      check(`${path}.Horizontal`),
      check(`${path}.Vertical`),
      check(`${path}.Square`),
      check(`${path}.SocialMedia`),
    ].some(Boolean);
    const isTouched = hasCss ?? hasAnimations ?? hasFormatOverrides;
    return { isTouched, hasCss, hasAnimations, hasFormatOverrides };
  }, [path, check]);
};

const useCheckIsTouched = () => {
  const def = useTemplateDefinition();
  return useCallback(
    (path: string) => {
      if (!path) return null;
      return typeof get(def, path) !== 'undefined';
    },
    [def]
  );
};

type PathTreeData = {
  // configuration
  icon?: ReactNode;
  label: string;
  path: string;
  alwaysVisible?: boolean;
  // state
  isSelected?: boolean;
  isChildSelected?: boolean;
  isTouched?: boolean;
  isChildTouched?: boolean;
};

const PathTreeNodeView: FC<{
  node: TreeNode<PathTreeData>;
  path: TemplateDefinitionPath;
  setPath: (value: Partial<TemplateDefinitionPath>) => void;
  skipLabel?: boolean;
}> = ({ node, path, setPath, skipLabel = false }) => {
  const touched = useIsTouched(node.data.path);
  const isCurrent = templateDefinitionPathToString(path) === node.data.path;

  const hasChildren = node.children?.length;
  const hasFoldableChildren = node.children?.some(isFoldable);
  const [folded, setFolded] = useState(true);

  return (
    <>
      {!skipLabel && (
        <Label current={isCurrent}>
          <BlankButton
            disabled={!hasFoldableChildren}
            onClick={() => setFolded((value) => !value)}
            aria-label="Expand tree view"
            aria-pressed={!folded}
          >
            {hasChildren ? (
              hasFoldableChildren ? (
                <Icon type={folded ? 'CaretRightIcon' : 'CaretDownIcon'} size="xs" />
              ) : (
                <Icon type="CaretDownIcon" size="xs" />
              )
            ) : (
              <Icon type="CircleIcon" size="xs" />
            )}
          </BlankButton>
          <BlankButton onClick={() => setPath(templateDefinitionPathFromString(node.data.path))}>
            {node.data.label}
          </BlankButton>
          {touched?.hasCss && <TouchedCSS title="CSS defined">S</TouchedCSS>}
          {touched?.hasAnimations && <TouchedAnimations title="Animations defined">A</TouchedAnimations>}
          {touched?.hasFormatOverrides && (
            <TouchedFormatOverrides title="Format overrides defined">O</TouchedFormatOverrides>
          )}
        </Label>
      )}
      {hasChildren && (
        <Level>
          {node.children
            .filter((child) => !isFoldable(child) || !folded || skipLabel)
            .map((child, index) => (
              <PathTreeNodeView key={`${node.id}-${index}`} node={child} path={path} setPath={setPath} />
            ))}
        </Level>
      )}
    </>
  );
};

const Label = styled(
  'span',
  preventForwardProps(['current'])
)<{ current: boolean }>(({ theme, current }) => ({
  ...theme.typography.m,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  backgroundColor: current ? theme.palette.brand.blue100 : 'transparent',
  color: current ? theme.palette.brand.blue : theme.palette.brand.grey700,
}));

const Level = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const Touched = styled('span')({
  borderRadius: '50%',
  width: '1rem',
  height: '1rem',
  lineHeight: '1rem',
  textAlign: 'center',
  cursor: 'default',
});

const TouchedCSS = styled(Touched)({
  color: 'white',
  backgroundColor: 'green',
});

const TouchedAnimations = styled(Touched)({
  color: 'white',
  backgroundColor: 'blue',
});

const TouchedFormatOverrides = styled(Touched)({
  color: 'white',
  backgroundColor: 'red',
});

const isFoldable = (child: TreeNode<PathTreeData>) =>
  !child.data.alwaysVisible &&
  !child.data.isSelected &&
  !child.data.isChildSelected &&
  !child.data.isTouched &&
  !child.data.isChildTouched;

const textCompositionSubtree = (textComposition: TextComposition): TreeNodeInit<PathTreeData> => {
  return {
    data: {
      label: textComposition.nameEN,
      path: `textElements.${textComposition.aeCompositionName}`,
    },
    children: [
      {
        data: {
          label: 'Wrapper',
          path: `textElements.${textComposition.aeCompositionName}.wrapper`,
          alwaysVisible: true,
        },
        children: [
          {
            data: {
              label: 'Element',
              path: `textElements.${textComposition.aeCompositionName}.element`,
              alwaysVisible: true,
            },
            children: [
              {
                data: {
                  label: 'Background',
                  path: `textElements.${textComposition.aeCompositionName}.background`,
                  alwaysVisible: true,
                },
                children: [svgSubcomponentSubtree(`textElements.${textComposition.aeCompositionName}.background`)],
              },
              {
                data: { label: 'Before Texts', path: `textElements.${textComposition.aeCompositionName}.texts.before` },
              },
              {
                data: {
                  label: 'Texts',
                  path: `textElements.${textComposition.aeCompositionName}.texts`,
                  alwaysVisible: true,
                },
                children: [
                  ...textComposition.textInputs.flatMap((textInput) => [
                    {
                      data: {
                        label: `Before ${textInput.nameEN}`,
                        path: `textElements.${textComposition.aeCompositionName}.text.${textInput.aeLayerName}.before`,
                      },
                    },
                    {
                      data: {
                        label: textInput.nameEN,
                        path: `textElements.${textComposition.aeCompositionName}.text.${textInput.aeLayerName}`,
                        alwaysVisible: true,
                      },
                      children: [
                        {
                          data: {
                            label: `Before content ${textInput.nameEN}`,
                            path: `textElements.${textComposition.aeCompositionName}.text.${textInput.aeLayerName}.beforeContent`,
                          },
                        },
                        {
                          data: {
                            label: `After content ${textInput.nameEN}`,
                            path: `textElements.${textComposition.aeCompositionName}.text.${textInput.aeLayerName}.afterContent`,
                          },
                        },
                        svgSubcomponentSubtree(
                          `textElements.${textComposition.aeCompositionName}.text.${textInput.aeLayerName}`
                        ),
                        {
                          data: {
                            label: 'Selectors',
                            path: `textElements.${textComposition.aeCompositionName}.text.${textInput.aeLayerName}.selectors`,
                          },
                        },
                      ],
                    },
                    {
                      data: {
                        label: `After ${textInput.nameEN}`,
                        path: `textElements.${textComposition.aeCompositionName}.text.${textInput.aeLayerName}.after`,
                      },
                    },
                  ]),
                  svgSubcomponentSubtree(`textElements.${textComposition.aeCompositionName}.texts`),
                ],
              },
              {
                data: { label: 'After Texts', path: `textElements.${textComposition.aeCompositionName}.texts.after` },
              },
              svgSubcomponentSubtree(`textElements.${textComposition.aeCompositionName}.element`),
            ],
          },
          svgSubcomponentSubtree(`textElements.${textComposition.aeCompositionName}.wrapper`),
        ],
      },
    ],
  };
};
const svgSubcomponentSubtree = (prefix: string): TreeNodeInit<PathTreeData> => ({
  data: { label: 'SVG', path: `${prefix}.svg.element` },
  children: [
    { data: { label: 'Path 0', path: `${prefix}.svg.path_0` } },
    { data: { label: 'Path 1', path: `${prefix}.svg.path_1` } },
    { data: { label: 'Path 2', path: `${prefix}.svg.path_2` } },
    { data: { label: 'Path 3', path: `${prefix}.svg.path_3` } },
    { data: { label: 'Path 4', path: `${prefix}.svg.path_4` } },
  ],
});
const transitionSubtree = (): TreeNodeInit<PathTreeData> => ({
  data: { label: 'Transition', path: 'transition' },
  children: [
    {
      data: { label: 'Wrapper', path: 'transition.wrapper' },
      children: [
        {
          data: { label: 'Element 0', path: 'transition.element_0', alwaysVisible: true },
          children: [svgSubcomponentSubtree('transition.element_0')],
        },
        {
          data: { label: 'Element 1', path: 'transition.element_1', alwaysVisible: true },
          children: [svgSubcomponentSubtree('transition.element_1')],
        },
        {
          data: { label: 'Element 2', path: 'transition.element_2', alwaysVisible: true },
          children: [svgSubcomponentSubtree('transition.element_2')],
        },
        {
          data: { label: 'Element 3', path: 'transition.element_3', alwaysVisible: true },
          children: [svgSubcomponentSubtree('transition.element_3')],
        },
        {
          data: { label: 'Element 4', path: 'transition.element_4', alwaysVisible: true },
          children: [svgSubcomponentSubtree('transition.element_4')],
        },
      ],
    },
  ],
});

const logoSubtree = (): TreeNodeInit<PathTreeData> => ({
  data: { label: 'Logo', path: 'logo' },
  children: [
    {
      data: { label: 'Wrapper', path: 'logo.wrapper', alwaysVisible: true },
      children: [
        {
          data: { label: 'Element', path: 'logo.element', alwaysVisible: true },
        },
      ],
    },
  ],
});
