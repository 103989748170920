import { type Dispatch, type SetStateAction, useCallback } from 'react';

import type { AnimatedStyle, StyleProperties } from '../remotion';

const EMPTY_OBJECT = {};
export const useAnimatedStyleState = ([state, setState]: [
  StyleProperties,
  Dispatch<SetStateAction<StyleProperties>>,
]): [AnimatedStyle, Dispatch<SetStateAction<AnimatedStyle>>] => {
  const subState = state.animations ?? EMPTY_OBJECT;
  const setSubState = useCallback<Dispatch<SetStateAction<AnimatedStyle>>>(
    (setterOrValue) => {
      return setState(({ animations: prevAnimations, ...rest }) => {
        const animations =
          typeof setterOrValue === 'function' ? setterOrValue(prevAnimations ?? EMPTY_OBJECT) : setterOrValue;
        return {
          ...rest,
          animations,
        };
      });
    },
    [setState]
  );

  return [subState, setSubState];
};
