import { type EasingName, Easings } from './Easings';
import {
  type Anchor,
  AnchorOption,
  EasingOption,
  OpacityOption,
  anchorToTransform,
  createBoundIntegerOption,
} from './Options';
import { DEFAULT_TIMING_IN, TimingOption } from './Options/Timing';
import { createPreset } from './Preset';
import { createTimeline } from './utils';

export const GrowIn = createPreset(
  'GrowIn',
  ({ timing, easing, opacity, anchor, growFrom }) => {
    return {
      ...(opacity && {
        opacity: createTimeline(timing, {
          0: { v: 0, e: Easings[easing] },
          1: { v: 1 },
        }),
      }),
      transform: createTimeline(timing, {
        0: { v: anchorToTransform(growFrom / 100, anchor), e: Easings[easing] },
        1: { v: 'scale(1)' },
      }),
    };
  },
  {
    timing: TimingOption,
    easing: EasingOption,
    opacity: OpacityOption,
    anchor: AnchorOption,
    growFrom: createBoundIntegerOption('Scale from', 0, 99, '%'),
  },
  {
    timing: DEFAULT_TIMING_IN,
    easing: 'EaseOut' as EasingName,
    opacity: true as boolean,
    anchor: 'Center' as Anchor,
    growFrom: 0 as number,
  }
);
