import { useCallback } from 'react';

import { type SendUserInviteEmailMutationVariables, useSendUserInviteEmailMutation } from '../../generated';

export const useSendUserInviteEmail = () => {
  const [sendUserInviteEmailMutation] = useSendUserInviteEmailMutation();

  return useCallback(
    (id: SendUserInviteEmailMutationVariables['id']) => sendUserInviteEmailMutation({ variables: { id } }),
    [sendUserInviteEmailMutation]
  );
};
