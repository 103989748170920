import { type FC, useEffect } from 'react';
import { useSignout } from '../../../api/hooks/staffUser/useSignout';
import { RouterButton } from '../../../components/navigation/RouterButton';
import { SignInHeadline, SignInLayout } from '../SignInLayout';

export const SignOut: FC = () => {
  const signout = useSignout();
  useEffect(() => {
    signout();
  }, [signout]);

  return (
    <SignInLayout>
      <SignInHeadline variant="h2" component="h1">
        You have been successfully signed out.
      </SignInHeadline>
      <RouterButton variant="primary" to="home">
        Sign in again
      </RouterButton>
    </SignInLayout>
  );
};
