import { useMemo } from 'react';

import {
  type InspectVideoAssetQueryResult,
  type InspectVideoAssetQueryVariables,
  useInspectVideoAssetQuery,
} from '../../generated';

export type InspectVideoAsset = NonNullable<InspectVideoAssetQueryResult['data']>['inspectVideoAsset'];

export const useInspectVideoAsset = (id?: InspectVideoAssetQueryVariables['id']) => {
  const { loading, error, data } = useInspectVideoAssetQuery({
    variables: { id: id ?? '' },
    skip: !id,
    fetchPolicy: 'cache-and-network',
  });

  return useMemo(() => ({ data: data?.inspectVideoAsset, loading, error }), [data?.inspectVideoAsset, loading, error]);
};
