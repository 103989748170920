import type { FC } from 'react';

import { Tooltip, Typography, styled } from '@cofenster/web-components';

import { type RenderJob, getStatusProps } from '../../../../contexts/exports';

const StatusText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

export const JobStatus: FC<RenderJob> = ({ step, status, error }) => {
  const statusProps = getStatusProps(status);

  return (
    <Tooltip title={error}>
      <StatusText data-testid={`status-${status}`}>
        {statusProps.label} {step ? <>({step})</> : null}
      </StatusText>
    </Tooltip>
  );
};
