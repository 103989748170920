import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner, PageHeadline, useGoto } from '@cofenster/web-components';

import { useExtendedRenderJob } from '../../../api/hooks/renderJob/useExtendedRenderJob';
import { CodeBlock } from '../../../components/display/CodeBlock';
import { ExportSubNavigation } from '../../../components/exports/ExportSubNavigation';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { routes } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';
import { NoAccessLayout } from '../../NoAccess';

export const ExportRenderDescription: FC = () => {
  const { id } = useParams();
  const { extendedRenderJob, error, loading } = useExtendedRenderJob(id);
  const title = [extendedRenderJob?.project?.name, 'Render description'].filter(Boolean).join(' — ');
  const goto = useGoto();

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <PermissionRestriction has="ExportRead" fallback={<NoAccessLayout documentTitle={title} />}>
      <AdminLayout
        documentTitle={title}
        header={{
          topLeft: <PageHeadline mb={0} title={title} onBack={() => goto(routes.exports)} />,
          bottomLeft: id && <ExportSubNavigation id={id} />,
        }}
      >
        {extendedRenderJob?.job.renderDescription ? (
          <CodeBlock title="Render description" code={extendedRenderJob.job.renderDescription} />
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <ErrorContent />
        )}
      </AdminLayout>
    </PermissionRestriction>
  );
};
