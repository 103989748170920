import type { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { SubNavigation } from '@cofenster/web-components';

import { SubNavigationItem } from '../../components/navigation/SubNavigationItem';
import { PermissionRestriction } from '../../contexts/staffUser/PermissionRestriction';
import { routes } from '../../routes';

export const ExportSubNavigation: FC<{ id: string }> = ({ id }) => {
  return (
    <SubNavigation>
      <SubNavigationItem iconType="InfoIcon" to="export" params={{ id }} active={!!useMatch(routes.export)}>
        Info
      </SubNavigationItem>

      <PermissionRestriction has="ExportRead">
        <SubNavigationItem
          iconType="EyeIcon"
          to="exportPreview"
          params={{ id }}
          active={!!useMatch(routes.exportPreview)}
        >
          Customer preview
        </SubNavigationItem>
        <SubNavigationItem
          iconType="CodeIcon"
          to="exportRenderDescription"
          params={{ id }}
          active={!!useMatch(routes.exportRenderDescription)}
        >
          Render description
        </SubNavigationItem>
      </PermissionRestriction>
    </SubNavigation>
  );
};
