/**
 * Only properties that can produce any uncontrolled motion
 */
export const IllegalCSSProperties = [
  'transition',
  'transitionProperty',
  'transitionDelay',
  'transitionDuration',
  'transitionTimingFunction',
  'animation',
  'animationDelay',
  'animationComposition',
  'animationDirection',
  'animationDuration',
  'animationFillMode',
  'animationIterationCount',
  'animationName',
  'animationPlayState',
  'animationTimeline',
  'animationTimingFunction',
] as const;
