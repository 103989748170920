import { type EasingName, Easings } from './Easings';
import { EasingOption, OpacityOption } from './Options';
import { type Rotation, RotationOption } from './Options/RotationOption';
import { DEFAULT_TIMING_IN, TimingOption } from './Options/Timing';
import { createPreset } from './Preset';
import { createTimeline } from './utils';

export const SpinIn = createPreset(
  'SpinIn',
  ({ timing, easing, opacity, rotation }) => {
    const { start, end } = rotation;
    const startRotation = `rotate(${start}deg)`;
    const endRotation = `rotate(${end}deg)`;

    return {
      ...(opacity && {
        opacity: createTimeline(timing, {
          0: { v: 0, e: Easings[easing] },
          1: { v: 1 },
        }),
      }),
      transform: createTimeline(timing, {
        0: { v: startRotation, e: Easings[easing] },
        1: { v: endRotation },
      }),
    };
  },
  {
    timing: TimingOption,
    rotation: RotationOption,
    easing: EasingOption,
    opacity: OpacityOption,
  },
  {
    timing: DEFAULT_TIMING_IN,
    easing: 'EaseOut' as EasingName,
    opacity: true as boolean,
    rotation: { start: 0, end: 0 } as Rotation,
  }
);
