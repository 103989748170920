import { useField } from 'formik';
import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Form,
  FormSubmitButton,
  GridContainer,
  GridItem,
  Headline,
  LoadingSpinner,
  PopoverMenuItem,
  PopoverMenuTriggerIcon,
  withPopoverMenu,
} from '@cofenster/web-components';

import type { FeatureFlag } from '../../../../../api/hooks/account/useAccount';
import { useAssignFeatureFlags } from '../../../../../api/hooks/account/useAssignFeatureFlags';
import { useFeatureFlags } from '../../../../../api/hooks/featureFlag/useFeatureFlags';
import { useHasPermission } from '../../../../../contexts/staffUser/PermissionRestriction';

import { DocsAlert } from './DocsAlert';
import { FeatureFlagRow } from './FeatureFlagRow';
import { BUNDLES } from './bundles';

type Values = {
  featureFlags?: string[];
};

type Props = {
  featureFlagsPerAccount: FeatureFlag[];
  accountId: string | null;
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  featureFlags: Yup.array().required(),
});

const useInitialValues = (featureFlags: FeatureFlag[]) => {
  return useMemo<Values>(
    () => ({
      featureFlags: featureFlags.map((featureFlag: FeatureFlag) => featureFlag.name),
    }),
    [featureFlags]
  );
};

const useSubmit = (accountId?: string) => {
  const assignFeatureFlags = useAssignFeatureFlags();

  return useCallback(
    async (values: Values) => {
      if (!accountId) return;
      await assignFeatureFlags(accountId, { featureFlagIds: values.featureFlags ?? [] });
    },
    [assignFeatureFlags, accountId]
  );
};

const BundleSelector = () => {
  const [_, __, { setValue }] = useField({ name: 'featureFlags' });
  const selectBundle = useCallback((bundle: keyof typeof BUNDLES) => setValue(BUNDLES[bundle]), [setValue]);

  const PopoverMenu = withPopoverMenu(PopoverMenuTriggerIcon, {
    children: (
      <>
        <PopoverMenuItem onClick={() => selectBundle('STARTER')}>
          Check <strong>Starter</strong> bundle
        </PopoverMenuItem>
        <PopoverMenuItem onClick={() => selectBundle('PROFESSIONAL')}>
          Check <strong>Professional</strong> bundle
        </PopoverMenuItem>
        <PopoverMenuItem onClick={() => selectBundle('ENTREPRISE')}>
          Check <strong>Entreprise</strong> bundle
        </PopoverMenuItem>
      </>
    ),
  });

  return <PopoverMenu />;
};

export const FeatureFlags: FC<Props> = ({ featureFlagsPerAccount, accountId }) => {
  const { featureFlags } = useFeatureFlags();
  const initialValues = useInitialValues(featureFlagsPerAccount);
  const canAssignFeatureFlags = useHasPermission({ has: 'FeatureFlagAssign' });
  const onSubmit = useSubmit(accountId ? accountId : '');

  return (
    <Form validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit}>
      <GridContainer alignItems="center" justifyContent="space-between" mb={4}>
        <GridItem>
          <Headline color="carbon" variant="h3" component="h2">
            Feature flags
          </Headline>
        </GridItem>
        {canAssignFeatureFlags && (
          <GridItem display="flex" gap={1}>
            <BundleSelector />
          </GridItem>
        )}
      </GridContainer>

      <GridContainer>
        {featureFlags?.map((featureFlag) => (
          <GridItem key={featureFlag.name} xs={12}>
            <FeatureFlagRow featureFlag={featureFlag} disabled={!canAssignFeatureFlags} />
          </GridItem>
        )) ?? (
          <GridItem xs={12}>
            <LoadingSpinner />
          </GridItem>
        )}

        <GridItem xs={12} md={12} textAlign="right">
          <FormSubmitButton autoDisable data-testid="flags-submit-button" disabled={!canAssignFeatureFlags}>
            Save
          </FormSubmitButton>
        </GridItem>

        <GridItem xs={12}>
          <DocsAlert />
        </GridItem>
      </GridContainer>
    </Form>
  );
};
