import get from 'just-safe-get';
import set from 'just-safe-set';
import {
  type Dispatch,
  type FC,
  type PropsWithChildren,
  type Reducer,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import type { VideoFormat } from '@cofenster/constants';
import { LoadingSpinner } from '@cofenster/web-components';

import { useCurrentTemplateDefinitionByRenderTemplate } from '../../../api/hooks/templateDefinition/useCurrentTemplateDefinitionByRenderTemplate';
import type {
  StyleProperties,
  TemplateDefinition,
  TemplateDefinitionLogoElement,
  TemplateDefinitionTextElement,
} from './remotion';
import { templateDefinitionPathToString } from './utils/templateDefinitionPathToString';

const EMPTY_OBJECT = {};

const DEFAULT_STATE = {
  textElements: {},
  logo: {},
  transition: {},
};

export type TemplateDefinitionTextElementStyleDefinitionPath = {
  composition: `textElements.${string}`;
  component: Exclude<keyof TemplateDefinitionTextElement, 'text'> | `text.${string}`;
  subComponent?: 'element' | `path_${string}` | 'selectors';
};

export type TemplateDefinitionLogoStyleDefinitionPath = {
  composition: 'logo';
  component: keyof TemplateDefinitionLogoElement;
  subComponent: undefined;
};

export type TemplateDefinitionTransitionStyleDefinitionPath = {
  composition: 'transition';
  component: `svg_path_${string}` | string;
  subComponent: undefined;
};

export type TemplateDefinitionPath =
  | TemplateDefinitionTextElementStyleDefinitionPath
  | TemplateDefinitionLogoStyleDefinitionPath
  | TemplateDefinitionTransitionStyleDefinitionPath;

export const isTemplateDefinitionTextElementStyleDefinitionPath = (
  path: TemplateDefinitionPath
): path is TemplateDefinitionTextElementStyleDefinitionPath => {
  return path.composition.startsWith('textElements.');
};

export const TemplateDefinitionStateContext = createContext<{
  state: TemplateDefinition;
  dispatch: Dispatch<Action>;
}>({ state: DEFAULT_STATE, dispatch: () => undefined });

type Action =
  | {
      type: 'SET_DEFINITION';
      value: TemplateDefinition;
    }
  | {
      type: 'SET_STYLE_PROPERTIES';
      path: string;
      format?: VideoFormat;
      value: StyleProperties;
    }
  | {
      type: 'SET_PROPERTY';
      path: string;
      value: unknown;
    };

const removeEmptyOrUndefined = <T extends object>(obj: T): T => {
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object') {
      removeEmptyOrUndefined(value);
    }

    const isEmptyObject = typeof value === 'object' && !Array.isArray(value) && Object.entries(value).length === 0;
    const isEmptyArray = Array.isArray(value) && value.length === 0;
    if (typeof value === 'undefined' || isEmptyObject || isEmptyArray) {
      try {
        delete obj[key as keyof T];
      } catch {
        // noop
      }
    }
  });

  return obj;
};

// 1. Remove empty or undefined values from the state is used because we have
//    a bunch of __metadata: undefined or __metadata: {} which doesn't work
//    fine with undo/redo functionality. Removing those empty objects solves
//    the issue.
const reducer: Reducer<TemplateDefinition, Action> = (state, action) => {
  switch (action.type) {
    case 'SET_DEFINITION':
      return removeEmptyOrUndefined(action.value); // 1
    case 'SET_STYLE_PROPERTIES': {
      const newState = structuredClone(state ?? DEFAULT_STATE);
      set(newState, action.format ? `${action.path}.${action.format}` : `${action.path}`, action.value);
      return removeEmptyOrUndefined(newState); // 1
    }
    case 'SET_PROPERTY': {
      const newState = structuredClone(state ?? DEFAULT_STATE);
      set(newState, action.path, action.value);
      return removeEmptyOrUndefined(newState); // 1
    }
    default:
      return state ?? DEFAULT_STATE;
  }
};

type Props = PropsWithChildren<{ templateDefinition: TemplateDefinition }>;
const TemplateDefinitionStateProviderWrapper: FC<Props> = ({ templateDefinition, children }) => {
  const [state, dispatch] = useReducer(reducer, templateDefinition);
  const context = useMemo(() => ({ state, dispatch }), [state]);

  return <TemplateDefinitionStateContext.Provider value={context}>{children}</TemplateDefinitionStateContext.Provider>;
};

export const TemplateDefinitionStateProvider: FC<PropsWithChildren<{ renderTemplateId: string }>> = ({
  renderTemplateId,
  children,
}) => {
  const { currentTemplateDefinitionByTemplate } = useCurrentTemplateDefinitionByRenderTemplate(renderTemplateId);

  if (!currentTemplateDefinitionByTemplate) {
    return <LoadingSpinner />;
  }

  return (
    <TemplateDefinitionStateProviderWrapper
      templateDefinition={currentTemplateDefinitionByTemplate as TemplateDefinition}
    >
      {children}
    </TemplateDefinitionStateProviderWrapper>
  );
};

export const useTemplateDefinition = () => useContext(TemplateDefinitionStateContext).state;

export const useSetTemplateDefinition = () => {
  const { dispatch } = useContext(TemplateDefinitionStateContext);
  return useCallback(
    (value: TemplateDefinition) => {
      dispatch({ type: 'SET_DEFINITION', value });
    },
    [dispatch]
  );
};

export const usePrimitivePropertyState = <T extends string | boolean | number>(
  path: string
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] => {
  const { state, dispatch } = useContext(TemplateDefinitionStateContext);
  const subState = useMemo(() => {
    return get(state, path);
  }, [state, path]);
  const setSubState = useCallback(
    (nextOrSetter: SetStateAction<T | undefined>) => {
      dispatch({
        type: 'SET_PROPERTY',
        path,
        value: typeof nextOrSetter === 'function' ? nextOrSetter(subState) : nextOrSetter,
      });
    },
    [path, subState, dispatch]
  );
  return [subState, setSubState];
};

export const useStylePropertiesState = (
  pathObject: TemplateDefinitionPath,
  format?: VideoFormat
): [StyleProperties, Dispatch<SetStateAction<StyleProperties>>] => {
  const { state, dispatch } = useContext(TemplateDefinitionStateContext);
  const path = templateDefinitionPathToString(pathObject);
  const subState = useMemo(() => {
    const fullPath = format ? `${path}.${format}` : path;
    return get(state, fullPath, EMPTY_OBJECT) as StyleProperties;
  }, [state, format, path]);

  const setSubState = useCallback(
    (nextOrSetter: SetStateAction<StyleProperties>) => {
      dispatch({
        type: 'SET_STYLE_PROPERTIES',
        path,
        format,
        value: typeof nextOrSetter === 'function' ? nextOrSetter(subState) : nextOrSetter,
      });
    },
    [path, format, subState, dispatch]
  );
  return [subState, setSubState];
};
