import { useCallback } from 'react';

import { type CreateMusicMutationVariables, useCreateMusicMutation } from '../../generated';

export const useCreateMusic = () => {
  const [createMusic] = useCreateMusicMutation({ refetchQueries: ['Musics'] });
  return useCallback(
    async (input: CreateMusicMutationVariables['input']) => createMusic({ variables: { input } }),
    [createMusic]
  );
};
