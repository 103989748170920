import type { FC } from 'react';
import { RouterButton } from '../../../components/navigation/RouterButton';
import { SignInHeadline, SignInLayout } from '../SignInLayout';

export const SignInError: FC = () => {
  return (
    <SignInLayout>
      <SignInHeadline variant="h2" component="h1">
        There was a problem signing you in.
      </SignInHeadline>
      <RouterButton variant="primary" to="home">
        Retry
      </RouterButton>
    </SignInLayout>
  );
};
