import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../routes';

export const useGotoMusic = (musicId?: string) => {
  const goto = useGoto();

  return useCallback((_musicId?: string) => goto(routes.music, { musicId: _musicId ?? musicId }), [goto, musicId]);
};
