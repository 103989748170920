import { useCallback } from 'react';

import { type DeleteTextCompositionMutationVariables, useDeleteTextCompositionMutation } from '../../generated';

export const useDeleteTextComposition = (confirm?: string) => {
  const [deleteTextComposition] = useDeleteTextCompositionMutation({
    refetchQueries: ['RenderTemplate'],
  });
  return useCallback(
    (id: DeleteTextCompositionMutationVariables['id']) => {
      if (confirm && !window.confirm(confirm)) return Promise.resolve();
      return deleteTextComposition({ variables: { id } });
    },
    [deleteTextComposition, confirm]
  );
};
