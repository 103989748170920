import { Switch } from '@cofenster/web-components';

import { AUTO_REFRESH_INTERVAL, useExportSettings } from '../../../../contexts/exports';

export const AutoRefreshSwitch = () => {
  const { withPolling, setWithPolling } = useExportSettings();

  return (
    <Switch id="withPolling" checked={withPolling} onChange={(event) => setWithPolling(event.target.checked)} isBlock>
      Auto-refresh ({AUTO_REFRESH_INTERVAL / 1000}s)
    </Switch>
  );
};
