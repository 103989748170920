import { useMemo } from 'react';

import { type CofensterProjectTemplateQueryResult, useCofensterProjectTemplateQuery } from '../../generated';

export type ProjectTemplate = NonNullable<CofensterProjectTemplateQueryResult['data']>['cofensterProjectTemplate'];

export const useCofensterProjectTemplate = (id: string) => {
  const { loading, error, data } = useCofensterProjectTemplateQuery({ variables: { id } });
  const cofensterProjectTemplate = data?.cofensterProjectTemplate;
  return useMemo(() => ({ cofensterProjectTemplate, loading, error }), [cofensterProjectTemplate, loading, error]);
};
