import { useCallback } from 'react';

import { type SortStoryScenesMutationVariables, useSortStoryScenesMutation } from '../../generated';

export const useSortStoryScene = () => {
  const [sortStoryScenes] = useSortStoryScenesMutation({ refetchQueries: ['Story'] });
  return useCallback(
    (ids: SortStoryScenesMutationVariables['ids']) => sortStoryScenes({ variables: { ids } }),
    [sortStoryScenes]
  );
};
