import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../routes';

export const useGotoRenderTemplate = (renderTemplateId?: string) => {
  const goto = useGoto();

  return useCallback(
    (_renderTemplateId?: string) => goto(routes.template, { renderTemplateId: _renderTemplateId ?? renderTemplateId }),
    [goto, renderTemplateId]
  );
};
