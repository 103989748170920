import { useCallback } from 'react';

import { type DeleteTemplateFontAssetMutationVariables, useDeleteTemplateFontAssetMutation } from '../../generated';

export const useDeleteTemplateFontAsset = (confirm?: string) => {
  const [deleteTemplateFontAssetUpload] = useDeleteTemplateFontAssetMutation();
  return useCallback(
    (id: DeleteTemplateFontAssetMutationVariables['id']) => {
      if (confirm && !window.confirm(confirm)) return Promise.resolve();
      return deleteTemplateFontAssetUpload({ variables: { id }, refetchQueries: ['TemplateFontAssets'] });
    },
    [deleteTemplateFontAssetUpload, confirm]
  );
};
