import { useMemo } from 'react';

import { type StoriesQueryResult, type StoriesQueryVariables, useStoriesQuery } from '../../generated';

export type Story = NonNullable<StoriesQueryResult['data']>['stories'][number];

export const useStories = (filter?: StoriesQueryVariables['filter']) => {
  const { loading, error, data } = useStoriesQuery({
    variables: { filter },
  });
  const stories = data?.stories;
  return useMemo(
    () => ({
      stories: stories ?? [],
      loading,
      error,
    }),
    [stories, loading, error]
  );
};
