import { useCallback, useMemo, useState } from 'react';

import type { VideoFit, VideoFormat } from '@cofenster/constants';
import { uploadFile } from '@cofenster/web-components';

import { useInitPublicVideoUpload } from '../api/hooks/videoAsset/useInitPublicVideoUpload';

export const usePublicVideoUpload = () => {
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const initPublicVideoUpload = useInitPublicVideoUpload();

  const upload = useCallback(
    async (file: File, options: { videoFormat?: VideoFormat; videoFit?: VideoFit } = {}) => {
      setProgress(0);

      try {
        const result = await initPublicVideoUpload(options.videoFormat, options.videoFit);

        if (!result.data?.initPublicVideoUpload) {
          throw new Error('Failed to initialize the video upload');
        }

        const { uploadUrl, videoAssetId } = result.data.initPublicVideoUpload;

        await uploadFile(uploadUrl, file, {
          onProgress: ({ progress }) => setProgress(progress),
        });

        return videoAssetId;
      } finally {
        setProgress(undefined);
      }
    },
    [initPublicVideoUpload]
  );
  return useMemo(() => [upload, progress] as const, [upload, progress]);
};
