import type { CSSProperties } from 'react';

import type { VideoFormat } from '@cofenster/constants';

import type { AnimatedCSSProperties } from './AnimatedCSSProperties';
import { IllegalCSSProperties } from './IllegalCSSProperties';

export type Responsive<T extends object> = T & Partial<Record<VideoFormat, T>>;

export type CSSProperty = keyof CSSProperties;

declare module 'react' {
  interface CSSProperties {
    d?: string;
  }
}

export type CSSValue = string | number | undefined;

export type IllegalProperty = (typeof IllegalCSSProperties)[number];

export type AllowedProperties = Omit<CSSProperties, IllegalProperty>;
export type AllowedProperty = keyof AllowedProperties;
// biome-ignore lint/suspicious/noExplicitAny: can legitimately be anything
export type Metadata = { __metadata?: Record<string, any> };

export type Easing =
  | 'linear'
  | `linear(${string})`
  | 'ease'
  | 'ease-in'
  | 'ease-out'
  | 'ease-in-out'
  | `cubic-bezier(${string})`;
export type Keyframes<V extends CSSValue = CSSValue> = Record<`${number}`, { v: V; e?: Easing }>;
export type Timeline<V extends CSSValue = CSSValue> = Keyframes<V> & Metadata;
export type Timelines<V extends CSSValue = CSSValue> = Timeline<V>[];

export type AnimatedValue<V extends CSSValue = CSSValue> = Timeline<V> | Timelines<V>;
export type AnimatedProperties = {
  [P in (typeof AnimatedCSSProperties)[number]]?: AnimatedValue<AllowedProperties[P]>;
};

export type AnimatedProperty = keyof AnimatedProperties;

export type CSSVariable = `--${string}`;
export type CSSVariables = Record<CSSVariable, CSSValue>;

export type StaticStyle = AllowedProperties & CSSVariables;
export type AnimatedStyle = AnimatedProperties & Metadata;

export type SVGElementStyleProperties = Omit<StyleProperties, 'svg'> & { viewBox?: string };
export type SVGPathStyleProperties = Omit<StyleProperties, 'svg'>;
export type SVGDefinition = {
  element?: SVGElementStyleProperties;
  [key: `path_${number}`]: SVGPathStyleProperties;
};

export type StyleProperties = {
  css?: StaticStyle;
  animations?: AnimatedStyle;
  svg?: SVGDefinition;
};

export type StyleDefinition = Responsive<StyleProperties>;

/* type helpers */
const isIllegalProperty = (property: CSSProperty): property is IllegalProperty =>
  IllegalCSSProperties.includes(property as IllegalProperty);

export const isAllowedProperty = (property: CSSProperty): property is AllowedProperty => !isIllegalProperty(property);

export const asTimelines = <V extends CSSValue = CSSValue>(value: AnimatedValue<V>): Timelines<V> => [value].flat(1);
