import { useCallback } from 'react';

import { type DeleteStoryCategoryMutationVariables, useDeleteStoryCategoryMutation } from '../../generated';

export const useDeleteStoryCategory = (confirm?: string) => {
  const [deleteStoryCategory] = useDeleteStoryCategoryMutation({ refetchQueries: ['StoryCategories'] });
  return useCallback(
    (id: DeleteStoryCategoryMutationVariables['id']) => {
      if (confirm && !window.confirm(confirm)) return Promise.resolve();
      return deleteStoryCategory({ variables: { id } });
    },
    [deleteStoryCategory, confirm]
  );
};
