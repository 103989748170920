import type { FC } from 'react';

import { Card, PageHeadline } from '@cofenster/web-components';

import { AccountForm } from '../../../components/accounts/AccountForm';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useGotoAccounts } from '../../../hooks/useGotoAccounts';
import { NoAccessLayout } from '../../NoAccess';

export const AccountCreate: FC = () => {
  const gotoAccounts = useGotoAccounts();

  return (
    <PermissionRestriction has="AccountCreate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle="New account"
        header={{
          topLeft: <PageHeadline mb={0} title="New account" onBack={gotoAccounts} />,
        }}
      >
        <Card>
          <AccountForm withTemplateManagement={true} />
        </Card>
      </AdminLayout>
    </PermissionRestriction>
  );
};
