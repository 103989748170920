// Animation functions copied from https://easings.net (last row)
function easeOutBounce(initialX: number): number {
  const n1 = 7.5625;
  const d1 = 2.75;
  let x = initialX;

  if (x < 1 / d1) {
    return n1 * x * x;
  }
  if (x < 2 / d1) {
    x -= 1.5;
    return n1 * (x / d1) * x + 0.75;
  }
  if (x < 2.5 / d1) {
    x -= 2.25;
    return n1 * (x / d1) * x + 0.9375;
  }
  x -= 2.625;
  return n1 * (x / d1) * x + 0.984375;
}

function easeInBounce(x: number): number {
  return 1 - easeOutBounce(1 - x);
}

function easeInOutElastic(x: number): number {
  const c5 = (2 * Math.PI) / 4.5;

  return x === 0
    ? 0
    : x === 1
      ? 1
      : x < 0.5
        ? -(2 ** (20 * x - 10) * Math.sin((20 * x - 11.125) * c5)) / 2
        : (2 ** (-20 * x + 10) * Math.sin((20 * x - 11.125) * c5)) / 2 + 1;
}

function easeOutElastic(x: number): number {
  const c4 = (2 * Math.PI) / 3;
  return x === 0 ? 0 : x === 1 ? 1 : 2 ** (-10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
}

function easeInElastic(x: number): number {
  const c4 = (2 * Math.PI) / 3;
  return x === 0 ? 0 : x === 1 ? 1 : -(2 ** (10 * x - 10)) * Math.sin((x * 10 - 10.75) * c4);
}

function easeInOutBounce(x: number): number {
  return x < 0.5 ? (1 - easeOutBounce(1 - 2 * x)) / 2 : (1 + easeOutBounce(2 * x - 1)) / 2;
}

const generateLinearValue = (fun: (x: number) => number, numPoints = 30): `linear(${string})` =>
  `linear(${Array.from({ length: numPoints })
    .map((_, i, { length }) => i / (length - 1))
    .map(fun)
    .join(', ')})`;

/**
 * Not supported on Safari
 * @see {@link https://caniuse.com/mdn-css_types_easing-function_linear-function|Can I use linear()}
 */
const LINEAR_FUNCTION_SUPPORTED = false;

export const Easings = {
  // ----- basic
  Linear: 'linear',
  EaseIn: 'ease-in',
  EaseInOut: 'ease-in-out',
  EaseOut: 'ease-out',
  // ----- popular sorted alphabetically
  BackIn: 'cubic-bezier(0.36, 0, 0.66, -0.56)',
  BackInOut: 'cubic-bezier(0.68, -0.6, 0.32, 1.6)',
  BackOut: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
  ...(LINEAR_FUNCTION_SUPPORTED
    ? {
        BounceIn: generateLinearValue(easeInBounce),
        BounceInOut: generateLinearValue(easeInOutBounce),
        BounceOut: generateLinearValue(easeOutBounce),
      }
    : undefined),
  CircIn: 'cubic-bezier(0.55, 0, 1, 0.45)',
  CircInOut: 'cubic-bezier(0.85, 0, 0.15, 1)',
  CircOut: 'cubic-bezier(0, 0.55, 0.45, 1)',
  CubicIn: 'cubic-bezier(0.32, 0, 0.67, 0)',
  CubicInOut: 'cubic-bezier(0.65, 0, 0.35, 1)',
  CubicOut: 'cubic-bezier(0.33, 1, 0.68, 1)',
  ...(LINEAR_FUNCTION_SUPPORTED
    ? {
        ElasticIn: generateLinearValue(easeInElastic),
        ElasticInOut: generateLinearValue(easeInOutElastic),
        ElasticOut: generateLinearValue(easeOutElastic),
      }
    : undefined),
  ExpoIn: 'cubic-bezier(0.7, 0, 0.84, 0)',
  ExpoInOut: 'cubic-bezier(0.87, 0, 0.13, 1)',
  ExpoOut: 'cubic-bezier(0.16, 1, 0.3, 1)',
  QuadIn: 'cubic-bezier(0.11, 0, 0.5, 0)',
  QuadInOut: 'cubic-bezier(0.45, 0, 0.55, 1)',
  QuadOut: 'cubic-bezier(0.5, 1, 0.89, 1)',
  QuartIn: 'cubic-bezier(0.5, 0, 0.75, 0)',
  QuartInOut: 'cubic-bezier(0.76, 0, 0.24, 1)',
  QuartOut: 'cubic-bezier(0.25, 1, 0.5, 1)',
  QuintIn: 'cubic-bezier(0.64, 0, 0.78, 0)',
  QuintInOut: 'cubic-bezier(0.83, 0, 0.17, 1)',
  QuintOut: 'cubic-bezier(0.22, 1, 0.36, 1)',
  SineIn: 'cubic-bezier(0.12, 0, 0.39, 0)',
  SineInOut: 'cubic-bezier(0.37, 0, 0.63, 1)',
  SineOut: 'cubic-bezier(0.61, 1, 0.88, 1)',
  // ----- custom
  SoftBackIn: 'cubic-bezier(0.600, -0.140, 0.765, 0.395)',
  SoftBackInOut: 'cubic-bezier(0.680, -0.220, 0.265, 1.230)',
  SoftBackOut: 'cubic-bezier(0.180, 0.565, 0.385, 1.160)',
} as const;

export const getOppositeEasing = (easing: EasingName): EasingName => {
  if (easing === 'Linear' || easing.endsWith('InOut')) {
    return easing;
  }
  const opposite = easing.endsWith('Out') ? easing.replace(/Out$/, 'In') : easing.replace(/In$/, 'Out');
  if (opposite in Easings) {
    return opposite as EasingName;
  }
  return easing;
};

export type EasingName = keyof typeof Easings;
