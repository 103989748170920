import type { FC } from 'react';

import { Icon, Tooltip, Typography, formatTime, styled } from '@cofenster/web-components';

import { ExpandedRouterLink } from '../../../../components/navigation/ExpandedRouterLink';
import type { ExtendedRenderJob } from '../../../../contexts/exports';
import { StatusIcon } from '../../Export/StatusIcon';

const SubGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: `${theme.spacing(4)} auto`,
  columnGap: theme.spacing(2),
  alignItems: 'center',

  '& > div': { gridRow: 'span 2' },
}));

const TrashIconWrapper = styled('span')(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  zIndex: theme.zIndex.above,
  transform: 'translateY(2.5px)',
}));

export const JobPrimary: FC<ExtendedRenderJob & { withLink?: boolean }> = ({
  job,
  subtitle,
  project,
  account,
  withLink = true,
}) => {
  const primary = `${project?.name ?? 'Deleted project'} ${subtitle ? `(subtitle: ${subtitle.language})` : ''}`;

  return (
    <SubGrid>
      <StatusIcon size="s" backgroundSize="l" status={job.status} />
      <Typography variant="h6" component="p" color="carbon">
        {withLink ? (
          <ExpandedRouterLink to="export" params={{ id: job.id }}>
            {primary}
          </ExpandedRouterLink>
        ) : (
          primary
        )}
        {project?.deletedAt && (
          <Tooltip title={<>This project was (soft-)deleted on {formatTime(project.deletedAt)}</>}>
            <TrashIconWrapper>
              <Icon type="TrashIcon" size="s" />
            </TrashIconWrapper>
          </Tooltip>
        )}
      </Typography>
      <Typography variant="s" component="p" color="grey600">
        {account?.name ?? 'Deleted account'}
        {account?.deletedAt && (
          <Tooltip title={<>This account was (soft-)deleted on {formatTime(account.deletedAt)}</>}>
            <TrashIconWrapper>
              <Icon type="TrashIcon" size="s" />
            </TrashIconWrapper>
          </Tooltip>
        )}
      </Typography>
    </SubGrid>
  );
};
