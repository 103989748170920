import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingSpinner } from '@cofenster/web-components';

import { useInspectVideoAsset } from '../../../api/hooks/videoAsset/useInspectVideoAsset';
import type { InspectRouteParams } from '../../../routes';
import { ErrorContent, ErrorLayout } from '../../Error';
import { InspectLayout } from '../InspectLayout';

import { InspectVideoAssetOriginalContent } from './InspectVideoAssetOriginalContent';

export const InspectVideoAssetOriginal: FC = () => {
  const { id } = useParams() as InspectRouteParams;
  const title = 'Original asset';
  const { data, loading, error } = useInspectVideoAsset(id);

  if (error) {
    return <ErrorLayout title={title} />;
  }

  return (
    <InspectLayout title={title}>
      {data ? <InspectVideoAssetOriginalContent {...data} /> : loading ? <LoadingSpinner /> : <ErrorContent />}
    </InspectLayout>
  );
};
