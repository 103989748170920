import type { FC } from 'react';

import { Card, EmptyState, Headline, RenderingProgress, Spacing } from '@cofenster/web-components';
import type { ExtendedRenderJob } from '../../../../contexts/exports';
import { NoAccessContent } from '../../../NoAccess';

export const CustomerPreview: FC<ExtendedRenderJob> = ({ project, job }) => {
  if (!project) {
    return <NoAccessContent resource="Project" />;
  }

  if (job.status === 'Done') {
    return (
      <EmptyState
        iconType="CheckIcon"
        title="Render successful"
        description="The project was successfully rendered and the customer is able to view, export or publish their video."
      />
    );
  }

  return (
    <Card>
      <Spacing bottom={2}>
        <Headline variant="h4" component="h2">
          Customer preview
        </Headline>
      </Spacing>
      <RenderingProgress status={job.status} step={job.step} stepProgress={job.stepProgress} />
    </Card>
  );
};
