import type { FC } from 'react';

import { Card, PageHeadline } from '@cofenster/web-components';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { MusicForm } from '../../../components/musics/MusicForm';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { useGotoMusics } from '../../../hooks/useGotoMusics';
import { NoAccessLayout } from '../../NoAccess';

export const MusicCreate: FC = () => {
  const gotoMusics = useGotoMusics();

  return (
    <PermissionRestriction has="MusicCreate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle="New Music"
        header={{
          topLeft: <PageHeadline mb={0} title="New Music" onBack={gotoMusics} />,
        }}
      >
        <Card>
          <MusicForm />
        </Card>
      </AdminLayout>
    </PermissionRestriction>
  );
};
