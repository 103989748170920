import type { FC } from 'react';

import { PopoverMenuDivider, PopoverMenuItem } from '@cofenster/web-components';

import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { type StaffUser, useStaffUser } from '../../../contexts/staffUser/useStaffUser';
import { RouterLink } from '../RouterLink';

const StaffUsersLink: FC<{ 'data-testid'?: string }> = (props) => <RouterLink to="staffUsers" {...props} />;

export type ProfilePopoverProps = {
  user: StaffUser;
};

export const ProfilePopover: FC<ProfilePopoverProps> = () => {
  const { signout } = useStaffUser();

  return (
    <>
      <PermissionRestriction has="StaffUserRead">
        <PopoverMenuItem icon="UserListIcon" component={StaffUsersLink} data-testid="staff-users-link">
          Staff Users
        </PopoverMenuItem>

        <PopoverMenuDivider />
      </PermissionRestriction>

      <PopoverMenuItem icon="SignOutIcon" onClick={() => signout()} data-testid="logout-button">
        Log out
      </PopoverMenuItem>
    </>
  );
};
