import { useCallback } from 'react';

import {
  type TranscodeVideoAssetMutationResult,
  type TranscodeVideoAssetMutationVariables,
  useTranscodeVideoAssetMutation,
} from '../../generated';

export type TranscodeVideoAsset = NonNullable<TranscodeVideoAssetMutationResult['data']>['transcodeVideoAsset'];
export type TranscodeVideoAssetId = TranscodeVideoAssetMutationVariables['id'];
export type TranscodeVideoAssetInput = TranscodeVideoAssetMutationVariables['input'];

export const useTranscodeVideoAsset = () => {
  const [TranscodeVideoAsset] = useTranscodeVideoAssetMutation({
    refetchQueries: ['VideoAsset'],
    awaitRefetchQueries: true,
  });

  return useCallback(
    (id: TranscodeVideoAssetId, input: TranscodeVideoAssetInput) => TranscodeVideoAsset({ variables: { id, input } }),
    [TranscodeVideoAsset]
  );
};
