import { useCallback } from 'react';

import { type UpdateTemplateFontAssetMutationVariables, useUpdateTemplateFontAssetMutation } from '../../generated';

export const useUpdateTemplateFontAsset = () => {
  const [updateTemplateFontAsset] = useUpdateTemplateFontAssetMutation();
  return useCallback(
    (
      id: UpdateTemplateFontAssetMutationVariables['id'],
      renderTemplateId: UpdateTemplateFontAssetMutationVariables['renderTemplateId'],
      input: UpdateTemplateFontAssetMutationVariables['input']
    ) =>
      updateTemplateFontAsset({ variables: { id, renderTemplateId, input }, refetchQueries: ['TemplateFontAssets'] }),
    [updateTemplateFontAsset]
  );
};
