import { useCallback } from 'react';

import { type SortStoryCategoriesMutationVariables, useSortStoryCategoriesMutation } from '../../generated';

export const useSortStoryCategories = () => {
  const [sortStoryCategories] = useSortStoryCategoriesMutation({ refetchQueries: ['StoryCategories'] });
  return useCallback(
    (ids: SortStoryCategoriesMutationVariables['ids']) => sortStoryCategories({ variables: { ids } }),
    [sortStoryCategories]
  );
};
