import { useMemo } from 'react';

import { type TeamsQuery, useTeamsQuery } from '../../generated';

export type Team = TeamsQuery['teams'][number];

export const useTeams = (accountId?: string) => {
  const { loading, error, data } = useTeamsQuery({
    variables: { accountId: accountId ?? '' },
    skip: !accountId,
  });
  const teams = data?.teams;

  return useMemo(() => ({ teams: teams ?? [], loading, error }), [teams, loading, error]);
};
