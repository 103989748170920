import { useCallback } from 'react';

import { useGoto } from '@cofenster/web-components';

import { routes } from '../routes';

export const useGotoStory = (storyId?: string) => {
  const goto = useGoto();

  return useCallback((_storyId?: string) => goto(routes.story, { storyId: _storyId ?? storyId }), [goto, storyId]);
};
