import type { FC } from 'react';

import type { VideoFit } from '@cofenster/constants';
import { Select, SelectOption, type SelectProps } from '@cofenster/web-components';

export type RetranscodeVideoFit = 'Automatic' | VideoFit;

export const VideoFitSelect: FC<Partial<SelectProps & { withAutomatic?: boolean }>> = ({ withAutomatic, ...rest }) => (
  <Select {...rest} pb={0.5} id="videoFit" name="videoFit" label="Video fit">
    {withAutomatic && <SelectOption value="Automatic">Automatic</SelectOption>}
    <SelectOption value="Fit">Fit</SelectOption>
    <SelectOption value="Crop">Crop</SelectOption>
  </Select>
);
