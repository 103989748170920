import { useCallback } from 'react';

import { type DeleteRenderTemplateMutationVariables, useDeleteRenderTemplateMutation } from '../../generated';

export const useDeleteRenderTemplate = (confirm: string) => {
  const [deleteRenderTemplate] = useDeleteRenderTemplateMutation({
    refetchQueries: ['RenderTemplates'],
  });
  return useCallback(
    (id: DeleteRenderTemplateMutationVariables['id']) => {
      if (confirm && !window.confirm(confirm)) return Promise.resolve();
      return deleteRenderTemplate({ variables: { id } });
    },
    [deleteRenderTemplate, confirm]
  );
};
