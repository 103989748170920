import { useCallback } from 'react';

import { isCancellationError, uploadFile } from '@cofenster/web-components';
import { useInitPublicAudioUpload } from './useInitPublicAudioUpload';

export const useUploadPublicAudioAsset = () => {
  const initPublicAudioUpload = useInitPublicAudioUpload();

  return useCallback(
    async (
      file: File,
      uploadId: string,
      onProgress: (id: string, progress: number) => unknown,
      signal: AbortController['signal']
    ) => {
      const audioElement = document.createElement('audio');
      audioElement.src = URL.createObjectURL(file);
      const duration = await new Promise<number>((resolve) => {
        audioElement.onloadedmetadata = () => {
          resolve(audioElement.duration);
        };
      });
      const result = await initPublicAudioUpload(duration, file.name);

      if (!result.data?.initPublicAudioUpload) {
        throw new Error('Failed to initialize the audio upload');
      }

      const { uploadUrl, audioAssetId } = result.data.initPublicAudioUpload;

      onProgress(uploadId, 0);

      try {
        await uploadFile(uploadUrl, file, {
          onProgress: ({ progress }) => onProgress(uploadId, progress),
          signal: signal,
        });

        onProgress(uploadId, 1);
      } catch (error) {
        if (isCancellationError(error)) {
          onProgress(uploadId, 0);
          return undefined;
        }
        throw error;
      }

      return audioAssetId;
    },
    [initPublicAudioUpload]
  );
};
