import { useCallback } from 'react';

import { type CloneRenderTemplateMutationVariables, useCloneRenderTemplateMutation } from '../../generated';

export const useCloneRenderTemplate = (confirm?: string) => {
  const [cloneTemplate] = useCloneRenderTemplateMutation({
    refetchQueries: ['RenderTemplates'],
    awaitRefetchQueries: true,
  });
  return useCallback(
    (id: CloneRenderTemplateMutationVariables['id']) => {
      if (confirm && !window.confirm(confirm)) return Promise.resolve();
      return cloneTemplate({ variables: { id } });
    },
    [cloneTemplate, confirm]
  );
};
