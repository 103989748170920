import { useEffect } from 'react';

import { useTemplateFontAssetLazyQuery } from '../../generated';

export const usePollingTemplateFontAsset = (requestedTemplateFontAssetId: string | null | undefined) => {
  const [getTemplateFontAsset, { data, stopPolling }] = useTemplateFontAssetLazyQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
  });

  useEffect(() => {
    return () => stopPolling();
  }, [stopPolling]);

  useEffect(() => {
    if (requestedTemplateFontAssetId) getTemplateFontAsset({ variables: { id: requestedTemplateFontAssetId } });
  }, [requestedTemplateFontAssetId, getTemplateFontAsset]);

  useEffect(() => {
    if (data?.templateFontAsset?.fontUrl) stopPolling();
  }, [data?.templateFontAsset?.fontUrl, stopPolling]);

  return requestedTemplateFontAssetId ? data?.templateFontAsset : null;
};
