import { type FormEventHandler, useCallback, useState } from 'react';

import {
  Button,
  Card,
  EmptyState,
  GridContainer,
  GridItem,
  Headline,
  PageHeadline,
  Spacing,
  TextField,
  Typography,
  useGoto,
} from '@cofenster/web-components';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { routes } from '../../../routes';

export const InspectIndex = () => {
  const [videoAssetId, setVideoAssetId] = useState('');
  const title = 'Inspect assets';
  const goto = useGoto();

  const handleSubmit: FormEventHandler = useCallback(
    (event) => {
      event.preventDefault();
      goto(routes.inspectVideoAsset, { id: videoAssetId });
    },
    [goto, videoAssetId]
  );

  return (
    <AdminLayout documentTitle={title} header={{ topLeft: <PageHeadline mb={0} title={title} /> }}>
      <PermissionRestriction
        has="AssetRead"
        fallback={
          <EmptyState
            iconType="NoOptionIcon"
            title="Missing permissions"
            description="Your current role does not allow you to access assets (lacking permission “AssetRead”). If you need access, please reach out to #team-product on Slack to be granted temporary access or for someone to support with your query."
          />
        }
      >
        <GridContainer>
          <GridItem xs={12} md={6}>
            <Card>
              <Spacing bottom={2}>
                <Headline variant="h3" component="h2">
                  How to
                </Headline>
              </Spacing>

              <Typography variant="l">To inspect a video asset:</Typography>

              <ol>
                <Typography component="li" variant="l">
                  Open the CoManager (typically by impersonating a user having access to said asset)
                </Typography>
                <Typography component="li" variant="l">
                  Head into the project editor
                </Typography>
                <Typography component="li" variant="l">
                  Open the popover menu from a scene thumbnail
                </Typography>
                <Typography component="li" variant="l">
                  Click on “Inspect asset”
                </Typography>
              </ol>

              <Typography variant="l">
                This will open CoAdmin in a new browser tab, and will provide plenty information about the video asset.
                Particularly, it will give you information about the original asset <strong>before</strong> it was
                transcoded during upload, and the ability to transcode it again.
              </Typography>
            </Card>
          </GridItem>

          <GridItem xs={12} md={6}>
            <Card>
              <Spacing bottom={2}>
                <Headline variant="h3" component="h2">
                  Direct search
                </Headline>
              </Spacing>

              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Video asset ID (UUID)"
                  placeholder="e.g. d75c5c31-f18a-4392-a1ac-d65df8e5fdc7"
                  value={videoAssetId}
                  onChange={(event) => setVideoAssetId(event.target.value)}
                  inputProps={{
                    pattern: '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$',
                    required: true,
                  }}
                />

                <Button type="submit" disabled={!videoAssetId}>
                  Inspect
                </Button>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </PermissionRestriction>
    </AdminLayout>
  );
};
