import type { BrandColor, IconType } from '@cofenster/web-components';

import type { RenderJob } from './ExportSettingsProvider';

export function getStatusProps(status: RenderJob['status']) {
  switch (status) {
    case 'Done':
      return { label: 'Done', icon: 'CheckIcon' as IconType, color: 'positiveDark' as BrandColor };
    case 'Failed':
      return { label: 'Failed', icon: 'WarningIcon' as IconType, color: 'negativeDark' as BrandColor };
    case 'InProgress':
      return { label: 'In progress', icon: 'ArrowsClockwiseIcon' as IconType, color: 'blue400' as BrandColor };
    case 'Waiting':
      return { label: 'Waiting', icon: 'HourglassIcon' as IconType, color: 'grey600' as BrandColor };
    default:
      return { label: 'Unknown', icon: 'CodeIcon' as IconType, color: 'grey600' as BrandColor };
  }
}
