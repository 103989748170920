import { useContext } from 'react';

import { ExportsSettingsContext } from './ExportSettingsProvider';

export const useExportSettings = () => {
  const context = useContext(ExportsSettingsContext);

  if (!context) {
    throw new Error('ExportsSettingsProvider missing in tree');
  }

  return context;
};
