import { useCallback, useMemo, useState } from 'react';

import { uploadFile } from '@cofenster/web-components';

import { useInitPublicImageUpload } from '../api/hooks/imageAsset/useInitPublicImageUpload';

export const usePublicImageUpload = () => {
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const initPublicImageUpload = useInitPublicImageUpload();

  const upload = useCallback(
    async (file: File) => {
      setProgress(0);

      try {
        const result = await initPublicImageUpload();

        if (!result.data?.initPublicImageUpload) {
          throw new Error('Failed to initialize the image upload');
        }

        const { uploadUrl, imageAssetId } = result.data.initPublicImageUpload;

        await uploadFile(uploadUrl, file, {
          onProgress: ({ progress }) => setProgress(progress),
        });

        return imageAssetId;
      } finally {
        setProgress(undefined);
      }
    },
    [initPublicImageUpload]
  );
  return useMemo(() => [upload, progress] as const, [upload, progress]);
};
