import { type FC, useEffect, useMemo } from 'react';

import {
  EmptyState,
  GridContainer,
  GridItem,
  Icon,
  LoadingSpinner,
  PageHeadline,
  SearchField,
  SearchResults,
  Select,
  SelectOption,
  useUrlParameter,
} from '@cofenster/web-components';

import { type AccountsFilterOrder, useAccounts } from '../../../api/hooks/account/useAccounts';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { RouterButton } from '../../../components/navigation/RouterButton';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { ErrorContent } from '../../Error';

import { AccountsList } from './AccountsList';

const ACCOUNTS_PER_PAGE = 30;

const useSearch = (accountsPerPage: number) => {
  const [order, setOrder] = useUrlParameter<AccountsFilterOrder>('NAME_ASC', 'order');
  const [page, setPage] = useUrlParameter(1, 'page', String, Number);
  const [search, setSearch] = useUrlParameter('', 'search');
  const filter = useMemo(
    () => ({
      search,
      limit: accountsPerPage,
      offset: Math.max(page - 1, 0) * accountsPerPage,
      order,
    }),
    [search, page, accountsPerPage, order]
  );

  // Whenever the search or order parameters have been changed, reinitialize the
  // page to the first one.
  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    if (search) setPage(1);
  }, [search, order]);

  return useMemo(() => ({ filter, setSearch, page, setPage, setOrder }), [filter, setSearch, page, setPage, setOrder]);
};

export const Accounts: FC = () => {
  const { page, setPage, filter, setSearch, setOrder } = useSearch(ACCOUNTS_PER_PAGE);
  const { paginatedAccounts, loading, error } = useAccounts(filter);

  return (
    <AdminLayout
      documentTitle="Accounts"
      header={{
        topLeft: <PageHeadline mb={0} title="Accounts" />,
        topRight: (
          <PermissionRestriction has="AccountCreate">
            <RouterButton to="accountCreate" data-testid="add-account-button">
              Add account
            </RouterButton>
          </PermissionRestriction>
        ),
      }}
    >
      <PermissionRestriction
        has="AccountRead"
        fallback={
          <EmptyState
            iconType="NoOptionIcon"
            title="Missing permissions"
            description="Your current role does not allow you to access accounts (lacking permission “AccountRead”). If you need access, please reach out to #team-product on Slack to be granted temporary access or for someone to support with your query."
          />
        }
      >
        <GridContainer mb={5}>
          <GridItem xs={12} md={9}>
            <SearchField
              id="accounts"
              name="accounts"
              label="Search"
              placeholder="Account, project or user ID or name"
              search={filter.search || ''}
              onSearch={setSearch}
              minLength={2}
              alwaysExtended
              autoFocus
              fullWidth
            />
          </GridItem>
          <GridItem xs={12} md={3}>
            <Select
              label="Order"
              id="order"
              name="order"
              onChange={(event) => setOrder(event.target.value as AccountsFilterOrder)}
              defaultValue="NAME_ASC"
              value={filter.order}
              pb={0}
            >
              <SelectOption value="NAME_ASC">
                <Icon type="ArrowUpIcon" size="s" />
                &nbsp; Name (A-Z)
              </SelectOption>
              <SelectOption value="NAME_DESC">
                <Icon type="ArrowDownIcon" size="s" />
                &nbsp; Name (Z-A)
              </SelectOption>
              <SelectOption value="CREATED_ASC">
                <Icon type="ArrowDownIcon" size="s" />
                &nbsp; Creation date (chrono)
              </SelectOption>
              <SelectOption value="CREATED_DESC">
                <Icon type="ArrowUpIcon" size="s" />
                &nbsp; Creation date (reverse chrono)
              </SelectOption>
              <SelectOption value="USERS_ASC">
                <Icon type="ArrowUpIcon" size="s" />
                &nbsp; Number of users (0..)
              </SelectOption>
              <SelectOption value="USERS_DESC">
                <Icon type="ArrowDownIcon" size="s" />
                &nbsp; Number of users (..0)
              </SelectOption>
              <SelectOption value="PROJECTS_ASC">
                <Icon type="ArrowUpIcon" size="s" />
                &nbsp; Number of projects (0..)
              </SelectOption>
              <SelectOption value="PROJECTS_DESC">
                <Icon type="ArrowDownIcon" size="s" />
                &nbsp; Number of projects (..0)
              </SelectOption>
            </Select>
          </GridItem>
        </GridContainer>
        <SearchResults id="accounts" search={filter.search ?? undefined} count={paginatedAccounts?.total ?? 0}>
          {error ? (
            <ErrorContent />
          ) : paginatedAccounts ? (
            <AccountsList
              page={page}
              setPage={setPage}
              search={filter.search ?? undefined}
              accounts={paginatedAccounts}
            />
          ) : loading ? (
            <LoadingSpinner />
          ) : null}
        </SearchResults>
      </PermissionRestriction>
    </AdminLayout>
  );
};
