import { useImpersonate as useImpersonateHook } from '../api/hooks/user/useImpersonate';
import { useImpersonate } from './useImpersonate';

export const useImpersonateUser = (userId: string, redirectPath = '') => {
  const impersonateUser = useImpersonateHook();

  const impersonate = useImpersonate(async () => {
    await impersonateUser(userId);
    return { userId };
  }, redirectPath);

  return impersonate;
};
