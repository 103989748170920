import { useMemo } from 'react';

import { type StoryQueryResult, type StoryQueryVariables, useStoryQuery } from '../../generated';

export type Story = NonNullable<StoryQueryResult['data']>['story'];
export type StoryCategory = Story['categories'][number];
export type StoryScene = Story['scenes'][number];

export const useStory = (id: StoryQueryVariables['id']) => {
  const { loading, error, data } = useStoryQuery({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });
  const story = data?.story ?? undefined;
  return useMemo(() => ({ story, loading, error }), [story, loading, error]);
};
