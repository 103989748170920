import { type FC, type PropsWithChildren, useState } from 'react';

import type { VideoFormat } from '@cofenster/constants';
import {
  ContentArea,
  GridContainer,
  GridItem,
  Icon,
  LoadingSpinner,
  preventForwardProps,
  styled,
} from '@cofenster/web-components';
import { RemotionPlayer, RemotionPlayerControls, type RemotionPlayerRef } from '@cofenster/web-remotion-player';

import type { RenderDescription } from '../../../../api/generated';
import { ErrorBoundary } from '../../../../components/ErrorBoundary';

const BorderRadiusBox = styled(
  'div',
  preventForwardProps(['borderRadius'])
)<{ borderRadius?: number }>(({ theme, borderRadius = 2 }) => ({
  width: '100%',
  height: '100%',
  borderRadius: theme.spacing(borderRadius),
  overflow: 'clip',
}));

type TemplateDefinitionPreviewProps = {
  renderDescription: RenderDescription;
  videoFormat: VideoFormat;
  previewBundleUrl: string;
  templateIdentifier: string;
};

export const TemplateDefinitionPreview: FC<TemplateDefinitionPreviewProps> = ({
  renderDescription,
  previewBundleUrl,
  templateIdentifier,
  videoFormat,
}) => {
  const [remotionPlayer, setRemotionPlayer] = useState<RemotionPlayerRef | null>(null);

  if (!renderDescription) return <LoadingSpinner />;

  return (
    <ErrorBoundary fallback={<Icon type="WarningCircleIcon" />}>
      <GridContainer>
        <GridItem xs={12}>
          <ContentArea videoFormat={videoFormat}>
            <ErrorBoundary fallback={<Icon type="WarningCircleIcon" />}>
              <BorderRadiusBox>
                <RemotionPlayer
                  ref={setRemotionPlayer}
                  renderDescription={renderDescription}
                  bundleUrl={previewBundleUrl}
                  templateIdentifier={templateIdentifier}
                />
              </BorderRadiusBox>
            </ErrorBoundary>
          </ContentArea>
        </GridItem>
        <GridItem xs={12}>
          <RemotionPlayerControls
            playerRef={{ current: remotionPlayer }}
            duration={renderDescription.totalDurationInSeconds}
            Wrapper={IgnorePropsOtherThanChildren}
            format={formatSecondsAsMMSSsss}
            showTicksEverySecond
          />
        </GridItem>
      </GridContainer>
    </ErrorBoundary>
  );
};

const formatSecondsAsMMSSsss = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toFixed(3).padStart(6, '0')}`;
};

const IgnorePropsOtherThanChildren: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => <>{children}</>;
