import { useMemo } from 'react';

import { type ProjectQueryResult, type ProjectQueryVariables, useProjectQuery } from '../../generated';

export type Project = NonNullable<ProjectQueryResult['data']>['project'];

export const useProject = (id: ProjectQueryVariables['id']) => {
  const { loading, error, data } = useProjectQuery({ variables: { id } });
  const project = data?.project ?? undefined;
  return useMemo(() => ({ project, loading, error }), [project, loading, error]);
};
