import { useCallback } from 'react';

import { type CreateAccountMutationVariables, useCreateAccountMutation } from '../../generated';

export const useCreateAccount = () => {
  const [createAccount] = useCreateAccountMutation({ refetchQueries: ['Accounts'], awaitRefetchQueries: true });
  return useCallback(
    (input: CreateAccountMutationVariables['input']) => createAccount({ variables: { input } }),
    [createAccount]
  );
};
