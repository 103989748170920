import { useCallback } from 'react';

import { type DeleteAuthProviderMutationVariables, useDeleteAuthProviderMutation } from '../../generated';

export const useDeleteAuthProvider = () => {
  const [deleteAuthProvider] = useDeleteAuthProviderMutation({ refetchQueries: ['Account'] });
  return useCallback(
    (id: DeleteAuthProviderMutationVariables['id']) => {
      return deleteAuthProvider({
        variables: { id },
      });
    },
    [deleteAuthProvider]
  );
};
