import { type ChangeEventHandler, useCallback } from 'react';

import { GridContainer, GridItem, TextField } from '@cofenster/web-components';

import { useExportSettings } from '../../../../contexts/exports';

export const DateFields = () => {
  const { dates, setDates } = useExportSettings();
  const updateAfterDate: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setDates((dates) => ({ after: event.target.value, before: dates.before })),
    [setDates]
  );
  const updateBeforeDate: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setDates((dates) => ({ after: dates.after, before: event.target.value })),
    [setDates]
  );

  return (
    <GridContainer spacing={{ xs: 0, sm: 2 }} mb={-3}>
      <GridItem xs={12} md={6}>
        <TextField
          value={dates.after}
          onChange={updateAfterDate}
          type="date"
          name="afterDate"
          id="afterDate"
          label="After date"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </GridItem>

      <GridItem xs={12} md={6}>
        <TextField
          value={dates.before}
          onChange={updateBeforeDate}
          type="date"
          name="beforeDate"
          id="beforeDate"
          label="Before date"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </GridItem>
    </GridContainer>
  );
};
