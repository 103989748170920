import type { UniqueIdentifier } from '@dnd-kit/core';
import type { FC, ReactNode } from 'react';

import type { SpacingProps } from '../../layout/Spacing';

import { SortableContext, type SortableContextProps } from './SortableContext';
import { SortableItem } from './SortableItem';

export type SortableListProps = SortableContextProps & {
  items: ReactNode[];
  spacing?: SpacingProps['all'];
};

export const SortableList: FC<SortableListProps> = ({
  items,
  ids,
  onSortEnd,
  spacing,
  restrictTo = 'none',
  disabled,
}) => (
  <SortableContext ids={ids} onSortEnd={onSortEnd} restrictTo={restrictTo} disabled={disabled}>
    {items.map((item: ReactNode, index: number) => {
      const id = ids[index] as UniqueIdentifier;
      return <SortableItem key={id} id={id} item={item} spacing={spacing} disabled={disabled} />;
    })}
  </SortableContext>
);
