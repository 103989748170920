import type { FC } from 'react';

import { EmptyState, PageHeadline } from '@cofenster/web-components';

import { AdminLayout } from '../../../components/layout/AdminLayout';
import { RouterButton } from '../../../components/navigation/RouterButton';
import { StoriesSubNavigation } from '../../../components/stories/StoriesSubNavigation';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';

import { StoriesList } from './StoriesList';

export const Stories: FC = () => {
  return (
    <AdminLayout
      documentTitle="Stories"
      header={{
        topLeft: <PageHeadline mb={0} title="Stories" />,
        topRight: (
          <PermissionRestriction has="StoryCreate">
            <RouterButton to="storyCreate">Create Story</RouterButton>
          </PermissionRestriction>
        ),
        bottomLeft: <StoriesSubNavigation />,
      }}
    >
      <PermissionRestriction
        has="StoryRead"
        fallback={
          <EmptyState
            iconType="NoOptionIcon"
            title="Missing permissions"
            description="Your current role does not allow you to access stories (lacking permission “StoryRead”). If you need access, please reach out to #team-product on Slack to be granted temporary access or for someone to support with your query."
          />
        }
      >
        <StoriesList />
      </PermissionRestriction>
    </AdminLayout>
  );
};
