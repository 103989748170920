import { useCallback } from 'react';

import {
  type InitPublicVideoUploadMutationResult,
  type InitPublicVideoUploadMutationVariables,
  useInitPublicVideoUploadMutation,
} from '../../generated';

export type VideoAssetUpload = NonNullable<InitPublicVideoUploadMutationResult['data']>['initPublicVideoUpload'];
export type UploadPost = VideoAssetUpload['uploadPost'];
export type VideoFormat = InitPublicVideoUploadMutationVariables['videoFormat'];
export type VideoFit = InitPublicVideoUploadMutationVariables['videoFit'];
export type VideoCaptureSource = InitPublicVideoUploadMutationVariables['videoCaptureSource'];

export const useInitPublicVideoUpload = () => {
  const [initPublicVideoUpload] = useInitPublicVideoUploadMutation();
  return useCallback(
    (
      videoFormat?: InitPublicVideoUploadMutationVariables['videoFormat'],
      videoFit?: InitPublicVideoUploadMutationVariables['videoFit'],
      videoCaptureSource?: InitPublicVideoUploadMutationVariables['videoCaptureSource']
    ) => initPublicVideoUpload({ variables: { videoFormat, videoFit, videoCaptureSource } }),
    [initPublicVideoUpload]
  );
};
