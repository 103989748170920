import { usePersistedState } from '@cofenster/web-components';

export const useRenderDescriptionCustomText = (key: string) => {
  const [texts, setTexts] = usePersistedState<Record<string, Record<string, string>>>(key, {});

  return {
    texts,
    setText: (textCompositionId: string, textInputId: string, text: string) => {
      setTexts((prev) => ({
        ...prev,
        [textCompositionId]: {
          ...prev[textCompositionId],
          [textInputId]: text,
        },
      }));
    },
  };
};
