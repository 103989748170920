import { useCallback } from 'react';

import { type CreateStorySceneMutationVariables, useCreateStorySceneMutation } from '../../generated';

export const useCreateStoryScene = () => {
  const [createStoryScene] = useCreateStorySceneMutation({ refetchQueries: ['Story'] });
  return useCallback(
    (input: CreateStorySceneMutationVariables['input']) => createStoryScene({ variables: { input } }),
    [createStoryScene]
  );
};
