import type { FC } from 'react';

import { Card, Spacing, Typography } from '@cofenster/web-components';

import type { TextComposition, TextInput } from '../../../../../../../api/hooks/renderTemplate/useRenderTemplate';
import { TextInputForm } from './TextInputForm';

type Props = {
  textComposition: TextComposition;
  textInput?: TextInput;
  deleteTextInput: VoidFunction;
};

export const TextCompositionTextInput: FC<Props> = ({ textComposition, textInput, deleteTextInput }) => (
  <Card spacing={{ all: 2 }}>
    <Typography variant="h4">{textInput ? `Text input: ${textInput.name}` : 'New text input'}</Typography>
    <Spacing top={2}>
      <TextInputForm textComposition={textComposition} textInput={textInput} deleteTextInput={deleteTextInput} />
    </Spacing>
  </Card>
);
