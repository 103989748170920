import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import {
  BlankButton,
  Form,
  FormCheckbox,
  FormSubmitButton,
  FormTextField,
  GridContainer,
  GridItem,
  Headline,
  Icon,
  styled,
  useDisclosure,
} from '@cofenster/web-components';

import type { RenderTemplate, TextComposition } from '../../../../../../api/hooks/renderTemplate/useRenderTemplate';
import { useCreateTextComposition } from '../../../../../../api/hooks/textComposition/useCreateTextComposition';
import {
  type SupportedSceneType,
  useUpdateTextComposition,
} from '../../../../../../api/hooks/textComposition/useUpdateTextComposition';
import { ImageAssetUpload } from '../../../../../../components/upload/ImageAssetUpload';
import { VideoAssetUpload } from '../../../../../../components/upload/VideoAssetUpload';
import { TextCompositionTextInputs } from '../TextCompositionTextInputs';

const supportedSceneTypes: SupportedSceneType[] = ['video', 'image', 'color', 'screenRecording'];

export type Values = {
  name: string;
  nameEN: string;
  previewVideoAssetId: Yup.Maybe<string>;
  previewImageAssetId: Yup.Maybe<string>;
  aeCompositionName: string;
  supportedSceneTypes: SupportedSceneType[];
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  name: Yup.string().trim().required('Please fill in this field'),
  nameEN: Yup.string().trim().required('Please fill in this field'),
  aeCompositionName: Yup.string().trim().required('Please fill in this field'),
  previewVideoAssetId: Yup.string(),
  previewImageAssetId: Yup.string(),
  supportedSceneTypes: Yup.array()
    .of(Yup.string().oneOf(supportedSceneTypes).required('Please fill in this field'))
    .required('Please fill in this field'),
});

const useInitialValues = (textComposition: TextComposition | undefined) => {
  return useMemo<Values>(
    () => ({
      name: textComposition?.name ?? '',
      nameEN: textComposition?.nameEN ?? '',
      previewVideoAssetId: textComposition?.previewVideoAsset?.id,
      previewImageAssetId: textComposition?.previewImageAsset?.id,
      aeCompositionName: textComposition?.aeCompositionName ?? '',
      supportedSceneTypes: textComposition?.supportedSceneTypes ?? [],
    }),
    [textComposition]
  );
};

const useCreate = (template: RenderTemplate) => {
  const createComposition = useCreateTextComposition();

  return useCallback(
    async (data: Values) => {
      try {
        await createComposition(template.id, data);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [template, createComposition]
  );
};

const useUpdate = () => {
  const updateTextComposition = useUpdateTextComposition();

  return useCallback(
    async (textComposition: TextComposition, data: Values) => {
      try {
        await updateTextComposition(textComposition.id, data);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [updateTextComposition]
  );
};

const useSubmit = (template: RenderTemplate, textComposition: TextComposition | undefined) => {
  const createTextComposition = useCreate(template);
  const updateTextComposition = useUpdate();

  return useCallback(
    async (values: Values) => {
      try {
        if (textComposition) return await updateTextComposition(textComposition, values);
        return await createTextComposition(values);
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [textComposition, createTextComposition, updateTextComposition]
  );
};

const ToggleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const Toggle = styled(BlankButton)(({ theme }) => ({
  display: 'flex',

  '&:focus': { outline: 0 },
  '&:focus-visible::after': theme.mixins.focusRing,

  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    zIndex: theme.zIndex.base,
    cursor: 'pointer',
    borderRadius: theme.shape['borderRadius-l'],
  },
}));

const Content = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),

  '&[aria-hidden="true"]': {
    display: 'none',
  },
}));

type Props = {
  renderTemplate: RenderTemplate;
  textComposition?: TextComposition | undefined;
};

export const TextCompositionForm: FC<Props> = ({ renderTemplate, textComposition }) => {
  const initialValues = useInitialValues(textComposition);
  const onSubmit = useSubmit(renderTemplate, textComposition);
  const id = textComposition?.id ?? 'new';
  const { toggleProps, contentProps, isExpanded } = useDisclosure({
    id: `tc-${id}-ti`,
    isExpanded: false,
  });

  return (
    <>
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <GridContainer>
          <GridItem xs={12}>
            <Headline variant="h4">Settings</Headline>
          </GridItem>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={6} md={4}>
                <FormTextField
                  id={`textCompositionName-${id}`}
                  name="name"
                  label="Name (DE)"
                  autoFocus={!textComposition}
                />
              </GridItem>
              <GridItem xs={6} md={4}>
                <FormTextField id={`textCompositionNameEN-${id}`} name="nameEN" label="Name (EN)" />
              </GridItem>
              <GridItem xs={6} md={4}>
                <FormTextField
                  id={`aeCompositionName-${id}`}
                  name="aeCompositionName"
                  label="Remotion text element name"
                />
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem xs={12} md={8} display="flex" gap={2}>
            {supportedSceneTypes.map((supportedSceneType) => (
              <FormCheckbox
                key={supportedSceneType}
                id={`supportedSceneTypes-${id}-${supportedSceneType}`}
                name="supportedSceneTypes"
                value={supportedSceneType}
                label={supportedSceneType}
              />
            ))}
          </GridItem>

          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <GridContainer>
                  <GridItem xs={12}>
                    <Headline variant="h4">Video thumbnail</Headline>
                  </GridItem>
                  <GridItem xs={12}>
                    <VideoAssetUpload name="previewVideoAssetId" />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} md={6}>
                <GridContainer>
                  <GridItem xs={12}>
                    <Headline variant="h4">Override image thumbnail</Headline>
                  </GridItem>
                  <GridItem xs={12}>
                    <ImageAssetUpload name="previewImageAssetId" />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem xs={12}>
            <FormSubmitButton autoDisable>{textComposition ? 'Save' : 'Create'}</FormSubmitButton>
          </GridItem>
        </GridContainer>
      </Form>

      {textComposition && (
        <GridItem xs={12} mt={2}>
          <ToggleContainer>
            <Icon type={isExpanded ? 'CaretDownIcon' : 'CaretRightIcon'} />
            <Toggle {...toggleProps}>
              <Headline variant="h5" component="span">
                Toggle text inputs
              </Headline>
            </Toggle>
          </ToggleContainer>
          <Content {...contentProps}>
            <TextCompositionTextInputs textComposition={textComposition} />
          </Content>
        </GridItem>
      )}
    </>
  );
};
