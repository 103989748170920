import { arrayMoveImmutable as arrayMove } from 'array-move';
import { type FC, type PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

import {
  EmptyState,
  LightTable,
  LoadingSpinner,
  Responsive,
  SortableHandle,
  SortableTable,
  SortableTableRow,
  TableColumnName,
  Typography,
  useSortable,
} from '@cofenster/web-components';

import { useSortStoryCategories } from '../../../../api/hooks/storyCategory/useSortStoryCategories';
import { type StoryCategory, useStoryCategories } from '../../../../api/hooks/storyCategory/useStoryCategories';
import { ExpandedRouterLink } from '../../../../components/navigation/ExpandedRouterLink';
import { PermissionRestriction, useHasPermission } from '../../../../contexts/staffUser/PermissionRestriction';
import { ErrorContent } from '../../../Error';

const useSortableStories = () => {
  const { storyCategories: apiStoryCategories, loading, error } = useStoryCategories();
  const sortStoryCategories = useSortStoryCategories();
  const [storyCategories, setStoryCategories] = useState(apiStoryCategories || []);
  useEffect(() => {
    !loading && setStoryCategories(apiStoryCategories);
  }, [apiStoryCategories, loading]);
  const onSort = useCallback(
    async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const sortedStoryCategories = arrayMove(storyCategories, oldIndex, newIndex);
      setStoryCategories(sortedStoryCategories);
      const data = sortedStoryCategories.map((storyCategory) => storyCategory.id);
      sortStoryCategories(data);
    },
    [storyCategories, sortStoryCategories]
  );
  return useMemo(
    () => ({ storyCategories, loading, error, onSort }) as const,
    [storyCategories, loading, error, onSort]
  );
};

const StoryCategoryTableRowHeader = () => (
  <tr>
    <th>
      <TableColumnName>Name</TableColumnName>
    </th>
    <th>
      <TableColumnName>Stories</TableColumnName>
    </th>
  </tr>
);

const StoryCategoryRow: FC<PropsWithChildren<{ item: StoryCategory; disabled?: boolean }>> = ({ item, disabled }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.id,
    disabled,
  });

  return (
    <SortableTableRow ref={setNodeRef} transform={transform} isDragging={isDragging} transition={transition}>
      <td data-label="Name">
        {!disabled && (
          <Responsive up="sm">
            <SortableHandle {...attributes} {...listeners} />
          </Responsive>
        )}
        <PermissionRestriction
          has="StoryUpdate"
          fallback={
            <Typography variant="h6" color="carbon">
              {item.nameEN}
            </Typography>
          }
        >
          <ExpandedRouterLink to="storyCategory" params={{ storyCategoryId: item.id }}>
            <Typography variant="h6" color="carbon">
              {item.nameEN}
            </Typography>
          </ExpandedRouterLink>
        </PermissionRestriction>
      </td>
      <td data-label="Stories">
        {item.stories.length} {item.stories.length === 1 ? 'story' : 'stories'}
      </td>
    </SortableTableRow>
  );
};

export const StoryCategoryList: FC = () => {
  const { storyCategories, loading, error, onSort } = useSortableStories();
  const canUpdateStories = useHasPermission({ has: 'StoryUpdate' });

  if (error) {
    return <ErrorContent />;
  }

  if (loading && !storyCategories.length) {
    return <LoadingSpinner />;
  }

  if (!storyCategories.length) {
    return (
      <EmptyState iconType="NoOptionIcon" title="No story categories" description="No story categories were found." />
    );
  }

  return (
    <SortableTable
      disabled={!canUpdateStories}
      onSort={onSort}
      items={storyCategories}
      components={{
        Table: LightTable,
        TableRowHeader: StoryCategoryTableRowHeader,
        TableRow: StoryCategoryRow,
      }}
    />
  );
};
