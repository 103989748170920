import type { FC } from 'react';

import {
  CSSGridTable,
  type CSSGridTableConfig,
  Card,
  type ColumnConfig,
  Headline,
  Icon,
  LoadingSpinner,
  PageHeadline,
  Spacing,
  Typography,
  VisuallyHidden,
  Wrappers,
  styled,
} from '@cofenster/web-components';

import {
  type StaffUserRole,
  useStaffUserRolesWithPermissions,
} from '../../../api/hooks/staffUserRole/useStaffUserRolesWithPermissions';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { RolesSubNavigation } from '../../../components/roles/RolesSubNavigation';
import { ErrorLayout } from '../../Error';

type Permission = StaffUserRole['permissions'][number];
type RoleName = StaffUserRole['name'];
type Column = {
  permission: Permission;
  roles: Record<RoleName, boolean>;
};

const nameMap = {
  CustomerSuccessManager: 'CSM',
  AccountExecutive: 'Accout Exec.',
  ContentManager: 'Content Manager',
  TemplateEditor: 'Template Editor',
};

const HeaderCell = styled(Wrappers.HeaderCell)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.above,
  backgroundColor: theme.palette.brand.linen,
  padding: theme.spacing(1, 0),
}));

const HeaderCellInner: FC<{ column: ColumnConfig<Column> }> = ({ column }) => (
  <Typography color="grey600" variant="h6" component="span">
    {column.name}
  </Typography>
);

export const StaffUserRoles: FC = () => {
  const title = 'Employee roles';
  const { staffUserRoles, loading } = useStaffUserRolesWithPermissions();
  const adminRole = staffUserRoles.find((role) => role.name === 'Administrator');

  if (!loading && !adminRole) {
    return <ErrorLayout title={title} />;
  }

  const roleNames = staffUserRoles.map((role) => role.name);
  const permissions = adminRole?.permissions ?? [];
  const allPermissions = permissions.slice(0).sort((a, b) => a.localeCompare(b));
  const data = allPermissions.map((permission) => ({
    permission,
    roles: staffUserRoles.reduce(
      (acc, role) => ({
        ...acc,
        [role.name]: role.permissions.includes(permission),
      }),
      {} as Column['roles']
    ),
  }));

  const columns: CSSGridTableConfig<Column>['columns'] = [
    {
      id: 'permission',
      name: 'Permission',
      cell: ({ item }) => (
        <Typography variant="m" component="code">
          {item.permission}
        </Typography>
      ),
      header: HeaderCellInner,
    },
    ...roleNames
      .filter((name) => name !== 'Administrator')
      .map((name) => ({
        id: name,
        name: name in nameMap ? nameMap[name as keyof typeof nameMap] : name,
        cell: ({ item }: { item: Column }) =>
          item.roles[name] ? (
            <>
              <Icon type="CheckIcon" color="positiveDark" />
              <VisuallyHidden>With permission</VisuallyHidden>
            </>
          ) : (
            <>
              <Icon type="CloseIcon" color="negativeDark" />
              <VisuallyHidden>Without permission</VisuallyHidden>
            </>
          ),
        header: HeaderCellInner,
      })),
  ];

  return (
    <AdminLayout
      documentTitle={title}
      header={{
        topLeft: <PageHeadline mb={0} title={title} />,
        bottomLeft: <RolesSubNavigation />,
      }}
    >
      <Spacing bottom={4}>
        <Card>
          <Spacing bottom={1}>
            <Headline variant="h5" component="h2">
              About roles & permissions
            </Headline>
          </Spacing>

          <Typography variant="l" component="p">
            The CoAdmin application uses a concept of <em>roles</em> and <em>permissions</em>. Each employee is assigned
            a role (such as <em>Engineer</em>, <em>Customer Success Manager</em> or <em>Account Executive</em>
            ), which has a certain set of permissions (like creating accounts, impersonating users or deleting music
            entries).
          </Typography>
        </Card>
      </Spacing>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <CSSGridTable data={data} columns={columns} wrappers={{ ...Wrappers, HeaderCell }} />
      )}
    </AdminLayout>
  );
};
