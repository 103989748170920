import type { FC } from 'react';

import { Card } from '@cofenster/web-components';

import type { Story } from '../../../api/hooks/story/useStory';
import { StoryForm } from '../../../components/stories/story/StoryForm';

type Props = {
  story: Story;
};

export const StoryCard: FC<Props> = ({ story }) => (
  <Card>
    <StoryForm story={story} />
  </Card>
);
