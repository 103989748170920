import camelCase from 'just-camel-case';
import type { CSSProperties } from 'react';

export const stringifyCssProperties = (css: CSSProperties) => {
  const styleDeclaration = document.createElement('div').style;
  Object.assign(styleDeclaration, css);

  return styleDeclaration.cssText.split(/(?<!data:\w+\/[\w+-]+);\s*/).join(';\n');
};

const parseCssLine = (line: string, forbidden: readonly (keyof CSSProperties)[] = []): CSSProperties | never => {
  if (typeof CSSStyleValue !== 'undefined') {
    // capturing group in regex makes it split by first colon only
    const [property, value] = line.replace(/;$/g, '').split(/:(.*)/);

    if (!property) throw new Error(`No property found in line ‘${line}’`);
    if (!value) throw new Error(`No value found in line ‘${line}’`);
    if (forbidden.includes(camelCase(property) as keyof CSSProperties))
      throw new Error(`Property ${property} is not allowed`);

    return {
      [camelCase(property)]: CSSStyleValue.parse(property, value).toString().trim(),
    };
  }
  throw new Error('CSSStyleValue not supported on your browser');
};

export const parseCssProperties = (css: string, forbidden: readonly (keyof CSSProperties)[] = []) => {
  const lines = css
    .split(/;|\n/g)
    // This entire reducer aims at avoiding the splits that inadvertently
    // happened within data URI expressions.
    .reduce((declarations, chunk, index, chunks) => {
      const previousChunk = chunks[index - 1];
      const dataUriRegex = /data:\w+\/\w+/;

      // If the current chunk is something like `background-image:
      // url(data:image/jpeg`, we do nothing, as it will be handled in the
      // next loop iteration.
      if (dataUriRegex.test(chunk)) return declarations;

      // If the previous chunk is something like `background-image:
      // url(data:image/jpeg`, we need to undo that split as this semi-colon
      // was not a declaration delimiter but part of a data URI expression.
      // If that’s the case, recompose the full declaration and add it.
      if (previousChunk && dataUriRegex.test(previousChunk)) declarations.push(`${previousChunk};${chunk}`);
      else declarations.push(chunk);

      return declarations;
    }, [] as string[])
    .map((line) => line.trim())
    .filter(Boolean);

  return lines.reduce<[CSSProperties, Record<number, Error>]>(
    ([cssProps, errors], line, index) => {
      try {
        return [
          {
            ...cssProps,
            ...parseCssLine(line, forbidden),
          },
          errors,
        ];
      } catch (error) {
        return [
          cssProps,
          {
            ...errors,
            [index]: error as Error,
          },
        ];
      }
    },
    [{}, {}]
  );
};
