import { useCallback } from 'react';

import {
  type UpdateStaffUserMutationResult,
  type UpdateStaffUserMutationVariables,
  useUpdateStaffUserMutation,
} from '../../generated';

type Result = NonNullable<UpdateStaffUserMutationResult['data']>['updateStaffUser'];
export type StaffUser = Extract<Result, { __typename?: 'StaffUser' }>;
export type StaffUserError = Extract<Result, { __typename?: 'StaffUserError' }>;

export const useUpdateStaffUser = () => {
  const [updateStaffUser] = useUpdateStaffUserMutation({
    refetchQueries: ['StaffUser', 'StaffUsers'],
  });

  return useCallback(
    (id: UpdateStaffUserMutationVariables['id'], input: UpdateStaffUserMutationVariables['input']) =>
      updateStaffUser({ variables: { id, input } }),
    [updateStaffUser]
  );
};
