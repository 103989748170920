import type { Dispatch, FC, SetStateAction } from 'react';

import { styled } from '@cofenster/web-components';

import type { AnimatedStyle } from '../remotion';

import { AnimationPresetEditor } from './AnimationPresetEditor';

export const AnimationEditor: FC<{
  state: AnimatedStyle;
  setState: Dispatch<SetStateAction<AnimatedStyle>>;
}> = ({ state, setState }) => {
  return (
    <>
      <AnimationPresets type="Enter" state={state} setState={setState} />
      <AnimationPresets type="Exit" state={state} setState={setState} />
    </>
  );
};

const StyledDetails = styled('details')(({ theme }) => ({
  backgroundColor: theme.palette.brand.linen,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
}));

const AnimationPresets: FC<{
  type: string;
  state: AnimatedStyle;
  setState: Dispatch<SetStateAction<AnimatedStyle>>;
}> = ({ type, state, setState }) => {
  const hasAdditionalPresets =
    Object.keys(state.__metadata ?? {}).filter((key) => key.startsWith(type) && key !== type).length > 0;

  return (
    <div>
      <AnimationPresetEditor id={type} state={state} setState={setState} />
      <StyledDetails open={hasAdditionalPresets}>
        <summary>More presets</summary>
        <AnimationPresetEditor id={`${type}_1`} state={state} setState={setState} />
        <AnimationPresetEditor id={`${type}_2`} state={state} setState={setState} />
      </StyledDetails>
    </div>
  );
};
