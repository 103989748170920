import { type FC, useEffect, useMemo } from 'react';

import {
  EmptyState,
  GridContainer,
  GridItem,
  LoadingSpinner,
  PageHeadline,
  SearchField,
  SearchResults,
  useUrlParameter,
} from '@cofenster/web-components';

import { useRenderTemplates } from '../../../api/hooks/renderTemplate/useRenderTemplates';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { RouterButton } from '../../../components/navigation/RouterButton';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';

import { ErrorContent } from '../../Error';
import { TemplatesList } from './TemplatesList';

const TEMPLATES_PER_PAGE = 30;

const useSearch = (templatesPerPage: number) => {
  const [page, setPage] = useUrlParameter(1, 'page', String, Number);
  const [search, setSearch] = useUrlParameter('', 'search');
  const filter = useMemo(
    () => ({
      search,
      limit: templatesPerPage,
      offset: Math.max(page - 1, 0) * templatesPerPage,
    }),
    [search, page, templatesPerPage]
  );

  // Whenever the search parameter has been changed, reinitialize the page to
  // the first one.
  // biome-ignore lint/correctness/useExhaustiveDependencies: safe
  useEffect(() => {
    if (search) setPage(1);
  }, [search]);

  return useMemo(() => ({ filter, setSearch, page, setPage }), [filter, setSearch, page, setPage]);
};

export const Templates: FC = () => {
  const { page, setPage, filter, setSearch } = useSearch(TEMPLATES_PER_PAGE);
  const { paginatedRenderTemplates, loading, error } = useRenderTemplates(filter);

  return (
    <AdminLayout
      documentTitle="Templates"
      header={{
        topLeft: <PageHeadline mb={0} title="Templates" />,
        topRight: (
          <PermissionRestriction has="TemplateCreate">
            <GridContainer spacing={1} justifyContent="flex-end">
              <GridItem>
                <RouterButton to="templateImport" variant="secondary">
                  Import
                </RouterButton>
              </GridItem>
              <GridItem>
                <RouterButton to="templateCreate">Create</RouterButton>
              </GridItem>
            </GridContainer>
          </PermissionRestriction>
        ),
      }}
    >
      <PermissionRestriction
        has="TemplateRead"
        fallback={
          <EmptyState
            iconType="NoOptionIcon"
            title="Missing permissions"
            description="Your current role does not allow you to access templates (lacking permission “TemplateRead”). If you need access, please reach out to #team-product on Slack to be granted temporary access or for someone to support with your query."
          />
        }
      >
        <GridContainer mb={5}>
          <GridItem xs>
            <SearchField
              id="templates"
              name="templates"
              label="Search"
              placeholder="Template name"
              search={filter.search || ''}
              onSearch={setSearch}
              minLength={2}
              alwaysExtended
              autoFocus
              fullWidth
            />
          </GridItem>
        </GridContainer>

        <SearchResults id="templates" search={filter.search ?? undefined} count={paginatedRenderTemplates?.total ?? 0}>
          {error ? (
            <ErrorContent />
          ) : paginatedRenderTemplates ? (
            <TemplatesList
              page={page}
              setPage={setPage}
              search={filter.search ?? undefined}
              templates={paginatedRenderTemplates}
            />
          ) : loading ? (
            <LoadingSpinner />
          ) : null}
        </SearchResults>
      </PermissionRestriction>
    </AdminLayout>
  );
};
