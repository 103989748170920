import { type FC, useCallback, useMemo } from 'react';
import * as Yup from 'yup';

import { EmptyState, Form, FormCheckbox, FormSubmitButton, GridContainer, GridItem } from '@cofenster/web-components';

import type { Story, StoryCategory } from '..';
import { useUpdateStory } from '../../../../api/hooks/story/useUpdateStory';
import { RouterButton } from '../../../../components/navigation/RouterButton';

type Values = {
  storyCategoryIds: string[];
};

const validationSchema: Yup.ObjectSchema<Values> = Yup.object().shape({
  storyCategoryIds: Yup.array().of(Yup.string().required()).required(),
});

const useInitialValues = (story: Story) => {
  return useMemo<Values>(
    () => ({
      storyCategoryIds: story.categories.map((storyCategory) => storyCategory.id),
    }),
    [story]
  );
};

const useOnSubmit = (story: Story) => {
  const updateStory = useUpdateStory();
  return useCallback(
    async ({ storyCategoryIds }: Values) => {
      try {
        await updateStory(story.id, {
          categories: storyCategoryIds || [],
        });
      } catch {
        throw new Error('An unexpected error has occurred');
      }
    },
    [story, updateStory]
  );
};

type Props = {
  story: Story;
  storyCategories: StoryCategory[];
};

export const StoryCategoriesForm: FC<Props> = ({ story, storyCategories }) => {
  const initialValues = useInitialValues(story);
  const onSubmit = useOnSubmit(story);

  if (!storyCategories.length) {
    return (
      <EmptyState
        iconType="NoOptionIcon"
        title="No story categories"
        description="No story categories were found"
        cta={
          <RouterButton variant="secondary" to="storyCategoryCreate">
            Add Story Category
          </RouterButton>
        }
      />
    );
  }

  return (
    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <GridContainer>
        {storyCategories.map((storyCategory) => (
          <GridItem key={storyCategory.id} xs={12}>
            <FormCheckbox
              id={`storyCategoryIds-${storyCategory.id}`}
              name="storyCategoryIds"
              value={storyCategory.id}
              label={`${storyCategory.name} / ${storyCategory.nameEN}`}
            />
          </GridItem>
        ))}

        <GridItem xs={12}>
          <FormSubmitButton autoDisable>Save</FormSubmitButton>
        </GridItem>
      </GridContainer>
    </Form>
  );
};
