import { type CSSProperties, type ChangeEventHandler, type FC, useEffect, useState } from 'react';

import { TextField, Typography, styled } from '@cofenster/web-components';

import { IllegalCSSProperties } from './remotion';
import { parseCssProperties, stringifyCssProperties } from './utils';

const StyledTextField = styled(TextField)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(() => ({
  color: 'red',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
}));

export const CSSInputComponent: FC<{
  value: CSSProperties;
  onChange?: (value: CSSProperties) => unknown;
  forbidden?: readonly (keyof CSSProperties)[];
  rows?: number;
}> = ({ value, onChange, forbidden = IllegalCSSProperties, rows = 10 }) => {
  const [cssText, setCssText] = useState(stringifyCssProperties(value));
  const [[cssProps, errors], setState] = useState(parseCssProperties(cssText, forbidden));

  useEffect(() => {
    setCssText(stringifyCssProperties(value));
  }, [value]);

  useEffect(() => {
    setState(parseCssProperties(cssText, forbidden));
  }, [cssText, forbidden]);
  const onCssTextChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => setCssText(event.target.value);

  const onBlur = () => {
    onChange?.(cssProps);
  };

  return (
    <>
      <StyledTextField
        label="CSS"
        value={cssText}
        onChange={onCssTextChange}
        onBlur={onBlur}
        fullWidth
        multiline
        rows={rows}
      />
      <ul>
        {Object.entries(errors).map(([line]) => (
          <StyledTypography key={line} component="li">
            Invalid styles on line: {+line + 1}
          </StyledTypography>
        ))}
      </ul>
    </>
  );
};
CSSInputComponent.displayName = 'CSSInputComponent';
