import { useFormikContext } from 'formik';
import { type FC, useEffect } from 'react';

export const PreserveFormState: FC<{ id: string }> = ({ id }) => {
  const { isValid, values, setValues, validateOnChange } = useFormikContext();

  // on mount
  useEffect(() => {
    const storedValues = sessionStorage.getItem(`form-state-${id}`);
    if (storedValues) {
      try {
        setValues(JSON.parse(storedValues));
      } catch (error) {
        console.warn('Failed to parse stored form state', error);
      }
    }
  }, [setValues, id]);

  // on every valid change
  useEffect(() => {
    if (isValid) {
      sessionStorage.setItem(`form-state-${id}`, JSON.stringify(values));
    }
  }, [isValid, values, id]);

  if (!validateOnChange) {
    throw new Error('PreserveFormState should only be used with validateOnChange');
  }

  return null;
};
