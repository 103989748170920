import type { FC } from 'react';

import {
  BlankButton,
  Card,
  GridContainer,
  GridItem,
  Headline,
  Icon,
  IconButton,
  styled,
  useDisclosure,
} from '@cofenster/web-components';

import type { RenderTemplate, TextComposition } from '../../../../../api/hooks/renderTemplate/useRenderTemplate';
import { TextCompositionForm } from './TextCompositionForm';

const ToggleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
}));

const Toggle = styled(BlankButton)(({ theme }) => ({
  display: 'flex',

  '&:focus': { outline: 0 },
  '&:focus-visible::after': theme.mixins.focusRing,

  '&::after': {
    content: '""',
    position: 'absolute',
    inset: 0,
    zIndex: theme.zIndex.base,
    cursor: 'pointer',
    borderRadius: theme.shape['borderRadius-l'],
  },
}));

const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  '&[aria-hidden="true"]': { display: 'none' },
}));

// 1. Align the button to the far right of the card
// 2. Make sure the button remains on top of the covering disclosure toggle
const DeleteButton = styled(IconButton)(({ theme }) => ({
  marginLeft: 'auto', // 1
  zIndex: theme.zIndex.above, // 2
}));

type Props = {
  renderTemplate: RenderTemplate;
  textComposition?: TextComposition;
  deleteTextComposition: VoidFunction;
};

export const TextCompositionCard: FC<Props> = ({ renderTemplate, textComposition, deleteTextComposition }) => {
  const { toggleProps, contentProps, isExpanded } = useDisclosure({
    id: `tc-${textComposition?.id ?? 'new'}`,
    isExpanded: false,
  });

  return (
    <Card spacing={{ all: 0 }}>
      <ToggleContainer>
        <Icon type={isExpanded ? 'CaretDownIcon' : 'CaretRightIcon'} />
        <Toggle {...toggleProps}>
          <Headline variant="h4" component="h2">
            {textComposition?.name ?? 'New Text Composition'}
          </Headline>
        </Toggle>

        <DeleteButton
          icon="TrashIcon"
          label="Delete"
          onClick={deleteTextComposition}
          backgroundColor="negative"
          hoverBackgroundColor="negativeDark"
          iconColor="white"
          hoverColor="white"
        />
      </ToggleContainer>

      <Content {...contentProps}>
        <GridContainer>
          <GridItem xs={12}>
            <TextCompositionForm renderTemplate={renderTemplate} textComposition={textComposition} />
          </GridItem>
        </GridContainer>
      </Content>
    </Card>
  );
};
