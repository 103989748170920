import { useCallback } from 'react';

import { useTerminateImpersonationMutation } from '../../generated';

export const useTerminateImpersonation = () => {
  const [terminateImpersonation] = useTerminateImpersonationMutation({
    // Refetch the `Me` query to update the `isImpersonating` field
    refetchQueries: ['Me'],
  });

  return useCallback(() => terminateImpersonation(), [terminateImpersonation]);
};
