import isEqual from 'lodash.isequal';
import { useCallback, useEffect, useState } from 'react';

type UndoableState<T> = {
  state: T;
  setState: (t: T) => void;
  undo: VoidFunction;
  redo: VoidFunction;
  reset: VoidFunction;
  overwrite: (state: T) => void;
  canUndo: boolean;
  canRedo: boolean;
};

export const useUndoableState = <T,>(
  [state, setState]: [T, (t: T) => void],
  compare: (a: T, b: T) => boolean = isEqual
): UndoableState<T> => {
  const [states, setStates] = useState<T[]>([state]);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    if (states[index] && compare(state, states[index])) return;

    const copy = states.slice(0, index + 1);
    copy.push(state);
    setStates(copy);
    const newIndex = copy.length - 1;

    setIndex(newIndex);
  }, [state, states, index, compare]);

  const undo = useCallback(() => {
    const targetIndex = index - 1;
    const targetState = states[targetIndex];

    if (!targetState) return;

    setIndex(targetIndex);
    setState(targetState);
  }, [states, index, setState]);

  const redo = useCallback(() => {
    const targetIndex = index + 1;
    const targetState = states[targetIndex];

    if (!targetState) return;

    setIndex(targetIndex);
    setState(targetState);
  }, [index, states, setState]);

  const reset = useCallback(() => {
    const [resetedState] = states;
    if (!resetedState) return;
    setState(resetedState);
    setStates([resetedState]);
    setIndex(0);
  }, [states, setState]);

  const overwrite = useCallback(
    (state: T) => {
      setState(state);
      setStates([state]);
      setIndex(0);
    },
    [setState]
  );

  return {
    state,
    setState,
    undo,
    redo,
    canUndo: index > 0,
    canRedo: index < states.length - 1,
    reset,
    overwrite,
  };
};
