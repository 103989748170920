import { type FC, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  GridContainer,
  GridItem,
  type SelectChangeEvent,
  Typography,
} from '@cofenster/web-components';

import { type RetranscodeVideoFit, VideoFitSelect } from './VideoFitSelect';
import { type RetranscodeVideoFormat, VideoFormatSelect } from './VideoFormatSelect';

export type RetranscodeAssetDialog = {
  isOpen: boolean;
  closeDialog: () => unknown;
  retranscode: ({
    videoFit,
    videoFormat,
  }: {
    videoFit: RetranscodeVideoFit;
    videoFormat: RetranscodeVideoFormat;
  }) => void;
};

export const RetranscodeAssetDialog: FC<RetranscodeAssetDialog> = ({ isOpen, closeDialog, retranscode }) => {
  const [videoFit, setVideoFit] = useState<RetranscodeVideoFit>('Automatic');
  const [videoFormat, setVideoFormat] = useState<RetranscodeVideoFormat>('Automatic');

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="Retranscode asset">
      <DialogContent>
        <Typography variant="l" component="p">
          Are you sure you want to retranscode this asset? It will replace the video in the scene that references it.
          However, existing and ongoing exports will not be affected.
        </Typography>

        <GridContainer mt={1}>
          <GridItem xs={6}>
            <VideoFitSelect
              withAutomatic
              value={videoFit}
              onChange={(event: SelectChangeEvent<unknown>) => setVideoFit(event.target.value as RetranscodeVideoFit)}
            />
          </GridItem>
          <GridItem xs={6}>
            <VideoFormatSelect
              withAutomatic
              value={videoFormat}
              onChange={(event: SelectChangeEvent<unknown>) =>
                setVideoFormat(event.target.value as RetranscodeVideoFormat)
              }
            />
          </GridItem>
        </GridContainer>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" fullWidth onClick={closeDialog}>
          Cancel
        </Button>
        <Button variant="destructive" fullWidth onClick={() => retranscode({ videoFit, videoFormat })}>
          Retranscode
        </Button>
      </DialogActions>
    </Dialog>
  );
};
