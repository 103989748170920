import type { FC } from 'react';

import { Card, PageHeadline, useGoto } from '@cofenster/web-components';
import { AdminLayout } from '../../../components/layout/AdminLayout';
import { StaffUserForm } from '../../../components/staffUsers/StaffUserForm';
import { PermissionRestriction } from '../../../contexts/staffUser/PermissionRestriction';
import { routes } from '../../../routes';
import { NoAccessLayout } from '../../NoAccess';

export const StaffUserCreate: FC = () => {
  const goto = useGoto();

  return (
    <PermissionRestriction has="StaffUserCreate" fallback={<NoAccessLayout />}>
      <AdminLayout
        documentTitle="New staff user"
        header={{
          topLeft: <PageHeadline mb={0} title="New staff user" onBack={() => goto(routes.staffUsers)} />,
        }}
      >
        <Card>
          <StaffUserForm />
        </Card>
      </AdminLayout>
    </PermissionRestriction>
  );
};
