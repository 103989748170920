import { type FC, useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, Text, useSnackbars } from '@cofenster/web-components';
import { useSendUserInviteEmail } from '../../../api/hooks/user/useSendUserInviteEmail';
import type { User } from '../../../api/hooks/user/useUser';

export type SendUserInviteEmailDialogProps = {
  user: User;
  isOpen: boolean;
  closeDialog: () => unknown;
};

export const SendUserInviteEmailDialog: FC<SendUserInviteEmailDialogProps> = ({ isOpen, closeDialog, user }) => {
  const { openSnackbar } = useSnackbars();
  const sendUserInviteEmail = useSendUserInviteEmail();

  const onAction = useCallback(async () => {
    await sendUserInviteEmail(user.id);
    closeDialog();
    openSnackbar({
      variant: 'success',
      children: `✉️ An invitation was sent to ${user.email}.`,
    });
  }, [user, sendUserInviteEmail, openSnackbar, closeDialog]);

  return (
    <Dialog open={isOpen} onClose={closeDialog} title="Send new invite">
      <DialogContent>
        <Text variant="l" color="grey600" component="p">
          This will send a new invite email, valid for 5 days. Previous invitation links will no longer be valid.
        </Text>
      </DialogContent>

      <DialogActions>
        <Button variant="tertiary" onClick={closeDialog} fullWidth>
          Cancel
        </Button>
        <Button variant="primary" onClick={onAction} fullWidth>
          Resend invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};
