import { useCallback } from 'react';

import { type DeleteStoryMutationVariables, useDeleteStoryMutation } from '../../generated';

export const useDeleteStory = (confirm?: string) => {
  const [deleteStory] = useDeleteStoryMutation({ refetchQueries: ['Stories'] });
  return useCallback(
    (id: DeleteStoryMutationVariables['id']) => {
      if (confirm && !window.confirm(confirm)) return Promise.resolve();
      return deleteStory({ variables: { id } });
    },
    [deleteStory, confirm]
  );
};
