import { Alert, Icon, TextLink } from '@cofenster/web-components';

export const DocsAlert = () => (
  <Alert severity="warning" icon={<Icon type="LightbulbIcon" />} data-testid="docs-alert">
    To learn more about each feature flag and what they do, please refer to the{' '}
    <TextLink
      underline
      href="https://www.notion.so/cofenster/a97ce8e34b2644edb80a8b4254df4332?v=b1bc6baed7064266b04dac69c5d2b13f"
      target="_blank"
    >
      documentation page on Notion
    </TextLink>
    .
  </Alert>
);
