import { MANAGER_URL } from '@cofenster/constants';
import { IconButton, ProfileCircle, Typography, styled } from '@cofenster/web-components';

import { useTerminateImpersonation } from '../../api/hooks/user/useTerminateImpersonation';
import { useUser } from '../../api/hooks/user/useUser';
import { useStaffUser } from '../../contexts/staffUser/useStaffUser';

const Badge = styled('div')(({ theme }) => ({
  color: theme.palette.brand.white,
  backgroundColor: theme.palette.brand.admin,

  borderRadius: '50vw',
  padding: theme.spacing(0.5, 1),

  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),

  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),

  '&:has(a:focus)': theme.mixins.focusRing,

  // When the badge is neither hovered, nor does it hold the focus, hide the
  // middle element (the impersonated user’s name), and reduce the gap between
  // the first and third elements (the badge and the terminate button). Hiding
  // the middle element is *not* done with `display: none` because it is a link
  // that can receive the focus, and that would prevent that.
  '&:not(:hover):not(:has(:focus))': {
    '& > :nth-child(2)': {
      width: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '& > :nth-child(3)': {
      marginLeft: theme.spacing(-2),
    },
  },
}));

const Left = styled('div')(() => ({}));

const Middle = styled('a')(() => ({
  '&:focus': { outline: 0 },
}));

const Right = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(-0.5),
}));

const UserName = styled(Typography)(() => ({
  display: 'block',
  fontWeight: 'bold',
}));

const Initials = styled(ProfileCircle)(() => ({
  fontSize: 14,
}));

const TerminateButton = styled(IconButton)(() => ({
  borderRadius: '50%',
}));

export const ImpersonationBadge = () => {
  const { staffUser } = useStaffUser();
  const impersonatedId = staffUser?.isImpersonating ?? undefined;
  const terminateImpersonation = useTerminateImpersonation();
  const { user: impersonatedUser } = useUser(impersonatedId);

  if (!impersonatedUser) {
    return null;
  }

  const { firstname, lastname } = impersonatedUser;
  const fullname = `${firstname} ${lastname}`;
  const initials = `${firstname?.[0] ?? '?'}${lastname?.[0] ?? '?'}`;

  return (
    <Badge>
      <Left>
        <Initials
          // biome-ignore lint/correctness/noChildrenProp: the `ProfileCircle` component normally picks a single letter; but we want both
          children={[initials]}
          color="light"
          size="large"
        />
      </Left>
      <Middle href={MANAGER_URL} target="_blank" rel="noopener noreferrer">
        <Typography>Impersonating</Typography>
        <UserName>{fullname}</UserName>
      </Middle>
      <Right>
        <TerminateButton label="Terminate" icon="SignOutIcon" iconColor="white" onClick={terminateImpersonation} />
      </Right>
    </Badge>
  );
};
