import { type Dispatch, type FC, type SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import {
  FieldLabel,
  GridContainer,
  GridItem,
  IconButton,
  PopoverMenuItem,
  Typography,
  useSnackbars,
  withPopoverMenu,
} from '@cofenster/web-components';

import type { AnimatedProperties, AnimatedStyle } from '../remotion';
import {
  filterAnimationsInAnimatedProperties,
  joinAnimatedProperties,
  mapAnimationsInAnimatedProperties,
} from '../utils';

import { PresetPicker } from './PresetPicker';
import { type Preset, type PresetOptions, deserializePreset } from './Presets';

export const AnimationPresetEditor: FC<{
  id: string;
  state: AnimatedStyle;
  setState: Dispatch<SetStateAction<AnimatedStyle>>;
}> = ({ id, state, setState }) => {
  const stateSerialized = state?.__metadata?.[id] ?? null;
  const [localStateSerialized, setLocalStateSerialized] = useState<string | null>(stateSerialized);
  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to recompute the value when the state changes
  const stateSerializedTimestamp = useMemo(() => Date.now(), [stateSerialized]);
  // biome-ignore lint/correctness/useExhaustiveDependencies: we want to recompute the value when the state changes
  const localStateSerializedTimestamp = useMemo(() => Date.now(), [localStateSerialized]);
  const { openSnackbar } = useSnackbars();

  const [preset, options] = useMemo(
    () => deserializePreset(localStateSerialized) ?? [null, null],
    [localStateSerialized]
  );

  const setPreset = useCallback(
    (preset: Preset<PresetOptions> | null) => {
      if (preset) {
        setLocalStateSerialized(
          preset.serialize({
            ...preset.defaults,
            ...options,
          })
        );
      } else {
        setLocalStateSerialized(null);
      }
    },
    [options]
  );

  const setOptions = useCallback(
    (options: PresetOptions) => {
      if (preset) {
        setLocalStateSerialized(
          preset.serialize({
            ...preset.defaults,
            ...options,
          })
        );
      }
    },
    [preset]
  );

  const copyToClipboard = async () => {
    if (!localStateSerialized) return;

    const stateToJson = {
      preset: localStateSerialized,
    };

    await navigator.clipboard.writeText(JSON.stringify(stateToJson));
  };

  const pasteFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const parsed = JSON.parse(text);

      if (parsed.preset) {
        setLocalStateSerialized(parsed.preset);
      }
    } catch {
      openSnackbar({
        variant: 'warning',
        children: 'You have not copied a valid preset.',
      });
    }
  };

  useEffect(() => {
    if (localStateSerialized === stateSerialized) return;

    if (stateSerializedTimestamp > localStateSerializedTimestamp) {
      setLocalStateSerialized(stateSerialized);
      return;
    }

    if (stateSerialized && !localStateSerialized) {
      setState((state) => removePreset(id, stateSerialized, state));
    } else if (!stateSerialized && localStateSerialized && preset && options) {
      setState((state) => addPreset(id, localStateSerialized, preset.apply(options), state));
    } else if (stateSerialized && localStateSerialized && preset && options) {
      setState((state) =>
        addPreset(id, localStateSerialized, preset.apply(options), removePreset(id, stateSerialized, state))
      );
    }
  }, [
    localStateSerialized,
    stateSerialized,
    stateSerializedTimestamp,
    localStateSerializedTimestamp,
    preset,
    options,
    id,
    setState,
  ]);

  const PopoverMenuIcon = withPopoverMenu(IconButton, {
    children: (
      <>
        <PopoverMenuItem icon="CopyIcon" onClick={copyToClipboard} disabled={!localStateSerialized}>
          Copy Preset
        </PopoverMenuItem>
        <PopoverMenuItem icon="ClipboardTextIcon" onClick={pasteFromClipboard}>
          Paste Preset
        </PopoverMenuItem>
      </>
    ),
  });

  return (
    <GridContainer direction="column" spacing={1}>
      <GridItem>
        <GridContainer spacing={0} display="flex" alignItems="center" justifyContent="space-between">
          <GridItem>
            <FieldLabel htmlFor={id}>
              <Typography variant="l">{id} animation</Typography>
            </FieldLabel>
          </GridItem>
          <GridItem>
            <PopoverMenuIcon
              icon="ThreeDotsIcon"
              iconColor="carbon"
              iconSize="m"
              label="More actions"
              data-testid="three-dots-button"
            />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem>
        <PresetPicker id={id} value={preset} onChange={setPreset} />
      </GridItem>
      {preset && (
        <GridItem>
          <preset.Editor value={options} onChange={setOptions} />
        </GridItem>
      )}
    </GridContainer>
  );
};

const removePreset = (id: string, presetTag: string, { __metadata, ...animations }: AnimatedStyle) => {
  return {
    __metadata: {
      ...__metadata,
      [id]: undefined,
    },
    ...filterAnimationsInAnimatedProperties(
      animations,
      (timeline) => timeline.__metadata?.preset !== `${id}.${presetTag}`
    ),
  };
};

const addPreset = (
  id: string,
  presetTag: string,
  presetStyle: AnimatedProperties,
  { __metadata, ...animations }: AnimatedStyle
) => {
  return {
    __metadata: {
      ...__metadata,
      [id]: presetTag,
    },
    ...joinAnimatedProperties(
      animations,
      mapAnimationsInAnimatedProperties(presetStyle, (timeline) => ({
        __metadata: {
          preset: `${id}.${presetTag}`,
        },
        ...timeline,
      }))
    ),
  };
};
